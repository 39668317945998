import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Action_Email_Push = (props) => {
    const classes = useStyles();
    const [name] =useState("Envio Mail/Push");
    const [subject,setSubject] =useState(null);
    const [body,setBody] =useState(null);
    const [notificationTitle,setNotificationTitle] =useState(null);
    const [notificationBody,setNotificationBody] =useState(null);

    const handleSubject=(e)=>{
        setSubject(e.target.value);
    }
    const handleBody=(e)=>{
        setBody(e.target.value);
    }
    const handleNotificationTitle=(e)=>{
        setNotificationTitle(e.target.value);
    }
    const handleNotificationBody=(e)=>{
        setNotificationBody(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos accion",props.id,name,subject);
        props.onSetValue(props.id,name,{"subject":subject,"body":body,"push_title":notificationTitle,"push_body":notificationBody},"Asunto:"+subject);
    }
    return(
        <div className="father-header-title">
            <div className="">
                Enviar email/Push
            </div>
            <div>Rellena la información de la accion de enviar un email y envío de notificación push</div>
            <div className={classes.formControl}>
                <TextField
                id="subject"
                label="Asunto del email"
                InputLabelProps={{ shrink: true }}
                helperText={"Asunto del email"}
                onChange={handleSubject}
                value={(subject?subject:"")}
                fullWidth
                />
            </div>
            <div className={classes.formControl}>
                <TextField
                    id="body"
                    label="Texto"
                    InputLabelProps={{ shrink: true }}
                    helperText={"Texto del email"}
                    onChange={handleBody}
                    value={(body?body:"")}
                    fullWidth
                />
            </div>
            <div className={classes.formControl}>
                <TextField
                    id="notif_title"
                    label="Título Notificación"
                    InputLabelProps={{ shrink: true }}
                    helperText={"Título notificación"}
                    onChange={handleNotificationTitle}
                    value={(notificationTitle?notificationTitle:"")}
                    fullWidth
                />
                </div><div className={classes.formControl}>
                <TextField
                id="notif_body"
                label="Texto Notificación"
                InputLabelProps={{ shrink: true }}
                helperText={"Texto de la notificación"}
                onChange={handleNotificationBody}
                value={(notificationBody?notificationBody:"")}
                fullWidth
                />
                </div>
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    startIcon={<EditIcon />}

                    onClick={handleSave}
                        >
                        Aplicar
                </Button>
            </div>
        </div>
)
}

export default Action_Email_Push;
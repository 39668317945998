import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {
    Avatar,
    Dialog,
    Divider,
    Input,
    InputLabel,
    ListItemAvatar,
    Paper,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import PublicPopup from "../../components/PublicPopup";
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OperatorPopup from "../../components/OperatorPopup";
import RelatedPublicResult from "../../components/RelatedPublicResult";
import PublicComponent from "../../components/PublicComponent";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
>
    {value === index && (
    <Box p={3}>
        <div>{children}</div>
        </Box>
    )}
</div>
);
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },

    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    width100:{
        width:'100%'
    },
    conditions:{
        marginBottom:10,
    },
    uniqueBlock:{
        width:'100%'
    },
    uniqueLine:{
        display:'inline-block',
        width:'50%'
    },
    rightText:{
        textAlign:'right'
    },
    padding10:{
        padding:10
    }
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const PublicRecord = (props) => {
    const [visiblePublicPopup,setVisiblePublicPopup] = useState(false);
    const [operatorPopup,setOperatorPopup] =useState(null);
    const [operatorValue,setOperatorValue] =useState("Y");
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);


    const handleChangeValuePopup=(e)=>{
        console.log("value",e);
    }
    const handleClosePublicPopup=()=>{
        setVisiblePublicPopup(false);
    }
    const handleSetOperatorPopup=(id,v)=>{
        setOperatorPopup(null);
        changeItem(condiciones,id,v);
    }
    const changeItem=(arr,id,value)=>{
        let cond=arr;
        cond.filter(elem => elem.id == id)
            .forEach(elem => elem.operator = value);
        setCondiciones(cond);
    }
    const AddCondition=(ev,name,v,textValue,operator,condiciones=null)=>{
        console.log("Añadir condicion");

        let cond=condiciones;
        if(cond==null)
            cond=[];
        let next=1;
        if(cond.length>0){
            next=cond[cond.length-1].id+1;
        }
        cond.push({id:next,ev:ev,name:name,operator:operator,value:v,textValue:textValue});
        console.log("condiciones",cond);
        return cond;
    }
    const handleAddCondition=(id,name,v,textValue)=>{
        console.log("value final",id,name,v,"Y");
        setVisiblePublicPopup(false);
        let cond=AddCondition(id,name,v,textValue,"Y",condiciones);
        setCondiciones(cond);
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [openCancelOrderPopup,setOpenCancelOrderPopup] =useState(false);
    const [userId,setUserId] =useState(null);
    const [name,setName] =useState(null);
    const [value,setValue] =useState(null);
    const [description,setDescription] =useState(null);
    const [phone,setPhone] =useState(null);
    const [visible,setVisible] =useState(null);
    const [result,setResult] =useState(null);
    const [favorite,setFavorite] =useState(null);
    const [email,setEmail] =useState(null);
    const [latitude,setLatitude] =useState(null);
    const [longitude,setLongitude] =useState(null);
    const [rol,setRol] =useState(null);
    const [readOnly,setReadOnly] =useState(false);
    const [basesUser,setBasesUser] =useState([]);
    const [bases,setBases] =useState([]);
    const [modeAdmin,setModeAdmin] =useState(false);
    const [officialBasePrice,setOfficialBasePrice] =useState(null);
    const handleBack=()=>{
        console.log("back");
        if(props.action=="edit")
            history.push(props.parentUrl+"/"+props.id+"/view");
        else
            history.push(props.parentUrl);
        // if(!isRefreshed)
        //     history.goBack();
        // else{
        //     setIsRefreshed(false);
        //     history.goBack();
        //     history.goBack();
        // }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    const handleDescriptionChange=(e)=>{
        setDescription(e.target.value);
    }
    const handleVisibleChange=(e)=>{
        console.log("change visible",e.target.value);
        setVisible(e.target.value);
    }
    const handleFavoriteChange=(e)=>{
        console.log("change favorite",e.target.value);
        setFavorite(e.target.value);
    }

    const [t, i18n] = useTranslation('common');

    const [data,setData]=useState([]);
    const [tab1Value, setTab1Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [condiciones,setCondiciones] =useState(null);

   useEffect(() => {
        let url="";
        // if(window.location.href.substring(window.location.href.length-3,window.location.href.length)=="new"){
        //     setAction("new");
        //     setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":"New" }]);
        //     url=props.parentId+"/"+props.action+"/";
        // }else{
        setAction(props.action);
        if(props.action=="new") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title}]);
            // url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.parentTitle+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        console.log("action",props.action);
        if(props.action=="new") {
            setVisible("0");
            setFavorite("0");
            setCondiciones([]);
        }
        if(props.action!="new") {
            Call_Get(url, storeToken).then((res) => {
                console.log(res);
                // console.log(res.item.itemColumns);
                // setFields(res.item.itemColumns);
                // if(props.action!="newAll") {
                setRecord(res.record);
                setRelateds(res.related);
                setName(res.record.name);
                setValue(JSON.parse(res.record.value));
                setCondiciones(JSON.parse(res.record.value));
                setVisible(res.record.visible.toString());
                setFavorite(res.record.favorite.toString());
                setResult(res.related["result"]);
                console.log("actualizado result");
                if (props.action == "view") {
                    setReadOnly(true);

                }else
                    setReadOnly(false);
                // loadConditions(value);
            });
        }
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props]);
   // const loadConditions=(value)=>{
   //     let obj=JSON.parse(value);
   //     let cond=[];
   //     for(let el in obj){
   //         let item=obj[el];
   //         cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,cond);
   //     }
   //     setCondiciones(cond);
   //     console.log("las condiciones son ",cond);
   // }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(props.parentUrl,props.id);
        history.push(props.parentUrl+"/"+props.id+"/edit");
    }
    const handleSave=()=>{
        console.log("save",props.id);
        console.log("condiciones",condiciones);
        console.log(name);
        console.log(description);
        console.log(condiciones);
        console.log(!props.id);
        // console.log(!props.id || name=="" || description=="" || condiciones.length==0);
        if(name=="" || description=="" || !condiciones || condiciones.length==0){
            alert("Faltan datos: Nombre, descripción o condiciones");
        }else {
            console.log("todo ok");
            let formData = {
                id: props.id,
                name: name,
                description: description,
                value: condiciones,
                visible: visible,
                favorite: favorite
            };
            http_post("public/save", formData, storeToken).then((res) => {
                if (res.status == "ok") {
                    console.log(res);
                    // alert("data saved successfully");
                    history.push(props.parentUrl);
                    // window.location.reload();
                } else
                    alert("There´s been an error during save action");
            });
        }
    }
    const handleChangeCondiciones=(cond)=>{
        console.log("cambio de condiciones",cond);
        setCondiciones(cond);
    }
    const handleCancelSave=()=>{
        console.log("cancel save");
        history.push(props.parentUrl+"/"+props.id+"/view");
    }

    const htmlContenido=()=>{
        return (
            <Grid container spacing={3}>

                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-name"
                        label="Nombre"
                        type="text"
                        autoComplete="off"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.name:(name?name:(record.name?record.name:"")))}
                        onChange={handleNameChange}
                        helperText="Nombre amigable para reutilizar"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-description"
                        label="Descripción"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.description:(description?description:(record.description?record.description:"")))}
                        onChange={handleDescriptionChange}
                        helperText="Descripción"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    {props.action!="view" && <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label">Visible</InputLabel>
                        <RadioGroup
                            aria-label="visible" name="Visible" value={visible} onChange={handleVisibleChange}>
                        <FormControlLabel  value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel  value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>}
                    {props.action=="view" && <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-lng"
                        label="Visible"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?(record.visible==1?"Sí":"No"):(visible?(visible==1?"Sí":"No"):(record.visible?(record.visible==1?"Sí":"No"):"")))}
                        helperText="Visibilidad del elemento"
                        fullWidth
                        />}
                    </Grid>
                <Grid item xs={12}  sm={6}>
                    {props.action!="view" && <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label">Favorito</InputLabel>
                            <RadioGroup
                        aria-label="favorite" name="Favorito" value={favorite} onChange={handleFavoriteChange}>
                        <FormControlLabel  value="1" control={<Radio />} label="Sí" />
                    <FormControlLabel  value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                    </FormControl>}
                {props.action=="view" && <TextField
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    id="filled-lng"
                    label="Favorito"
                    type="default"
                    InputProps={{
                    readOnly: readOnly
                }}
                    value={(props.action=="view"?(record.favorite==1?"Sí":"No"):(favorite?(favorite==1?"Sí":"No"):(record.favorite?(record.favorite==1?"Sí":"No"):"")))}
                    helperText="Visibilidad del elemento"
                    fullWidth
                    />}
            </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" && <div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEdit}
                                                    >
                                                Editar público
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleSave}
                                                    >
                                                    Guardar
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSave}
                                                    >
                                                    Cancelar
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Público Objetivo</h2>
                                                {htmlContenido()}
                                                {/*htmlPublico()*/}
                                            {(value ||props.action=="new" )&& <PublicComponent action={props.action} value={value} onSetCondiciones={handleChangeCondiciones} />}
                                            </Paper>
                                            {props.action=="view" && <div className={classes.contentTab}>
                                                <div className={classes.content}>
                                                    <div className={classes.tabComponent}>
                                                        <AppBar position="static">
                                                            <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="simple tabs example">
                                                                {<Tab className={classes.tab} label={t("tabs.result")} {...a11yProps(0)} />}
                                                            </Tabs>
                                                        </AppBar>
                                                        {<TabPanel value={tab1Value} index={0}>
                                                            {result && <RelatedPublicResult table={result}/>}
                                                        </TabPanel>}
                                                    </div>
                                                </div>
                                            </div>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </main>
            <PublicPopup open={visiblePublicPopup} onChange={handleChangeValuePopup} onClose={handleClosePublicPopup} onSetValue={handleAddCondition}/>
            <OperatorPopup open={operatorPopup} value={operatorValue} onSetValue={handleSetOperatorPopup}/>
                                            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>

    );
}
export default PublicRecord;


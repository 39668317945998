import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from 'react';
import {makeStyles,useTheme} from "@material-ui/core/styles";
import clsx from "clsx";
import {Divider, Paper} from "@material-ui/core";
import {FormatDate, FormatNumber, FormatPrice} from "../providers/Utils/utils";
import useLocalStorage from "../providers/store/localStorage";

//pages

const useStyles = makeStyles((theme) => ({
    scoreType:{
        paddingTop:5,
        textAlign:'right',
        // paddingLeft:'20px'
    },
    scoreValue:{
        fontWeight:"bold",
        fontSize:"20px"
    },
    sectionBackground:{
        // backgroundColor:"#242518",
        marginBottom:"10px",
    },
    sectionTitle:{
        borderBottom:"4px solid #1b2982",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
    },
    sectionNameSmall:{
        textSize:14
    },
    centerItem:{
        textAlign:"center"
    },
    smallScore:{
        "& div":{
            marginTop:'0!important',
            paddingTop:'0!important',
            marginBottom:'0!important',
            paddingBottom:'0!important',
            fontSize:12
        },
        // backgroundColor:"#ff0000",
    },
    bigScore:{
        // display:"inline-block",
        // padding:"10px 40px",
        // margin:"10px"
    },
    gasoleoC:{
        backgroundColor:theme.palette.gasoleoC.main,
        width:'33%',
        '& .scoreTitle': {
            color: theme.palette.gasoleoCColor.main,
        },
    },
    gasoleoA:{
        backgroundColor:theme.palette.gasoleoA.main,
        width:'33%',

    },
    gasoleoAColor: {
        color: theme.palette.gasoleoAColor.main,
    },
    gasoleoBColor: {
        color: theme.palette.gasoleoBColor.main,
    },
    gasoleoCColor: {
        color: theme.palette.gasoleoCColor.main,
    },
    gasoleoB:{
        backgroundColor:theme.palette.gasoleoB.main,
        width:'33%',
        '& .scoreTitle': {
            color: theme.palette.gasoleoBColor.main,
        },
    },
    scoreTitle:{
        fontWeight:"bold",
        fontSize:"16px",
        borderBottomWidth:1,
        borderBottomColor:"#ffffff",
        borderBottomStyle:'solid',
        padding:5,
    },
    scoreDate:{
        borderTopWidth:1,
        borderTopColor:"#ffffff",
        borderTopStyle:'solid',
        padding:5,
        textAlign:'right'
    },
    sectionItem:{
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        '& > *': {
            margin: theme.spacing(1),
            width: '300px',
        },
    },
    noTablePrices:{
        textAlign:'center',
        width:'100%',
        margin:'15px'
    },
    width100:{
        width:'100%'
    }
}));
const PriceTable=(props)=>{
    const classes = useStyles();
    return (<Paper elevation={3} className={props.className}>
        {props.value.data[0] && <div className={clsx((props.size=="small"?classes.smallScore:classes.bigScore))}>
            <div className={clsx(classes.scoreTitle,props.color)}>{props.title} </div>
            <Grid container>
                <Grid item md={7} xs={12}>
                    <div className={clsx(classes.scoreType,props.color)}>0-500 litros</div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div className={clsx(classes.scoreValue,props.color)}>{FormatNumber(props.value.data[0].price,false,true)}</div>
                </Grid>
                <Grid item md={7} xs={12}>
                    <div className={clsx(classes.scoreType,props.color)}>501-1.000 litros</div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div className={clsx(classes.scoreValue,props.color)}>{FormatNumber(props.value.data[1].price,false,true)}</div>
                </Grid>
                <Grid item md={7} xs={12}>
                    <div className={clsx(classes.scoreType,props.color)}>1.001-3.000 litros</div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div className={clsx(classes.scoreValue,props.color)}> {FormatNumber(props.value.data[2].price,false,true)}</div>
                </Grid>
                <Grid item md={7} xs={12}>
                    <div className={clsx(classes.scoreType,props.color)}>3.001-32.000 litros</div>
                </Grid>
                <Grid item md={5} xs={12}>
                    <div className={clsx(classes.scoreValue,props.color)}> {FormatNumber(props.value.data[3].price,false,true)}</div>
                </Grid>
            </Grid>
            <div className={clsx(classes.scoreDate,props.color)}>Ult.Actualización: {FormatDate(props.value.data[0].date)}</div>
        </div>}
    {!props.value.data[0] && <div className={classes.smallScore}>Sin registros</div>}
    </Paper>)
}
const OfficialPrices2 = (props) => {
    const classes = useStyles();
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    const [baseValue,setBaseValue] =useLocalStorage('GSYM_store_base_selected',1);
    const [baseSelectedText,setBaseSelectedText] =useState(null);
    const [foundA,setFoundA] = useState(null);
    const [foundB,setFoundB] = useState(null);
    const [foundC,setFoundC] = useState(null);
    const theme = useTheme();


    useEffect(() => {
    // console.log("aaaa",props.a);
        console.log("props",props);
        console.log("prices",props.prices);
        let a =props.prices.find(element => element.product_id == 1);
        let b=props.prices.find(element => element.product_id == 2);
        let c=props.prices.find(element => element.product_id == 3);
        setFoundA(a);
        setFoundB(b);
        setFoundC(c);

        console.log("A",a);
        console.log("B",b);
        console.log("C",c);
    //     if(baseValue)
    //     if(baseValue==-1){
    //         setBaseSelectedText(null);
    //     }else {
    //         let foundProducts = storeBases.find(element => element.id == baseValue);
    //         if(foundProducts) {
    //             console.log("fff", foundProducts);
    //             setBaseSelectedText(foundProducts.name.toUpperCase());
    //         }
    //     }
    },[]);
    return(
        <div className={classes.width100}>
        {<Grid item md={12} xs={12} className={classes.sectionBackground}>
        <div className={classes.sectionTitle}><div className={(props.size=="small"?classes.sectionNameSmall:classes.sectionName)}>PRECIOS COMBUSTIBLES OFICIALES PARA <b>{props.name.toUpperCase() }</b></div></div>
        <div className={clsx(classes.sectionItem,classes.centerItem)}>
            {foundA && <PriceTable className={classes.gasoleoA} color={classes.gasoleoAColor} title="PRECIO GASÓLEO A" value={foundA}/>}
            {foundB && <PriceTable className={classes.gasoleoB} color={classes.gasoleoBColor} title="PRECIO GASÓLEO B" value={foundB}/>}
            {foundC && <PriceTable className={classes.gasoleoC} color={classes.gasoleoCColor} title="PRECIO GASÓLEO C" value={foundC}/>}

        </div>
            </Grid>}
            </div>
)
}

export default OfficialPrices2;



import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import SearchIcon2 from '@material-ui/icons/FindInPage';
const defaultToolbarStyles = {
    iconButton: {
    },
};
const useStyles = makeStyles((theme) => ({

}));
const MapSearchComponent = (props) => {
    const classes = useStyles();

    const [search,setSearch] =useState(null);
    useEffect(() => {
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleSearchChange=(e)=>{
        setSearch(e.target.value);
    }
    // const { classes } = props;
    const handleSearch=()=>{
        console.log("search",search);
        props.onSearch(search);
    }
        return (
            <div>
                <Grid container>
                    <Grid item xs={8}>
                        <TextField
                            id="filled-name"
                            label="Nombre"
                            type="text"
                            helperText="Nombre del cliente"
                            onChange={handleSearchChange}
                            value={search}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleSearch}>
                        <SearchIcon2 />
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        );

}
export default MapSearchComponent;
import React, {useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import UploadIcon from '@material-ui/icons/Publish';
import {useTranslation} from "react-i18next";
import {http_download} from "../providers/http/http";
import fileDownload from "js-file-download";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
}));
const FieldRecordFile = (props) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [selectedFile,setSelectedFile] =useState(null);
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');

    const onChangeHandler=(e)=>{
        // console.log("change file");
        setSelectedFile(e.target.files[0]);
        props.onSetValue({"item":props.field,"value":e.target.files[0]});
    }
    const handleUploadFile=(e)=>{
        // const data = new FormData();
        // data.append('file', selectedFile);
        // props.onSetValue({"item":props.item.field,"value":selectedFile});
        // console.log(selectedFile);
    }
    const handleDownloadFile=(e,file)=>{
        console.log("download");
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+file;
        http_download(props.source+"-file/"+file,storeToken).then((res)=> {
            fileDownload(res, file.replace("--","."));
        });
    }
    const handleChange=(e)=>{
        console.log("change");
        props.onSetValue({"item":props.item.field,"value":selectedFile});
    }
    useEffect(() => {

    },[props]);
    // if(props.action=="view"|| props.allowEdit=="false"){
        //boton de descargar
        return(
            <div>
                {props.action && (props.action=="view") && <div id={props.field}>
                    <div className="field-view-title" >{props.title}</div>
                    <div className={classes.textField} >{(props.value?props.value.replace("--","."):"")}</div>
                    <IconButton aria-label="download" size="medium" onClick={(e)=>{handleDownloadFile(e,props.value)}}>
                        <DownloadIcon color="secondary" fontSize="inherit" />
                    </IconButton>
                </div>}
                {props.action && props.action=="edit" &&
                    <div>
                        <div className="field-view-title" >{props.title}</div>
                        <div className={classes.textField} >{(props.value?props.value.replace("--","."):"")}</div>
                        <input type="file" name={props.field} onChange={onChangeHandler}/>
                    </div>
                }
                {props.action && props.action=="new" &&
                    <div>
                        <div className="field-view-title" >{props.title}</div>
                        <input type="file" name={props.field} onChange={onChangeHandler}/>
                    </div>
                }
            </div>
        );
    // }else if(props.action=="edit"){
        //boton de descargar o subir otro archivo
        // return(
        //     <div>
        //         <div className="field-view-title" >{props.title}</div>
        //         <div className={classes.textField} >{(props.value?props.value.replace("--","."):"")}</div>
        //         <input type="file" name="file" onChange={onChangeHandler}/>
        //     </div>
        //     );
    // }else if(props.action=="new"){
    //     return (<div>
    //         <div className="field-view-title" >{props.title}</div>
    //         <input type="file" name="file" onChange={onChangeHandler}/>
    //
    //     </div>);
    // }
}

export default FieldRecordFile;
import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const PopupYesNo = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const handleClosePopup=()=>{
        props.onClose(false);
    }
    const handleYesPopup=()=>{
        props.onYes();
    }
    useEffect(()=>{
        setOpenPopup(props.open);
    },[props]);
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
                        {props.description}
                </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleYesPopup} color="primary">
        Sí
        </Button>
        <Button onClick={handleClosePopup} color="primary">
        No
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupYesNo;




import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Promocion_CP_Unico = (props) => {
    const classes = useStyles();
    const [name] =useState("Por cod único");
    const [codigo,setCodigo] =useState(null);

    const handleCodigo=(e)=>{
        setCodigo(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos Promocion", props.id, name, codigo);
        props.onSetValue(props.id, name, codigo,"Prefijo:"+codigo);
    }
    return(
        <div className="father-header-title">
        <div className="">
        Descuento Código Promocional Único para cada cliente
    </div>
        <div>Este código se generará automáticamente para cada usuario</div>
    <div>Escribe un prefijo para generar el código que podrán usar todos los clientes que encajen en la promoción </div>
    <div className={classes.formControl}>
        <TextField
    id="codigo"
    label="Código de Descuento"
    InputLabelProps={{ shrink: true }}
    helperText={"Prefijo de descuento"}
    autoComplete='off'
    onChange={handleCodigo}
    value={(codigo?codigo:"")}
    fullWidth
    />
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Promocion_CP_Unico;
import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import history from "../providers/History/history";
import useLocalStorage from "../providers/store/localStorage";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    blackColor: {
        color:'#000000' ,
        height: '100%',
    },

}));
const BreadCrumbComponent = (props) => {
    const [t, i18n] = useTranslation('common');
    const classes = useStyles();


    function handleClick(event,link) {
        history.push(link);
    }
    return (
        <Breadcrumbs aria-label="breadcrumb" className={(props.theme?"breadcrumb--"+props.theme:"breadcrumb") }>
        <Link color="inherit" href="#" onClick={(e)=>handleClick(e,"/dashboard")}>
            Dashboard
        </Link>
            {props.data.map((item,index)=>{

                if(props.data.length===index+1){
                    let tarr=item.title.split("--");
                    let result=t("pages."+item.title.toLowerCase());
                    if(tarr.length==3){
                        let tit=t("pages."+tarr[0].toLowerCase());
                        let action=t("action."+tarr[1]);
                        let name=tarr[2];
                        result=action +" " +tit +" "+ name;
                    }else if(item.title.toLowerCase()=="new"){
                        result=t("action."+item.title.toLowerCase());
                    }
                    return (<Typography key={index} className={classes.blackColor}>{result}</Typography>);
                }else{
                    return (
                        <Link key={index} color="inherit" href="#" onClick={(e)=>handleClick(e,item.src)}>
                            {t("pages."+item.title.toLowerCase())}
                        </Link>);
                }

            })}

        </Breadcrumbs>
);
}
export default BreadCrumbComponent;


import React,{useState,useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    paperBackground:{
        backgroundColor:"#f0f0f0"}
}));
const ManageTableComponent = (props) => {
    const classes = useStyles();
    const [eventName,setEventName] =useState("");
    const [publicName,setPublicName] =useState(null);
    const [actionName,setActionName] =useState(null);

    useEffect(()=>{
        // console.log(props.value);
        // console.log("evento",JSON.parse(props.value));
        setEventName(JSON.parse(props.value).event[0].name);
        setPublicName(JSON.parse(props.value).public[0].name);
        setActionName(JSON.parse(props.value).actions[0].name);

    },[props]);

    return(
            <Grid container className={classes.paperBackground}>
                    <Grid container>
                        <Grid item xs={3}>Evento:<br/><b>{(eventName?eventName:"")}</b></Grid>
                    <Grid item xs={3}>Público:<br/><b>{(publicName?publicName:"")}</b></Grid>
                    <Grid item xs={3}>Acción:<br/><b>{(actionName?actionName:"")}</b></Grid>
                    </Grid>
            </Grid>
    )
}

export default ManageTableComponent;






import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import {Button} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {CheckToken} from "../../providers/Utils/utils";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appbar:{
        height:'5'
    }
}));

export default function Logout() {
    const classes = useStyles();
    const bc=[{"id":"profile","title":"Profile"}];
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeName,setStoreName] =useLocalStorage('GSYM_store_name');
    const handleButtonBack=e=>{
        console.log("back");
        history.goBack();
    }
    const handleLogout=()=>{
        console.log("logout");
        setStoreToken("");
        setStoreName("");
        history.push("/");
    }
    useEffect(() => {
        CheckToken(storeToken,"logged");
        handleLogout();
    },[]);

    return (
        <div className={classes.root}>

        </div>
    );
}

import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const PopupVerifyBillingAddress = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const [navisionId,setNavisionId] =useState(null);
    const handleClosePopup=()=>{
        console.log("yes");
        props.onClose(false);
    }
    const handleYes=()=>{
        console.log("verificar direccion");
        props.onVerifyBillingAddress(navisionId);
    }
    const handleNo=()=>{
        props.onClose(false);
    }
    const handleNavisionChange=(e)=>{
        console.log(e);
        setNavisionId(e.target.value);
    }
    useEffect(()=>{
        setOpenPopup(props.open);
        setNavisionId(null);
    },[props]);
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Verificar la dirección de facturación con navision</DialogTitle>
        <DialogContent>
                        <div>
                            <div>Verificación de la dirección consultando a navision los datos de facturación</div>
                            <div>Según si encontramos coincidencias las marcamos y se inserta el idNavision para tenerla verificada</div>
                            <TextField
                            id="NavisionId"
                            label="Id Navision"
                            helperText="Inserta aquí el Id de Navision"
                            value={(navisionId?navisionId:"")}
                            onChange={handleNavisionChange}
                            fullWidth
                            />
                        </div>


        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes} color="primary">
        Verificar
        </Button>
        <Button onClick={handleNo} color="primary">
        No
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupVerifyBillingAddress;




import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, Input, InputLabel, Paper, Select} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {
    Call_Get,
    GetProducts,
    PedidoEstado,
    PedidoFranja,
    FormatNumber,
    getClientStatuses
} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import {getWheelDelta} from "leaflet/src/dom/DomEvent";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    }
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const UsersRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const handleMoreClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleEditUser=()=>{
        setName(record.name);
        setEmail(record.email);
        history.push("/Config-users/"+props.id+"/edit");
    }
    const handleCancelUser=()=>{
        // setOpenCancelOrderPopup(true);
        console.log("cancelar");
        history.push("/Config-users/"+props.id+"/view");
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [roles,setRoles] = useState([]);
    const [tables,setTables] =useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [openCancelOrderPopup,setOpenCancelOrderPopup] =useState(false);
    const [orderPrice,setOrderPrice] =useState(null);
    const [numPedido,setNumPedido] =useState(null);
    const [userId,setUserId] =useState(null);
    const [email,setEmail] =useState(null);
    const [name,setName] =useState(null);
    const [rol,setRol] =useState(null);
    const [statusId,setStatusId] =useState(null);
    const [readOnly,setReadOnly] =useState(false);
    const [basesUser,setBasesUser] =useState([]);
    const [bases,setBases] =useState([]);
    const [modeAdmin,setModeAdmin] =useState(false);
    const [officialBasePrice,setOfficialBasePrice] =useState(null);
    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed){
            if(props.action=="view")
                history.push("/"+props.parentId);
            else{
                history.goBack();
            }
        }
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }

    }
    const handleSendPrice=()=>{
        if(orderPrice==0 || orderPrice==null)
            alert("el precio no está establecido");
        else{
            console.log("hay que enviar ",orderPrice);
            let formData={"order_id":record.id,"base_price":0.712,"price":orderPrice};
            http_post("order-set-price",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    history.push("/"+props.parentId);
                    setOpenPopup(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            setOpenPopup(false);
        }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleCloseCancelOrderPopup=()=>{
        setOpenCancelOrderPopup(false);
    }
    const handleSetPrice=(e)=>{
        setOrderPrice(e.target.value);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleTab2Change=(e,newValue)=>{
        setContent2Value(false);
        console.log("change");
        setTab2Value(newValue);
        setContent2Value(true);

    }
    const handleEmailChange=(e)=>{
        // console.log("cambiar email",e.target.value);
        setEmail(e.target.value);
    }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    const handleRolChange=(e)=>{
        setRol(e.target.value);
    }
    const handleStatusChange=(e)=>{
        setStatusId(e.target.value);
    }

    const handleCancelarPedido=()=>{
        let formData={"order_id":record.id};
        http_post("order-admin-cancel",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
                setOpenCancelOrderPopup(false);
            }else{
                alert("There´s been an error during save action");
            }
        });
        setOpenCancelOrderPopup(false);
    }
    const handleDelete=(e)=>{
        console.log("vamos a eliminar el id="+props.id);
        http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleSaveUser=()=>{
        console.log("save user");
        let formData={"email":email,"name":name,"rol_id":rol,"bases":basesUser,"status_id":statusId};
        console.log(formData);
        http_post("config-users/save/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCancelSaveUser=()=>{
        setName(null);
        setEmail(null);
        history.push("/Config-users/"+props.id+"/view");
    }

    const handleView=(e,rowData)=>{
        console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(rowData.id);
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
        // history.push("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.replace("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.refreshState();
    }
    // const handleSavePreGame=(e)=>{
    //     console.log("pregame");
    // }
    // const handleSavePostGame=(e)=>{
    //     console.log("postGame");
    // }
    const handleSave=(e,id)=>{
        //if id then edit
        if(action=="newAll"){
            console.log(id);
            console.log("guardar todos");
            console.log(updateDetail);
            console.log(listItems);
            const postData = new FormData();
            for (var key in listItems) {
                if(typeof listItems[key] === "object")
                    postData.append(key, listItems[key]);
                else
                    postData.append(key, listItems[key]);
            }
            for (var key in updateDetail) {
                if(typeof updateDetail[key] === "object")
                    postData.append(key, updateDetail[key]);
                else
                    postData.append(key, updateDetail[key]);
            }
            http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
                if(res.res=="ok"){
                    history.push("/"+props.parentId);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            return;
        }
        console.log(id);
        var postItems=[];
        const postData = new FormData();
        postData.append("id",id);
        console.log(listItems);
        for (var key in listItems) {
            if(typeof listItems[key] === "object"){
                // console.log("file");
                // console.log(listItems[key]);
                postData.append(key, listItems[key]);
            }else {
                // console.log("no file");
                postData.append(key, listItems[key]);
            }
            // postItems.push({"item":key,"value":listItems[key]});
        }
        // var postData={id:props.id,data:postItems};
        http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleItems=(item)=>{
        console.log("handle items ");
        listItems[item.item]=item.value;
        setListItems(listItems);
    }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['GK- Juan Gomez', 'DF- Luis Martínez', 'MD- Manuel Alen', 'FW - Alejandro Saez']);
    const [right, setRight] = React.useState(['MD- Alonso Moya', 'FW- Gonzalo Montes', 'DF- Rafael Simarro', 'DF- Gabriel del fresno']);

    const [t, i18n] = useTranslation('common');

    const [tab1Value, setTab1Value] = useState(0);
    const [tab2Value, setTab2Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [content2Value,setContent2Value] = useState(true);
    const [data,setData]=useState([]);
    const [stats,setStats] =useState(null);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [pricesPopup,setPricesPopup] =useState([]);
    const CssTextField = withStyles({
        root: {
            marginTop:15,
            '& label.Mui-focused': {
                color: 'green',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'green',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red',
                },
                '&:hover fieldset': {
                    borderColor: 'yellow',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green',
                },
            },
        },
    })(TextField);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleEvolutionNew=(e,id)=>{
        history.push("/tracing/"+id+"/new");
        console.log("tracing "+id);
    }
    const handleOnSetValue=(val)=>{
        setUpdateDetail(val);
    }
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const handleWallet=()=>{
        console.log("wallet");
    };
    const handleFavorite=(e,isFavorite)=>{
        console.log("favorite");
        console.log(isFavorite);
    }
    const handleFriends=(e,value)=>{
        console.log("friends");
        console.log(value);
    }
    const handleLiberarPedido=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const getImporte=()=>{
        if(orderPrice)
            return (<span className={classes.totalPrice}>{(FormatNumber(parseFloat(record.amount)*parseFloat(orderPrice)))} €</span>)
        else
            return (<b><span className={classes.redColor}>precio no establecido</span></b>)
    }

    const variacionImporte=()=>{
        let p=((officialBasePrice-orderPrice)*-1);
        if(p>0)
            return (<b><span className={classes.greenColor}>+{FormatNumber(p*record.amount)} €</span></b>)
        else if(p<0)
            return (<b><span className={classes.redColor}>{FormatNumber(p*record.amount)} €</span></b>)
        else
            return (<b><span className={classes.greenColor}>Coincide con los precios oficiales</span></b>)
    }
    const getClassDifference=()=>{
        let p=getPriceDifference();
        if(p>0)
            return classes.greenColor;
        else
            return classes.redColor;
    }
    const getPriceDifference=()=>{
        let p;
        if(record.base_price>0)
            p=record.base_price;
        else
            p=officialBasePrice;
        return ((p-orderPrice)*-1).toFixed(3);
    }
    const handleLiberarPedidoSalir=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }

   useEffect(() => {
        let url="";
        setAction(props.action);
        if(props.action=="newAll") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action}]);
            url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        Call_Get(url,storeToken).then((res)=> {
            console.log(res);
            // console.log(res.item.itemColumns);
            // setFields(res.item.itemColumns);
            // if(props.action!="newAll") {
            setRecord(res.record);

            setRelateds(res.related);
            setRoles(res.related["roles"]);
            setBases(res.related["bases"]);
            setModeAdmin(res.related["mode"]);
            setRol(res.record.rol_id);
            setBasesUser(res.related["myBases"]);
            setName(res.record.name);
            setEmail(res.record.email);
            if(props.action=="view")
                setReadOnly(true);
            else
                setReadOnly(false);
            // // setSessions(res.item.tables.sessions);
            // setTables(res.item.tables);
            // console.log(res);
        });
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChangeBases = (event) => {
        console.log("base change",event.target.value,event);
        setBasesUser(event.target.value);
    };
    const htmlContenido=()=>{
        return (
            <Grid container spacing={3}>

                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-email"
                        label="Email"
                        type="email"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.email:(email?email:(record.email?record.email:"")))}
                        onChange={handleEmailChange}
                        helperText="Email del usuario"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-email"
                        label="Nombre"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.name:(name?name:(record.name?record.name:"")))}
                        onChange={handleNameChange}
                        helperText="Nombre del usuario"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label">Rol</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            inputProps={{ readOnly: readOnly }}
                            value={(rol?rol:(record.rol_id?record.rol_id:null))}
                            onChange={handleRolChange}
                        >
                                {roles.map(menuItem=>{
                                        return (
                                            <MenuItem key={menuItem.id} value={menuItem.id}>
                                            {menuItem.name}
                                            </MenuItem>
                                    );
                                    })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">Bases</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={basesUser}
                            onChange={handleChangeBases}
                            input={<Input />}
                            inputProps={{ readOnly: readOnly }}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            helperText="Bases a las que tiene acceso"
                            >
                            {bases.map((item) => (
                                <MenuItem key={item.id} value={item.name} >
                                    <Checkbox checked={(basesUser?(basesUser.indexOf(item.name) > -1):false)} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label">Estado</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            inputProps={{ readOnly: !modeAdmin || readOnly }}
                            value={(statusId?statusId:(record.status_id?record.status_id:null))}
                            onChange={handleStatusChange}
                                >
                                {getClientStatuses().map(menuItem=>{
                                        return (
                                            <MenuItem key={menuItem.id} value={menuItem.id}>
                                            {menuItem.name}
                                            </MenuItem>
                                    );
                                    })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent mode="profile" selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                            {(record.operator_id?(record.operator_id==storeAdminId?<span><span className={classes.blockUser}>{"Tú"}</span> tienes bloqueado este registro <a href="#" onClick={handleLiberarPedidoSalir}>Liberar y salir</a></span>:<span><span className={classes.blockUser}>{record.operator_name}</span> ha bloqueado el registro <a href="#" onClick={handleLiberarPedido}>Liberar</a></span>):"")}
                                        </div>
                                        {props.action=="view" && modeAdmin==true && <div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEditUser}
                                                    >
                                                    <span className="textButton">Editar usuario</span>
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={handleSaveUser}
                                                    >
                                                    <span className="textButton">Guardar usuario</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSaveUser}
                                                    >
                                                    <span className="textButton">Cancelar guardar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Permisos</h2>
                                                {htmlContenido()}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </main>
        </div>

    );
}
export default UsersRecord;


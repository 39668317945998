import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
import {http_get} from "../providers/http/http";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
}));
const FieldBoolean = (props) => {
    const classes = useStyles();
    const [checkedB,setCheckedB]=useState(null);

    const handleChange=(e)=>{
        console.log("change");
        if(checkedB==null){
            setCheckedB(!props.record);
            props.onSetValue({"item":props.item.field,"value":!props.record});
            // props.onSetValue("1");
        }else {
            setCheckedB(!checkedB);
            props.onSetValue({"item":props.item.field,"value":!checkedB});
            // props.onSetValue("2");
        }


    }
    useEffect(() => {

    },[]);

    if(props.action=="view"){
        return(
            <div id={props.item.field}>
                <div className="field-view-title" >{props.item.title}</div>
                <FormControlLabel disabled control={<Checkbox name={props.item.field} checked={(props.record==1?true:false)}/>}  />
            </div>
        );
    }else if(props.action=="edit"){
        return(
            <div>
            <div className="field-edit-title" >{props.item.title}</div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={(checkedB==null?(props.record?props.record:false):checkedB)}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                    />
                }
            />
            </div>
        );
    }
}

export default FieldBoolean;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {GetRelDates} from "../providers/Utils/utils";
import moment from "moment";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_FechaRegistro = (props) => {
    const classes = useStyles();
    const [name] =useState("Por Fecha de registro");
    const [cp,setCp] =useState(null);
    const [dateFromValue,setDateFromValue] =useState(new Date());
    const [dateFromRelValue,setDateFromRelValue] =useState(null);
    const [dateToRelValue,setDateToRelValue] =useState(null);

    const [dateMode,setDateMode] =useState("absolute");
    const [dateToValue,setDateToValue] =useState(new Date());
    const [relDates] =useState(GetRelDates());
    const handleSave=()=>{
        if(dateMode=="absolute") {
            let date={"from":moment(dateFromValue).format("YYYY-MM-DD"),"to":moment(dateToValue).format("YYYY-MM-DD")};
            console.log("aplicamos evaluador", props.id, name, JSON.stringify(date));
            props.onSetValue(props.id, name, JSON.stringify(date),"Desde: "+moment(date.from).format("DD/MM/YYYY") +", Hasta: "+moment(date.to).format("DD/MM/YYYY"));
        }else{
            let dateRel={"fromRel":dateFromRelValue,"toRel":dateToRelValue};
            props.onSetValue(props.id, name, JSON.stringify(dateRel),"Desde: "+dateRel.fromRel +", Hasta: "+dateRel.toRel);
        }
    }
    const handleDateFromChange=(v)=>{
        setDateFromValue(v);
    }
    const handleDateToChange=(v)=>{
        setDateToValue(v);
    }
    const handleDateModeChange=(v)=>{
        setDateMode(v.target.value);
    }
    const handleChangeFromRel=(e)=>{
        setDateFromRelValue(e.target.value);
    }
    const handleChangeToRel=(e)=>{
        setDateToRelValue(e.target.value);
    }

    return(
        <div className="father-header-title">
        <div className="">
            Busca clientes por fecha de registro
        </div>
        <div>Obtenemos datos de clientes donde su código postal corresponda con una de sus direcciones de entrega</div>
        <Grid container spacing={3} className={classes.formControl}>
            <Grid item xs={12}>
                <FormControl component="fieldset">
                <FormLabel component="legend">Modo de fechas</FormLabel>
                <RadioGroup aria-label="mode" name="dateMode" value={dateMode} onChange={handleDateModeChange}>
                <FormControlLabel value="absolute" control={<Radio />} label="Fechas Absolutas" />
                <FormControlLabel value="relative" control={<Radio />} label="Fechas Relativas" />
                </RadioGroup>
                </FormControl>
            </Grid>
            {dateMode=='absolute' && <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        readOnly={props.action=="view"}
                        id="date-picker-inline"
                        label="Fecha Desde"
                        value={dateFromValue}
                        onChange={handleDateFromChange}
                        helperText={"Fecha desde"}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

            </Grid>}
            {dateMode=='absolute' && <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        readOnly={props.action=="view"}
                        id="date-picker-inline"
                        label="Fecha Hasta"
                        value={dateToValue}
                        helperText={"Fecha hasta"}
                        onChange={handleDateToChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>}
            {dateMode=="relative" && <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Fecha Rel Desde
                </InputLabel>
                <Select
                value={dateFromRelValue}
                onChange={handleChangeFromRel}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ 'aria-label': 'fromrel' }}
                autoWidth
            >
                {relDates && relDates.map((el)=>{
                    return (
                        <MenuItem value={el}>{el}</MenuItem>
                )
                })}
            </Select>
            <FormHelperText>Fecha relativa ej(D0 ->Hoy, D-5 ->hace 7 dias) </FormHelperText>
            </FormControl>
            </Grid>}
            {dateMode=="relative" && <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Fecha Rel Hasta
                    </InputLabel>
                    <Select
                        value={dateToRelValue}
                        onChange={handleChangeToRel}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'fromrel' }}
                        autoWidth
                        >
                        {relDates && relDates.map((el)=>{
                            return (
                                <MenuItem value={el}>{el}</MenuItem>
                        )
                        })}
                    </Select>
                    <FormHelperText>Fecha relativa ej(D0 ->Hoy, D-5 ->hace 7 dias) </FormHelperText>
                </FormControl>
            </Grid>}
        </Grid>
        <div>
        <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={<EditIcon />}
            onClick={handleSave}
                >
            Aplicar
        </Button>
            </div>

    </div>
)
}

export default Evaluador_FechaRegistro;
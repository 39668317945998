

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,UserRol} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from "react-i18next";
import {Button, Dialog} from "@material-ui/core";
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";

import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CustomToolbar from "../../components/CustomToolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    }

}));

const BaseTableUsers = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }

    // const handleResendClient=(selectedRows,d)=>{
    //     // console.log("main view",selectedRows);
    //     console.log("reenviamos el correo de verificacion");
    //     // console.log(table.data(selectedRows.data[0].dataIndex));
    //     console.log("id a reenviar",d[selectedRows.data[0].dataIndex].data[0]);
    //     console.log("id a reenviar",d[selectedRows.data[0].dataIndex].data[1]);
    //     setResendEmail(d[selectedRows.data[0].dataIndex].data[1]);
    //     setAddUserPopup(true);
    //     // history.push(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
    // }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");
    }
    const handleNewUser=()=>{
        setAddUserPopup(true);
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [addUserPopup,setAddUserPopup] =useState(false);
    const [email,setEmail] =useState(null);
    const [password,setPassword] =useState(null);
    const [name,setName] =useState(null);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'none',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},
        customToolbar: () => {
            return (
                <CustomToolbar onNewClick={handleNewUser} />
        );
        },
        // customToolbarSelect: (selectedRows,data) => {
        //     return (
        //
        //         <CustomClientsToolbar mode="not-verified" selectedRows={selectedRows} onRowResend={()=>handleResendClient(selectedRows,data)}/>
        // );
        // },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleEmailChange=(e)=>{
        console.log("emailchange");
        setEmail(e.target.value);
    }
    const handlePwdChange=(e)=>{
        console.log("pwd change");
        setPassword(e.target.value);
    }
    const handleNameChange=(e)=>{
        console.log("name change");
        setName(e.target.value);
    }
    const handleSendEmail=()=>{
        console.log("send email");
        let formData={"email":email,"password":password,"name":name};
        http_post("Config-users-new",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
                setAddUserPopup(false);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCloseAddUserPopup=()=>{
        console.log("close popup");
        setAddUserPopup(false);
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log(res);

            let foundRol=res.columns.findIndex(element=>element.name=="rol_id");
            if(foundRol!=-1){
                res.columns[foundRol]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {UserRol(value)}
                        </div>
                );
                }
            }
            setTable(res);

        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  mode="profile" selected={pageSelected} />
    <main className="page-main">
        <div className={classes.toolbar}/>
    <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
    <div className={(table.data==null?"circular-position":"is-hidden")}>
<CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
    title={t("pages."+props.title)}
    data={table.data}
    columns={table.columns}
    options={tableOptions}
    />
    </div>
    </div>
    <div className={clsx(classes.contentBody,"page-footer")}>
<Footer/>
    </div>
    <Dialog
    open={(addUserPopup?addUserPopup:false)}
    onClose={handleCloseAddUserPopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Añadir usuario al panel de administración</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
        <div>Esta funcionalidad no está en su versión definitiva.Crearemos un usuario con las credenciales de aqui debajo (email y contraseña)</div>
    <div>Cuando este finalizada esta funcionalidad el mismo usuario podrá crear su contraseña de panel</div>
    <div className={classes.centerText}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField className={classes.whiteText}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Email"}
            name="email"
            autoComplete="email"
            onChange={handleEmailChange}
            value={email}
            autoFocus
            />
            <TextField className={classes.whiteText}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Name"}
            name="name"
            autoComplete="name"
            onChange={handleNameChange}
            value={name}
            autoFocus
            />
        <TextField className={classes.whiteText}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Contraseña"}
            name="password"
            autoComplete="off"
            onChange={handlePwdChange}
            value={password}
            autoFocus
            />
    </form>

    <Button variant="contained" onClick={handleSendEmail} color="primary">
        {"Añadir usuario"}
        </Button>
        </div>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAddUserPopup} color="primary">
        {t("buttons.cancel")}
</Button>
    </DialogActions>
    </Dialog>
    </main>
    </div>
);
}
export default BaseTableUsers;
import React, {createRef, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useLocalStorage from "../providers/store/localStorage";
import history from "../providers/History/history";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {useTranslation} from "react-i18next";
import myTheme from "../theme/theme";
import {Paper} from "@material-ui/core";
import theme from "../theme/theme";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import cross from "../res/cross.png";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Button from "@material-ui/core/Button";
import ButtonCalificationComponent from "./ButtonCalificationComponent";
import {format} from "date-fns";
import Tooltip from '@material-ui/core/Tooltip';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fieldTitle: {
        fontSize: "12px",
        color: myTheme.palette.titleFields.main,
        padding: "0!important"
    },
    fieldValue: {
        fontSize: "12px",
        color: "#ffffff",
        padding: "5px 0!important"
    },
    size_xs: {
        width: "40px",
        height: "35px"
    },
    size_md:{
        width:"80px",
        height:"35px"
    },
    size_xl: {
        width: "100%"
    },
    link:{
        color:myTheme.palette.primary.main,
        textDecoration:'none',
        "&:hover": {
            textDecoration:'underline'
        }
    },
    text:{
        color:"#ffffff",
    },
    cross:{
        width:"15px"
    },
    thumb:{
        width:"15px",
        color:"#00ff00"
    },
    calification:{
        width:"40px",
        minWidth:"40px"
    },
    jsonField:{
        display:"inline-block"

    },
    jsonTitle:{
        // display:"inline-block"
        margin:"5px"
    },
    jsonValue:{
        display:"inline-block"
    }
}));
const TableViewComponent = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useLocalStorage(true);
    const [storeName] =useLocalStorage('RV_store_prof_name');
    const matches = useMediaQuery('(min-width:800px)');
    const [t, i18n] = useTranslation('common');
    const [checked, setChecked] = React.useState([]);
    const [isChecked, setIsChecked] = useState({});
    const [itemField,setItemField] =useState({});
    const [test,setTest] =useState({});

    const inputRef = useRef();
    let items=[];
    let i=0;

    const handleChange = (event,id) => {
        var tmpChecked=[];
        tmpChecked=checked;
        tmpChecked[id]=event.target.checked;
        setChecked(tmpChecked);
        // setChecked(event.target.checked);
    };

    const handleEdit=(e,id)=>{
        console.log("edit record "+id);
        console.log(props.link);
        let relUrl="";
        if(props.rel) {
            relUrl="/"+props.rel+"/"+props.relid;
        }
        history.push("/"+props.link+"/"+ id+"/edit"+relUrl);
    }
    const handleDelete=(e,id)=>{
        console.log("delete");
    }
    const handleView=(e,id)=>{
        console.log("view record "+id);
        console.log(props.link);
        let relUrl="";
        if(props.rel) {
            relUrl="/"+props.rel+"/"+props.relid;
        }
        history.push("/"+props.link+"/"+ id+"/view"+relUrl);
    }
    const handleRelate=(e,rel,rel_id)=>{
        console.log("relate");
        console.log(rel);
        console.log(rel_id);
        history.push("/"+rel+"/"+rel_id+"/view");
    }
    const handleSingleCheck = e => {
        console.log(e.target.name);
        setItemField({ ...itemField, [e.target.name]: (e.target.checked?1:0) });
        console.log({ ...itemField, [e.target.name]: (e.target.checked?1:0) });
        props.onSetValue({...itemField,[e.target.name]:(e.target.checked?1:0)});
    };

    const handlePermissionsSingleCheck = e => {
        // console.log(e.target.name);
        // console.log(e.target.checked);
        let section=e.target.name.split("_");
        if(section[1]=="all"){
            // console.log("all");
            let list=itemField;
            for(let item in itemField){
                let key=item.split("_")[0];
                if(key==section[0])
                    list={...list,[item]:(e.target.checked?1:0)}
            }
            setItemField(list);
            props.onSetValue(list);
        }else if(section[1]=="sec"){
            if(e.target.checked==false){
                console.log("tenemos que desactivar todas");
                let list=itemField;
                for(let item in itemField){
                    let key=item.split("_")[0];
                    if(key==section[0])
                        list={...list,[item]:0}
                }
                setItemField(list);
                props.onSetValue(list);
            }else {
                setItemField({...itemField, [e.target.name]: 1});
                props.onSetValue({...itemField, [e.target.name]: 1});

            }
        }else{
            if(e.target.checked==1){
                setItemField({...itemField,[section[0]+"_"+"sec"]:1, [e.target.name]: (e.target.checked?1:0) });
                props.onSetValue({...itemField,[section[0]+"_"+"sec"]:1, [e.target.name]: (e.target.checked?1:0) });

            }else{
                // console.log("no");
                // console.log(section[0]+"_"+"all");
                setItemField({...itemField,[section[0]+"_"+"all"]:0, [e.target.name]: (e.target.checked?1:0)});
                props.onSetValue({...itemField,[section[0]+"_"+"all"]:0, [e.target.name]: (e.target.checked?1:0)});
            }
        }
        // setItemField({ ...itemField, [e.target.name]: (e.target.checked?1:0) });
        // console.log({ ...itemField, [e.target.name]: (e.target.checked?1:0) });
        // props.onSetValue({...itemField,[e.target.name]:(e.target.checked?1:0)});
    };
    const handleNumberChange =(e)=>{
        // console.log(elementsRef.current[index].current);
        // console.log(e.target.name);
        // let time;
        // clearTimeout(time);
        // time=setTimeout(()=>{
        //     console.log("pepe")
        // },1000);
        setItemField({...itemField,[e.target.name]:e.target.value});
        // console.log({...itemField,[e.target.name]:e.target.value});
        props.onSetValue({...itemField,[e.target.name]:e.target.value});
        // console.log(ref);

    }
    const handleTextChange =e=>{

        setItemField({...itemField,[e.target.name]:e.target.value});
        // console.log({...itemField,[e.target.name]:e.target.value});
        props.onSetValue({...itemField,[e.target.name]:e.target.value})
    }
    const handleSetValue=(val,id)=>{
        // console.log(val);
        // console.log(id);
        // console.log("ppp");
        setItemField({...itemField,[id]:val});
        // console.log({...itemField,[e.target.name]:e.target.value});
        props.onSetValue({...itemField,[id]:val});
        // itemField[field.field+"_"+row.id]
    }
    const formatDate=(d)=>{
        var date=new Date(d);
        var formattedDate = format(date, "dd/MM/yyyy HH:mm:ss");
        return formattedDate;
    }

    const getJsonFields=(name,jsonData)=>{
        let v=[];
        if(jsonData!=null) {
            for (let elem in jsonData){
                v.push(<div key={name+"_"+elem} className={classes.jsonField}>
                    <div className={classes.jsonTitle}>{elem}</div>
                    <Checkbox
                        checked={(itemField[name+"_"+elem]==undefined?(jsonData[name+"_"+elem]==1?true:false):(itemField[name+"_"+elem]==1?true:false))}
                        color="primary"
                        name={name+"_"+elem}
                        onChange={handlePermissionsSingleCheck}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    {/*<span className={classes.jsonValue}>{jsonData[elem]}</span>*/}
                </div>);
            }
        }
        return v;
    }
    useEffect(() => {
        if(props.data){
            let list={};
            for(let elem in props.data){
                // console.log(props.data);
                let key=props.data[elem]["name"];
                for(let permission in props.data[elem]["permissions"]){
                    // console.log(permission);
                    list={...list,[key+"_"+permission]:props.data[elem]["permissions"][permission]};
                    setItemField(list);
                }
            }

        }

    },[]);

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {(props.allowView=="true" || props.allowEdit=="true" || props.allowDelete=="true") &&
                        <TableCell>{t("fields.actions")}</TableCell>
                        }
                        {props.fields && props.fields.map((field)=>(
                            <TableCell key={Math.random()} className={(field.hidden!=true?classes.fieldTitle:"is-hidden")}>{t("fields."+field.field)}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data && props.data.map((row,indexRow) => {
                        return (
                            <TableRow key={indexRow}>
                                {(props.allowView=="true" || props.allowEdit=="true" || props.allowDelete=="true") &&
                                    <TableCell>
                                        {props.allowView=="true" &&
                                        <IconButton aria-label="back" size="small" onClick={(e)=>{handleView(e,row.id)}}>
                                            <ViewIcon />
                                        </IconButton>}
                                        {props.allowEdit=="true" &&
                                        <IconButton aria-label="back" size="small" onClick={(e)=>{handleEdit(e,row.id)}}>
                                            <EditIcon />
                                        </IconButton>
                                        }
                                        {props.allowDelete=="true" &&
                                        <IconButton aria-label="back" size="small" onClick={(e)=>{handleDelete(e,row.id)}}>
                                            <DeleteIcon />
                                        </IconButton>
                                        }

                                    </TableCell>
                                }
                                 {props.fields && props.fields.map((field,index) => {
                                     if(field.isEdit==true){
                                         var r;
                                         switch(field.type){
                                             case "boolean":
                                                 r=  <Checkbox
                                                     checked={(itemField[field.field+"_"+row.id]==undefined?(row[field.field]==1?true:false):(itemField[field.field+"_"+row.id]==1?true:false))}
                                                     color="primary"
                                                     name={field.field+"_"+row.id}
                                                     onChange={handleSingleCheck}
                                                     inputProps={{ 'aria-label': 'primary checkbox' }}
                                                 />
                                                 break;

                                             case "number":
                                                 r=<input key={index} className={(field.size=="xs"?classes.size_xs:(field.size=="md"?classes.size_md:"normal"))} name={field.field+"_"+row.id} type="number"
                                                          min={(field.min?field.min:0)} max={(field.max?field.max:"")} value={(itemField[field.field+"_"+row.id]==undefined?row[field.field]:itemField[field.field+"_"+row.id])} onChange={(e)=>{handleNumberChange(e)}} />
                                                 break;
                                             case "calification":
                                                 r=<ButtonCalificationComponent onSetValue={(val)=>{handleSetValue(val,field.field+"_"+row.id)}} title={field.title} name={field.field+"_"+row.id} value={(itemField[field.field+"_"+row.id]==undefined?row[field.field]:itemField[field.field+"_"+row.id])}/>
                                                 break;
                                             case "json":
                                                 r=<span>kdkdkd</span>
                                                 break;

                                             default:
                                                 r=<input name={field.field+"_"+row.id}
                                                          className={(field.size=="xl"?classes.size_xl:classes.fieldValue)}
                                                          type="text"
                                                          value={(itemField[field.field+"_"+row.id]==undefined?row[field.field]:itemField[field.field+"_"+row.id])}
                                                          onChange={handleTextChange}/>
                                         }
                                         return (
                                             <TableCell key={field.field+"_"+row.id}
                                                 className={(field.hidden != true ? classes.fieldValue : "is-hidden")}
                                                 component="th" scope="row">
                                                 {(row ? r : null)}
                                             </TableCell>
                                         )
                                     }else {
                                         // console.log(field);
                                         var r2;
                                         switch(field.type){
                                             case "related":
                                                 if(field.allowLink=="true" || field.allowLink==undefined) {
                                                     r2 = <div className={classes.link} onClick={(e)=>{handleRelate(e,field.rel,row[field.field_id])}}>{row[field.field]}  </div>
                                                 }else {
                                                     if(field.field=="current_status" && row[field.field]=="0"){
                                                         r2=<img className={classes.cross} src={cross}/>
                                                     }else if(field.field=="current_status" && row[field.field]=="1")
                                                         r2=<ThumbUpIcon className={classes.thumb} />
                                                         else
                                                     r2 = <div className={classes.text}> {row[field.field]} </div>
                                                 }
                                                     break;
                                             case "json":
                                                 r2=getJsonFields(row["name"],row[field.field]);
                                                 break;
                                             case "date":
                                                 r2= r2=(row ? formatDate(row[field.field]) : null)
                                                 break;
                                             case "link":
                                                 let valLink;
                                                 valLink=row[field.field];
                                                 if(valLink && valLink.length && valLink.length>30){
                                                     let text=valLink.substring(0,30)

                                                     r2=<Tooltip title={valLink}>
                                                         <a href={"/"+field.page+"/"+row[field.rel]+"/view"} >{text+"..."}</a>
                                                     </Tooltip>
                                                 }
                                                 else
                                                     r2=(row ? <a href={"/"+field.page+"/"+row[field.rel]+"/view"} >{valLink}</a> : null)
                                                 break;
                                             default:

                                                 // <Tooltip title={longText}>
                                                 //     <Button className={classes.button}>Default Width [300px]</Button>
                                                 // </Tooltip>
                                                 let val;
                                                 val=row[field.field];
                                                 if(val && val.length && val.length>30){
                                                     let text=val.substring(0,30)

                                                     r2=<Tooltip title={val}>
                                                         <div>{text+"..."}</div>
                                                          </Tooltip>
                                                 }
                                                 else
                                                    r2=(row ? val : null)
                                         }
                                         return (
                                             <TableCell key={Math.random()}
                                                 className={(field.hidden != true ? classes.fieldValue : "is-hidden")}
                                                 component="th" scope="row">
                                                 {r2}
                                             </TableCell>
                                         )
                                     }
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default TableViewComponent;


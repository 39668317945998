import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        "& tr:not(.MuiTableRow-head)": {
            height: '60px',
            cursor: 'pointer'
        },
    },
}));
const CustomToolbar = (props) => {
    const handleNewClick = () => {
        // console.log("clicked on icon!");
        props.onNewClick();
    }
    const classes = useStyles();

    return(
            <React.Fragment>
            <Tooltip title={"Nuevo elemento"}>
            <IconButton className={classes.iconButton} onClick={handleNewClick}>
            <AddIcon className={classes.deleteIcon} />
        </IconButton>
        </Tooltip>
        </React.Fragment>
    );
}
export default CustomToolbar;

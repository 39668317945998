import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Button, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import Grid from '@material-ui/core/Grid';
import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from "mui-datatables";
import CustomClientsToolbar from "./CustomClientsToolbar";
import history from "../providers/History/history";
import { PedidoEstado} from "../providers/Utils/utils";

import MapIcon from "@material-ui/icons/Room";


const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    },
    resumePaper:{
        padding:10
    },
    resumeTitle:{
        fontWeight:"bold"
    },
    resumeValue:{
        textAlign:"center"
    }
}));
const OrdersResume = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [table, setTable] = useState({});
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("300px");

    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomClientsToolbar mode="not-verified" selectedRows={selectedRows} />
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push("/Addresses/"+row[0]+"/view");
    }
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("REsume client orders");
        console.log("datos",props.data);
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickVerify=()=>{
        console.log("verificar cuenta");
        props.onRowVerifyAddress(props.selectedRows)
    }
    const handleClickLocation=()=>{
        console.log("resend email");
        props.onRowLocation(props.selectedRows)
        // props.onRowView("value");
    }
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    Resumen del cliente en la APP
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Num. Solicitudes totales</div>
                        <div className={classes.resumeValue}>
                            {props.data.totalSolicitudes}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Num. Compras totales</div>
                        <div className={classes.resumeValue}>
                            {props.data.totalNumber}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Total litros comprados</div>
                        <div className={classes.resumeValue}>
                            {props.data.totalLiters}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Total Gasóleo A</div>
                        <div className={classes.resumeValue}>
                            Pedidos:{props.data.gasoleoA.number}
                        </div>
                        <div className={classes.resumeValue}>
                            Litros:{props.data.gasoleoA.liters}
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Total Gasóleo B</div>
                        <div className={classes.resumeValue}>
                            Pedidos:{props.data.gasoleoB.number}
                        </div>
                        <div className={classes.resumeValue}>
                            Litros:{props.data.gasoleoB.liters}
                        </div>
                    </Paper>                </Grid>
                <Grid item xs={4}>
                    <Paper className={classes.resumePaper}>
                        <div className={classes.resumeTitle}>Total Gasóleo C</div>
                        <div className={classes.resumeValue}>
                            Pedidos:{props.data.gasoleoC.number}
                        </div>
                        <div className={classes.resumeValue}>
                            Litros:{props.data.gasoleoC.liters}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );

}
export default OrdersResume;
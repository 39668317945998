import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, Input, InputLabel, Link, Paper, Select} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_get, http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import {getWheelDelta} from "leaflet/src/dom/DomEvent";
import RelatedAddress from "../../components/RelatedAddress";
import RelatedBillings from "../../components/RelatedBillings";
import MapComponent from "../../components/MapComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PopupDenyAddress from "../../components/PopupDenyAddress";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    link:{
        color:"#62bf62!important",
        display:'block',
        paddingTop:15
    },

}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const AddressesRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const handleMoreClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleOpenSendCredentials=()=>{
        let formData={"userId":record.id};
        setOpenPopup(true);
    }
    const handleEditAddress=()=>{
        setName(record.name);
        setEmail(record.email);
        history.push(props.parentUrl+"/"+props.id+"/edit");
    }
    const handleDenyAddress=()=>{
        setPopupDenyAddress(true);
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState(null);
    const [relateds,setRelateds] = useState([]);
    const [addresses,setAddresses] = useState(null);
    const [billings,setBillings] = useState([]);
    const [tables,setTables] =useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [popupDenyAddress,setPopupDenyAddress] =useState(false);
    const [orderPrice,setOrderPrice] =useState(null);
    const [numPedido,setNumPedido] =useState(null);
    const [userId,setUserId] =useState(null);
    const [id,setId] =useState(null);
    const [email,setEmail] =useState(null);
    const [navisionId,setNavisionId] =useState(null);
    const [phone,setPhone] =useState(null);
    const [address,setAddress] =useState(null);
    const [floor,setFloor] =useState(null);
    const [cp,setCp] =useState(null);
    const [contact,setContact] =useState(null);
    const [latitud,setLatitud] =useState(null);
    const [longitud,setLongitud] =useState(null);
    const [poblation,setPoblation] =useState(null);
    const [province,setProvince] =useState(null);
    const [gps,setGps] =useState(null);
    const [observations,setObservations] =useState(null);
    const [client_observations,setClientObservations] =useState(null);
    const [postalCodeId,setPostalCodeId] =useState(null);
    const [name,setName] =useState(null);
    const [rol,setRol] =useState(null);
    const [basesUser,setBasesUser] =useState([]);
    const [bases,setBases] =useState([]);
    const [modeAdmin,setModeAdmin] =useState(false);
    const [officialBasePrice,setOfficialBasePrice] =useState(null);
    const [poblationList,setPoblationList] =useState([]);
    const [openCPPopup,setOpenCPPopup] = useState(null);
    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed){
            if(props.action=="view")
                history.push("/"+props.parentId);
            else{
                history.goBack();
            }
        }
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }

    }

    const handleClosePopup=()=>{
        setOpenCPPopup(false);
    }
    const handleSetPrice=(e)=>{
        setOrderPrice(e.target.value);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleCPClose=(e)=>{
        setOpenCPPopup(false);
    }
    // const handleEmailChange=(e)=>{
    //     // console.log("cambiar email",e.target.value);
    //     setEmail(e.target.value);
    // }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    const handleEmailChange=(e)=>{
        setEmail(e.target.value);
    }
    const handleRolChange=(e)=>{
        setRol(e.target.value);
    }


    // const handleDelete=(e)=>{
    //     console.log("vamos a eliminar el id="+props.id);
    //     http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
    //         if(res.res=="ok"){
    //             history.push("/"+props.parentId);
    //         }else{
    //             alert("There´s been an error during save action");
    //         }
    //     });
    // }
    const handleSaveAddress=()=>{
        let formData={"id":id,"observations":observations,"clientObservations":client_observations};
        http_post("addresses-save/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCancel=()=>{
        setName(null);
        setEmail(null);
        history.push("/addresses/"+props.id+"/view");
    }

    const handleView=(e,rowData)=>{
        console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(rowData.id);
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
        // history.push("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.replace("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.refreshState();
    }
    // const handleSavePreGame=(e)=>{
    //     console.log("pregame");
    // }
    // const handleSavePostGame=(e)=>{
    //     console.log("postGame");
    // }
    const handleSave=(e,id)=>{
        //if id then edit
        if(action=="newAll"){
            console.log(id);
            console.log("guardar todos");
            console.log(updateDetail);
            console.log(listItems);
            const postData = new FormData();
            for (var key in listItems) {
                if(typeof listItems[key] === "object")
                    postData.append(key, listItems[key]);
                else
                    postData.append(key, listItems[key]);
            }
            for (var key in updateDetail) {
                if(typeof updateDetail[key] === "object")
                    postData.append(key, updateDetail[key]);
                else
                    postData.append(key, updateDetail[key]);
            }
            http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
                if(res.res=="ok"){
                    history.push("/"+props.parentId);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            return;
        }
        console.log(id);
        var postItems=[];
        const postData = new FormData();
        postData.append("id",id);
        console.log(listItems);
        for (var key in listItems) {
            if(typeof listItems[key] === "object"){
                // console.log("file");
                // console.log(listItems[key]);
                postData.append(key, listItems[key]);
            }else {
                // console.log("no file");
                postData.append(key, listItems[key]);
            }
            // postItems.push({"item":key,"value":listItems[key]});
        }
        // var postData={id:props.id,data:postItems};
        http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleItems=(item)=>{
        console.log("handle items ");
        listItems[item.item]=item.value;
        setListItems(listItems);
    }
    //refresh if we change the page from same baserecord
    // if(props.action!=actionPage){
    //     let init=false;
    //     if(actionPage=="")
    //         init=true;
    //     setActionPage(props.action);
    //     if(init==false)
    //         history.go(0);
    // }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['GK- Juan Gomez', 'DF- Luis Martínez', 'MD- Manuel Alen', 'FW - Alejandro Saez']);
    const [right, setRight] = React.useState(['MD- Alonso Moya', 'FW- Gonzalo Montes', 'DF- Rafael Simarro', 'DF- Gabriel del fresno']);

    const [t, i18n] = useTranslation('common');

    const [tab1Value, setTab1Value] = useState(0);
    const [tab2Value, setTab2Value] = useState(1);
    const [content1Value,setContent1Value] = useState(true);
    const [content2Value,setContent2Value] = useState(true);
    const [data,setData]=useState([]);
    const [stats,setStats] =useState(null);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [readOnly,setReadOnly] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [pricesPopup,setPricesPopup] =useState([]);
    const [pos,setPos] =useState(null);
    const CssTextField = withStyles({
        root: {
            marginTop:15,
            '& label.Mui-focused': {
                color: 'green',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'green',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red',
                },
                '&:hover fieldset': {
                    borderColor: 'yellow',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green',
                },
            },
        },
    })(TextField);

    const handleOnSetValue=(val)=>{
        setUpdateDetail(val);
    }

    const handleDenyAddressPost=()=>{
        console.log("Deny address");
        let formData={id:props.id};
        http_post("addresses-deny",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
        setPopupDenyAddress(false);
    }
    const handleCloseDenyAddress=()=>{
        setPopupDenyAddress(false);
    }

   useEffect(() => {
        let url="";

        setAction(props.action);
        if(props.action=="newAll") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action}]);
            url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setReadOnly(true);
            setIsRefreshed(false);
        }else{
            setReadOnly(false);
        }
        Call_Get(url,storeToken).then((res)=> {
            console.log(res);
            setRecord(res.record);
            if(res.record.gps_address) {
                let tmpPos = res.record.gps_address.split(",");
                setPos(tmpPos);
                setLatitud(tmpPos[0]);
                setLongitud(tmpPos[1]);
            }
            setId(res.record.id);
            setPostalCodeId(res.record.postalCodeId);
            setRelateds(res.related);
        });
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChangeBases = (event) => {
        console.log("base change",event.target.value,event);
        setBasesUser(event.target.value);
    };
    const onVerifyAddress=(lat,lng,address,cp,navisionId)=>{
        console.log("1",lat);
        console.log("2",lng);
        console.log("3",address);
        console.log("4",cp);
        let formData={lat:lat,lng:lng,address:address,cp:cp,navisionId:navisionId};
        http_post("addresses-verify/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });

    }
    const handleAddressChange=(e)=>{
        setAddress(e.target.value);
    }
    const handleLatitudChange=(e)=>{
        console.log(e.target.value);
        setLatitud(e.target.value);
   }
   const handleLongitudChange=(e)=>{
        console.log(e.target.value);
        setLongitud(e.target.value);
    }
    const handleCPChange=(e)=>{
        let val=e.target.value;
        setCp(val);
        if(val.length==5){
            http_get("check-address-admin/"+val,storeToken).then((res)=> {
                console.log("resul",res);
                if(res.status=="ok"){
                    if(res.data.length==1) {
                        console.log(res.data[0].poblacion);
                        setPoblation(res.data[0].poblacion);
                        setProvince(res.data[0].provincia);
                        setPostalCodeId(res.data[0].id);
                    }else if(res.data.length>1){
                        //meter un dialog para seleccionar la poblacion concreta
                        setPoblationList(res.data);
                        setOpenCPPopup(true);
                    }else{
                        alert("este codigo postal no está disponible");
                    }
                    //     history.push("/"+props.parentId);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            // alert("tenemos que obtener el postalcodeid");
        }
    }
    const handleNavisionIdChange=(e)=>{
        setNavisionId(e.target.value);
    }
    const handlePhoneChange=(e)=>{
        setPhone(e.target.value);
    }
    const handleContactChange=(e)=>{
        setContact(e.target.value);
    }
    const handleSelectPoblation=(e,id,pobl,prov)=>{
        console.log("cambio",id,pobl,prov);
        setPoblation(pobl);
        setProvince(prov);
        setPostalCodeId(id);
        setOpenCPPopup(false);
    }
    const handleObservationsChange=(e)=>{
        setObservations(e.target.value);
    }
    const handleClientObservationsChange=(e)=>{
        setClientObservations(e.target.value);
    }

    const handleChangeAddress=()=>{
        alert("para cambiar la direccion tienes que usar la verificación con el mapa");
    }
    const htmlContenido=()=>{
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        InputProps={{
                        readOnly: readOnly,
                    }}
                        id="filled-name"
                        label="Navision Id"
                        type="text"
                        onChange={handleNavisionIdChange}
                        helperText="Id de navision para esta dirección de cliente"
                        value={(props.action=="view"?(record && record.navision_id?record.navision_id:""):(navisionId?navisionId:(record && record.navision_id?record.navision_id:"")))}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        id="filled-name"
                        label="Nombre"
                        type="text"
                        helperText="Nombre del cliente"
                        value={(props.action=="view"?(record && record.name?record.name:""):(name?name:(record && record.name?record.name:"")))}
                        onChange={handleNameChange}

                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        id="filled-name"
                        label="Teléfono"
                        type="text"
                        helperText="Teléfono del cliente"
                        value={(props.action=="view"?(record && record.phone?record.phone:""):(phone?phone:(record && record.phone?record.phone:"")))}
                        // onChange={handlePhoneChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="direccion"
                        label="Dirección"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Direccion del cliente"
                        value={(props.action=="view"?(record && record.address?record.address:""):(address?address:(record && record.address?record.address:"")))}
                        // onChange={handleAddressChange}
                        onClick={handleChangeAddress}
                        fullWidth
                    />
                </Grid>
                        <Grid item xs={12} sm={3}>
            <TextField
        id="floor"
        label="Portal/Piso/Planta"
        InputProps={{
            readOnly: readOnly,
        }}
        helperText="Portal, Piso o Planta"
        value={(props.action=="view"?(record && record.floor?record.floor:""):(floor?floor:(record && record.floor?record.floor:"")))}
        // onChange={handleCPChange}
        // onClick={handleChangeAddress}
        fullWidth
        />
        </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        id="cp"
                        label="CP"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Código postal"
                        value={(props.action=="view"?(record && record.cp?record.cp:""):(cp?cp:(record && record.cp?record.cp:"")))}
                        // onChange={handleCPChange}
                        onClick={handleChangeAddress}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                        <TextField
                        id="town"
                        label="Población"
                        InputProps={{
                        readOnly: true,
                    }}
                        helperText="Población (se rellena automáticamente a partir del CP"
                        value={(props.action=="view"?(record && record.poblation?record.poblation:""):(poblation?poblation:(record && record.poblation?record.poblation:"")))}
                        fullWidth
                            onClick={handleChangeAddress}
                        />
                </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                        id="town"
                        label="Provincia"
                        InputProps={{
                        readOnly: true,
                    }}
                        helperText="Provincia (se rellena automáticamente a partir del CP"
                        onClick={handleChangeAddress}
                        value={(props.action=="view"?(record && record.province?record.province:""):(province?province:(record && record.province?record.province:"")))}
                        fullWidth
                        />
                        </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lat"
                        label="Latitud"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Latitud (posicion gps)"
                        value={(props.action=="view"?
                            (record && record.gps_address?record.gps_address.split(",")[0]:""):
                            (latitud?latitud:(record && record.gps_address?record.gps_address.split(",")[0]:"")))
                        }
                        // onChange={handleLatitudChange}
                        onClick={handleChangeAddress}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="long"
                        label="Longitud"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Longitud (posición gps)"
                        value={(props.action=="view"?
                                    (record && record.gps_address?record.gps_address.split(",")[1]:""):
                                    (longitud?longitud:(record && record.gps_address?record.gps_address.split(",")[1]:"")))
                        }
                        // onChange={handleLongitudChange}
                        onClick={handleChangeAddress}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    id="contact"
                    label="Contacto"
                    InputProps={{
                    readOnly: readOnly,
                }}
                    helperText="Persona de contacto en esta dirección"
                    value={(props.action=="view"?(record && record.contact?record.contact:""):(contact?contact:(record && record.contact?record.contact:"")))}
                    // onChange={handleContactChange}
                    fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <TextField
                        id="obs"
                        label="Observaciones de dirección GSYM"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Observaciones específicas para esta dirección"
                        className={(props.action=="view"?"":classes.editInput)}

                        value={(props.action=="view"?(record && record.observations?record.observations:""):(observations!=null?observations:(record && record.observations?record.observations:"")))}
                        onChange={handleObservationsChange}
                        fullWidth
                    />
                </Grid>
                        {record && record.verified!=2 && <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                        checked={(record && record.verified?(record.verified==1?true:false):false)}
                        name="checkedF"
                            />
                    }
                        label="Dirección verificada"
                    />
                </Grid>}
                {record && record.verified==2 && <Grid item xs={12}>
                        <div className={classes.redColor}><b>DIRECCION DENEGADA POR GSYM . El cliente debe modificarla</b></div>
                </Grid>}
        </Grid>
        );
    }
    const htmlContenidoClienteAPP=()=>{
        return (
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <InputLabel shrink id={"select_"+props.field}>
            {"Cliente"}
            </InputLabel>
        {record && <Link href={"/Clients/"+record.client_id+"/view"}
            className={classes.link}>
                &nbsp; {record.client_name}
        </Link>}
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
            InputProps={{
            readOnly: true,
        }}
            id="filled-name"
            label="Email"
            type="text"
            helperText="Email del cliente"
            value={(props.action=="view"?(record && record.email?record.email:""):(email?email:(record && record.email?record.email:"")))}
            onChange={handleEmailChange}
            fullWidth
            />
            </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
            InputProps={{
            readOnly: true,
        }}
            id="filled-name"
            label="Teléfono"
            type="text"
            helperText="Teléfono del cliente"
            value={(props.action=="view"?(record && record.phone?record.phone:""):(phone?phone:(record && record.phone?record.phone:"")))}
            // onChange={handlePhoneChange}
            fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={12}>
            <TextField
            id="obs"
            label="Observaciones del cliente APP GSYM"
            InputProps={{
            readOnly: readOnly,
        }}
            helperText="Observaciones específicas para el cliente de la APP"
            className={(props.action=="view"?"":classes.editInput)}

            value={(props.action=="view"?(record && record.client_observations?record.client_observations:""):(client_observations!=null?client_observations:(record && record.client_observations?record.client_observations:"")))}
            onChange={handleClientObservationsChange}
            fullWidth
            />
            </Grid>
                </Grid>
                );
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" && <div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEditAddress}
                                                    >
                                                <span className="textButton">Editar dirección</span>
                                            </Button>
                                            {/*<Button
                                                color="secondary"
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<CheckIcon />}
                                                onClick={handleDenyAddress}
                                                >
                                                Denegar dirección
                                            </Button>*/}
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={handleSaveAddress}
                                                    >
                                                    <span className="textButton">Guardar dirección</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancel}
                                                    >
                                                    <span className="textButton">Cancelar guardar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Grid container spacing={0}>
                                            <Grid item sm={12} md={6}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Información de dirección</h2>
                                                {htmlContenido()}
                                            </Paper>
                                            </Grid>
                                        <Grid item sm={12} md={6}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                            <h2>Información del cliente APP</h2>
                                            {htmlContenidoClienteAPP()}
                                        </Paper>
                                        </Grid>
                                            </Grid>
                                            {/* DETALLE */}
                                            {/*{action == "view" &&*/}
                                        <div className={classes.contentTab}>
                                            <div className={classes.content}>
                                            <div className={classes.tabComponent}>
                                            <AppBar position="static">
                                            <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="simple tabs example">
                                            {<Tab className={classes.tab} label={t("tabs.map")} {...a11yProps(0)} />}
                                        </Tabs>
                                        </AppBar>
                                            <TabPanel value={tab1Value} index={0}>
                                            {record && record.address}
                                            {record && <MapComponent address={(record?record.address:null)} cp={(record?record.cp:null)} position={(record?record.gps_address:null)} action={props.action} onVerifyAddress={onVerifyAddress}/>}
                                                {/*<PlayerDetailTrainingsComponent data={data.trainings} chartList={data.trainingList30} chartTotal={data.trainingTotal30}/>*/}
                                            </TabPanel>
                                            </div>
                                            </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
                <PopupDenyAddress address={(record?record.address:null)} cp={(record?record.cp:null)} open={popupDenyAddress} onClose={handleCloseDenyAddress} onDenyAddress={handleDenyAddressPost}/>
            </main>
            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
            <Dialog
                open={openCPPopup}
                onClose={handleCPClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">Selecciona una población</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                        { poblationList && poblationList.map((item)=>{
                            return (<ListItem
                            button
                            key={"i" & item.id}
                                >
                                <ListItemText
                            primary={item.poblacion + " - "+item.provincia}
                            onClick={(e)=>{handleSelectPoblation(e,item.id,item.poblacion,item.provincia)}}
                            />
                            </ListItem>           )
                        })
                        }
                 </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCPClose} color="primary" autoFocus>
            Cancel
            </Button>
            </DialogActions>
            </Dialog>
        </div>

    );
}
export default AddressesRecord;


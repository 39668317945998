import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, InputLabel, Paper, Select} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post,http_get} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import Moment from 'react-moment';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProductRanges, GetProducts, PedidoEstado, PedidoFranja} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import 'moment/locale/es';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Popup from "../../components/Popup";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
    color:"#ff2727"
    },
    greenColor:{
        color:"#27ff27"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    gasoilTitle:{
        padding:10,
        fontSize:18,
        fontWeight:'bold',
        "& a":{
            fontSize:12
        }
    },
    rangoTitle:{
        padding:10,
        textAlign:'right',
        paddingTop:35,
        fontSize:16
    },
    paperStyle:{
        margin:10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const PricesRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const [options,setOptions] = useState([]);
    const handleMoreClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleOpenSetPrice=()=>{
        setOpenPopup(true);
    }
    const handleEditOrder=()=>{
        setNumPedido(null);
        history.push("/Orders-no-price/"+props.id+"/edit");
    }
    const handleCancelOrder=()=>{
        setOpenCancelOrderPopup(true);
    }
    const handleDateChange=(v)=>{
        setDateValue(v);
    }
    const handleBaseChange=(e)=>{
        console.log(e.target.value);
        setBaseValue(e.target.value);
        let url = props.parentId + "/" + props.action+"/"+e.target.value;
        Call_Get(url,storeToken).then((res)=> {
            console.log(res);
            // console.log(res.item.itemColumns);
            // setFields(res.item.itemColumns);
            // if(props.action!="newAll") {
            setRecord(res.record.bases);
            setLastPrices(res.record.lastPrices);
        });
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    const [storeBaseValue,setStoreBaseValue] =useLocalStorage('GSYM_store_base_selected');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState(null);
    const [relateds,setRelateds] = useState([]);
    const [tables,setTables] =useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [openCancelOrderPopup,setOpenCancelOrderPopup] =useState(false);
    const [orderPrice,setOrderPrice] =useState(null);
    const [numPedido,setNumPedido] =useState(null);
    const [a1,setA1] =useState(null);
    const [a2,setA2] =useState(null);
    const [a3,setA3] =useState(null);
    const [a4,setA4] =useState(null);
    const [b1,setB1] =useState(null);
    const [b2,setB2] =useState(null);
    const [b3,setB3] =useState(null);
    const [b4,setB4] =useState(null);
    const [c1,setC1] =useState(null);
    const [c2,setC2] =useState(null);
    const [c3,setC3] =useState(null);
    const [c4,setC4] =useState(null);
    const [lastPrices,setLastPrices] =useState(null);

    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed)
            history.goBack();
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }

    }
    const handleSendPrice=()=>{
        if(orderPrice==0 || orderPrice==null)
            alert("el precio no está establecido");
        else{
            console.log("hay que enviar ",orderPrice);
            let formData={"order_id":record.id,"base_price":0.712,"price":orderPrice};
            http_post("order-set-price",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    history.push("/"+props.parentId);
                    setOpenPopup(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            setOpenPopup(false);
        }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleCloseCancelOrderPopup=()=>{
        setOpenCancelOrderPopup(false);
    }
    const handleSetPrice=(e)=>{
        setOrderPrice(e.target.value);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleTab2Change=(e,newValue)=>{
        setContent2Value(false);
        console.log("change");
        setTab2Value(newValue);
        setContent2Value(true);

    }
    const handleNumPedidoChange=(e)=>{
        setNumPedido(e.target.value);
    }

    const handleCancelarPedido=()=>{
        let formData={"order_id":record.id};
        http_post("order-admin-cancel",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
                setOpenCancelOrderPopup(false);
            }else{
                alert("There´s been an error during save action");
            }
        });
        setOpenCancelOrderPopup(false);
    }
    const handleDelete=(e)=>{
        console.log("vamos a eliminar el id="+props.id);
        http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCancelSave=()=>{
        console.log("cancel");
        history.push("/"+props.parentId);
    }

    const handleView=(e,rowData)=>{
        console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(rowData.id);
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
        // history.push("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.replace("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.refreshState();
    }
    // const handleSavePreGame=(e)=>{
    //     console.log("pregame");
    // }
    // const handleSavePostGame=(e)=>{
    //     console.log("postGame");
    // }
    const handleSave=(e,id)=>{
        //if id then edit
        if(action=="new"){
            console.log("base", baseValue);
            console.log("fecha1111", moment(dateValue).format("YYYY-MM-DD"));
            console.log("por aqui");
            if(baseValue!=null && dateValue!=null && a1 && a2 && a3 && a4 && b1 && b2 && b3 && b4 && c1 && c2 && c3 && c4) {
                console.log("base", baseValue);
                console.log("fecha", moment(dateValue,"YYYY-MM-DD"));
                console.log("por aqui");
                console.log("a1", a1);
                console.log("a2", a2);
                console.log("a3", a3);
                console.log("a4", a4);

                console.log("b1", b1);
                console.log("b2", b2);
                console.log("b3", b3);
                console.log("b4", b4);

                console.log("c1", c1);
                console.log("c2", c2);
                console.log("c3", c3);
                console.log("c4", c4);
                console.log(props.parentId);
                let postData={
                    "fecha": moment(dateValue).format("YYYY-MM-DD"),
                    "base":baseValue,
                    "a1":a1,
                    "a2":a2,
                    "a3":a3,
                    "a4":a4,
                    "b1":b1,
                    "b2":b2,
                    "b3":b3,
                    "b4":b4,
                    "c1":c1,
                    "c2":c2,
                    "c3":c3,
                    "c4":c4,
                    "last_prices":lastPrices
                }
                http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
                    if(res.status=="ok"){
                        history.push("/"+props.parentId);
                    }else{
                        alert("There´s been an error during save action");
                    }
                });
            }else{
                console.log("faltan datos");
                setDialogTitle("Fantan datos");
                setDialogDescription("Falta información en alguno de los campos del formulario, asegúrate, y vuelve a guardar el registro");
                setOpenPopupError(true);
            }
            // http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
            //     if(res.res=="ok"){
            //         history.push("/"+props.parentId);
            //     }else{
            //         alert("There´s been an error during save action");
            //     }
            // });
            // console.log(id);
            // console.log("guardar todos");
            // console.log(updateDetail);
            // console.log(listItems);
            // const postData = new FormData();
            // for (var key in listItems) {
            //     if(typeof listItems[key] === "object")
            //         postData.append(key, listItems[key]);
            //     else
            //         postData.append(key, listItems[key]);
            // }
            // for (var key in updateDetail) {
            //     if(typeof updateDetail[key] === "object")
            //         postData.append(key, updateDetail[key]);
            //     else
            //         postData.append(key, updateDetail[key]);
            // }
            // http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
            //     if(res.res=="ok"){
            //         history.push("/"+props.parentId);
            //     }else{
            //         alert("There´s been an error during save action");
            //     }
            // });
            // return;
        }
        // console.log(id);
        // var postItems=[];
        // const postData = new FormData();
        // postData.append("id",id);
        // console.log(listItems);
        // for (var key in listItems) {
        //     if(typeof listItems[key] === "object"){
        //         // console.log("file");
        //         // console.log(listItems[key]);
        //         postData.append(key, listItems[key]);
        //     }else {
        //         // console.log("no file");
        //         postData.append(key, listItems[key]);
        //     }
        //     // postItems.push({"item":key,"value":listItems[key]});
        // }
        // // var postData={id:props.id,data:postItems};
        // http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
        //     if(res.res=="ok"){
        //         history.push("/"+props.parentId);
        //     }else{
        //         alert("There´s been an error during save action");
        //     }
        // });
    }
    const handleItems=(item)=>{
        console.log("handle items ");
        listItems[item.item]=item.value;
        setListItems(listItems);
    }
    //refresh if we change the page from same baserecord
    // if(props.action!=actionPage){
    //     let init=false;
    //     if(actionPage=="")
    //         init=true;
    //     setActionPage(props.action);
    //     if(init==false)
    //         history.go(0);
    // }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['GK- Juan Gomez', 'DF- Luis Martínez', 'MD- Manuel Alen', 'FW - Alejandro Saez']);
    const [right, setRight] = React.useState(['MD- Alonso Moya', 'FW- Gonzalo Montes', 'DF- Rafael Simarro', 'DF- Gabriel del fresno']);

    const [t, i18n] = useTranslation('common');

    const [tab1Value, setTab1Value] = useState(0);
    const [tab2Value, setTab2Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [content2Value,setContent2Value] = useState(true);
    const [data,setData]=useState([]);
    const [stats,setStats] =useState(null);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dateValue,setDateValue] =useState(new Date());
    const [baseValue,setBaseValue] =useLocalStorage('GSYM_store_base_selected');
    const [prices,setPrices] =useState(null);
    const [openPopupError,setOpenPopupError] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const CssTextField = withStyles({
        root: {
            marginTop:15,
            '& label.Mui-focused': {
                color: 'green',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'green',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red',
                },
                '&:hover fieldset': {
                    borderColor: 'yellow',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green',
                },
            },
        },
    })(TextField);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };
    const handleTextChange=(e,t,v)=>{
        console.log("change");
        switch(t){
            case "a":
                switch(v){
                    case 1:
                        setA1(e.target.value);
                        break;
                    case 2:
                        setA2(e.target.value);
                        break;
                    case 3:
                        setA3(e.target.value);
                        break;
                    case 4:
                        setA4(e.target.value);
                        break;
                }
                break;
            case "b":
                switch(v){
                    case 1:
                        setB1(e.target.value);
                        break;
                    case 2:
                        setB2(e.target.value);
                        break;
                    case 3:
                        setB3(e.target.value);
                        break;
                    case 4:
                        setB4(e.target.value);
                        break;
                }
                break;
            case "c":
                switch(v){
                    case 1:
                        setC1(e.target.value);
                        break;
                    case 2:
                        setC2(e.target.value);
                        break;
                    case 3:
                        setC3(e.target.value);
                        break;
                    case 4:
                        setC4(e.target.value);
                        break;
                }
                break;
        }
    }

    const handleEvolutionNew=(e,id)=>{
        history.push("/tracing/"+id+"/new");
        console.log("tracing "+id);
    }
    const handleOnSetValue=(val)=>{
        setUpdateDetail(val);
    }
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const handleWallet=()=>{
        console.log("wallet");
    };
    const handleFavorite=(e,isFavorite)=>{
        console.log("favorite");
        console.log(isFavorite);
    }
    const handleFriends=(e,value)=>{
        console.log("friends");
        console.log(value);
    }
    const handleLiberarPedido=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const getHelperText=(elem,value=null)=>{
        if(value==null)
            return (<div>Precio anterior: {(lastPrices?lastPrices[elem]:"")} <a href="#" onClick={(e)=>{handleCopy(e,elem,lastPrices[elem])}}>copiar precio</a></div>)
        else
            return (<div>Precio anterior: {value}</div>)

    }
    const handleCopy=(e,t,elem)=>{
        e.preventDefault();
        console.log("copy",t,elem);
        switch(t){
            case "a1":
                setA1(elem);
                break;
            case "a2":
                setA2(elem);
                break;
            case "a3":
                setA3(elem);
                break;
            case "a4":
                setA4(elem);
                break;
            case "b1":
                setB1(elem);
                break;
            case "b2":
                setB2(elem);
                break;
            case "b3":
                setB3(elem);
                break;
            case "b4":
                setB4(elem);
                break;
            case "c1":
            case "c1":
                setC1(elem);
                break;
            case "c2":
                setC2(elem);
                break;
            case "c3":
                setC3(elem);
                break;
            case "c4":
                setC4(elem);
                break;
        }
    }
    const handleLiberarPedidoSalir=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleClosePopupError=()=>{
        setOpenPopupError(false);
    }
    const handleCopyAll=(e,g)=>{
        handleCopy(e,g+"1",lastPrices[g+"1"]);
        handleCopy(e,g+"2",lastPrices[g+"2"]);
        handleCopy(e,g+"3",lastPrices[g+"3"]);
        handleCopy(e,g+"4",lastPrices[g+"4"]);
    }
   useEffect(() => {
        console.log("load prices record");
        console.log(props.action);
        let url="";
        // if(window.location.href.substring(window.location.href.length-3,window.location.href.length)=="new"){
        //     setAction("new");
        //     setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":"New" }]);
        //     url=props.parentId+"/"+props.action+"/";
        // }else{
        setAction(props.action);
        if(props.action=="new") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action}]);
            url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        console.log("url",url);
        // if(props.action!="new"){
            Call_Get(url+"/"+storeBaseValue,storeToken).then((res)=> {
                console.log(res);
                // console.log(res.item.itemColumns);
                // setFields(res.item.itemColumns);
                // if(props.action!="newAll") {
                setRecord(res.record.bases);
                setLastPrices(res.record.lastPrices);
                if(props.action=="view"){
                    setDateValue(res.record.date);
                    setBaseValue(res.record.base_id);
                    setPrices(res.record.prices);
               }
            });
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);
    const htmlContenidoPrecios=()=>{
        return (
            <Grid container spacing={3} className={classes.paperStyle}>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            readOnly={props.action=="view"}
                            id="date-picker-inline"
                            label="Fecha"
                            value={dateValue}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
            <InputLabel shrink id="demo-simple-select-label">Base</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                inputProps={{ readOnly: props.action=="view" }}
                id="demo-simple-select"
                value={baseValue}
                defaultValue={baseValue}
                onChange={handleBaseChange}
            >
                    {record && record.map(menuItem=>{
                            return (
                                <MenuItem key={menuItem.id} value={menuItem.id}>
                                {menuItem.name}
                                </MenuItem>
                        );
                        })}
            </Select>
            </FormControl>
                </Grid>
        {baseValue && <Grid item xs={12} sm={12} md={4} >
                    <Paper elevation={3}>
                        <div className={classes.gasoilTitle}>Gasóleo A (Automoción)
                                <a href="#" onClick={(e)=>{handleCopyAll(e,"a")}}>copiar precio</a>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.rangoTitle}>{GetProductRanges(1)} </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="precioa1"
                                    label="Precio Rango 1 x litro"
                                    InputProps={{
                                        readOnly: props.action=="view",
                                    }}
                                    helperText={(prices==null?getHelperText("a1"):getHelperText("a1",prices["a1"].last_price))}
                                    onChange={(e)=>{handleTextChange(e,"a",1)}}
                                    value={(prices==null?a1?a1:"":prices["a1"].price)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.rangoTitle}>{GetProductRanges(2)} </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="address-billing"
                                    label="Precio Rango 2 x litro"
                                    InputProps={{
                                        readOnly: props.action=="view",
                                    }}
                                    helperText={(prices==null?getHelperText("a2"):getHelperText("a2",prices["a2"].last_price))}
                                    value={(prices==null?a2?a2:"":prices["a2"].price)}
                                    onChange={(e)=>{handleTextChange(e,"a",2)}}
                                    fullWidth
                                    />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.rangoTitle}>{GetProductRanges(3)} </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                            id="address-billing"
                            label="Precio Rango 3 x litro"
                            InputProps={{
                                readOnly: props.action=="view",
                            }}
                            helperText={(prices==null?getHelperText("a3"):getHelperText("a3",prices["a3"].last_price))}
                            value={(prices==null?a3?a3:"":prices["a3"].price)}
                            onChange={(e)=>{handleTextChange(e,"a",3)}}
                            fullWidth
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={classes.rangoTitle}>{GetProductRanges(4)} </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="address-billing"
                                    label="Precio Rango 4 x litro"
                                    InputProps={{
                                        readOnly: props.action=="view",
                                    }}
                                    helperText={(prices==null?getHelperText("a4"):getHelperText("a4",prices["a4"].last_price))}
                                    value={(prices==null?a4?a4:"":prices["a4"].price)}
                                    onChange={(e)=>{handleTextChange(e,"a",4)}}
                                    fullWidth
                                    />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>}
            {baseValue && <Grid item xs={12} sm={12} md={4}>
                    <Paper elevation={3}>
                        <div className={classes.gasoilTitle}>Gasóleo B (Agrícola)
                            <a href="#" onClick={(e)=>{handleCopyAll(e,"b")}}>copiar precio</a>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(1)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="precioa1"
                                label="Precio Rango 1 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("b1"):getHelperText("b1",prices["b1"].last_price))}
                                value={(prices==null?b1?b1:"":prices["b1"].price)}
                                onChange={(e)=>{handleTextChange(e,"b",1)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(2)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 2 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("b2"):getHelperText("b2",prices["b2"].last_price))}
                                value={(prices==null?b2?b2:"":prices["b2"].price)}
                                onChange={(e)=>{handleTextChange(e,"b",2)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(3)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 3 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("b3"):getHelperText("b3",prices["b3"].last_price))}
                                value={(prices==null?b3?b3:"":prices["b3"].price)}
                                onChange={(e)=>{handleTextChange(e,"b",3)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(4)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 4 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("b4"):getHelperText("b4",prices["b4"].last_price))}
                                value={(prices==null?b4?b4:"":prices["b4"].price)}
                                onChange={(e)=>{handleTextChange(e,"b",4)}}
                                fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>}

                {baseValue && <Grid item xs={4} sm={12} md={4}>
                    <Paper elevation={3}>
                        <div className={classes.gasoilTitle}>Gasóleo C (Calefacción)
                            <a href="#" onClick={(e)=>{handleCopyAll(e,"c")}}>copiar precio</a>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(1)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="precioa1"
                                label="Precio Rango 1 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("c1"):getHelperText("c1",prices["c1"].last_price))}
                                value={(prices==null?c1?c1:"":prices["c1"].price)}
                                onChange={(e)=>{handleTextChange(e,"c",1)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(2)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 2 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("c2"):getHelperText("c2",prices["c2"].last_price))}
                                value={(prices==null?c2?c2:"":prices["c2"].price)}
                                onChange={(e)=>{handleTextChange(e,"c",2)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(3)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 3 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("c3"):getHelperText("c3",prices["c3"].last_price))}
                                value={(prices==null?c3?c3:"":prices["c3"].price)}
                                onChange={(e)=>{handleTextChange(e,"c",3)}}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.rangoTitle}>{GetProductRanges(4)} </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="address-billing"
                                label="Precio Rango 4 x litro"
                                InputProps={{
                                    readOnly: props.action=="view",
                                }}
                                helperText={(prices==null?getHelperText("c4"):getHelperText("c4",prices["c4"].last_price))}
                                value={(prices==null?c4?c4:"":prices["c4"].price)}
                                onChange={(e)=>{handleTextChange(e,"c",4)}}
                                fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>}
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" &&<div className={classes.right}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<DeleteIcon />}
                                                onClick={handleCancelOrder}
                                                >
                                                    <span className="textButton">Eliminar precio</span>
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={handleSave}
                                                    >
                                                    <span className="textButton">Guardar precios</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSave}
                                                    >
                                                    <span className="textButton">Cancelar guardar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12} >
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Tabla de precios</h2>
                                                {htmlContenidoPrecios()}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
                {<Popup title={dialogTitle} description={dialogDescription} open={openPopupError} onClose={handleClosePopupError}/>}
            </main>
            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>

    );
}
export default PricesRecord;

import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            //main: "#e5011e", rojo
            main:"#1f2884", //azul
        },
        danger: {
             main: "#bf0811",
        },
        dark:{
            main:"#000000",
        },
        light:{
            main:"#ffffff"
        },
        bodyBackground:{
            main:"#F6F7F9"
        },
        gasoleoA:{
            main:"#ffdd23"
        },
        gasoleoAColor:{
          main:"#000000"
        },
        gasoleoB:{
            main:"#b91d2c"
        },
        gasoleoBColor:{
            main:"#ffffff"
        },
        gasoleoC:{
            main:"#0b3f6f"
        },
        gasoleoCColor:{
            main:"#ffffff"
        },
        // goalkeeper:{
        //     main:"#2469d9",
        // },
        // defender:{
        //     main:"#dba110",
        // },
        // midfield:{
        //     main:"#4b9f23",
        // },
        // forward:{
        //     main:"#bf0811",
        // },
        titleFields:{
            main:"#a8a4a4"
        },
        type: 'light',
    },
    fieldRow:{
        borderBottom:"1px solid #a8a4ae",
        marginBottom:"5px"
    },
    fieldField:{
        display:"inline-block"
    },
    fieldTitle:{
        color:"#a8a4a4",
        textAlign:"left",
        width:"30%",
        fontSize:"12px"
    },
    fieldValue:{
        color:"#ffffff",
        textAlign:"right",
        width:"70%"
    },
    textLink:{
        color:"#ffffff",
        textDecoration:"none",
        "&:hover": {
            textDecoration: "underline",
        }
    }

});

export default theme;

import React, {Fragment, useEffect, useState} from 'react';
import './Fonts.css';
import './App.css';
import {  Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from './providers/History/history';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
// import Register from './pages/Login/Register';
// import Dashboard from './pages/Dashboard/Dashboard';
import BaseTable from "./pages/BaseTable";
import Profile from "./pages/Profile/Profile";
import Logout from "./pages/Logout/Logout";
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme/theme';
import useLocalStorage from "./providers/store/localStorage";
import {useTranslation} from "react-i18next";
import Verification from "./pages/Login/Verification";
import UserProblem from "./pages/Login/UserProblem";
// import Notifications from "./pages/Notifications/notifications";
import Languages from "./pages/Profile/Languages/Languages";
import Users from "./pages/Profile/Users/Users_no";
import Dashboard from "./pages/Dashboard/Dashboard";
import BaseTable2 from "./pages/BaseTable2";
import BaseTableUsers from "./pages/Config/BaseTableUsers";
import ProfileUserRecord from "./pages/Profile/Users/ProfileUserRecord";
import PermissionDeny from "./pages/Login/PermissionDeny";
import BaseTableProvinces from "./pages/Config/BaseTableProvinces";
import BaseTableBases from "./pages/Config/BaseTableBases";
import BaseTablePostalCodes from "./pages/Config/BaseTablePostalCodes";
import BaseTableClients from "./pages/Clients/BaseTableClients";
import BaseTableOrders from "./pages/Orders/BaseTableOrders";
import BaseTableOrdersNoPrice from "./pages/Orders/BaseTableOrdersNoPrice";
import BaseTableAlerts from "./pages/Alerts/BaseTableAlerts";
import OrdersRecord from "./pages/Orders/OrdersRecord";
import BaseTableOrdersNoPaid from "./pages/Orders/BaseTableOrdersNoPaid";
import BaseTableAddressesNoVerified from "./pages/Address/BaseTableAddressesNoVerified";
import NotFound from "./components/NotFound";
import BaseTablePrices from "./pages/Config/BaseTablePrices";
import PricesRecord from "./pages/Config/PricesRecord";
import UsersRecord from "./pages/Config/UsersRecord";
import ClientsRecord from "./pages/Clients/ClientsRecord";
import AddressesRecord from "./pages/Address/AddressesRecord";
import BasesRecord from "./pages/Config/BasesRecord";
import ProvincesRecord from "./pages/Config/ProvincesRecord";
import BaseTableClientsNoVerified from "./pages/Clients/BaseTableClientsNoVerified";
import BaseTablePromotions from "./pages/Marketing/BaseTablePromotions";
import BaseTablePublic from "./pages/Marketing/BaseTablePublic";
import PublicRecord from "./pages/Marketing/PublicRecord";
import EventRecord from "./pages/Marketing/EventRecord";
import ActionRecord from "./pages/Marketing/ActionRecord";

import BaseTableAddresses from "./pages/Address/BaseTableAddresses";
import BaseTablePayments from "./pages/Config/BaseTablePayments";
import PaymentsRecord from "./pages/Config/PaymentsRecord";
import BaseTableBillingAddresses from "./pages/Address/BaseTableBillingAddresses";
import BillingAddressesRecord from "./pages/Address/BillingAddressesRecord";
import BaseTableOrdersNavision from "./pages/Orders/BaseTableNavision";
import OrdersNoPriceRecord from "./pages/Orders/OrdersNoPriceRecord";
import BaseTableBillingAddressesNoVerified from "./pages/Address/BaseTableBillingAddressesNoVerified";
import BaseTableActions from "./pages/Marketing/BaseTableActions";
import BaseTableClientsInvite from "./pages/Clients/BaseTableClientsInvite";
import ClientsInviteRecord from "./pages/Clients/ClientsInviteRecord";
import BaseTableEvents from "./pages/Marketing/BaseTableEvents";
import ManageRecord from "./pages/Marketing/ManageRecord";
import BaseTableManage from "./pages/Marketing/BaseTableManage";
import PromotionRecord from "./pages/Marketing/PromotionRecord";
import OrdersNavisionRecord from "./pages/Orders/OrdersNavisionRecord";
import BaseTableOrdersProcessed from "./pages/Orders/BaseTableOrdersProcessed";
import OrdersProcessedRecord from "./pages/Orders/OrdersProcessedRecord";
import BaseTableOrdersFinished from "./pages/Orders/BaseTableOrdersFinished";
import OrdersFinishedRecord from "./pages/Orders/OrdersFinishedRecord";
import OrdersNoPaidRecord from "./pages/Orders/OrdersNoPaidRecord";
import BaseTableOrdersEncuestas from "./pages/Orders/BaseTableOrdersEncuestas";
import OrdersReturn from "./pages/Orders/OrdersReturn";
import OrdersReturnKo from "./pages/Orders/OrdersReturnKo";
import CancelKo from "./pages/Orders/CancelKo";
import CancelOk from "./pages/Orders/CancelOk";
function App() {
    // history.listen(location => {
    //     ReactGA.set({ page: location.pathname }); // Update the user's current page
    //     ReactGA.pageview(location.pathname); // Record a pageview for the given page
    // });
    const [socketObj,setSocketObj] =useState();
    const [langSelected,setLangSelected] =useLocalStorage("language","es");
    const [t, i18n] = useTranslation('common');
    const closeSocket=()=>{
        if(socketObj!=null){
            socketObj.close();
        }
    }
    useEffect(() => {
        // console.log(langSelected);
        i18n.changeLanguage(langSelected);
        // console.log(i18n);
    },[]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Fragment>
                <Router history={history}>
                    <div>
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route path="/login">
                                <Login />
                            </Route>
                            <Route path="/verification">
                                <Verification />
                            </Route>
                            <Route path="/user-problem">
                                <UserProblem />
                            </Route>
                            <Route path="/permission-deny">
                                <PermissionDeny />
                            </Route>
                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>
                            <Route exact path="/Config-languages">
                                <Languages />
                            </Route>
                            <Route exact path="/Config-profile">
                                <Profile/>
                            </Route>
                            <Route exact path="/logout">
                                <Logout/>
                            </Route>
                            <Route exact path="/payments">
                            <BaseTablePayments id="payments" title="payments"/>
                            </Route>
                            <Route exact path="/payments/:id/:action" render={props=><PaymentsRecord parentId="payments" parentTitle="payments" parentUrl="/payments" title="payments" {...props.match.params}/>} />
                            <Route exact path="/payments/new" render={props=><PaymentsRecord action="new" parentId="payments" parentTitle="payments" parentUrl="/payments" title="New"/>} />

                            <Route exact path="/Config-provinces">
                                <BaseTableProvinces id="config-provinces" title="provinces"/>
                            </Route>
                            <Route exact path="/Config-provinces/:id/:action" render={props=><ProvincesRecord parentId="config-provinces" parentTitle="config-provinces" parentUrl="/Config-provinces" title="config-provinces" {...props.match.params}/>} />

                            <Route exact path="/Config-bases">
                                <BaseTableBases id="config-bases" title="config-bases"/>
                            </Route>
                            <Route exact path="/Config-bases/:id/:action" render={props=><BasesRecord parentId="config-bases" parentTitle="config-bases" parentUrl="/Config-bases" title="config-bases" {...props.match.params}/>} />
                            <Route exact path="/Config-bases/new" render={props=><BasesRecord action="new" parentId="config-bases" parentTitle="config-bases" parentUrl="/Config-bases" title="new-base" {...props.match.params}/>} />

                           <Route exact path="/Config-users">
                                <BaseTableUsers id="config-users" title="config-users"/>
                            </Route>
                            <Route exact path="/Config-users/:id/:action" render={props=><UsersRecord parentId="config-users" parentTitle="Config-users" parentUrl="/Config-users" title="config-users" {...props.match.params}/>} />
                            <Route exact path="/Config-prices">
                                <BaseTablePrices id="config-prices" title="config-prices"/>
                            </Route>
                            <Route exact path="/Config-prices/:id/:action" render={props=><PricesRecord parentId="config-prices" parentTitle="config-prices" parentUrl="/Config-prices" title="Ver " {...props.match.params}/>} />
                            <Route exact path="/Config-prices/new" render={props=><PricesRecord action="new" parentId="config-prices" parentTitle="config-prices" parentUrl="/Config-prices" title="New" {...props.match.params}/>} />
                            <Route exact path="/Config-postal-codes">
                                <BaseTablePostalCodes id="config-postal-codes" title="postalcodes"/>
                            </Route>
                            <Route exact path="/Marketing-Actions">
                            <BaseTableActions id="actions" title="actions"/>
                            </Route>
                            <Route exact path="/Marketing-Events">
                            <BaseTableEvents id="events" title="events"/>
                            </Route>
                            <Route exact path="/Marketing-Public">
                            <BaseTablePublic id="public" title="public"/>
                            </Route>
                            <Route exact path="/Marketing-Manage">
                            <BaseTableManage id="manage" title="manage"/>
                            </Route>
                            <Route exact path="/Marketing-Manage/:id/:action" render={props=><ManageRecord parentId="manage" parentTitle="Marketing-Manage" parentUrl="/Marketing-Manage" title="View" {...props.match.params}/>} />
                            <Route exact path="/Marketing-Manage/new" render={props=><ManageRecord action="new" parentId="marketing-manage" parentTitle="Marketing-Manage" parentUrl="/Marketing-Manage" title="New"/>} />

                            <Route exact path="/Marketing-Public/:id/:action" render={props=><PublicRecord parentId="public" parentTitle="Marketing-Public" parentUrl="/Marketing-Public" title="View" {...props.match.params}/>} />
                            <Route exact path="/Marketing-Public/new" render={props=><PublicRecord action="new" parentId="marketing-public" parentTitle="Marketing-Public" parentUrl="/Marketing-Public" title="New"/>} />

                            <Route exact path="/Marketing-Event/:id/:action" render={props=><EventRecord parentId="events" parentTitle="Marketing-Event" parentUrl="/Marketing-Event" title="View" {...props.match.params}/>} />
                            <Route exact path="/Marketing-Event/new" render={props=><EventRecord action="new" parentId="marketing-event" parentTitle="Marketing-Event" parentUrl="/Marketing-Event" title="New"/>} />

                            <Route exact path="/Marketing-Actions/:id/:action" render={props=><ActionRecord parentId="action" parentTitle="Marketing-Action" parentUrl="/Marketing-Action" title="View" {...props.match.params}/>} />
                            <Route exact path="/Marketing-Actions/new" render={props=><ActionRecord action="new" parentId="marketing-action" parentTitle="Marketing-Action" parentUrl="/Marketing-Action" title="New"/>} />


                            <Route exact path="/Promotions">
                                <BaseTablePromotions id="promotions" title="promotions"/>
                            </Route>
                            <Route exact path="/Promotions/:id/:action" render={props=><PromotionRecord parentId="promotions" parentTitle="Promotions" parentUrl="/Promotions" title="View" {...props.match.params}/>} />
                            <Route exact path="/Promotions/new" render={props=><PromotionRecord action="new" parentId="promotions" parentTitle="Promotions" parentUrl="/Promotions" title="New"/>} />

                            <Route exact path="/Clients-invite">
                                <BaseTableClientsInvite id="clients-invite" title="clients-invite"/>
                            </Route>
                            <Route exact path="/Clients-invite/:id/:action" render={props=><ClientsInviteRecord parentId="clients-invite" parentTitle="Clients-invite" parentUrl="/Clients-invite" title="clients-invite" {...props.match.params}/>} />
                            <Route exact path="/Clients-invite/new" render={props=><ClientsInviteRecord action="new" parentId="clients-invite" parentTitle="Clients-invite" parentUrl="/Clients-invite" title="New"/>} />

                            <Route exact path="/Clients">
                                <BaseTableClients id="clients" title="clients"/>
                            </Route>
                            <Route exact path="/Clients/:id/:action" render={props=><ClientsRecord parentId="clients" parentTitle="Clients" parentUrl="/Clients" title="clients" {...props.match.params}/>} />
                            <Route exact path="/Clients-no-verified">
                                <BaseTableClientsNoVerified id="clients-no-verified" title="clients-no-verified"/>
                            </Route>
                            <Route exact path="/Clients-no-verified/:id/:action" render={props=><ClientsRecord parentId="clients-no-verified" parentTitle="clients-no-verified" parentUrl="/Clients-no-verified" title="clients-no-verified" {...props.match.params}/>} />

                            <Route exact path="/Addresses">
                                <BaseTableAddresses id="addresses" title="addresses"/>
                            </Route>
                            <Route exact path="/Addresses-no-verified">
                                <BaseTableAddressesNoVerified id="addresses-no-verified" title="addresses-no-verified"/>
                            </Route>
                            <Route exact path="/Addresses-no-verified/:id/:action" render={props=><AddressesRecord parentId="addresses-no-verified" parentTitle="Addresses-no-verified" parentUrl="/Addresses-no-verified" title="address" {...props.match.params}/>} />

                            <Route exact path="/Billing-Addresses-no-verified/:id/:action" render={props=><BillingAddressesRecord parentId="billing-addresses-no-verified" parentTitle="Billing-Addresses-no-verified" parentUrl="/Billing-Addresses-no-verified" title="address" {...props.match.params}/>} />

                            <Route exact path="/Addresses/:id/:action" render={props=><AddressesRecord parentId="addresses" parentTitle="Address" parentUrl="/Addresses" title="address" {...props.match.params}/>} />
                            <Route exact path="/Billing-Addresses">
                                <BaseTableBillingAddresses id="billing-addresses" title="billing-addresses"/>
                            </Route>

                            <Route exact path="/Billing-Addresses/:id/:action" render={props=><BillingAddressesRecord parentId="billing-addresses" parentTitle="billing-addresses" parentUrl="/billing-addresses" title="billing-addresses" {...props.match.params}/>} />

                            <Route exact path="/Billing-Addresses-no-verified">
                                <BaseTableBillingAddressesNoVerified id="billing-addresses-no-verified" title="billing-addresses-no-verified"/>
                            </Route>

                            <Route exact path="/Orders">
                                <BaseTableOrders id="orders" title="orders"/>
                            </Route>
                            <Route exact path="/Orders-no-price">
                                <BaseTableOrdersNoPrice id="orders-no-price" title="orders-no-price"/>
                            </Route>
                            <Route exact path="/Orders-no-paid">
                                <BaseTableOrdersNoPaid id="orders-no-paid" title="orders-no-paid"/>
                            </Route>
                            <Route exact path="/Orders-navision">
                                <BaseTableOrdersNavision id="orders-navision" title="orders-navision"/>
                            </Route>
                            <Route exact path="/Orders-processed">
                                <BaseTableOrdersProcessed id="orders-processed" title="orders-processed"/>
                            </Route>
                            <Route exact path="/Orders-finished">
                                <BaseTableOrdersFinished id="orders-finished" title="orders-finished"/>
                            </Route>
                            <Route exact path="/Orders-encuestas">
                                <BaseTableOrdersEncuestas id="orders-encuestas" title="orders-encuestas"/>
                            </Route>
                            <Route exact path="/Orders-processed/:id/:action" render={props=><OrdersProcessedRecord parentId="orders-processed" parentTitle="Orders-processed" parentUrl="/Orders-processed" title="orders-processed" {...props.match.params}/>} />
                            <Route exact path="/Orders-finished/:id/:action" render={props=><OrdersFinishedRecord parentId="orders-finished" parentTitle="Orders-finished" parentUrl="/Orders-finished" title="orders-finished" {...props.match.params}/>} />

                            <Route exact path="/Orders-navision/:id/:action" render={props=><OrdersNavisionRecord parentId="orders-navision" parentTitle="Orders-navision" parentUrl="/Orders-navision" title="orders-navision" {...props.match.params}/>} />
                            <Route exact path="/Orders-no-paid/:id/:action" render={props=><OrdersNoPaidRecord parentId="orders-no-paid" parentTitle="Orders-no-paid" parentUrl="/Orders-no-paid" title="orders-no-paid" {...props.match.params}/>} />

                            <Route exact path="/Orders-no-price/:id/:action" render={props=><OrdersNoPriceRecord parentId="orders-no-price" parentTitle="Orders-no-price" parentUrl="/Orders-no-price" title="orders-no-price" {...props.match.params}/>} />
                            <Route exact path="/Orders/:id/:action" render={props=><OrdersRecord parentId="orders" parentTitle="Orders" parentUrl="/Orders" title="orders" {...props.match.params}/>} />

                            <Route exact path="/Alerts">
                                <BaseTableAlerts id="alerts" title="Alerts"/>
                            </Route>
                            // <Route exact path="/users">
                            //     <BaseTable id="users" title="Users"/>
                            // </Route>
                            // <Route exact path="/profile/users">
                            //     <BaseTableUsers id="profile-users" title="Profile Users"/>
                            // </Route>
                            <Route exact path="/profile/profile-users/:id/:action" render={props=><ProfileUserRecord parentId="users" parentTitle="Profile" parentUrl="/profile/users" title="User" {...props.match.params}/>} />
                            <Route exact path="/profile/profile-users/new" render={props=><ProfileUserRecord action="new" parentId="users" parentTitle="Profile" parentUrl="/profile/users" title="New" {...props.match.params}/>} />
                            <Route exact path="/devolucionok" render={props=><OrdersReturn  {...props.match.params}/>} />
                            <Route exact path="/devolucionko" render={props=><OrdersReturnKo  {...props.match.params}/>} />

                            <Route exact path="/cancelok" render={props=><CancelOk  {...props.match.params}/>} />
                            <Route exact path="/cancelko" render={props=><CancelKo  {...props.match.params}/>} />

    <Route component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Fragment>
        </ThemeProvider>
);
}

export default App;

import React,{useState,useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import useLocalStorage from "../providers/store/localStorage";
const useStyles = makeStyles((theme) => ({
    paperBackground:{
        backgroundColor:"#f0f0f0"}
}));
const PromotionConditionComponent = (props) => {
    const classes = useStyles();

    useEffect(()=>{
    },[props]);

    return(
            <Grid container className={classes.paperBackground}>
                    <Grid container>
                        <Grid item xs={6}>Código:</Grid>
                        <Grid item xs={6}><b>{props.el.textValue.Código}</b></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>Tipo:</Grid>
                        <Grid item xs={6}><b>{props.el.textValue.Tipo}</b></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>Valor:</Grid>
                        <Grid item xs={6}><b>{props.el.textValue.Valor}</b></Grid>
                    </Grid>
            </Grid>
    )
}

export default PromotionConditionComponent;




import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, Paper} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import {getWheelDelta} from "leaflet/src/dom/DomEvent";
import RelatedAddress from "../../components/RelatedAddress";
import RelatedOrders from "../../components/RelatedOrders";
import OrdersResume from "../../components/OrdersResume";
import OrderInfoComponent from "../../components/OrderInfoComponent";
import moment from "moment";
import PopupCancelOrder from "../../components/PopupCancelOrder";
import {set} from "react-ga";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    bigTextField:{
        "& input":{
            fontSize:20,
            color:'#000000'
        }
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    orderDetail:{
        padding:10,
        marginTop:10,
        marginBottom:10
    },
    totalLine:{
        fontSize:18,
        borderTop:'1px solid #cccccc',
        paddingTop:10
    },
    line:{
        paddingTop:5,
        paddingBottom:5
    }
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const OrdersRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const [options,setOptions] = useState([]);
    const handleMoreClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    // const handleOpenSetPrice=()=>{
    //     let formData={"orderId":record.id};
    //     http_post("order-get-price",formData,storeToken).then((res)=> {
    //         if(res.status=="ok"){
    //             setPricesPopup(res.data);
    //             // alert(JSON.stringify(res.data[0].data));
    //             let data=res.data[0].data;
    //             let found=data.find((element=>element.min<=record.amount && element.max>=record.amount));
    //             if(found!=-1){
    //                 setOfficialBasePrice(found.price);
    //             }
    //             setOpenPopup(true);
    //         }else{
    //             alert("111There´s been an error during save action");
    //         }
    //     });
    //
    // }
    const handleEditOrder=()=>{
        setNumPedido(null);
        history.push(props.parentUrl+"/"+props.id+"/edit");
    }
    const handleCancelOrder=()=>{
        setCancelPopup(true);
        //setOpenCancelOrderPopup(true);
    }


    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [tables,setTables] =useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [orderPrice,setOrderPrice] =useState(null);
    const [numPedido,setNumPedido] =useState(null);
    const [cancelPopup,setCancelPopup] =useState(false);
    const [officialBasePrice,setOfficialBasePrice] =useState(null);
    const [formData,setFormData] =useState({});

    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed){
            if(props.action=="view")
                history.push("/"+props.parentId);
            else{
                history.goBack();
            }
        }
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }

    }
    const handleCancelPopup=()=>{
        console.log("action cancel");
    }
    const handleCloseCancelPopup=()=>{
        setCancelPopup(false);
    }
    const handleSendPrice=()=>{
        if(orderPrice==0 || orderPrice==null)
            alert("el precio no está establecido");
        else{
            console.log("hay que enviar ",orderPrice);
            let formData={"order_id":record.id,"base_price":0.712,"price":orderPrice};
            http_post("order-set-price",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    history.push("/"+props.parentId);
                    setOpenPopup(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            setOpenPopup(false);
        }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleCloseCancelOrderPopup=()=>{
        setCancelPopup(true);
    }
    const handleSetPrice=(e)=>{
        setOrderPrice(e.target.value);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleTab2Change=(e,newValue)=>{
        setContent2Value(false);
        console.log("change");
        setTab2Value(newValue);
        setContent2Value(true);

    }
    const handleNumPedidoChange=(e)=>{
        setNumPedido(e.target.value);
    }

    const handleDelete=(e)=>{
        console.log("vamos a eliminar el id="+props.id);
        http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleSaveOrder=()=>{
        console.log("save order");
        console.log("formData",formData);
        http_post("order-save/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCancelSaveOrder=()=>{
        setNumPedido(null);
        history.push(props.parentUrl+"/"+props.id+"/view");
    }

    const handleView=(e,rowData)=>{
        console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(rowData.id);
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
        // history.push("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.replace("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.refreshState();
    }
    // const handleSavePreGame=(e)=>{
    //     console.log("pregame");
    // }
    // const handleSavePostGame=(e)=>{
    //     console.log("postGame");
    // }
    const handleSave=(e,id)=>{
        //if id then edit
        if(action=="newAll"){
            console.log(id);
            console.log("guardar todos");
            console.log(updateDetail);
            console.log(listItems);
            const postData = new FormData();
            for (var key in listItems) {
                if(typeof listItems[key] === "object")
                    postData.append(key, listItems[key]);
                else
                    postData.append(key, listItems[key]);
            }
            for (var key in updateDetail) {
                if(typeof updateDetail[key] === "object")
                    postData.append(key, updateDetail[key]);
                else
                    postData.append(key, updateDetail[key]);
            }
            http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
                if(res.res=="ok"){
                    history.push("/"+props.parentId);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            return;
        }
        console.log(id);
        var postItems=[];
        const postData = new FormData();
        postData.append("id",id);
        console.log(listItems);
        for (var key in listItems) {
            if(typeof listItems[key] === "object"){
                // console.log("file");
                // console.log(listItems[key]);
                postData.append(key, listItems[key]);
            }else {
                // console.log("no file");
                postData.append(key, listItems[key]);
            }
            // postItems.push({"item":key,"value":listItems[key]});
        }
        // var postData={id:props.id,data:postItems};
        http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleItems=(item)=>{
        console.log("handle items ");
        listItems[item.item]=item.value;
        setListItems(listItems);
    }
    //refresh if we change the page from same baserecord
    // if(props.action!=actionPage){
    //     let init=false;
    //     if(actionPage=="")
    //         init=true;
    //     setActionPage(props.action);
    //     if(init==false)
    //         history.go(0);
    // }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['GK- Juan Gomez', 'DF- Luis Martínez', 'MD- Manuel Alen', 'FW - Alejandro Saez']);
    const [right, setRight] = React.useState(['MD- Alonso Moya', 'FW- Gonzalo Montes', 'DF- Rafael Simarro', 'DF- Gabriel del fresno']);

    const [t, i18n] = useTranslation('common');
    const [historyOrders,setHistoryOrders] = useState(null);
    const [resumeOrders,setResumeOrders] = useState(null);
    const [orderDetail,setOrderDetail] = useState(null);
    const [orderTransaction,setOrderTransaction] =useState(null);
    const [tab1Value, setTab1Value] = useState(0);
    const [tab2Value, setTab2Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [content2Value,setContent2Value] = useState(true);
    const [data,setData]=useState([]);
    const [stats,setStats] =useState(null);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [pricesPopup,setPricesPopup] =useState([]);
    const CssTextField = withStyles({
        root: {
            marginTop:15,
            '& label.Mui-focused': {
                color: 'green',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'green',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red',
                },
                '&:hover fieldset': {
                    borderColor: 'yellow',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green',
                },
            },
        },
    })(TextField);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleEvolutionNew=(e,id)=>{
        history.push("/tracing/"+id+"/new");
        console.log("tracing "+id);
    }
    const handleOnSetValue=(val)=>{
        setUpdateDetail(val);
    }
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const handleWallet=()=>{
        console.log("wallet");
    };
    const handleFavorite=(e,isFavorite)=>{
        console.log("favorite");
        console.log(isFavorite);
    }
    const handleFriends=(e,value)=>{
        console.log("friends");
        console.log(value);
    }
    const handleLiberarPedido=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const getImporte=()=>{
        if(orderPrice)
            return (<span className={classes.totalPrice}>{(FormatNumber(parseFloat(record.amount)*parseFloat(orderPrice)))} €</span>)
        else
            return (<b><span className={classes.redColor}>precio no establecido</span></b>)
    }

    const variacionImporte=()=>{
        let p=((officialBasePrice-orderPrice)*-1);
        if(p>0)
            return (<b><span className={classes.greenColor}>+{FormatNumber(p*record.amount)} €</span></b>)
        else if(p<0)
            return (<b><span className={classes.redColor}>{FormatNumber(p*record.amount)} €</span></b>)
        else
            return (<b><span className={classes.greenColor}>Coincide con los precios oficiales</span></b>)
    }
    const getClassDifference=()=>{
        let p=getPriceDifference();
        if(p>0)
            return classes.greenColor;
        else
            return classes.redColor;
    }
    const getPriceDifference=()=>{
        let p;
        if(record.base_price>0)
            p=record.base_price;
        else
            p=officialBasePrice;
        return ((p-orderPrice)*-1).toFixed(3);
        // return (((record.base_price>0?record.base_price:officialBasePrice)-orderPrice)*-1).toFixed(3).toString();
    }
    const handleLiberarPedidoSalir=()=>{
        let formData={"order_id":record.id};
        http_post("order-free",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }

   useEffect(() => {
        console.log("load");
        console.log(props.action);
        let url="";
        // if(window.location.href.substring(window.location.href.length-3,window.location.href.length)=="new"){
        //     setAction("new");
        //     setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":"New" }]);
        //     url=props.parentId+"/"+props.action+"/";
        // }else{
        setAction(props.action);
        if(props.action=="newAll") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action}]);
            url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        Call_Get(url,storeToken).then((res)=> {
            console.log(res);
            // console.log(res.item.itemColumns);
            // setFields(res.item.itemColumns);
            // if(props.action!="newAll") {
            setRecord(res.record);
            if(res.record.operator_id){
                if(res.record.operator_id==storeAdminId) {
                    setRecordBlocked(false);
                }
                else
                    setRecordBlocked(true);
            }

            //     setTracingDetail(res.item.tables.tracingDetails);
            //     setMonthWeight6(res.item.tables.MonthWeight6);
            //     setObjectiveWeight(res.item.tables.objectiveWeight);
            //     if(res.item.tables.objectiveWeight[0]) {
            //         setMinWeight(parseFloat(res.item.tables.objectiveWeight[0]["objectiveWeight"]) - 10);
            //         setMaxWeight(parseFloat(res.item.tables.objectiveWeight[0]["objectiveWeight"]) + 10);
            //     }
            // }else{
            //     setPlayersDetail(res.item.tables.players);
            // }
            setRelateds(res.related);
            setHistoryOrders(res.related["historyOrders"]);
            setResumeOrders(res.related["resumeOrders"]);
            if(res.record.status_id>=4){
                setOrderDetail(res.related["orderDetail"]);
                setOrderTransaction(res.related["orderTransaction"]);

            }
            // // setSessions(res.item.tables.sessions);
            // setTables(res.item.tables);
            // console.log(res);
        });
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);
    const htmlContenidoCliente=()=>{
        return (
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        id="filled-name"
                        label="Nombre"
                        type="text"
                        helperText="Nombre del cliente"
                        value={(record.client_name?record.client_name:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        id="filled-name"
                        label="Teléfono"
                        type="text"
                        helperText="Teléfono del cliente"
                        value={(record.client_phone?record.client_phone:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="address-billing"
                        label="Razón social factura"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Razón social de la empresa para facturar"
                        value={(record.billing_company?record.billing_company:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="address-billing"
                        label="NIF"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="NIF del cliente"
                        value={(record.billing_nif?record.billing_nif:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        id="address-billing"
                        label="Dirección de facturacion"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Dirección de facturación"
                        value={(record.billing_address?record.billing_address:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        id="cp"
                        label="CP facturación"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="CP de facturación"
                        value={(record.billing_address_cp?record.billing_address_cp:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="observations"
                        label="Observaciones"
                        helperText="Observaciones de este cliente"
                        value={(record.observations?record.observations:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="observations_gsym"
                        label="Observaciones de GSYM"
                        helperText="Observaciones de GSYM para este pedido"
                        value={(record.observations_gsym?record.observations_gsym:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="invoice_url"
                        label="Descarga de factura"
                        helperText="Click de descarga de factura"
                        value={(record.invoice_url?record.invoice_url:"")}
                        fullWidth
                        />
                </Grid>
            </Grid>
        );
    }
    const htmlContenidoDetalle=()=>{
        return (
            <Grid container >
                {orderDetail && orderDetail.data.map((item)=>{
                    return(
                        <Grid container >
                            <Grid item xs={6} className={classes.line}>
                                {item.line}
                            </Grid>
                            <Grid item xs={6} className={classes.line}>
                                {FormatNumber(item.price)} Eur
                            </Grid>
                        </Grid>
                    )
                })}
            <Grid item xs={6} className={classes.totalLine}>
                    <b>Total</b>
                </Grid>
                <Grid item xs={6} className={classes.totalLine}>
                    <b>{orderDetail && FormatNumber(orderDetail.total)} Eur</b>
                </Grid>
            </Grid>
    )}
    const htmlContenidoPedido=()=>{
        return (
            <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={(props.action=="view"?"":classes.editInput)}
                        InputProps={{
                            readOnly: (props.action=="view"?true:false)
                        }}
                        InputLabelProps={{ shrink: true }}
                        id="filled-number"
                        label="Num.Pedido"
                        value={(props.action=="view"?(record.number?record.number:""):(numPedido==null?record.number:numPedido))}
                        defaultvalue="1"
                        onChange={handleNumPedidoChange}
                        fullWidth
                        helperText="Número de pedido"
                            />
                </Grid>
                <Grid item xs={6}  sm={3}>
                    <TextField
                        className={classes.textField}
                        id="filled-email"
                        label="Fecha Pedido"
                        type="email"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record.date?FormatDateTime(record.date):"")}
                        helperText="Fecha del pedido"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}  sm={3}>
                    <TextField
                        className={classes.textField}
                        id="filled-email"
                        label="Franja Solicitada"
                        type="default"
                        InputProps={{
                            readOnly: (props.action=="view"?true:false)
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record.hour_id?PedidoFranja(record.hour_id):"")}
                        helperText="Franja solicitada"
                        fullWidth
                    />
                    </Grid>
                    <Grid item xs={6}  sm={4}>
                        <TextField
                            className={classes.textField}
                            id="filled-email"
                            label="Fecha Entrega"
                            type="email"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={(record.date_delivery?FormatDateTime(record.date_delivery):"")}
                            helperText="Fecha de entrega GSYM"
                            fullWidth
                            />
                    </Grid>
                        <Grid item xs={6}  sm={4}>
                            <TextField
                                className={classes.textField}
                                id="filled-email"
                                label="Franja de Entrega"
                                type="email"
                                InputProps={{
                                    readOnly: true,
                                }}
                                value={(record.hour_delivery_id?PedidoFranja(record.hour_delivery_id):"")}
                                helperText="Franja de entrega GSYM"
                                fullWidth
                            />
                        </Grid>
                <Grid item xs={12} sm={4}>
                    {action==="view" && record.status_id && PedidoEstado(record.status_id,"order-view")}
                    {action!="view" && <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        id="status-id"
                        label="Estado"
                        helperText="Estado del cliente"
                        value={(record.status_id?PedidoEstado(record.status_id,"order-view",true):"")}
                        fullWidth
                    />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="amount"
                        label="Cantidad"
                        helperText="Cantidad en litros solicitada"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record.amount?record.amount:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        id="product-id"
                        label="Gasóleo seleccionado"
                        helperText="Tipo de gasóleo a suministrar"
                        value={(record.product_id?GetProducts(record.product_id):"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        id="address"
                        label="Dirección de entrega"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Dirección de entrega"
                        value={(record.address?record.address:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        id="cp"
                        label="CP"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Código postal"
                        value={(record.address_cp?record.address_cp:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="base-price"
                        label="Precio base"
                        helperText="Precio oficial base x litro"
                        value={(record.base_price?record.base_price:"")}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="precio"
                        label="Precio"
                        helperText="Precio para establecer a este pedido"
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        className={classes.textField}
                        id="filled-email"
                        label="Precio Gestionado por"
                        type="text"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record.operator_name?record.operator_name:"")}
                        helperText="Operador que ha gestionado el precio de este pedido"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
        );
    }
    const handleFormData=(o,v)=>{
        if(o=="deliveryDate") {
            v=moment(v.toLocaleString(),"DD/MM/YYYY").format('YYYY-MM-DD');
        }
        setFormData({
            ...formData,
            // Trimming any whitespace
            [o]: v
        });
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" &&<div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEditOrder}
                                                    >
                                                    <span className="textButton">Editar pedido</span>
                                            </Button>
                                            {/*<Button
                                                color="primary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<DeleteIcon />}
                                                onClick={handleOpenSetPrice}
                                                >
                                                Asignar Precio
                                            </Button>*/}
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={record.status_id==7}
                                                className={classes.button}
                                                startIcon={<DeleteIcon />}
                                                onClick={handleCancelOrder}
                                                >
                                                    <span className="textButton">Cancelar pedido</span>
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={handleSaveOrder}
                                                    >
                                                    <span className="textButton">Guardar pedido</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSaveOrder}
                                                    >
                                                    <span className="textButton">Cancelar guardar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <OrderInfoComponent action={props.action} orderTransaction={orderTransaction} record={record} numPedido={numPedido} orderDetail={orderDetail}
                                onSetFranja={(v)=>{handleFormData("franja",v)}}
                                onSetDate={(v)=>{handleFormData("deliveryDate",v)}}
                                onSetOrderObservations={(v)=>{handleFormData("orderObservations",v)}}/>
                                            {/* DETALLE */}
                                {/*{action == "view" &&*/}
                                <div className={classes.contentTab}>
                                    <div className={classes.content}>
                                        <div className={classes.tabComponent}>
                                            <AppBar position="static">
                                                <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="simple tabs example">
                                                    {<Tab className={classes.tab} label={t("tabs.history")} {...a11yProps(0)} />}
                                                    {<Tab className={classes.tab} label={t("tabs.resume")} {...a11yProps(1)} />}
                                                </Tabs>
                                            </AppBar>
                                            {<TabPanel value={tab1Value} index={0}>
                                                {historyOrders && <RelatedOrders table={historyOrders}/>}
                                            </TabPanel>}
                                            {<TabPanel value={tab1Value} index={1}>
                                                <OrdersResume data={resumeOrders}/>
                                            </TabPanel>}
                                        </div>
                                    </div>
                                </div>

                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>

                <Dialog
                    open={(openPopup?openPopup:false)}
                    onClose={handleClosePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Cotización de pedido"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <OfficialPrices2 prices={pricesPopup} />
                            <div className={classes.centerText}>
                                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSendPrice}>
                                    <TextField className={clsx(classes.bigTextField,classes.width50)}
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                    fullWidth
                                    id="email"
                                    label={"Litros"}
                                    name="Liters"
                                    value={record.amount}
                                    autoFocus
                                    />
                                    <TextField className={clsx(classes.bigTextField,classes.width50)}
                                    variant="outlined"
                                    margin="normal"
                                    disabled
                                    fullWidth
                                    id="email"
                                    label={"Precio oficial"}
                                    name="Price"
                                    onChange={handleSetPrice}
                                    value={(record.base_price && record.base_price>0?record.base_price:officialBasePrice)}
                                    autoFocus
                                    />
                                        <CssTextField
                                                className={clsx(classes.bigTextField,classes.margin)}
                                                label={"Precio asignado al cliente x litro"}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="custom-css-outlined-input"
                                                placeholder={"Ejemplo "+(record.base_price -0.01).toString()}
                                                value={orderPrice}
                                                onChange={handleSetPrice}
                                                helperText={<div>La variación es de <b><span className={getClassDifference()}>{getPriceDifference()} € x litro</span></b> sobre las tarifas oficiales</div>}
                                                autoFocus
                                            />
                                                <Divider/>
                                </form>
                                <div className={clsx(classes.centerText,classes.totalStyle)}>
                                    <br/>
                                    Importe Total= {getImporte()}
                                    <br/>
                                </div>
                                <div className={classes.variationStyle}>
                                    Variación=El precio varia {variacionImporte()} con respecto a las tarifas oficiales
                                </div>
                                <Button variant="contained" onClick={handleSendPrice} color="primary">
                                                {"Enviar precio al cliente"}
                                            </Button>
                            </div>

                        </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClosePopup} color="primary">
                Cancelar
                </Button>
                </DialogActions>
                </Dialog>
                <PopupCancelOrder record={record} orderDetail={null} open={cancelPopup} onCancel={handleCancelPopup} onClose={handleCloseCancelPopup}/>
                </main>
            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>

    );
}
export default OrdersRecord;


import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const PopupDenyAddress = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const handleClosePopup=()=>{
        console.log("yes");
        props.onClose(false);
    }
    const handleYes=()=>{
        console.log("yes");
        props.onDenyAddress();
    }
    const handleNo=()=>{
        props.onClose(false);
    }
    useEffect(()=>{
        setOpenPopup(props.open);
    },[props]);
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Denegar dirección</DialogTitle>
        <DialogContent>
                        <div>
                            <div>¿Estás seguro que quieres denegar esta dirección?</div>
                            <div>Dirección:<b>{props.address} {props.cp}</b></div>
                            <div>Se notificará al cliente de que esa dirección no es válida y tendrá que modificarla ya que no hemos podido hayar una ubicación exacta debido a que faltan campos o son incompletos.</div>
                        </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes} color="primary">
        Denegar
        </Button>
        <Button onClick={handleNo} color="primary">
        No
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupDenyAddress;




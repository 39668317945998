import React, {useEffect,useState,useCallback} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Button, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from "mui-datatables";
import CustomClientsToolbar from "./CustomClientsToolbar";
import history from "../providers/History/history";
import Geocode from "react-geocode";
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import {GoogleMap,GoogleMapLoader,useLoadScript,Marker,InfoWindow}
from "@react-google-maps/api";

import usePlacesAutoComplete,{getGeocode,getLatLng} from 'use-places-autocomplete';
import MapSearchComponent from "./MapSearchComponent";
import {Combobox, ComboboxInput, ComboboxOption, ComboboxPopover} from "@reach/combobox";
import pinIcon from '../res/pin-icon.png';
import EditIcon from "@material-ui/icons/Edit";
import Popup from "./Popup";
import PopupVerifyAddress from "./PopupVerifyAddress";
const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    },
    searchMap:{
        position:'relative',
    },
    searchBlock:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    button:{
        margin:20
    },
    // search:{
    //     position:'absolute',
    //     top: '1rem',
    //     left: '25%',
    //     transform: 'translateX(-25%)',
    //     width: '100%',
    //     maxWidth: '800px',
    //     zIndex: 1000
    // },
    searchInput: {
        padding: '0.5rem',
        fontSize: '1rem',
        width: '100%'
    },
    searchPopover:{
        backgroundColor:'#ffffff',
        border:'1px solid #cccccc',
        listStyleType:'none'
    },
    searchOption:{
        height:'50px',
        padding:10,
        '&:hover':{
            backgroundColor:'#cccccc',
            cursor:'pointer'
        }
    },

    inline:{
        display:'inline-block',
        padding:10,
        margin:10,
        width:'350px'
    }
}));


const MapComponent = (props) => {
    const [markerPosition,setMarkerPosition]=useState(null);
    const [markerSelected,setMarkerSelected] =useState(null);
    const [addressSelected,setAddressSelected] =useState(null);
    const [popupVerifyAddress,setPopupVerifyAddress] =useState(false);
    const [cpSelected,setCPSelected] =useState(null);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    const onclosePopupVerifyAddress=()=>{
        setPopupVerifyAddress(false);
    }
    const onVerifyAddress=(lat,lng,address,cpSel,navisionId)=>{
        console.log("1",lat);
        console.log("2",lng);
        console.log("3",address);
        console.log("4",cpSel);
        setPopupVerifyAddress(false);
        props.onVerifyAddress(lat,lng,address,cpSel,navisionId);

    }
    const handleModifyAddress=()=>{
        console.log("modify address",markerSelected,addressSelected);
    }
    useEffect(() => {
        // console.log("value",props.address);
        Geocode.setApiKey("AIzaSyAAQ9WssXuFdBkkbKdw_nWtznHPMr_gu3Q");
        Geocode.setRegion("es");
        Geocode.setLanguage("es");
        // console.log("Cargado el mapa");
        // console.log("mostrar mapa",props.address);
        // console.log("1",props.position);
        let pos=null;
        if(props.position)
            pos=props.position.split(",");
        if(pos){
            // console.log(pos[0]);
            // console.log(pos[1]);

            let p={
                lat: parseFloat(pos[0]),
                lng: parseFloat(pos[1]),
                time: new Date(),
            };
            setMarkerSelected(p);
        }
    },[props]);
    const mapContainerStyle={
        width:"100%",
        height:"100vh"
    };

    const defaultCenter={
        lat:+38.99593210142879,
        lng:-1.8587000489722776
    }
    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    const libraries=["places"];
     const classes  = useStyles();
    const [map, setMap] = useState(null);
    const [mapLoaded,setMapLoaded] = useState(null);
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
        setMapLoaded(true);
    }, []);
    const onMapClick=React.useCallback(function callback(e) {
        let m={
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            time: new Date(),
        };
        setMarkerSelected(
            m
        );
        // console.log("geocode",m.lat,m.lng);
        map.panTo({lat:m.lat,lng:m.lng});
        Geocode.fromLatLng(m.lat, m.lng).then(
            (response) => {
                // console.log("respuesta",response);
                // console.log("result",response.results[0]);
                let a= response.results[0].address_components;
                // console.log("datos direccion",a);
                if(a.length>2) {
                    const addr = a[1].long_name + " ," + a[0].long_name +", " + a[2].long_name;
                    // console.log("geocode1", response.results[0]);
                    // console.log("lat", m.lat);

                    setAddressSelected(addr);
                }else{
                    console.log("error",a);
                    alert("error en la direccion");
                }
            },
            (error) => {
                console.error(error);
            }
        );

    });
    const verifyAddress=async()=>{
        console.log("verificar 11111");
        console.log(addressSelected);
        console.log(cpSelected);
        console.log(markerSelected);
        setPopupVerifyAddress(true);
    }
    const panTo=async({lat,lng,address,cpSel})=>{
        // console.log("pppppp",cpSel);
        // console.log(lat);
        // console.log(lng);
        // console.log(address);
        setAddressSelected(address);
            setCPSelected(cpSel);
        setMarkerSelected({
            lat: lat,
            lng: lng,
            time: new Date(),
        });
        map.setZoom(15);
        map.panTo({lat,lng});


    }
    const handleSearch=(searchValue)=>{
        console.log("searchvalue",searchValue);
        // const {}=usePlacesAutoComplete({requestOptions})
    }
    const handleVerifyAddress=(e)=>{
        console.log("verificar direccion");
    }

    const {isLoaded,loadError} =useLoadScript({
            googleMapsApiKey:"AIzaSyAAQ9WssXuFdBkkbKdw_nWtznHPMr_gu3Q",
            libraries
        })
        if(loadError)return "error loading maps";
        if(!isLoaded)return "Loading maps...";
        else
        return (
            <div className={classes.searchMap}>
        {props.action=="edit" && <div className={classes.searchBlock}>
                    <Typography>Escribe una dirección a verificar</Typography>
                    <Search2  addr={props.address+ " "+ props.cp} value={addressSelected} panTo={panTo} verifyAddress={verifyAddress}/>
                </div>}

                {/* <MapSearchComponent onSearch={handleSearch}/>*/}
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={(props.gps_position?null:defaultCenter)}
                    zoom={15}
                    onLoad={onLoad}
                    onClick={onMapClick}
                    onUnmount={onUnmount} >
                        {markerSelected && <Marker
                            key={`${markerSelected.lat}-${markerSelected.lng}`}
                            position={{ lat: markerSelected.lat, lng: markerSelected.lng }}
                            icon={{
                            url: pinIcon,
                            origin: new window.google.maps.Point(0, 0),
                            // anchor: new window.google.maps.Point(30, 30),
                            // scaledSize: new window.google.maps.Size(60, 60)
                            }}
                            />}
                    </GoogleMap>
                            <PopupVerifyAddress open={popupVerifyAddress}
                            address={addressSelected} cp={cpSelected}
                            lat={(markerSelected?markerSelected.lat:null)} lng={(markerSelected?markerSelected.lng:null)}
                            onClose={onclosePopupVerifyAddress}
                            onVerifyAddress={onVerifyAddress}/>
            </div>
        );


}
// const  Search=()=>
//     const {ready,value,suggestions:{status,data},setValue,clearSuggestions} = usePlacesAutoComplete({
//         requestOptions: {
//
//             location: {lat: () => 43.22255, lng: () => -1.855222},
//             radius:200*1000
//         }
//     });
//     return (<div className={classes.search} >1</div>)
// }

export default MapComponent;
function Search2(props){
    const [loaded,setLoaded] =useState(false);
    const [tmpValue,setTmpValue] =useState(null);
    const [selectValue, setSelectValue] = useState(null);
    const classes=useStyles();
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions,
    } =usePlacesAutoComplete({
        requestOptions:{
            location:{lat:()=>38.99599915306523,lng:()=>-1.860931805737518},
            radius:200*1000,
        }
    });
     useEffect(() => {
        if(props.value!="") {
            console.log("cambio en value",props.value);

            if(loaded==false || props.value!=tmpValue) {
                if (props.value) {
                    console.log("llamada a google apis");
                    setLoaded(true);
                    setTmpValue(props.value);
                    search(props.value);
                }
            }

            async function search(str) {
                await searchString(str);
            }
            // setTimeout(()=>{
            //
            // },1000);

            // await searchString(props.value);
        }else{
            setValue("")
        }
    },[props]);
    const handleSearchAddress=async(e)=>{
        console.log("search");
        await searchString(value);
    }
    const handleLoadAddress=async(e)=>{
        console.log("load address");
        await loadAddress();
    }
    const handleModifyAddress=(e)=>{
        console.log("modify");
        props.verifyAddress();
    }
    const handleSearchKey=async(e)=>{
        if (e.key === 'Enter') {
            console.log('do validate');
            await searchString(value);
        }
    }
    const loadAddress=async()=>{
        console.log("cogemos la direccion mas el codigo postal");
        console.log(props.addr);
        setValue(props.addr);
    }
    const searchString=async(address)=>{
        console.log("buscar address",address);
        setValue(address,false);
        clearSuggestions();
        setSelectValue(true);
        try{
            const results=await getGeocode({address});
            const {lat,lng} =await getLatLng(results[0]);
            // console.log(lat,lng);
            console.log(results[0]);
            let found=results[0].address_components.find(element=>element.types=='postal_code');
            let cpSel=null;
            if(found) {
                cpSel=found.long_name;
            }
            props.panTo({lat,lng,address,cpSel});

        }
        catch(error){
            console.log(error);

        }
    }

    return (<div className={classes.search}>
        <Combobox className={classes.inline}
    autocomplete={false}
            onSelect={async (address)=>{
               await searchString(address)
            console.log(address);
        }}>
            <ComboboxInput className={classes.searchInput}
                value={value}
                onChange={(e)=>{
                    setValue(e.target.value);
                }}
                onKeyDown={handleSearchKey}
                disabled={!ready}
                placeholder="Escribe una dirección..."
            />
            <ComboboxPopover className={classes.searchPopover}>
                    {status==="OK" && data.map(({id,description})=>(
                        <ComboboxOption className={classes.searchOption} key={(id?id+Math.random():Math.random())} value={description} />
                    ))}
            </ComboboxPopover>
        </Combobox>
    <Button
    variant="contained"
    color="secondary"
    onClick={handleSearchAddress}
    className={classes.button}
    startIcon={<SearchIcon />}
>
    Buscar
    </Button>
    <Button
    variant="contained"
    color="secondary"
    onClick={handleLoadAddress}
    className={classes.button}
    startIcon={<SearchIcon />}
>
    Cargar dirección
    </Button>
    {selectValue && <Button
        color="primary"
        variant="contained"
        className={classes.inline}
        startIcon={<CheckIcon />}
        className={classes.button}

        onClick={handleModifyAddress}
            >
            Verificar dirección
        </Button>}
        </div>);
}


import React,{useState,useEffect} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Dialog, Typography, Paper} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    selected:{
        backgroundColor:"#ff0000",
        color:"#ffffff"
    },
    normal:{
        backgroundColor:"#f0f0f0",
        color:"#000000"
    },
    paperSize:{
        width:'50px',
        height:'50px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
const OperatorPopup = (props) => {
    const classes = useStyles();
    const [openPopup,setOpenPopup]=useState(false);
    const [operatorValue,setOperatorValue] =useState(null);
    const handleClosePopup=()=>{
        props.onSetValue(props.open,operatorValue)
    }
    const handleSelectY=()=>{
        setOperatorValue("Y");
    }
    const handleSelectO=()=>{
        setOperatorValue("O");
    }
    useEffect(()=>{
        setOpenPopup(props.open);
        setOperatorValue(props.value);
    },[props]);
    return(
        <Dialog
            open={(openPopup?openPopup:false)}
            onClose={handleClosePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Cambio de Operador</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Grid container>
                    <Grid item xs={6}>
                        <Paper onClick={handleSelectY} className={clsx(classes.paperSize,(operatorValue==="Y"?classes.selected:classes.normal))}><Typography>Y</Typography></Paper>
                       </Grid>
                    <Grid item xs={6}>
                        <Paper onClick={handleSelectO} className={clsx(classes.paperSize,(operatorValue==="O"?classes.selected:classes.normal))}><Typography>O</Typography></Paper>
                    </Grid>
                </Grid>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClosePopup} color="primary">
        Ok
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default OperatorPopup;




import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../../components/AppBarComponent";
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import Footer from "../../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import history from "../../../providers/History/history";
import useLocalStorage from "../../../providers/store/localStorage";
import {Paper} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_get} from "../../../providers/http/http";
import {http_post} from "../../../providers/http/http";
import {get_Status_User_Team} from "../../../providers/Utils/utils";
import Field from "../../../components/Field";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import {Call_Get} from "../../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../../theme/theme";
import TableViewComponent from "../../../components/TableViewComponent";
import FieldRecord from "../../../components/FieldRecord";
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#3d3f49"
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#383b44"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldValue100:{
        width:"100%"
    },
    fieldRow: {
        ...myTheme.fieldRow
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
}));
const ProfileUserRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [t, i18n] = useTranslation('common');
    const [tables,setTables] =useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [last5Local,setLast5Local] = useState([]);
    const [last5Away,setLast5Away] =useState([]);
    const [upDownDetail,setUpDownDetail] =useState([]);
    const [revisionDetail,setRevisionDetail] =useState([]);
    const [permissionsTable,setPermissionsTable] =useState(null);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [updatePermissions,setUpdatePermissions] =useState(null);
    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed){
            if(props.action=="view")
                history.push("/"+props.parentId);
            else{
                history.goBack();
            }
        }
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }
    }
    const handleDelete=(e)=>{
        console.log("vamos a eliminar el id="+props.id);
        http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
            if(res.res=="ok")
                history.push("/"+props.parentId);
            else
                alert("There´s been an error during save action");
        });
    }
    const handleResend=(e)=>{
        console.log("resend invitation");
    }
    const handlePermissions=(val)=>{
        console.log("permissions");
        console.log(val);
        setUpdatePermissions(val);
        // setUpdateDetail(val);
        // setUpdatePermissions(JSON.stringify(val));
        // console.log("ddd");
        // setUpDownDetail(val);
    }

    const handleView=(e,rowData)=>{
        // console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
    }

    const handleSessionDetailSave=(e,id)=>{
        const postData = new FormData();
        for (var key in updateDetail)
            postData.append(key, updateDetail[key]);

        http_post(props.parentId+"/saveDetail",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                // alert("data saved successfully");
                window.location.reload();
            }else
                alert("There´s been an error during save action");
        });
    }
    const handleSave=(e,id)=>{
        var postItems=[];
        if(id!=undefined)
            updateRecord(id);
        else
            newRecord();
    }
    function updateRecord(id){
        const postData = new FormData();
        postData.append("id",id);
        // console.log(listItems);
        for (var key in listItems) {
            if(typeof listItems[key] === "object")
                postData.append(key, listItems[key]);
            else
                postData.append(key, listItems[key]);
        }
        // var postData={id:props.id,data:postItems};
        http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                if(props.rel){
                    history.push("/"+props.rel+"/"+props.relid+"/view");
                }else
                    handleBack();
            }
            else
                alert("There´s been an error during save action");
        });
        // console.log("update");
    }
    function newRecord(){
        const postData = new FormData();
        console.log(listItems);
        if(listItems["email"] && listItems["company_name"]){
            for (var key in listItems) {
                if(typeof listItems[key] === "object")
                    postData.append(key, listItems[key]);
                else
                    postData.append(key, listItems[key]);
            }
            http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
                if(res.res=="ok") {
                    if(action=="new"){
                        history.goBack();
                    }else
                        history.push("/" + props.parentId);
                }else
                    alert("There´s been an error during save action");
            });
        }else
            console.log("nop");
    }
    // const handleOnSetValue=(val)=>{
    //     setUpdateDetail(val);
    // }
    const handleItems=(item)=>{
        listItems[item.item]=item.value;
        setListItems(listItems);
    }
    const handleSavePermissions=(e)=>{
        console.log("save permissions");
        console.log(updatePermissions);
        const postData = new FormData();
        postData.append("permissions",JSON.stringify(updatePermissions));
        // for (var key in updatePermissions)
        //     postData.append(key, updateDetail[key]);
        postData.append("id",props.id);
        http_post(props.parentId+"/"+props.id+"/save-permissions",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                // alert("data saved successfully");
                console.log(res);
                // window.location.reload();
            }else
                alert("There´s been an error during save action");
        });
    }
    useEffect(() => {
        let url="";
        let mode="";
        if(window.location.href.substring(window.location.href.length-3,window.location.href.length)=="new"){
            setAction("new");
            mode="new";
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":"New" }]);
            url=props.parentId+"/new";
        }else{
            setAction(props.action);
            // setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action+ " "+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
        if(props.action=="view")
            setIsRefreshed(false);
        if(mode!="new") {
            Call_Get(url, storeToken).then((res) => {
                // console.log(action);
                console.log(res);
                if (mode != "new") {
                    setRecord(res.item.record);

                    setBc([{
                        "id": props.id,
                        "title": props.parentTitle,
                        "src": props.parentUrl
                    }, {
                        "id": props.id + '-' + props.action,
                        "title": props.title + ' ' + props.action + ' "' + res.item.record.email + '"'
                    }]);
                }
                setTables(res.item.tables);
                setPermissionsTable(res.item.tables.permissions);
                // if(mode!="new")
                //setSessionDetail(res.item.tables.sessionDetails);
            });
        }
    },[]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
                <div className={classes.contentBody}>
                    <BreadCrumbComponent data={bc}/>
                    <Paper elevation={3} className={classes.paper} >
                        <FormControl className={classes.formFields}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <div>
                                        <IconButton aria-label="back" size="small" onClick={handleBack}>
                                            <BackIcon />
                                        </IconButton>
                                        {/*{props.action && props.action == "view" &&*/}
                                        {/*    <IconButton aria-label="back" size="small" onClick={handleEdit}>*/}
                                        {/*        <EditIcon />*/}
                                        {/*    </IconButton>*/}
                                        {/*}*/}
                                        {((props.action && props.action == "edit")) &&
                                            <IconButton aria-label="back" size="small" onClick={(e)=>{handleSave(e,props.id);}}>
                                                <SaveIcon/>
                                            </IconButton>
                                        }
                                        {((props.action && props.action == "new")) &&
                                        <IconButton aria-label="back" size="small" onClick={(e)=>{handleSave(e,props.id);}}>
                                            <SendIcon/> Send invitation
                                        </IconButton>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Paper>
                    <Box margin={2}>
                        <Grid container spacing={2}>
                            {/*--INFO  --*/}
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                    <Grid container spacing={2}>
                                        {record && action && <FieldRecord action={action} type="text" description="Email" field="email" title="Email" value={record.email} onSetItem={handleItems}/>}
                                        {record && action && <FieldRecord action={action} type="text" description="Status" field="status" title="Status" value={get_Status_User_Team(record.status)} onSetItem={handleItems}/>}
                                        {record.status==1 &&
                                            <IconButton aria-label="back" size="small" onClick={handleResend}>
                                                <SendIcon />Resend Invitation
                                            </IconButton>
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                            {/* PERMISOS DEL USUARIO */}
                            {action == "view" &&
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>{t("profile.userPermissions").toUpperCase()}</div><div className={classes.sectionLink}><div className={classes.textLink} onClick={handleSavePermissions}>Save permissions</div></div></div>
                                <div className={classes.sectionItem}>
                                    <Grid container spacing={2}>
                                        <Grid item md={12} xs={12}>
                                            {permissionsTable && <TableViewComponent name="revisions" fields={(permissionsTable?permissionsTable["fields"]:null)} data={(permissionsTable?permissionsTable["data"]:null)} onSetValue={handlePermissions}></TableViewComponent>}
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            }
                        </Grid>
                    </Box>
                </div>
                <div className="page-footer">
                    <Footer/>
                </div>
            </div>
        </main>
    </div>

);
}
export default ProfileUserRecord;


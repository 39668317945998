import React,{useState,useEffect} from 'react';
import {Dialog, Divider} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import myTheme from "../theme/theme";
import clsx from "clsx";
import {http_post} from "../providers/http/http";
import history from "../providers/History/history";
import useLocalStorage from "../providers/store/localStorage";
import {GetPaymentType, openWindowRedsys} from "../providers/Utils/utils";
import PopupYesNo from "./PopupYesNo";


const useStyles = makeStyles((theme) => ({
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },

    bigTextField:{
        "& div": {
            backgroundColor: 'rgba(200,200,200,.5)',
        },
        "& input":{
            fontSize:20,
            color:'#c0c0c0'
        }
    },
    text:{
        fontSize:14,
        color:'#c0c0c0',
        paddingTop:24
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    finalText:{
        color:"rgba(35,193,48,1)"
    }
}));

const PopupCancelOrder = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const classes = useStyles();
    const [finalAmount,setFinalAmount] =useState(null);
    const [finalPrice,setFinalPrice] =useState(null);
    const [otherPrice,setOtherPrice] =useState(null);
    const [totalPrice,setTotalPrice] =useState(null);
    const [totalPrice2,setTotalPrice2] =useState(null);
    const [paymentType,setPaymentType]=useState(null);
    const [totalPriceLabel,setTotalPriceLabel] =useState(null);
    const [totalPrice2Label,setTotalPrice2Label] =useState(null);
    const [openYesNoPopup,setOpenYesNoPopup] =useState(false);
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');

    const handleClosePopup=()=>{
        props.onClose(false);
    }
    const handleCancelPopup=()=>{
        setOpenYesNoPopup(true);

    }
    const getImporte=()=>{
        let myTotalPrice=props.record.amount*props.record.price+otherPrice;
        setTotalPrice(myTotalPrice);
        let v=new Intl.NumberFormat("es-ES",{style: "currency", currency: "EUR"} ).format(myTotalPrice);
        setTotalPriceLabel(v);
        // return v;
    }
    const getImporte2=(finalAmount,finalPrice)=>{
        let myTotal2=finalAmount*finalPrice+otherPrice;
        // setTotalPrice2(myTotal2);
        if(myTotal2>totalPrice) {
            alert("El precio no puede ser superior a lo que el cliente ya pagó con tarjeta");
            return false;
        }else {
            setTotalPrice2(myTotal2);
            let v=new Intl.NumberFormat("es-ES",{style: "currency", currency: "EUR"} ).format(myTotal2);
            setTotalPrice2Label(v);
            return true;
        }
    }
    const variacionImporte=()=>{
        let v=new Intl.NumberFormat("es-ES", {style: "currency", currency: "EUR"}).format(totalPrice-totalPrice2);
        return v;
    }
    const handleReturnYes=()=>{
        let formData={"orderId":props.record.id};
        console.log("toooken",storeToken,formData);
        http_post("order-admin-cancel-return", formData, storeToken).then((res) => {
            console.log("resultaddddddd",res);
            if(res.status=="ok"){
                if(res.redsys){
                    openWindowRedsys(res.redsys.url, res.redsys.version, res.redsys.parameters, res.redsys.signature);
                }else{
                    history.push("/orders/");
                }
            }else{
                alert("error desconocido");
            }
            setOpenYesNoPopup(false);
        });
        props.onCancel();
    }
    const handleCloseYesNoPopup=()=>{
        setOpenYesNoPopup(false);
    }
    const handleChangeFinalPrice=(e)=>{

        if(getImporte2(finalAmount,e.target.value)){
            setFinalPrice(e.target.value);
        }
    }
    const handleChangeFinalAmount=(e)=>{
        if(e.target.value<=props.record.amount) {
            setFinalAmount(e.target.value);
            getImporte2(e.target.value,finalPrice);
        }else
            alert("La cantidad entregada no puede ser superior a la solicitada");
    }

    useEffect(()=>{
        setTotalPrice(0);
        setTotalPrice2(0);

        setOpenPopup(props.open);
        // {"orderId":record.id,"amount":record.amount,"price":record.price}
        setFinalAmount(props.record.amount);
        setFinalPrice(props.record.price);
        setPaymentType(props.record.payment_type_id);
        // console.log(props.record);
        // console.log("orderdetail",props.orderDetail);
        generateOtherPrice(props.orderDetail);
        getImporte();
        getImporte2(props.record.amount,props.record.price);
    },[props]);

    const generateOtherPrice=(orderDetail)=>{
        let sum=0;
        if(orderDetail) {
            // console.log(orderDetail.data);
            for(let el in orderDetail.data){
                // console.log("element",el);
                if(orderDetail.data[el].order_detail_category_id!=1){
                    sum+=orderDetail.data[el].price;
                }
            }
        }
        //
        setOtherPrice(sum);
    }
    return(
        <>
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">¿Cancelar pedido?</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className={classes.centerText}>
                    {paymentType==1 && <div>¿Estas seguro que quieres cancelar este pedido?, si se pagó con tarjeta tendrás que efectuar la devolución</div>}
    {paymentType!=1 && <div>¿Estás seguro que quieres cancelar este pedido? el cliente no llegó a pagarlo por tanto no hay ninguna devolución que realizar</div>}
                    <form className={classes.form} noValidate autoComplete="off" >
                        <TextField className={clsx(classes.bigTextField,classes.width50)}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                            id="email"
                            label={"Cantidad solicitada"}
                            name="Liters"
                            value={props.record.amount}
                            autoFocus
                        />
                        <TextField className={clsx(classes.bigTextField,classes.width50)}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                            id="email"
                            label={"Precio asignado x litro"}
                            name="Price"
                            value={props.record.price}
                            autoFocus
                        />
                        <div className={clsx(classes.text)}>
                            Otros cargos debidos a gastos de forma de pago o dto de promociones
                        </div>
                        <TextField className={clsx(classes.bigTextField,classes.width50)}
                            variant="outlined"
                            margin="normal"
                            disabled
                            fullWidth
                            id="email"
                            label={"Precio otros cargos"}
                            name="Price"
                            value={otherPrice}
                            autoFocus
                        />
                            {props.record && props.record.payment_type_id && <div className={classes.finalText}>Forma de pago:{GetPaymentType(props.record.payment_type_id)}</div>}
                            <Divider/>
                    </form>
                    {paymentType==1 && <div className={clsx(classes.totalStyle)}>
                        <br/>
                        Importe a devolver (en caso de tener forma de pago Visa)<br/>
                        Total= {totalPriceLabel}
                        <br/>
                    </div>}
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCancelPopup} color="primary">
            Cancelar pedido
        </Button>
        <Button onClick={handleClosePopup} color="primary">
            Salir
        </Button>
        </DialogActions>
        </Dialog>
        <PopupYesNo title="Devolución" description={"¿Estás seguro que quieres hacer la devolución del pedido?"} open={openYesNoPopup} onYes={handleReturnYes} onClose={handleCloseYesNoPopup}/>
        </>
)
}

export default PopupCancelOrder;




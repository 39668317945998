import React,{useState,useEffect} from 'react';
import {Dialog, IconButton, InputLabel, TextField, Tooltip} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {PhotoCamera} from "@material-ui/icons";
import {AttachFile} from '@material-ui/icons';
import {http_post} from "../providers/http/http";
import useLocalStorage from "../providers/store/localStorage";
import ReactHtmlParser from 'react-html-parser';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const PopupDevolucion = (props) => {

    useEffect(() => {
    },[]);

    const useStyles = makeStyles((theme) => ({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        faceImage: {
            color: theme.palette.primary.light,
        },
    }));
    const [openPopup,setOpenPopup]=useState(false);
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const classes = useStyles();
    const [dateInvoice,setDateInvoice] =useState("");
    const [nameInvoice,setNameInvoice] =useState("");
    const [documentTypeSelected,setDocumentTypeSelected] =useState(-1);
    const [documentTypes,setDocumentTypes] =useState([{"id":-1,"name":"Sin seleccionar"},{"id":1,"name":"Factura"},{"id":2,"name":"Modelo 347"},{"id":3,"name":"Otros"}]);
    const handleClosePopup=()=>{
        console.log("yes");
        props.onClose(false);
    }
    const handleSave=()=>{
        console.log("Guardar nueva factura");
        console.log("fecha",dateInvoice);
        console.log("numFactura",nameInvoice);
        console.log("file",selectedFile);
        console.log("documentType",documentTypeSelected);

        if(dateInvoice!="" && nameInvoice!=="" && selectedFile!=null && documentTypeSelected!=-1){
            const formData = new FormData();

            // Update the formData object
            formData.append(
                "file",
                selectedFile,
                selectedFile.name
            );
            formData.append("id",props.id);
            formData.append("name",nameInvoice);
            formData.append("date",dateInvoice);
            formData.append("type",documentTypeSelected);

            http_post("document-upload",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    setNameInvoice("");
                    setDateInvoice("");
                    setSelectedFile(null);
                    props.onClose(false);
                    window.location.reload();
                }else{
                    alert("There´s been an error during save action");
                }
            });
            console.log("formulario",formData);

        }else{
            alert("faltan datos");
            return false;
        }


    }
    const handleChangeDate=(e)=>{
        setDateInvoice(e.target.value);
    }
    const handleChangeNameInvoice=(e)=>{
        setNameInvoice(e.target.value);
    }
    const handleCancel=()=>{
        setNameInvoice("");
        setDateInvoice("");
        setSelectedFile(null);
        setDocumentTypeSelected(-1);
        props.onClose(false);
    }
    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleCapture = ({ target }: any) => {
        setSelectedFile(target.files[0]);
    };
    const handleDocumentType=(e)=>{
        console.log(e.target.value);
        setDocumentTypeSelected(e.target.value);
    }
    useEffect(()=>{
        setOpenPopup(props.open);
    },[props]);
    return(
        <Dialog
            open={(openPopup?openPopup:false)}
            onClose={handleClosePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Nueva factura</DialogTitle>
        <DialogContent>
            <div>
    { ReactHtmlParser (props.data) }
            </div>
        </DialogContent>
    </Dialog>
)
}

export default PopupDevolucion;




import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import ResendIcon from '@material-ui/icons/Email';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import {Button, Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

const defaultToolbarStyles = {
    iconButton: {
    },
};

const CustomDeleteToolbar = (props) => {
    const [selected,setSelected] =useState([]);
    const [deletePopup,setDeletePopup] =useState(false);
    const handleCloseDeletePopup=()=>{
        setDeletePopup(false);
    }
    useEffect(() => {
        console.log("custom delete toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);

        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data,props.mode]);
    const handleClickDeleted=()=>{
        console.log("Delete element");
        setDeletePopup(true);

        // props.onRowView("value");
    }
    const handleDelete=()=>{
        setDeletePopup(false);
        props.onRowDeleted(props.selectedRows)
    }
    // const { classes } = props;

        return (
            <div>
            <Tooltip title={"Eliminar elemento"}>
                <IconButton  onClick={handleClickDeleted}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        <Dialog
            open={(deletePopup?deletePopup:false)}
            onClose={handleCloseDeletePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Eliminar elemento/s</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div>¿Estás seguro que quieres eliminar el/los elemento/s seleccionado/s?</div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDelete} color="primary">
        {"Sí"}</Button>
        <Button onClick={handleCloseDeletePopup} color="primary">
        {"No"}
        </Button>
    </DialogActions>
    </Dialog>
        </div>
        );

}
export default CustomDeleteToolbar;
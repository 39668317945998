

import React, {Fragment, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import {Button, Dialog, Paper} from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Call_Get, CheckToken, FormatDate} from "../../providers/Utils/utils";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get, http_post} from "../../providers/http/http";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';
import myTheme from "../../theme/theme";
import EditIcon from '@material-ui/icons/Edit';
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    sectionBackground:{
        // backgroundColor:"#242518",
        marginBottom:"10px",
    },
    sectionTitle:{
        borderBottom:"4px solid #1b2982",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    centerItem:{
        textAlign:"center"
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    appbar:{
        height:'5'
    },
    centerContent:{
        textAlign:"center"
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldField:{
        ...myTheme.fieldField
    },
    fieldRow: {
        ...myTheme.fieldRow
    },
    fieldTitle2:{
        color:"#a8a4a4",
        fontSize:"12px",
        textAlign:"left"
    },
    contentBlock:{
        display: 'flex',
        alignItems: 'center',
        justifyContent:"center",

    },
    contentWidth:{
        // maxWidth:"600px",
        width:'100%',
        maxWidth:'600px'

    },
    gridBlock:{
        marginBottom:"15px"

    },
    imageLogo:{
        width:"90px",
        height:"120px"
    },
    button:{
        margin:"5px"
    },
    profilePaper:{
        //minWidth:500,
        maxWidth:800,
        padding:15
    },
    sectionItem:{
        justifyContent:'center',
        padding:"10px",
        display:'flex',
    },
}));


export default function Profile() {
    const classes = useStyles();
    const bc=[{"id":"profile","title":"Profile"}];
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    // const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeName,setStoreName] =useLocalStorage('GSYM_store_name');
    const [profile,setProfile] =useState([]);
    const [profileEmail,setProfileEmail] =useState(null);
    const [t, i18n] = useTranslation('common');
    const [openPassPopup,setOpenPassPopup] =useState(false);
    const [newPass,setNewPass] =useState(null);
    const [newPassCheck,setNewPassCheck] =useState(null);
    const handleButtonBack=e=>{
        console.log("back");
        history.goBack();
    }
    useEffect(() => {
        console.log("profile");
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        setPageSelected({"id":"profile","title":"profile"});
        Call_Get('admin-profile',storeToken).then((res)=> {
            setProfile(res.data);
        }).catch(err=>{
            console.log(err);
        });
    },[]);
    const handleProfileEmailChange=(e)=>{
        setProfileEmail(e.target.value)
    }
    const handleChangePassword=()=>{
        setOpenPassPopup(true);
        console.log("pass");
    }
    const handleChangePasswordAction=()=>{
        console.log("action change pass to server");
        if(newPass==newPassCheck){
            let formData={"newPass":newPass};
            http_post("profile-change-password",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    setOpenPassPopup(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
        }else{
            alert("Las contraseñas no coinciden");
        }
    }
    const handleClosePopup=()=>{
        setOpenPassPopup(false);
    }
    const handlePassChange=(e)=>{
        console.log(e.target.value);
        setNewPass(e.target.value);
    }
    const handlePassCheckChange=(e)=>{
        setNewPassCheck(e.target.value);
    }
    return (
        <div className={classes.root}>
            <AppBarComponent mode="profile" selected={pageSelected} />
            <CssBaseline />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent theme="dark" data={bc}/>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>PERFIL</div></div>
                                <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                    <Paper elevation={3} className={classes.profilePaper}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>
                                                <div>Email</div>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    id="email"
                                                    label="Email del usuario"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={"Email de la cuenta"}
                                                    value={profile["email"]}
                                                    fullWidth
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>Nombre</div>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    id="nombre"
                                                    label="Nombre del usuario"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={"Nombre del usuario de la cuenta"}
                                                    value={profile["name"]}
                                                    fullWidth
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>Rol</div>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    id="rol"
                                                    label="Rol"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={"Rol para el acceso a la plataforma"}
                                                    value={profile["rol_name"]}
                                                    fullWidth
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>Contraseña</div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id="nombre"
                                                    label="Contraseña"
                                                    InputLabelProps={{ shrink: true }}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={"*****"}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    className={classes.button}
                                                    startIcon={<CancelIcon />}
                                                    onClick={handleChangePassword}
                                                >
                                                        Cambiar contraseña
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </div>
                            </Grid>
                        </Grid>

                        <div className={clsx(classes.contentBody,"page-footer")}>
                            <Footer/>
                        </div>
                    </div>

                </div>
            </main>
            <Dialog
                open={(openPassPopup?openPassPopup:false)}
                onClose={handleClosePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                    >
                <DialogTitle id="alert-dialog-title">Cambio de Contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>
                            <TextField
                                id="email"
                                label="Contraseña"
                                InputLabelProps={{ shrink: true }}
                                helperText={"Escribe la nueva contraseña"}
                                value={newPass}
                                type="password"
                                onChange={handlePassChange}
                                fullWidth
                            />
                            <TextField
                                id="email"
                                label="Repetir contraseña"
                                InputLabelProps={{ shrink: true }}
                                helperText={"Vuelve a escribir la nueva contraseña"}
                                value={newPassCheck}
                                type="password"
                                onChange={handlePassCheckChange}
                                fullWidth
                                />
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangePasswordAction} color="primary">
                        Cambiar contraseña
                    </Button>
                    <Button onClick={handleClosePopup} color="primary">
                    Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

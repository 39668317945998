import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, Paper} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import {getWheelDelta} from "leaflet/src/dom/DomEvent";
import RelatedAddress from "../../components/RelatedAddress";
import RelatedOrders from "../../components/RelatedOrders";
import OrdersResume from "../../components/OrdersResume";
import OrderInfoComponent from "../../components/OrderInfoComponent";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        margin:'auto'
    },
    block:{
        width:400,
        height:400,
        border:"1px solid #ff2727",
        borderRadius:"5px",
        margin:'auto',
        marginTop:'40px',
        backgroundColor:'#ffffff'
    },
    header:{
        height:40,
        width:'100%',
        textAlign:'center',
        borderBottom:'1px solid #000000',
        paddingTop:10
    },
    result:{
        paddingTop:20,
        textAlign:'center'
    },
    resultValue:{
        textAlign:"center",
        fontSize:24
    },
    closePage:{
        paddingTop:20,
        textAlign:'center'
    },
    buttonClose:{
        backgroundColor:"#ff2727"
    },
    orderUrl:{
        textAlign:"center"
    }

}));

const CancelOk = (props) => {
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const matches = useMediaQuery('(min-width:800px)');
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [returnStatus,setReturnStatus] =useState(false);
    const [returnData,setReturnData] =useState(null);
    const [message,setMessage] =useState(null);
    const [orderUrl,setOrderUrl] =useState(null);

   useEffect(() => {
       console.log("load");
       let v = new URLSearchParams(window.location.search);
       let check=v.get('check');
       let order=v.get('order');
       let d=new Date();

       let date= moment(d).format("YYYY-MM-DD")
       console.log(date);
       console.log(btoa(order+date));
       if (btoa(order+date)==check){
           console.log("yes");
           console.log(storeToken);
           let formData={"order_id":order};
           http_post("order-admin-cancel-finish",formData,storeToken).then((res)=> {
               if(res.status=="ok"){
                   // alert(JSON.stringify(res.data[0].data));
                   console.log(res);
                  history.push("/orders");
               }else{
                   alert("There´s been an error during save action");
               }
           });
       }else{
           console.log("noo");
           alert("datos erroneos");
       }
       console.log("order",order);
       // let formData={"orderId":order,"final_amount":final_amount,"final_price":final_price,"return":returnAmount};
       // http_post("order-return-finish",formData,storeToken).then((res)=> {
       //     if(res.status=="ok"){
       //         // alert(JSON.stringify(res.data[0].data));
       //         console.log(res);
       //         setReturnStatus(true);
       //         setReturnData(res.data);
       //         setMessage(res.message);
       //         setOrderUrl(res.url);
       //     }else{
       //         alert("There´s been an error during save action");
       //     }
       // });

       //https://gsym-admin.tvrlp.com/devolucionok?final_amount=1400&final_price=0.75&amount=75&Ds_SignatureVersion=HMAC_SHA256_V1&Ds_MerchantParameters=eyJEc19EYXRlIjoiMTclMkYxMSUyRjIwMjEiLCJEc19Ib3VyIjoiMTElM0EwNCIsIkRzX1NlY3VyZVBheW1lbnQiOiIxIiwiRHNfQW1vdW50IjoiNzUwMCIsIkRzX0N1cnJlbmN5IjoiOTc4IiwiRHNfT3JkZXIiOiIyMTExMTY1MTk0IiwiRHNfTWVyY2hhbnRDb2RlIjoiMTY0MTQxODg5IiwiRHNfVGVybWluYWwiOiIwMDEiLCJEc19SZXNwb25zZSI6IjA5MDAiLCJEc19UcmFuc2FjdGlvblR5cGUiOiIzIiwiRHNfTWVyY2hhbnREYXRhIjoiIiwiRHNfQXV0aG9yaXNhdGlvbkNvZGUiOiIxNTY5ODEiLCJEc19Db25zdW1lckxhbmd1YWdlIjoiMSIsIkRzX0NhcmRfQ291bnRyeSI6IjcyNCIsIkRzX0NhcmRfQnJhbmQiOiIxIiwiRHNfUHJvY2Vzc2VkUGF5TWV0aG9kIjoiNSJ9&Ds_Signature=ITRJfhhvPasVmrV9iijIqlThBS1YeE4KWHjlsO3BXf0=
        // console.log(props.action);
        let url="";
        // setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);
   const handleClose=()=>{
       console.log("cerrar pagina1");
       window.close();
       console.log("cerrar");
   }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.block}>
                        <div className={classes.header}>
                            Retorno de la devolución
                        </div>
                        <div className={classes.result}>
                            El resultado de la devolucion es <br/>
                            <div className={classes.resultValue}>
                                OK
                            </div>

                        </div>
                        {returnStatus==true && <div className={classes.result}>
                            Actualización del pedido <br/>
                            <div className={classes.resultValue}>
                                {returnData}
                            </div>
                         </div>}
                        {message && <div className={classes.closePage}>
                            {message}
                        </div>}
                            {orderUrl && <div className={classes.orderUrl}>
                                También puedes hacer click en este enlace<br/>
                                <a href={orderUrl}>{orderUrl}</a>
                            </div>}
                    </div>
                </div>
            </main>
        </div>

    );
}
export default CancelOk;


import React from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
}));
const FieldImage = (props) => {
    const classes = useStyles();
    const handleChange=(e)=>{
        console.log("change");
    }
    if(props.action=="view"){
        return(
            <div id={props.item.field}>
                <div className="field-view-title" >{props.item.title}</div>
                <img src={(props.record?props.record:"-")} className="field-view-image"/>
            </div>
        );
    }else if(props.action=="edit"){
        return(
                <TextField
                    className={classes.textField}
                    id="filled-name"
                    label={props.item.title}
                    type={props.type}
                    helperText={(props.item.description?props.item.description:"")}
                    value={props.record}
                    onChange={handleChange}
                    fullWidth
                />
        );
    }
}

export default FieldImage;


import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../components/Footer';
import AppBarComponent from '../components/AppBarComponent';
import useLocalStorage from "../providers/store/localStorage";
import history from "../providers/History/history";
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbComponent from "../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../providers/http/http";
import {Call_Get, CheckToken} from "../providers/Utils/utils";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import fileDownload from 'js-file-download';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/GetApp';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f0f0f0"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    }

}));

const BaseTable2 = (props) => {

    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        history.push(pageSelected.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer');

    const bc=[{"id":props.id,"title":props.title}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [tableActions,setTableActions]=useState([
    ]);
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
        { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
        { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
        { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
        { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
    ];
    const {  onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    //refresh if we change the page from same basetable
    if(props.title!=pageSelected.title){
        history.go(0);
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            let foundAvatar=res.columns.findIndex(element=>element.field=="avatar");
            if(foundAvatar!=-1)
                res.columns[foundAvatar]["render"]=rowData => <img src={rowData.avatar} style={{width: 30,height:30 , borderRadius: '50%'}}/>;
            let foundImage=res.columns.findIndex(element=>element.field=="image");
            if(foundImage!=-1)
                res.columns[foundImage]["render"]=rowData => <img src={rowData.image} style={{width: 30,height:30}}/>;

            let foundDownload=res.columns.findIndex(element=>element.field=="documentUrl");
            if(foundDownload!=-1){
                let file=res.columns[foundDownload]["documentUrl"];
                res.columns[foundDownload]["render"]=rowData =>
                    <IconButton aria-label="download" className={(rowData.documentUrl!=null?classes.margin:"is-hidden")} size="small" onClick={(e)=>{handleDownloadFile(e,rowData.documentUrl)}}>
                        <DownloadIcon color="secondary" fontSize="inherit" />
                    </IconButton>;
            }
            setTable(res);
            var tmp_actions=tableActions;
            // if(res.allowNew!==false ){
            //     tmp_actions.push({
            //         icon: tableIcons.Add,
            //         tooltip: 'Add Element',
            //         isFreeAction: true,
            //         onClick: (event) => handleNew(event)
            //     });
            // }
            // if(res.allowView!==false){
            //     tmp_actions.push({
            //         icon: tableIcons.View,
            //             tooltip: 'View Element',
            //         onClick: (event, rowData) => handleView(event,rowData)
            //     });
            // }
            // if( res.allowEdit!==false){
            //     tmp_actions.push({
            //         icon: tableIcons.Edit,
            //             tooltip: 'Edit Element',
            //         onClick: (event, rowData) => handleEdit(event,rowData)
            //     });
            //
            // }
            // if(res.allowDelete!==false){
            //     tmp_actions.push({
            //         icon: tableIcons.Delete,
            //             tooltip: 'Delete Element',
            //         onClick: (event, rowData) => handleDelete(event,rowData)
            //     });
            //
            // }
            setTableActions(tableActions);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent selected={pageSelected} permission={storePermissions}/>
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
        <div className={(table.data==null?"circular-position":"is-hidden")}>
            <CircularProgress disableShrink className="is-loading"/>
        </div>
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>

    </div>
    <div className="page-footer">
        <Footer/>
        </div>
        </div>
        </main>
        </div>
);
}
export default BaseTable2;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Promocion_Dto_Directo = (props) => {
    const classes = useStyles();
    const [name] =useState("Por dto directo");
    const [dto,setDto] =useState(null);
    const [percentage,setPercentage] =useState(null);

    const handleDto=(e)=>{
        setDto(e.target.value);
    }
    const handlePercentage=(e)=>{
        setPercentage(e.target.value);
    }
    const handleSave=()=>{
        if(dto!=null) {
            console.log("apclicamos Promocion", props.id, name, dto);
            props.onSetValue(props.id, name, dto,dto+" €");
        }else
            props.onSetValue(props.id, name, percentage,percentage +" %");
    }
    return(
        <div className="father-header-title">
        <div className="">
        Descuento directo
    </div>
    <div>Descuento directo para clientes </div>
    <div className={classes.formControl}>
    <TextField
    id="email"
    label="Descuento"
    InputLabelProps={{ shrink: true }}
    helperText={"Descuento en euros"}
    autoComplete='off'
    onChange={handleDto}
    value={(dto?dto:"")}
    fullWidth
    />
    </div>
    <div className={classes.formControl}>
        <TextField
    id="percentage"
    label="Descuento (porcentaje)"
    InputLabelProps={{ shrink: true }}
    helperText={"Descuento porcentaje"}
    autoComplete='off'
    onChange={handlePercentage}
    value={(percentage?percentage:"")}
    fullWidth
    />
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Promocion_Dto_Directo;
import React, {useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableViewComponent from "./TableViewComponent";


const useStyles = makeStyles((theme) => ({
   calification:{
       width:"50px",
       minWidth:"50px",
       height:"40px",
       margin:"5px"
   },
    calification1:{
        width:"40px",
        minWidth:"40px",
        height:"30px"
    },
    buttonCalification:{
       width:"100%",
        textAlign:"center"
    }
}));
const ButtonCalificationComponent = (props) => {
    const [open,setOpen] =useState(false);
    const handleOpenDialog=e=>{
        setOpen(true);
    }
    const handleSetValue=(e,value)=>{
        props.onSetValue(value);
        setOpen(false);
    }
    const handleClose=e=>{
        setOpen(false);
    }
    const classes = useStyles();
    return(
        <div>
                <Button
                    size="small"
                    className={classes.calification1}
                    color="primary"
                    variant="contained"
                    name={props.name}
                    onClick={handleOpenDialog}
                >{props.value}
                </Button>
            <Dialog className={classes.pepe} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <div className={classes.content}>Selecciona una puntuación</div>
                    <div className={classes.buttonCalification}>
                        <Button  className={classes.calification} variant="contained" size="small" onClick={(e)=>{handleSetValue(e,1)}}
                                 color={(props.value==1?"primary":"default")}>
                            1
                        </Button>
                        <Button   className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,2)}}
                                  color={(props.value==2?"primary":"default")}>
                            2
                        </Button>
                        <Button  className={classes.calification}  variant="contained" size="small" onClick={(e)=>{handleSetValue(e,3)}}
                                 color={(props.value==3?"primary":"default")}>
                            3
                        </Button>
                    </div>
                    <div  className={classes.buttonCalification}>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,4)}}
                                 color={(props.value==4?"primary":"default")}>
                            4
                        </Button>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,5)}}
                                 color={(props.value==5?"primary":"default")}>
                            5
                        </Button>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,6)}}
                                 color={(props.value==6?"primary":"default")}>
                            6
                        </Button>
                    </div>
                    <div  className={classes.buttonCalification}>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,7)}}
                                 color={(props.value==7?"primary":"default")}>
                            7
                        </Button>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,8)}}
                                 color={(props.value==8?"primary":"default")}>
                            8
                        </Button>
                        <Button  className={classes.calification} variant="contained"  size="small" onClick={(e)=>{handleSetValue(e,9)}}
                                 color={(props.value==9?"primary":"default")}>
                            9
                        </Button>
                    </div>
                    <div  className={classes.buttonCalification}>
                        <Button  className={classes.calification} variant="contained" size="small" onClick={(e)=>{handleSetValue(e,10)}}
                                 color={(props.value==10?"primary":"default")}>
                            10
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ButtonCalificationComponent;
import React from 'react';
import Evaluador_Provincia from "./Evaluador_Provincia";
import Evaluador_Email from "./Evaluador_Email";
import Evaluador_CodPostal from "./Evaluador_CodPostal";
import Evaluador_GasoleoA from "./Evaluador_GasoleoA";
import Evaluador_GasoleoB from "./Evaluador_GasoleoB";
import Evaluador_GasoleoC from "./Evaluador_GasoleoC";
import Evaluador_Almacen from "./Evaluador_Almacen";
import Evaluador_OtraConsulta from "./Evaluador_OtraConsulta";
import Evaluador_FechaCompra from "./Evaluador_FechaCompra";
import Evaluador_FechaRegistro from "./Evaluador_FechaRegistro";
import Evaluador_Todos from "./Evaluador_Todos";
import Evento_Fin_Compra from "./Evento_Fin_Compra";
import Evento_Login from "./Evento_Login";
import Action_Descuento from "./Action_Descuento";
import Action_Codigo_Promocional from "./Action_Codigo_Promocional";
import Action_Codigo_Promocional_Generico from "./Action_Codigo_Promocional_Generico";
import Action_Popup from "./Action_Popup";
import Action_Email_Push from "./Action_Email_Push";
import Action_Encuesta from "./Action_Encuesta";
//pages

const Action = (props) => {
    const handleOnSetValue=(id,name,v,textValue)=>{
        props.onSetValue(id,name,v,textValue);
    }
    const EvList = {
        3: Action_Descuento,
        4: Action_Codigo_Promocional,
        5: Action_Codigo_Promocional_Generico,
        6: Action_Popup,
        7: Action_Email_Push,
        22: Action_Encuesta

    };
    const Ev = EvList[props.ev];
    return (<Ev id={props.ev} onSetValue={handleOnSetValue} />);

}

export default Action;
import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import history from "../providers/History/history";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Field from "./Field";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formControl:{
        margin: theme.spacing(1),
        width: '100%',
    },
}));
const FieldRelated = (props) => {
    const classes = useStyles();
    const [value,setValue]=useState(null);
    const handleChange=(e)=>{
        console.log("change" + e.target.value);
        setValue(e.target.value);
        props.onSetValue({"item":props.item.field,"value":e.target.value});
    }
    const handleClickLink=(e,rel,id)=>{
        history.push("/"+rel.page+"/view/"+id);
    }
    let rel="";
    if(props.rel[props.item.field]){
        rel=props.rel[props.item.field];
        // console.log(rel);
    }
    // console.log(props.rel);
    if(props.action=="view"){


        return(
            <div id={props.item.field}>
                <div className="field-view-title" >{props.item.title}</div>
                <div className={(rel!=""?"field-view-link":"is-hidden")} onClick={(e)=>handleClickLink(e,rel,props.record)}>{(rel?rel.name:"-")}</div>
            </div>
        );
    }else if(props.action=="edit" || props.action=="new"){
       //  console.log(props.tables);
       //  console.log(props.rel[props.item.field]);
       // console.log(rel);

       let p=props.rel[props.item.field];

        return(
            <FormControl className={classes.formControl}>
            <InputLabel shrink id={"select_"+props.item.field}>
            {props.item.title}
            </InputLabel>
            <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                onChange={handleChange}
                value={(value==null?(props.record?props.record:"-1"):value)}
                displayEmpty
                className={classes.selectEmpty}
            >
            {props.item.defaultOption==true && <MenuItem value="-1">(Sin seleccionar)</MenuItem>}
            {p && props.tables[p.page] && props.tables[p.page].map(menuItem => {
                    return (
                        <MenuItem key={menuItem.id} value={menuItem.id}>
                            {menuItem.name}
                        </MenuItem>
                );
                })
            }
            </Select>
            <FormHelperText>{props.item.description}</FormHelperText>
            </FormControl>
        );
    }
}

export default FieldRelated;
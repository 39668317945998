import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import history from "../providers/History/history";
import {makeStyles} from "@material-ui/core/styles";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import myTheme from "../theme/theme";
import cross from "../res/cross.png";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Field from "./Field";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formControl:{
        // margin: theme.spacing(1),
        width: '100%',
    },
    link:{
        color:myTheme.palette.primary.main,
        textDecoration:'none',
        "&:hover": {
            textDecoration:'underline'
        }
    },
    text:{
        color:"#ffffff",
    },
    cross:{
        width:"15px"
    },
    thumb:{
        width:"15px",
        color:"#00ff00"
    }
}));
const FieldRecordRelated = (props) => {
    const classes = useStyles();
    const [value,setValue]=useState(null);
    const [action,setAction] =useState("");
    const [inputValue,setInputValue]= useState(null);
    const [viewHtml,setViewHtml] =useState("");
    const handleChange=(e)=>{
        setValue(e.target.value);
        props.onSetValue({"item":props.field,"value":e.target.value});
    }
    const handleClickLink=(e,table,value)=>{
        history.push("/"+table+"/"+value+"/view");
        // history.push("/"+rel.page+"/view/"+id);
    }
    useEffect(() => {
        if(props.action) {
            // console.log("action is "+props.action);
            setAction(props.action);
        }
        // console.log(props);
        if(props.value && props.rel!=undefined && props.rel!=null) {
            let input = props.rel.find((item) => {
                return item.id == props.value;
            });
            let iv=input[props.field];
            if(iv!=undefined)
                setInputValue(input[props.field]);
            console.log(props.action);
            if(props.action && (props.action=="view" || props.allowEdit=="false")){
                switch (props.field){
                    case "current_status":
                        if(inputValue=="0" || inputValue=="Off")
                            setViewHtml(<img className={classes.cross} src={cross}/>)
                        else
                            setViewHtml(<ThumbUpIcon className={classes.thumb}/>)
                        break;
                    default:
                        if(props.allowLink=="false"){
                            setViewHtml(<div className={(props.rel!=""?classes.text:"is-hidden")}>{input[props.field]}</div>);
                        }else
                            setViewHtml(<div className={(props.rel!=""?classes.link:"is-hidden")} onClick={(e)=>handleClickLink(e,props.table,props.value)}>{input[props.field]}</div>);
                }
            }
        }

    },[props]);


    return(
        <div>
            {props.action && (props.action!="new" && (props.action=="view" || props.allowEdit=="false")) &&
            <div id={props.field}>
                {action!="new" && <div className="field-view-title" >{props.title}</div>}
                {viewHtml}
            </div>}
            {props.action && (props.action=="edit"   && (props.allowEdit=="true" || props.allowEdit==undefined) || (props.action=="new" && props.allowNew=="true")) &&
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id={"select_"+props.field}>
                        {props.title}
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-placeholder-label-label"
                        id="demo-simple-select-placeholder-label"
                        name={props.field}
                        onChange={handleChange}
                        value={(value==null?(props.value?props.value:"-1"):value)}
                        displayEmpty
                        className={classes.selectEmpty}
                        disabled={(props.disable?props.disable:false)}
                    >
                        <MenuItem value="-1">(Sin seleccionar)</MenuItem>
                        {props.rel && props.rel.map(menuItem=>{
                            return (
                                <MenuItem key={menuItem.id} value={menuItem.id}>
                                    {menuItem[props.field]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>{props.description}</FormHelperText>
                </FormControl>
            }
        </div>
    );
}

export default FieldRecordRelated;
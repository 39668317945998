

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,GetProducts,GetBases,GetProductRanges,FormatDate} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from "react-i18next";
import {Button, Dialog, FormControlLabel, InputLabel, Select} from "@material-ui/core";
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";

import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import OfficialPrices2 from "../../components/OfficialPrices2";
import CustomToolbar from "../../components/CustomToolbar";
import CustomToolbarSelect from "../../components/CustomToolbarSelect";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    rightAlign:{
        textAlign:'center',
        paddingTop:15
    },
    leftAlign:{
        textAlign:'left'
    },
    priceStyle:{
        fontWeight:'bold',
        color:'#23c130',
        fontSize:18
    },
    lastPriceStyle:{
        fontWeight:'bold',
        color:'#ff5800',
        fontSize:16
    },
    gridStyle:{
        borderBottom:'1px solid #cccccc',
        borderLeft:'1px solid #cccccc',
        padding:5,
    },
    negativeColor:{
        color:'#ff2727',
    },
    negative:{
        fontSize:12
    },
    positiveColor:{
        color:'#23c130',
    },
    positive:{
        fontSize:12
    },
    equal:{

    }

}));

const BaseTablePrices = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }

    const handleDeleteClick=(selectedRows,d)=>{
        // console.log("main view",selectedRows);
        console.log("elimimamos registros");
        console.log("Eliminamos el elemento");
        // console.log(table.data(selectedRows.data[0].dataIndex));
        // console.log("id a eliminar",d[selectedRows.data[0].dataIndex].data[1]);
        // console.log("id a eliminar",d[selectedRows.data[0].dataIndex].data[2]);

        // console.log("sel rows",selectedRows);
        // console.log("d",d);
        // console.log(selectedRows,d);

        // console.log(table.data(selectedRows.data[0].dataIndex));
        console.log("data= ",d[selectedRows.data[0].dataIndex]);
        console.log("fecha= ",d[selectedRows.data[0].dataIndex].data[1].props.children);
        console.log("baseId= ",d[selectedRows.data[0].dataIndex].data[0]);
        let rows=[];
        for(let row in selectedRows.data){
            // console.log("row",row);
            let item={};
            item.fecha=d[selectedRows.data[row].dataIndex].data[1].props.children;
            item.baseId=d[selectedRows.data[row].dataIndex].data[0];
            rows.push(item);
        }
        setRowsSelected(rows);
        setDeletePopup(true);

    }

    const handleRowClick=(row)=>{
        console.log("click",row);
        // console.log(row[0].props.children);
        // console.log(row[1].props.id);
        let info=row[1].props.children.replaceAll("/","-");
        info=info+"_"+row[0];
        console.log("esta es la info",info);
        let url=props.id+"/view/"+info;
        history.push(url);
        // console.log("url",props.id+"/view/"+info);
        // history.push(props.id+"/"+info+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [deletePopup,setDeletePopup] =useState(false);
    const [resendEmail,setResendEmail] =useState(null);
    const [prices,setPrices] =useState(null);
    const [rowsSelected,setRowsSelected] =useState(null);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    // const [baseValue,setBaseValue] =useLocalStorage('GSYM_store_base_selected');
    const [tableOptions,setTableOptions] =useState({filter: false,
        filterType: "dropdown",
        download:false,
        print:false,
        viewColumns:false,
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},
        customToolbar: () => {
            return (
                <CustomToolbar onNewClick={handleNewPrice} />
        );
        },
        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomToolbarSelect mode="not-verified" selectedRows={selectedRows} onRowDelete={()=>handleDeleteClick(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        // onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleNewPrice=()=>{
        console.log("nuevo precio mostrar");
        console.log(props.id);
        history.push(props.id+'/new');
    }
    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleResendEmail=(e)=>{
        console.log("resend email");
        setResendEmail(e.target.value);
    }
    const handleDeleteRecords=()=>{
        console.log("eliminar seleccionados");

        let formData={"records":rowsSelected};
        http_post("config-prices/delete",formData,storeToken).then((res)=> {
            console.log(res);
            if(res.status=="ok"){
                setDeletePopup(false);
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });

    }
    const handleCloseDeletePopup=()=>{
        console.log("close popup");
        setDeletePopup(false);
    }
    function getPrice(value,id){
        let diff=JSON.parse(value)[id]["p"]-JSON.parse(value)[0]["l"];
        if(diff>0)
            return <span className={classes.positiveColor}>{JSON.parse(value)[id]["p"]}<span className={clsx(classes.positive,classes.positiveColor)}>{" (+"+diff.toFixed(3)+")"}</span></span>
    else if(diff<0)
            return <span className={classes.negativeColor}>{JSON.parse(value)[id]["p"]}<span className={clsx(classes.negative,classes.negativeColor)}>{" ("+diff.toFixed(3)+")"}</span></span>
    else
        return <span className={classes.equal}>{JSON.parse(value)[id]["l"]}<span className={classes.positive}>{" (=)"}</span></span>
        // return JSON.parse(value)[0]["l"] +" "+diff;
    }
    function getLastPrice(value,id){
        return <span>{JSON.parse(value)[id]["l"]}</span>
    }
    function renderPrices(res,field){
        res.columns[field]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
            return (
                <div>
                <Grid container>
            <Grid item xs={4} className={classes.gridStyle}><div className={classes.rightAlign}>{GetProductRanges(JSON.parse(value)[0]["r"],true)}</div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Precio:<br/><span className={classes.priceStyle}>{getPrice(value,0)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Anterior:<br/><span className={classes.lastPriceStyle}>{getLastPrice(value,0)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div className={classes.rightAlign}>{GetProductRanges(JSON.parse(value)[1]["r"],true)}</div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Precio:<br/><span className={classes.priceStyle}>{getPrice(value,1)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Anterior:<br/><span className={classes.lastPriceStyle}>{getLastPrice(value,1)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div className={classes.rightAlign}>{GetProductRanges(JSON.parse(value)[2]["r"],true)}</div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Precio:<br/><span className={classes.priceStyle}>{getPrice(value,2)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Anterior:<br/><span className={classes.lastPriceStyle}>{getLastPrice(value,2)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div className={classes.rightAlign}>{GetProductRanges(JSON.parse(value)[3]["r"],true)}</div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Precio:<br/><span className={classes.priceStyle}>{getPrice(value,3)}</span></div></Grid>
            <Grid item xs={4} className={classes.gridStyle}><div>Anterior:<br/><span className={classes.lastPriceStyle}>{getLastPrice(value,3)}</span></div></Grid>
            </Grid>
            </div>
        );
        }
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id+"/",storeToken).then((res)=> {
            console.log(res);
            setPrices(res.prices);

            let foundProducts=res.columns.findIndex(element=>element.name=="product_id");
            if(foundProducts!=-1){
                res.columns[foundProducts]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {GetProducts(value)}
                        </div>
                );
                }
            }
            // let foundBases=res.columns.findIndex(element=>element.name=="base_id");
            // if(foundBases!=-1){
            //     res.columns[foundBases]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
            //         return (
            //             <div id={value}>
            //             {GetBases(value)}
            //             </div>
            //     );
            //     }
            // }

            let foundGasoleoB=res.columns.findIndex(element=>element.name=="gasoleoB");
            if(foundGasoleoB!=-1 ){
                renderPrices(res,foundGasoleoB);
            }
            let foundGasoleoC=res.columns.findIndex(element=>element.name=="gasoleoC");
            if(foundGasoleoC!=-1 ){
                renderPrices(res,foundGasoleoC);
            }
            let foundGasoleoA=res.columns.findIndex(element=>element.name=="gasoleoA");
            if(foundGasoleoA!=-1 ){
               renderPrices(res,foundGasoleoA);
            }

            let foundRange=res.columns.findIndex(element=>element.name=="range_id");
            if(foundRange!=-1){
                res.columns[foundRange]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {GetProductRanges(value)}
                        </div>
                );
                }
            }
            let foundDate=res.columns.findIndex(element=>element.name=="date");
            // console.log("found",foundDate);
            if(foundDate!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundDate]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {FormatDate(value)}
                        </div>
                );
                }
            }
            let foundPrice=res.columns.findIndex(element=>element.name=="price");
            // console.log("found",foundDate);
            if(foundPrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundPrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.leftAlign}>
                        {v}
                        </div>
                );
                }
            }
            let foundLastPrice=res.columns.findIndex(element=>element.name=="last_price");
            // console.log("found",foundDate);
            if(foundLastPrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundLastPrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.leftAlign}>
                        {v}
                        </div>
                );
                }
            }

            setTable(res);

        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  mode="profile" selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
                <div className={classes.contentBody}>
                    <BreadCrumbComponent data={bc}/>
                    <div className={(table.data==null?"circular-position":"is-hidden")}>
                        <CircularProgress disableShrink className="is-loading"/>
                    </div>
                    {/*<Grid container spacing={2}>
                        {prices && <OfficialPrices2 prices={prices} />}
                    </Grid>*/}
                    <MUIDataTable
                        title={t("pages."+props.title)}
                        data={table.data}
                        columns={table.columns}
                        options={tableOptions}
                    />
                </div>
            </div>
            <div className={clsx(classes.contentBody,"page-footer")}>
                <Footer/>
            </div>
            <Dialog
            open={(deletePopup?deletePopup:false)}
            onClose={handleCloseDeletePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Eliminar registros de precios</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div>¿Estás seguro que quiers eliminar {(rowsSelected?rowsSelected.length:"0")} precios que has seleccionado?
                </div>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDeleteRecords} color="primary">
                {t("buttons.delete")}
        </Button>
    <Button onClick={handleCloseDeletePopup} color="primary">
        {t("buttons.cancel")}
</Button>
            </DialogActions>
            </Dialog>
        </main>
    </div>
);
}
export default BaseTablePrices;

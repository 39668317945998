import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const PopupVerifyAddress = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const [navisionId,setNavisionId] =useState(null);
    const handleClosePopup=()=>{
        console.log("yes");
        props.onClose(false);
    }
    const handleNavisionChange=(e)=>{
        console.log(e);
        setNavisionId(e.target.value);
    }
    const handleYes=()=>{
        console.log("yes");
        if(!navisionId)
            alert("Tienes que insertar un id de navision");
        else
            props.onVerifyAddress(props.lat,props.lng,props.address,props.cp,navisionId);
    }
    const handleNo=()=>{
        props.onClose(false);
    }
    useEffect(()=>{
        setOpenPopup(props.open);
    },[props]);
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Verificar la dirección</DialogTitle>
        <DialogContent>
                        <div>
                            <div>¿Estás seguro que quieres modificar la dirección por estos valores?</div>
                            <div>Direccion:<b>{props.address}</b></div>
                            {props.cp && <div>Cod.Postal:<b>{props.cp}</b></div>}
                                <div>Latitud:<b>{props.lat}</b></div>
                            <div>Longitud:<b>{props.lng}</b></div>
                            <TextField
                            id="NavisionId"
                            label="Id Navision"
                            helperText="Inserta aquí el Id de Navision"
                            value={(navisionId?navisionId:"")}
                            onChange={handleNavisionChange}
                            fullWidth
                            />
                        </div>


        </DialogContent>
        <DialogActions>
        <Button onClick={handleYes} color="primary">
        Sí
        </Button>
        <Button onClick={handleNo} color="primary">
        No
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupVerifyAddress;




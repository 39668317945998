import React,{useState,useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    paperBackground:{
        backgroundColor:"#f0f0f0"}
}));
const ActionConditionComponent = (props) => {
    const classes = useStyles();
    const [name,setName] =useState(null);
    const [title,setTitle] =useState(null);
    const [value,setValue] =useState(null);

    useEffect(()=>{
        // console.log("event",props.el);
        console.log("el",props.el);
        setName(props.el.name);
        setTitle(props.el.value.title);
        // if(props.el.textValue)
        setValue(props.el.value.description);
        // else
        //     setValue(props.el.value);
    },[]);

    return(
            <Grid container className={classes.paperBackground}>
                    <Grid container>
                        <Grid item xs={6}>Nombre:</Grid>
                        <Grid item xs={6}><b>{(name?name:"")}</b></Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>Título:</Grid>
                        <Grid item xs={6}><b>{(title?title:"")}</b></Grid>
                    </Grid>
                    {value && <Grid container>
                        <Grid item xs={6}>Valor:</Grid>
                        <Grid item xs={6}><b>{(value?value:"")}</b></Grid>
                    </Grid>}
            </Grid>
    )
}

export default ActionConditionComponent;




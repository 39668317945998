import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import DeleteIcon from '@material-ui/icons/Delete';
const defaultToolbarStyles = {
    iconButton: {
    },
};

const CustomToolbarSelect = (props) => {
    const [selected,setSelected] =useState([]);
    useEffect(() => {
        console.log("custom order toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickDelete=()=>{
        console.log("delete records");
        props.onRowDelete(props.selectedRows)
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
                {props.mode=="not-verified" && <Tooltip title={"Eliminar"}>
                <IconButton  onClick={handleClickDelete}>
                    <DeleteIcon />
            </IconButton>
            </Tooltip>}

        </div>
        );

}
export default CustomToolbarSelect;
import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
import FieldText from './FieldText';
import FieldRelated from './FieldRelated';
import FieldBoolean from './FieldBoolean';
import FieldDate from './FieldDate';
import FieldImage from './FieldImage';
import FieldVideo from './FieldVideo';
import FieldFile from "./FieldFile";
import FieldRecordText from "./FieldRecordText";
import FieldRecordFile from "./FieldRecordFile";
import FieldRecordDate from "./FieldRecordDate";
import FieldRecordRelated from "./FieldRecordRelated";
import FieldRecordCheckbox from "./FieldRecordCheckbox";
const FieldRecord = (props) => {
    const handleChange=(item)=>{
        // console.log("kkk "+item.item);
        props.onSetItem(item);
    }
    if (props.hidden!=true){
        switch(props.type){
            case undefined:
            case "text":
                return(
                    <Grid item xs={12} sm={(props.size?parseInt(props.size):6)}>
                        <FieldRecordText {...props} onSetValue={handleChange} />
                    </Grid>
                );
                break;
            case "textarea":
                return(
                    <Grid item xs={12} sm={(props.size?parseInt(props.size):6)}>
                        <FieldRecordText multiline="true" {...props} onSetValue={handleChange} />
                    </Grid>
                );
                break;
            case "related":
                return(
                    <Grid item xs={12} sm={(props.size?parseInt(props.size):6)}>
                         <FieldRecordRelated {...props} onSetValue={handleChange}/>
                     </Grid>
                 );
                 break;
            // case "boolean":
            //     return(
            //         <Grid item xs={12} sm={6}>
            //             <FieldBoolean action={props.action} item={props.item} record={props.record} onSetValue={handleChange} />
            //         </Grid>
            //     );
            //    break;
            case "date":
                return(
                    <Grid item xs={12} sm={6}>
                        <FieldRecordDate {...props} onSetValue={handleChange} />
                    </Grid>
                );
                break;
            case "datetime":
                return(
                    <Grid item xs={12} sm={6}>
                        <FieldRecordDate time="yes" {...props} onSetValue={handleChange} />
                    </Grid>
                );
                break;
            // case "video":
            //     return(
            //         <Grid item xs={12} sm={12}>
            //             <FieldVideo action={props.action} item={props.item} record={props.record} />
            //         </Grid>
            // );
            // break;
            // case "image":
            //     return(
            //         <Grid item xs={12} sm={6}>
            //             <FieldImage action={props.action} item={props.item} record={props.record} />
            //         </Grid>
            // );
            // break;
            case "file":
                return(
                    <Grid item xs={12} sm={6}>
                        <FieldRecordFile {...props} onSetValue={handleChange}  />
                    </Grid>
                );
                break;
            case "checkbox":
                return (
                    <Grid item xs={12} sm={12}>
                        <FieldRecordCheckbox {...props} onSetValue={handleChange} />
                    </Grid>
                );
        }
    }else if(props.action=="edit" || props.action=="new"){
        return(<TextField value={props.value} name={props.field} id={props.field} type="hidden" />);
    }else{
        return(<div></div>);
    }
}

export default FieldRecord;

import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evento_Direccion_Entrega_Nueva = (props) => {
    const classes = useStyles();
    const [name] =useState("Nueva dirección de entrega");
    const [email,setEmail] =useState(null);
    // const handleEmail=(e)=>{
    //     setEmail(e.target.value);
    // }
    const handleSave=()=>{
        console.log("apclicamos evento",props.id,name);
        props.onSetValue(props.id,name,null,"Nueva dirección de entrega");
    }
    return(
        <div className="father-header-title">
            <div className="">
                Evento Nueva dirección de entrega
            </div>
        <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Evento_Direccion_Entrega_Nueva;
import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ViewIcon from '@material-ui/icons/Visibility';
import { withStyles } from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";

const defaultToolbarStyles = {
    iconButton: {
    },
};

const CustomOrderToolbar = (props) => {
    const [selected,setSelected] =useState([]);
    useEffect(() => {
        console.log("custom order toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickView=()=>{
        console.log("view Element");
        props.onRowResend(props.selectedRows)
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
            <Tooltip title={"Añadir"}>
                <IconButton  onClick={handleClickAdd}>
                    <AddIcon  />
                </IconButton>
            </Tooltip>
            <Tooltip title={"Ver pedido"}>
                <IconButton  onClick={handleClickView}>
                    <ViewIcon />
            </IconButton>
            </Tooltip>

        </div>
        );

}
export default CustomOrderToolbar;
import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Button, Paper} from '@material-ui/core';
import {useTranslation} from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from "mui-datatables";
import CustomClientsToolbar from "./CustomClientsToolbar";
import history from "../providers/History/history";
import MapIcon from "@material-ui/icons/Room";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    },
    paperTitle:{
        paddingTop:4,
        fontSize:16,
        fontWeight:'bold'
    },
    paperValue:{
        fontSize:25,
        fontWeight:'normal',
        // paddingLeft:20,
        // textAlign:'left'
    },
    paperInfo:{
        fontSize:12,
        fontWeight:'normal',
    },
    paperWidth:{
        height:'100px',
        textAlign:'center',
        backgroundColor:'#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
    },
}));
const RelatedPromoResult = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [data, setData] = useState({});
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("300px");

    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        // customToolbarSelect: (selectedRows,data) => {
        //     return (
        //         <CustomClientsToolbar mode="not-verified" selectedRows={selectedRows} />
        // );
        // },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push("/clients/"+row[0]+"/view");
    }
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("Related address");
        setData(props.data);
        console.log("resultado data",props.data);


    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickVerify=()=>{
        console.log("verificar cuenta");
        props.onRowVerifyAddress(props.selectedRows)
    }
    const handleClickLocation=()=>{
        console.log("resend email");
        props.onRowLocation(props.selectedRows)
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
            <Grid container spacing={3}>
                <Grid item xs={3} md={3}>
                    <Paper className={classes.paperWidth}>
                        <div className={classes.paperTitle}>Total clientes</div>
                        <div className={classes.paperValue}>{data.publicTotal}</div>
                        <div className={classes.paperInfo}>Público objetivo</div>

                    </Paper>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Paper className={classes.paperWidth}>
                        <div className={classes.paperTitle}>Conversiones</div>
                        <div className={classes.paperValue}>{data.clientUsados}</div>
                        <div className={classes.paperInfo}>Clientes únicos</div>
                    </Paper>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Paper className={classes.paperWidth}>
                        <div className={classes.paperTitle}>Total promos</div>
                        <div className={classes.paperValue}>{data.totalUsados}</div>
                        <div className={classes.paperInfo}>Promos usados</div>
                    </Paper>
                </Grid>
                </Grid>
            </div>
        );

}
export default RelatedPromoResult;
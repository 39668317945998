import React,{useState,useEffect} from 'react';
import {Avatar, Dialog, ListItemAvatar, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";
import clsx from "clsx";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Call_Get} from "../providers/Utils/utils";
import history from "../providers/History/history";
import useLocalStorage from "../providers/store/localStorage";
import Evaluador_Provincia from "../Evaluadores/Evaluador_Provincia";
import Evaluador from "../Evaluadores/Evaluador";
import Action from "../Evaluadores/Action";
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PublicPopup from "./PublicPopup";
import PublicConditionComponent from "./PublicConditionComponent";
const useStyles = makeStyles((theme) => ({
    paperWidth:{
        width:'200px',
        height:'100px',
        textAlign:'center',
        backgroundColor:'#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
    },
    paperTitle:{
        fontWeight:"bold",
        fontSize:16
    },
    width100:{
        width:'100%',
        marginTop:10
    },
    uniqueBlock:{
        width:'100%'
    },
    uniqueLine:{
        display:'inline-block',
        width:'50%'
    },
    conditions:{
        marginBottom:10,
    },
    padding10:{
        padding:10
    },
    rightText:{
        textAlign:'right'
    },
    centerText:{
        textAlign:'center'
    },
}));
const PublicComponent = (props) => {
    const classes = useStyles();
    const [condiciones,setCondiciones] =useState(null);
    const [visiblePublicPopup,setVisiblePublicPopup] = useState(false);

    useEffect(()=>{
        console.log("public component",props.value);
        setCondiciones(props.value)

    },[]);

    const handleNewCondition=()=>{
        console.log("new condition");
        setVisiblePublicPopup(true);
    }
    const handleDeleteCondition=(id)=>{
        let condStr=JSON.stringify(condiciones);
        let cond=JSON.parse(condStr);
        let found=cond.findIndex(element=>element.id==id);
        if(found>=0){
            cond.splice(found,1);
        }
        setCondiciones(cond);
        props.onSetCondiciones(cond);

    }
    const AddCondition=(ev,name,v,textValue,operator,condiciones=null)=>{
        // console.log("Añadir condicion");
        let cond=condiciones;
        if(cond==null)
            cond=[];
        let next=1;
        if(cond.length>0){
            next=cond[cond.length-1].id+1;
        }
        cond.push({id:next,ev:ev,name:name,operator:operator,value:v,textValue:textValue});
        return cond;
    }
    const handleAddCondition=(id,name,v,textValue)=>{
        console.log("value final",id,name,v,"Y");
        setVisiblePublicPopup(false);
        let cond=AddCondition(id,name,v,textValue,"Y",condiciones);
        setCondiciones(cond);
        props.onSetCondiciones(cond);
        // console.log("condiciones!!!!!",cond);
    }
    const handleChangeValuePopup=(e)=>{
        console.log("value",e);
    }
    const handleClosePublicPopup=()=>{
        setVisiblePublicPopup(false);
    }
    const handleOnSetCondiciones=(cond)=>{
        console.log("cambio de las condiciones",cond);
        setCondiciones(cond);
        props.onSetCondiciones(cond);
    }
    return(
        <Paper variant="outlined" square className={classes.width100} >
            <div className={classes.uniqueBlock}>
                <div className={classes.uniqueLine}>
                    <Typography className={clsx(classes.conditions,classes.padding10)}>{(props.title?props.title:"Condiciones")}</Typography>
                </div>
                <div className={clsx(classes.uniqueLine,classes.rightText,classes.padding10)}>
                    {props.action!="view" &&
                        <IconButton edge="end" aria-label="delete" onClick={handleNewCondition}>
                            <AddBoxIcon />
                        </IconButton>
                    }
                    </div>
                    </div>
                    { condiciones && condiciones.length>0 &&
                    <Grid container spacing={0}>
                        {condiciones.map((el)=>{
                            // console.log(con);
                        return(
                            <Grid container key={el.id}   justifyContent="space-around">
                                <Grid item xs={8}>
                                    <Paper elevation={2} key={el.id}>
                                        {el && condiciones && <PublicConditionComponent el={el} condiciones={condiciones} action={props.action} onSetCondiciones={handleOnSetCondiciones}/>}
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    {props.action!="view" &&
                                        <div className={clsx(classes.rightText,classes.padding10)}>
                                            <IconButton edge="end" aria-label="delete" onClick={()=>{handleDeleteCondition(el.id)}}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        )
                        })}
                    </Grid>}
                   {condiciones && condiciones.length==0 && <Typography className={classes.centerText}>No hay ninguna condición todavía
                        </Typography>}
                <PublicPopup open={visiblePublicPopup} onChange={handleChangeValuePopup} onClose={handleClosePublicPopup} onSetValue={handleAddCondition}/>

        </Paper>
    )
}

export default PublicComponent;




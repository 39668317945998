import React,{useState,useEffect} from 'react';
import {Dialog, Divider} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import myTheme from "../theme/theme";
import clsx from "clsx";
import {http_post} from "../providers/http/http";
import history from "../providers/History/history";
import useLocalStorage from "../providers/store/localStorage";
import {GetPaymentType, openWindowRedsys} from "../providers/Utils/utils";
import PopupYesNo from "./PopupYesNo";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    width100:{
        width:'100%',
    },
    bigTextField:{
        "& input":{
            fontSize:20,
            color:'#000'
        }
    },
    text:{
        fontSize:14,
        color:'#c0c0c0',
        paddingTop:24
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    finalText:{
        color:"rgba(35,193,48,1)"
    },
    redColor:{
        color:"#ff2727"
    }
}));

const PopupCotejarManual = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const classes = useStyles();
    const [openYesNoPopup,setOpenYesNoPopup] =useState(false);
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [operationType,setOperationType] =useState(null);
    const [importeManual,setImporteManual] =useState(null);
    const handleClosePopup=()=>{
        props.onClose(false);
    }
    const handleGuardarPopup=()=>{
        if(operationType==null) {
            alert("Necesitas introducir un tipo de operación (Pago o devolución)");
            return;
        }
        if(importeManual==null) {
            alert("Necesitas introducir un importe manual");
            return;
        }
        setOpenYesNoPopup(true);
    }
    const handleImporteManualChange=(e)=>{
        setImporteManual(e.target.value);
    }
    const handleOperationTypeChange=(e)=>{
        setOperationType(e.target.value);
    }
    const handleReturnYes=()=>{
        let formData={"orderId":props.record.id,"type":operationType,"price":importeManual};
        console.log("toooken",storeToken,formData);
        http_post("order-admin-manual-operation", formData, storeToken).then((res) => {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("error desconocido");
            }
            setOpenYesNoPopup(false);
        });
        props.onCancel();
    }
    const handleCloseYesNoPopup=()=>{
        setOpenYesNoPopup(false);
    }

    useEffect(()=>{
        setOpenPopup(props.open);
        // {"orderId":record.id,"amount":record.amount,"price":record.price}
        // console.log(props.record);
        // console.log("orderdetail",props.orderDetail);
    },[props]);

    const generateOtherPrice=(orderDetail)=>{
        let sum=0;
        if(orderDetail) {
            // console.log(orderDetail.data);
            for(let el in orderDetail.data){
                // console.log("element",el);
                if(orderDetail.data[el].order_detail_category_id!=1){
                    sum+=orderDetail.data[el].price;
                }
            }
        }
        //
    }
    return(
        <>
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Transacción manual</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className={classes.centerText}>
                    <div>Si ha habido algún problema tanto en el pago como en la devolución con Redsys y no se refleja la operacion en el panel de administración, podemos hacerla manualmente para que concuerden las transacciones de redsys con las transacciones del panel.</div>
                    <form className={classes.form} noValidate autoComplete="off" >
                        <div className={classes.width100}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Tipo de operación
                            </InputLabel>
                            <Select
                                value={operationType}
                                onChange={handleOperationTypeChange}
                                displayEmpty
                                className={classes.selectEmpty}
                                inputProps={{ 'aria-label': 'Tipo de operacion' }}
                            >
                                <MenuItem value="1">Pago</MenuItem>
                                <MenuItem value="2">Devolución</MenuItem>
                            </Select>
                        </div>
                        <TextField className={clsx(classes.bigTextField,classes.width50)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="importe"
                            label={"Importe"}
                            name="Price"
                            value={importeManual}
                            onChange={handleImporteManualChange}
                            autoFocus
                        />
                    </form>
                    <div className={classes.redColor}>Nota: No se produce ninguna operación bancaria real, solamente es a modo administrativo y que se refleje tanto en este panel como en la APP del cliente.</div>

    </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleGuardarPopup} color="primary">
            Guardar
        </Button>
        <Button onClick={handleClosePopup} color="primary">
            Salir
        </Button>
        </DialogActions>
        </Dialog>
        <PopupYesNo title="Devolución" description={"¿Estás seguro que quieres hacer la devolución del pedido?"} open={openYesNoPopup} onYes={handleReturnYes} onClose={handleCloseYesNoPopup}/>
        </>
)
}

export default PopupCotejarManual;




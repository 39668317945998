import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import SaveIcon from '@material-ui/icons/Save';

import {
    Avatar,
    Dialog,
    Divider,
    Input,
    InputLabel,
    ListItemAvatar,
    Paper,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import EditIcon from '@material-ui/icons/Edit';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PublicPopup from "../../components/PublicPopup";
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OperatorPopup from "../../components/OperatorPopup";
import RelatedPublicResult from "../../components/RelatedPublicResult";
import ActionPopup from "../../components/ActionPopup";
import EventPopup from "../../components/EventPopup";
import PublicComponent from "../../components/PublicComponent";
import EventComponent from "../../components/EventComponent";
import ActionComponent from "../../components/ActionComponent";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    marginTopM10:{
        marginTop:'-10px!important'
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    width100:{
        width:'100%'
    },
    conditions:{
        marginBottom:0,
    },
    uniqueBlock:{
        width:'100%'
    },
    uniqueLine:{
        display:'inline-block',
        width:'50%'
    },
    rightText:{
        textAlign:'right'
    },
    padding10:{
        padding:10
    },
    paperBackground:{
        backgroundColor:"#f0f0f0"
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ManageRecord = (props) => {
    const [visiblePublicPopup,setVisiblePublicPopup] = useState(false);
    const [visiblePopup,setVisiblePopup] = useState(false);
    const [visiblePopupMode,setVisiblePopupMode] = useState(false);

    const [operatorPopup,setOperatorPopup] =useState(null);
    const [operatorValue,setOperatorValue] =useState("Y");
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);


    const handleChangeValuePopup=(e)=>{
        console.log("value",e);
    }
    const handleClosePublicPopup=()=>{
        setVisiblePublicPopup(false);
    }
    const handleCloseVisiblePopup=()=>{
        setVisiblePopup(false);
    }
    const handleSetOperatorPopup=(id,v)=>{
        setOperatorPopup(null);
        changeItem(condiciones,id,v);
    }
    const changeItem=(arr,id,value)=>{
        let cond=arr;
        cond.filter(elem => elem.id == id)
            .forEach(elem => elem.operator = value);
        setCondiciones(cond);
    }

    // const getEventInfo=(el)=>{
    //     let element="pepe";
    //     console.log("el",el);
    //     if (el.textValue)
    //         element=<Grid container className={classes.paperBackground}>
    //         <Grid container><Grid item xs={6}>Nombre:</Grid><Grid item xs={6}><b>{el.name}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.textValue}</b></Grid></Grid>
    //     </Grid>;
    //     //.(el.textValue);
    //     else{
    //         element=<Grid container className={classes.paperBackground}>
    //             <Grid container><Grid item xs={6}>Nombre:</Grid><Grid item xs={6}><b>{el.name}</b></Grid></Grid>
    //         <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.value}</b></Grid></Grid>
    //         </Grid>;
    //     }
    //     return (<div>{element}</div>)
    // }
    // const getActionsInfo=(el)=>{
    //     let element="pepe";
    //     console.log("action el",el);
    //     element=<Grid container className={classes.paperBackground}>
    //             <Grid container><Grid item xs={6}>Nombre:</Grid><Grid item xs={6}><b>{el.name}</b></Grid></Grid>
    //             <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.value.title}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.value.description}</b></Grid></Grid>
    //
    //     </Grid>;
    //     return (<div>{element}</div>)
    // }
    // const getPublicInfo=(el)=>{
    //     let element="pepe";
    //     console.log("el",el);
    //     if (el.textValue)
    //         element=<Grid container className={classes.paperBackground}>
    //         <Grid container><Grid item xs={6}>Nombre:</Grid><Grid item xs={6}><b>{el.name}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Operador:</Grid><Grid item xs={6}><b>{el.operator}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.textValue}</b></Grid></Grid>
    //     </Grid>;
    //     //.(el.textValue);
    // else
    //     element=<Grid container className={classes.paperBackground}>
    //         <Grid container><Grid item xs={6}>Nombre:</Grid><Grid item xs={6}><b>{el.name}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Operador:</Grid><Grid item xs={6}><b>{el.operator}</b></Grid></Grid>
    //     <Grid container><Grid item xs={6}>Valor:</Grid><Grid item xs={6}><b>{el.value}</b></Grid></Grid>
    //     </Grid>;
    //     return (<div>{element}</div>)
    // }
    const AddCondition=(ev,name,v,textValue,operator,element=null)=>{
        console.log("Añadir condicion");

        let cond=element;
        if(cond==null)
            cond=[];
        let next=1;
        if(cond.length>0){
            next=cond[cond.length-1].id+1;
        }
        cond.push({id:next,ev:ev,name:name,operator:operator,value:v,textValue:textValue});
        console.log("condiciones",cond);
        return cond;
    }
    // const handleAddCondition=(id,name,v,textValue)=>{
    //     console.log("value final",id,name,v,"Y");
    //     setVisiblePublicPopup(false);
    //     let cond=AddCondition(id,name,v,textValue,"Y",condiciones);
    //     setCondiciones(cond);
    // }
    const handleAddCondition=(mode,id,name,v,textValue)=>{
        console.log("value final",id,name,v,textValue,"Y");
        setVisiblePopup(false);
        let cond=null;
        switch(mode){
            case "event":
                cond=AddCondition(id,name,v,textValue,"Y",eventsEl);
                setEventsEl(cond);
                break;
            case "public":
                cond=AddCondition(id,name,v,textValue,"Y",publicEl);
                setPublicEl(cond);
                setValuePublic(cond);

                break;
            case "action":
                cond=AddCondition(id,name,v,textValue,"Y",actionsEl);
                setActionsEl(cond);
                break;
        }
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [openCancelOrderPopup,setOpenCancelOrderPopup] =useState(false);
    const [userId,setUserId] =useState(null);
    const [name,setName] =useState(null);
    const [description,setDescription] =useState(null);
    const [phone,setPhone] =useState(null);
    const [visible,setVisible] =useState(null);
    const [result,setResult] =useState(null);

    const [email,setEmail] =useState(null);
    const [latitude,setLatitude] =useState(null);
    const [longitude,setLongitude] =useState(null);

    const [rol,setRol] =useState(null);
    const [readOnly,setReadOnly] =useState(false);
    const [basesUser,setBasesUser] =useState([]);
    const [bases,setBases] =useState([]);
    const [modeAdmin,setModeAdmin] =useState(false);
    const [officialBasePrice,setOfficialBasePrice] =useState(null);
    const [valuePublic,setValuePublic] =useState(null);
    const [valueEvent,setValueEvent] =useState(null);
    const [valueAction,setValueAction] =useState(null);
    const handleBack=()=>{
        console.log("back");
        if(props.action=="edit")
            history.push(props.parentUrl+"/"+props.id+"/view");
        else
            history.push(props.parentUrl);
        // if(!isRefreshed)
        //     history.goBack();
        // else{
        //     setIsRefreshed(false);
        //     history.goBack();
        //     history.goBack();
        // }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    const handleDescriptionChange=(e)=>{
        setDescription(e.target.value);
    }
    const handleVisibleChange=(e)=>{
        console.log("change visible",e.target.value);
        setVisible(e.target.value);
    }

    const [t, i18n] = useTranslation('common');

    const [data,setData]=useState([]);
    const [tab1Value, setTab1Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [condiciones,setCondiciones] =useState(null);
    const [eventsEl,setEventsEl] =useState(null);
    const [publicEl,setPublicEl] =useState(null);
    const [actionsEl,setActionsEl] =useState(null);
    const [numAperturas,setNumAperturas] =useState([]);
    const [numberSelected,setNumberSelected] =useState(null);
   useEffect(() => {
        let url="";
        setAction(props.action);
        if(props.action=="new") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title}]);
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.parentTitle+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        setNumAperturas([{id:-1,name:"Infinitas"},{id:1,name:"1 apertura"},{id:2,name:"2 aperturas"},{id:3,name:"3 aperturas"},{id:4,name:"4 aperturas"}])
        // console.log("action",props.action);
        if(props.action=="new") {
            setNumberSelected(1);
            setVisible("0");
            // setPublicEl([]);
            setValuePublic([]);
            setValueEvent([]);
            setValueAction([]);
            setActionsEl([]);
            setEventsEl([]);
        }
        if(props.action!="new") {
            Call_Get(url, storeToken).then((res) => {
                console.log(res);
                // console.log(res.item.itemColumns);
                // setFields(res.item.itemColumns);
                // if(props.action!="newAll") {
                setRecord(res.record);

                setRelateds(res.related);
                setName(res.record.name);
                setVisible(res.record.visible.toString());
                setResult(res.related["result"]);
                setNumberSelected(res.record.open_times);
                console.log(res.record.open_times);
                console.log("actualizado result");
                if (props.action == "view") {
                    setReadOnly(true);
                    // console.log("value",res.record.value);

                }else
                    setReadOnly(false);

                let v=JSON.parse(res.record.value);
                loadEvents(v["event"]);
                loadActions(v["actions"]);
                loadPublic(v["public"]);
            });
        }
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);

    const loadEvents=(value)=>{
        console.log("eventos",value);
        let cond=[];
        for(let el in value){
            let item=value[el];
            cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,eventsEl);
        }
        // setEventsEl(cond);
        setValueEvent(cond);
    }
   const handleNumberChange=(e)=>{
        console.log(e);
        setNumberSelected(e.target.value);
    }
    const loadActions=(value)=>{
        let cond=[];
        for(let el in value){
            let item=value[el];
            cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,actionsEl);
        }
        // setActionsEl(cond);
        setValueAction(cond);
    }
    const loadPublic=(value)=>{
        let cond=[];
        for(let el in value){
            let item=value[el];
            cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,valuePublic);
        }
        // setPublicEl(cond);
        setValuePublic(cond);

    }

    const handleChangeCondiciones=(cond)=>{
        console.log("cambio de publico",cond);
        // setCondiciones(cond);
        setValuePublic(cond);
    }
    const handleChangeEvents=(cond)=>{
        console.log("cambio eventos",cond);
        setValueEvent(cond);
    }
    const handleChangeActions=(cond)=>{
        console.log("cambio en acciones");
        setValueAction(cond);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);
    }
    const handleEdit=()=>{
        console.log("edit");
        history.push("/blank");
        setTimeout(() => history.push(props.parentUrl+"/"+props.id+"/edit"), 10);
    }
    const handleSave=()=>{
        console.log("save");
        console.log("evento",valueEvent);
        console.log("acciones",valueAction);
        console.log("public",valuePublic);
        console.log("name",name);
        console.log("desc",description);
        console.log("num",numberSelected);
        if(name=="" || description=="" || valueEvent.length==0 || valuePublic.length==0 || valueAction.length==0){
            alert("Faltan datos: Nombre, descripción o (evento,publico y acciones)");
        }else {
            let formData = {
                id:props.id,
                name: name,
                description: description,
                events: valueEvent,
                public: valuePublic,
                actions: valueAction,
                visible: visible,
                number:numberSelected
            };
            console.log(formData);
            http_post("manage/save", formData, storeToken).then((res) => {
                if (res.status == "ok") {
                    console.log(res);
                    // alert("data saved successfully");
                    history.push( props.parentUrl);
                    // window.location.reload();
                } else {
                    if (res.message == "duplicate-name") {
                        alert("El nombre especificado ya existe, modifícalo y guarda de nuevo");
                    } else
                        alert("There´s been an error during save action");
                }

            });
        }
    }
    const handleCancelSave=()=>{
        console.log("cancel save");
        history.push("/blank");
        setTimeout(() => {
                if (props.action == "new")
                    history.push(props.parentUrl);
                else
                    history.push(props.parentUrl + "/" + props.id + "/view");
            }
            , 10);
    }
    const handleNewCondition=()=>{
        console.log("new condition");
        setVisiblePublicPopup(true);
    }
    const handleNewEvent=()=>{
        console.log("new event");
        setVisiblePopupMode("event");
        setVisiblePopup(true);
    }
    const handleNewPublic=()=>{
        console.log("new public");
        setVisiblePopupMode("public");
        setVisiblePopup(true);
    }
    const handleNewAction=()=>{
        console.log("new action");
        setVisiblePopupMode("action");
        setVisiblePopup(true);
    }
    const handleChangeOperator=(id,value)=>{
       if(props.action!="view") {
           console.log("change operator", id);
           setOperatorPopup(id);
           setOperatorValue(value);
       }
   }
    const handleDeleteCondition=(mode,id)=>{
        let condStr="";
        switch(mode){
            case "event":
                condStr=JSON.stringify(eventsEl);
                break;
            case "action":
                condStr=JSON.stringify(actionsEl);
                break;
            case "public":
                condStr=JSON.stringify(publicEl);
                break;
        }

        console.log("id",id);
        console.log("condstr",condStr);
        let cond=JSON.parse(condStr);
        console.log("cond",cond);
        let found=cond.findIndex(element=>element.id==id);
        console.log("found",found);
        if(found>=0){
            cond.splice(found,1);
        }
        console.log("condiciones",cond);
        switch(mode){
            case "event":
                // setEventsEl(cond);
                setValueEvent(cond);
                break;
            case "action":
                // setActionsEl(cond);
                setValueAction(cond);
                break;
            case "public":
                // setPublicEl(cond);
                setValuePublic(cond);

                break;
        }
    }

    const htmlContenido=()=>{
        return (
            <Grid container spacing={3}>

                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-name"
                        label="Nombre"
                        type="text"
                        autoComplete="off"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.name:(name?name:(record.name?record.name:"")))}
                        onChange={handleNameChange}
                        helperText="Nombre amigable para reutilizar"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-description"
                        label="Descripción"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.description:(description?description:(record.description?record.description:"")))}
                        onChange={handleDescriptionChange}
                        helperText="Descripción"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={6}>
                    {props.action!="view" && <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label" className={classes.marginTopM10}>Visible</InputLabel>
                        <RadioGroup
                            aria-label="visible" name="Visible" value={visible} onChange={handleVisibleChange}>
                        <FormControlLabel  value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel  value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>}
                    {props.action=="view" && <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-lng"
                        label="Visible"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?(record.visible==1?"Sí":"No"):(visible?(visible==1?"Sí":"No"):(record.visible?(record.visible==1?"Sí":"No"):"")))}
                        helperText="Visibilidad del elemento"
                        fullWidth
                        />}
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Número de aperturas
                        </InputLabel>
                        <Select
                            value={numberSelected}
                            onChange={handleNumberChange}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Num aperturas', readOnly: (props.action=="view"?true:false)  }}
                        >
                        {numAperturas && numAperturas.map((el)=>{
                            return (
                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                        )
                        })}
                        </Select>
                        <FormHelperText>Número de veces que el usuario puede abrir el popup</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    return (
        <div className={classes.root}>
           <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" && <div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEdit}
                                                    >
                                                    <span className="textButton">Editar regla de gestión</span>
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<SaveIcon />}
                                                onClick={handleSave}
                                                    >
                                                    <span className="textButton">Guardar</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSave}
                                                    >
                                                    <span className="textButton">Cancelar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Regla de gestión</h2>
                                                {htmlContenido()}
                                                {(valueEvent ||props.action=="new" )&& <EventComponent title="Evento" action={props.action} value={valueEvent} onSetCondicionesEvent={handleChangeEvents} />}
                                                {(valuePublic ||props.action=="new" )&& <PublicComponent title="Público" action={props.action} value={valuePublic} onSetCondiciones={handleChangeCondiciones} />}
                                                {(valueAction ||props.action=="new" )&& <ActionComponent title="Acción" action={props.action} value={valueAction} onSetCondiciones={handleChangeActions} />}



                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </main>
            {visiblePopupMode=="action" &&<ActionPopup open={visiblePopup} onChange={handleChangeValuePopup} onClose={handleCloseVisiblePopup} onSetValue={(id,name,v,textValue)=>handleAddCondition("action",id,name,v,textValue)}/>}
            {visiblePopupMode=="public" && <PublicPopup open={visiblePopup} onChange={handleChangeValuePopup} onClose={handleCloseVisiblePopup} onSetValue={(id,name,v,textValue)=>handleAddCondition("public",id,name,v,textValue)}/>}
            {visiblePopupMode=="event" && <EventPopup open={visiblePopup} onChange={handleChangeValuePopup} onClose={handleCloseVisiblePopup} onSetValue={(id,name,v,textValue)=>handleAddCondition("event",id,name,v,textValue)}/>}

            <OperatorPopup open={operatorPopup} value={operatorValue} onSetValue={handleSetOperatorPopup}/>

        </div>

    );
}
export default ManageRecord;

import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_Todos = (props) => {
    const classes = useStyles();
    const handleSave=()=>{
        console.log("aplicamos evaluador");
        props.onSetValue(props.id,"Clientes","all","Todos los clientes");
    }
    return(
        <div className="father-header-title">
        <div className="">
        Busca todos los clientes que esten activos
    </div>
        <div>Obtenemos datos de todos los clientes de la base de datos</div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Evaluador_Todos;
import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Call_Get} from "../providers/Utils/utils";
import history from "../providers/History/history";
import useLocalStorage from "../providers/store/localStorage";
import Evaluador_Provincia from "../Evaluadores/Evaluador_Provincia";
import Evaluador from "../Evaluadores/Evaluador";
const useStyles = makeStyles((theme) => ({
    paperWidth:{
        width:'200px',
        height:'100px',
        textAlign:'center',
        backgroundColor:'#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center'
    },
    paperTitle:{
        fontWeight:"bold",
        fontSize:16
    }
}));
const PublicPopup = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const classes = useStyles();
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [categories,setCategories] =useState(null);
    const [evaluador,setEvaluador] =useState(null);

    const handleClosePopup=()=>{
        props.onClose(false);
        setEvaluador(null);
        setCategories(null);
    }
    const handleClickOption=(id)=>{
        Call_Get('public-category/'+id,storeToken).then((res)=> {
            if(res.status=="ok"){
                if(res.isFinal) {
                    setCategories(null);
                    setEvaluador(res.result["id"]);
                }else{
                    setCategories(res.result);
                    setEvaluador(null);
                }

            }
            setOpenPopup(props.open);
        }).catch(err=>{
            alert("error");
            setOpenPopup(false);
            console.log(err);
        });
    }
    const handleOnSetValue=(id,name,v,textValue)=>{
        props.onSetValue(id,name,v,textValue);
        setEvaluador(null);
        setCategories(null);
    }
    useEffect(()=>{
        if(props.open==true){
            Call_Get('public-category/',storeToken).then((res)=> {
                if(res.status=="ok"){
                    setCategories(res.result);
                }
                setOpenPopup(props.open);
            }).catch(err=>{
                alert("error");
                setOpenPopup(false);
                console.log(err);
            });
        }
        setOpenPopup(props.open);

    },[props]);
    return(
        <Dialog
            open={(openPopup?openPopup:false)}
            onClose={handleClosePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
                >
            <DialogTitle id="alert-dialog-title">{(evaluador?"Configura":"Selecciona una opcion")}</DialogTitle>
            <DialogContent>
                {categories && <Grid container spacing={3} >
            {categories.map((item)=>{
                return (<Grid key={item.id} item xs={6} md={6} onClick={()=>{handleClickOption(item.id)}}>
                        <Paper className={classes.paperWidth}>
                            <div className={classes.paperTitle}>{item.name}</div>
                            <div className={classes.paperValue}>{(item.description?item.description:"")}</div>
                        </Paper>
                    </Grid>);
            })}
                </Grid>}
            {evaluador && <Grid container spacing={3} >
            <Grid item xs={12} md={12}>
                <Evaluador ev={evaluador} onSetValue={handleOnSetValue}/>
            </Grid>
                </Grid>
            }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClosePopup} color="primary">
                Cancelar
                </Button>
            </DialogActions>
        </Dialog>
)
}

export default PublicPopup;




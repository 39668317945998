import React,{useState,useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {GetPaymentType,NumberPrint} from "../providers/Utils/utils";
import {makeStyles} from "@material-ui/core/styles";
import {http_post} from "../providers/http/http";
import useLocalStorage from "../providers/store/localStorage";
import history from "../providers/History/history";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    finishStep2:{
    width:"100%"
    },
    redColor:{
        color:"#ff2727",
        fontWeight:"bold"
    }
}));
const PopupFinish2 = (props) => {
    const classes = useStyles();
    const [openPopup,setOpenPopup]=useState(false);
    const [difference,setDifference] =useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [totalPricePrint,setTotalPricePrint] =useState(null);
    const [totalPricePrint2,setTotalPricePrint2] =useState(null);
    const [totalPrice2,setTotalPrice2] =useState(null);

    const handleClosePopup=()=>{
        props.onClose(false);
    }
    const handleBackPopup=()=>{
        props.onBack();
    }
    const handleYesPopup=()=>{
        props.onYes(props.data);//final_amount: "1400",final_price: 0.75,total_price: 1125,total_price2: 1050
        let formData={"orderId":props.record.id,"final_amount":props.data.final_amount,"final_price":props.data.final_price,"total_price":props.data.total_price,"total_price2":props.data.total_price2};
        console.log("toooken",storeToken);
        if(props.record.payment_type_id==1) {
            http_post("order-return", formData, storeToken).then((res) => {
                if(res.status=="ok"){
                    if(res.redsys){
                        openWindowRedsys(res.redsys.url, res.redsys.version, res.redsys.parameters, res.redsys.signature);
                    }else{
                        history.push("/orders-processed/");
                    }
                }else{
                    alert("error desconocido");
                }
                // console.log("resultado");
                // // setPopupDevolucion(true);
                // // setPopupDevolucionData(res);
                // // window.open(res,"_blank");
                // console.log(res);
                // openWindowRedsys(res.url, res.version, res.parameters, res.signature);

                // console.log(res);
            });
        }else{
            let formData={"orderId":props.record.id,"final_amount":props.data.final_amount,"final_price":props.data.final_price,"return":0};
            http_post("order-return-finish", formData, storeToken).then((res) => {
                console.log("resultado");
                // setPopupDevolucion(true);
                // setPopupDevolucionData(res);
                // window.open(res,"_blank");
                console.log(res);
                //openWindowRedsys(res.url, res.version, res.parameters, res.signature);

                // console.log(res);
            });
        }
    }
    function openWindowRedsys(url,version, parameters, signature) {
        console.log("version",version);

        console.log("params",parameters);
        console.log("signature",signature);
        console.log("url",url);
        let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);

        form.setAttribute("target", windowName);

        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "Ds_SignatureVersion");
        hiddenField.setAttribute("value", version);
        form.appendChild(hiddenField);
        var hiddenField2 = document.createElement("input");
        hiddenField2.setAttribute("type", "hidden");
        hiddenField2.setAttribute("name", "Ds_MerchantParameters");
        hiddenField2.setAttribute("value", parameters);
        form.appendChild(hiddenField2);
        var hiddenField3 = document.createElement("input");

        hiddenField3.setAttribute("type", "hidden");
        hiddenField3.setAttribute("name", "Ds_Signature");
        hiddenField3.setAttribute("value", signature);
        form.appendChild(hiddenField3);
        document.body.appendChild(form);

        window.open('', windowName);

        form.submit();
    }
    useEffect(()=>{
        setDifference("");
        setOpenPopup(props.open);
        console.log("datos",props.data);
        if(props.data){
            if(props.data.total_price && props.data.total_price2){
                let value=props.data.total_price-props.data.total_price2;
                if(value>0) {
                    let vStr = new Intl.NumberFormat("es-ES", {style: "currency", currency: "EUR"}).format(value);
                    console.log("formateado", vStr);
                    setDifference(vStr);

                    setTotalPrice2(props.data.total_price2);
                }
                setTotalPricePrint(NumberPrint(props.data.total_price.toFixed(2).toString()));
                setTotalPricePrint2(NumberPrint(props.data.total_price2.toFixed(2).toString()));
            }
        }
    },[props]);
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Finalizar pedido (2/2)</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <div className={classes.finishStep2}>
        {props.record.payment_type_id && <div>Forma de pago: <b>{GetPaymentType(props.record.payment_type_id)}</b></div>}
        {props.data && props.data.total_price && <div>Precio solicitado: <b>{totalPricePrint}</b></div>}
            {props.data && props.data.total_price2 && <div>Precio final suministrado: <b>{totalPricePrint2}</b></div>}
            {difference!="" && <div>La diferencia es de <span className={classes.redColor}>{difference}</span>.</div>}
            {props.record && props.record.payment_type_id==1 && difference!="" &&<div>Como la forma de pago es pago con tarjeta procederemos
                a la devolucion de <span className={classes.redColor}>{difference}</span>, serás redirigido a una pantalla de confirmacion de devolución de Redsys</div>}
        </div>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleBackPopup} color="primary">
        Atrás
        </Button>
        <Button onClick={handleYesPopup} color="primary">
        Finalizar
        </Button>
        <Button onClick={handleClosePopup} color="primary">
        Cancelar
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupFinish2;






import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,PedidoEstado,PedidoFranja,GasoleoIcon,FormatPrice,FormatNumber} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
// import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from '@material-ui/icons/Visibility';
import {useTranslation} from "react-i18next";
import {Button, Dialog, FormControl, InputLabel, Select} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";
import {FormatDateTime} from '../../providers/Utils/utils'
import Tooltip from '@material-ui/core/Tooltip';


import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from "clsx";
import CustomOrderToolbar from "../../components/CustomOrderToolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import CustomOrderProcessedToolbar from "../../components/CustomOrderProcessedToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
Save:forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
Edit:forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
View:forwardRef((props, ref) => <ViewIcon {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    },
    centerAlign:{
        textAlign:'center'
    },
    rightAlign:{
        textAlign:'right'
    }
}));

const BaseTableOrdersProcessed = (props) => {
    const [table, setTable] = useState({});
    const [data, setData] = useState([]);
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const [selectedFinishIds,setSelectedFinishIds] =useState([]);
    const [selectedIncidenceIds,setSelectedIncidenceIds] = useState([]);
    const [openSelecteds,setOpenSelecteds] =useState(false);
    const [incidence,setIncidence] = useState("");
    const [newDateIncidence,setNewDateIncidence] = useState("");

    const [openIncidenceSelecteds,setOpenIncidenceSelecteds] =useState(false);

    const [dialogTitle,setDialogTitle]  = useState("");
    const [dialogDesc,setDialogDesc] =useState("");
    const handleNewDatencidenceChange=(e)=>{
        setNewDateIncidence(e.target.value);
    }
    const handleIncidenceChange=(e)=>{
        setIncidence(e.target.value);
    }
    const handleFinishOrder=(selectedRows,d)=>{
        let listIds=[];
        for(let row in selectedRows.data){
            let index= selectedRows.data[row]["dataIndex"];
            listIds.push(d[index].data[0]);
        }
        console.log("list",listIds);
        setSelectedFinishIds(listIds);
        setSelectedIncidenceIds([]);
        setOpenSelecteds(true);
        setDialogTitle("Finalizar pedidos");
        setDialogDesc("¿Estás seguro que quieres finalizar los " +listIds.length+" pedidos?");
    }
    const handleIncidenceOrder=(selectedRows,d)=>{
        let listIds=[];
        for(let row in selectedRows.data){
            let index= selectedRows.data[row]["dataIndex"];
            listIds.push(d[index].data[0]);
        }
        console.log("list",listIds);
        setSelectedIncidenceIds(listIds);
        setOpenIncidenceSelecteds(true);
        setDialogTitle("Incidencia pedidos");
        setDialogDesc("¿Estás seguro que quieres recolocar los pedidos a causa de la incidencia para los " +listIds.length+" pedidos?");
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");
    }
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'multiple',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomOrderProcessedToolbar selectedRows={selectedRows} onRowIncidence={()=>handleIncidenceOrder(selectedRows,data)} onRowFinish={()=>handleFinishOrder(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.title}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleClickYesIncidenceSelecteds=()=>{
        console.log("yes");
        console.log("fin",selectedIncidenceIds.length);
        let url="";
        let formData={};
        if(selectedIncidenceIds.length>0){
            //finalizamos la lista de ids
            formData={"order_ids":selectedIncidenceIds,"description":incidence,"newDate":newDateIncidence};
            http_post("order-incidence-list",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    window.location.reload();
                    setOpenSelecteds(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
        }

    }
    const handleClickYesSelecteds=()=>{
        console.log("yes");
        console.log("fin",selectedFinishIds.length);
        let url="";
        let formData={};
        if(selectedFinishIds.length>0){
            //finalizamos la lista de ids
            formData={"order_ids":selectedFinishIds};
            http_post("order-finish-list",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    window.location.reload();
                    setOpenSelecteds(false);
                }else{
                    alert("There´s been an error during save action");
                }
            });
        }
    }
    const handleCloseOpenIncidenceSelecteds=()=>{
        console.log("close incidence popup");
        setOpenIncidenceSelecteds(false);
    }
    const handleCloseOpenSelecteds=()=>{
        console.log("close");
        setOpenSelecteds(false);
    }
    useEffect(() => {
        console.log("init");
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log("res server",res);
            // console.log("columns",res.columns);

            // let foundDate=res.columns.findIndex(element=>element.name=="date");
            // // console.log("found",foundDate);
            // if(foundDate!=-1){
            //     // console.log("yes",res.columns[foundDate]);
            //     res.columns[foundDate]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
            //         return (
            //             <div>
            //             {FormatDateTime(value)}
            //             </div>
            //     );
            //     }
            // }
            let foundStatus=res.columns.findIndex(element=>element.name=="status_id");
            if(foundStatus!=-1){
                res.columns[foundStatus]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {PedidoEstado(value)}
                        </div>
                );
                }
            }
            let foundFranja=res.columns.findIndex(element=>element.name=="hour_id");
            if(foundFranja!=-1){
                res.columns[foundFranja]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div className={classes.centerAlign}>
                        {PedidoFranja(value,"short")}
                </div>
                );
                }
            }
            let foundAmount=res.columns.findIndex(element=>element.name=="amount");
            if(foundAmount!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundAmount]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.rightAlign}>
                        {v}
                        </div>
                );
                }
            }
            let foundBasePrice=res.columns.findIndex(element=>element.name=="base_price");
            if(foundBasePrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundBasePrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    // let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.rightAlign}>
                        {FormatNumber(value,false,true)} €
                        </div>
                );
                }
            }
            let foundPrice=res.columns.findIndex(element=>element.name=="price");
            if(foundPrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundPrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div className={classes.rightAlign}>
                            {value>0 && <b>{FormatNumber(value,false,true)} €</b>}
                            {value==0 && <span>{value}</span>}
                        </div>
                );
                }
            }
            console.log("columnas" ,res.columns);
            let foundOperatorId=res.columns.findIndex(element=>element.name=="operator_id");
            console.log(foundOperatorId);
            if(foundOperatorId!=-1){
                res.columns[foundOperatorId]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    if(value==null){
                        return (<div></div>);
                    }else{
                        if(value!=storeAdminId){
                            return (<div>Usuario</div>);

                        }else
                            return (<div>Tú</div>);
                    }
                }
            }
            let foundObservations=res.columns.findIndex(element=>element.name=="observations");
            if(foundObservations!=-1){
                res.columns[foundObservations]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {value && value.length>30 && <Tooltip placement="bottom" title={value}>
                        <span>{value.substring(1,30)+"..."}</span>
                        </Tooltip>}
                    {!value || value.length<=30 && <span>{value}</span>}
                    </div>
                    );
                }
            }

            let foundGasoil=res.columns.findIndex(element=>element.name=="product_id");
            if(foundGasoil!=""){
                res.columns[foundGasoil]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {GasoleoIcon(value)}
                        </div>
                );
                }
            }
            setTable(res);
            setData(res.data);
            var tmp_actions=tableActions;
            if(res.allowNew!==false ){
                tmp_actions.push({
                    icon: tableIcons.Add,
                    tooltip: 'Add Element',
                    isFreeAction: true,
                    onClick: (event) => handleNew(event)
                });
            }
            if(res.allowView!==false){
                tmp_actions.push({
                    icon: tableIcons.View,
                    tooltip: 'View Element',
                    onClick: (event, rowData) => handleView(event,rowData)
                });
            }
            if( res.allowEdit!==false){
                tmp_actions.push({
                    icon: tableIcons.Edit,
                    tooltip: 'Edit Element',
                    onClick: (event, rowData) => handleEdit(event,rowData)
                });

            }
            if(res.allowDelete!==false){
                tmp_actions.push({
                    icon: tableIcons.Delete,
                    tooltip: 'Delete Element',
                    onClick: (event, rowData) => handleDelete(event,rowData)
                });

            }
            setTableActions(tableActions);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  selected={pageSelected} />
    <main className="page-main">
        <div className={classes.toolbar}/>
    <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
    <div className={(table.data==null?"circular-position":"is-hidden")}>
<CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
    title={t("pages."+props.title)}
    data={table.data}
    columns={table.columns}
    options={tableOptions}
    />
    {/*<MaterialTable icons={tableIcons}
            className={classes.materialtable}
            title=""
            columns={table.columns}
            data={table.data}
            options={{
                cellStyle:{
                    paddingTop:'5px',
                    paddingBottom:'5px',
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                },
                filtering:(table.filtering==false?false:true),
                sorting:true,
                exportButton:true,
                pageSize:storePagination["pageSize"],
                emptyRowsWhenPaging: storePagination["emptyRowsWhenPaging"],
                pageSizeOptions:storePagination["pageSizeOptions"]}}
                actions={tableActions}
               localization={storeLocalization}

    />*/}
</div>
    {/*<div className="page-footer">*/}
    {/*    <Footer/>*/}
    {/*    </div>*/}
</div>
    <div className={clsx(classes.contentBody,"page-footer")}>
<Footer/>
    </div>
    </main>
    <Dialog
        open={(openSelecteds?openSelecteds:false)}
        onClose={handleCloseOpenSelecteds}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{dialogTitle} </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div>{dialogDesc}</div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClickYesSelecteds} color="primary">
                {"Sí"}
            </Button>
            <Button onClick={handleCloseOpenSelecteds} color="primary">
                    {"No"}
            </Button>
        </DialogActions>
    </Dialog>
    <Dialog
        open={(openIncidenceSelecteds?openIncidenceSelecteds:false)}
        onClose={handleCloseOpenIncidenceSelecteds}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">{dialogTitle} </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <div>
            {dialogDesc}
        <TextField
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            id="filled-name"
            label="Descripción de incidencia"
            type="text"
            autoComplete="off"
            value={(incidence?incidence:"")}
            onChange={handleIncidenceChange}
            helperText="Establece una descripción para la incidencia"
            fullWidth
            />
            <TextField
                InputLabelProps={{ shrink: true }}
                className={classes.textField}
                id="filled-name"
                label="Nueva fecha de recolocación de pedidos"
                type="text"
                autoComplete="off"
                value={(newDateIncidence?newDateIncidence:"")}
                onChange={handleNewDatencidenceChange}
                helperText="Establece una nueva fecha para recolocar pedidos"
                fullWidth
                />
    </div>

        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClickYesIncidenceSelecteds} color="primary">
        {"Sí"}
        </Button>
        <Button onClick={handleCloseOpenIncidenceSelecteds} color="primary">
        {"No"}
        </Button>
        </DialogActions>
        </Dialog>
    </div>
);
}
export default BaseTableOrdersProcessed;

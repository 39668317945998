import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({

}));
const FieldRecordText = (props) => {
    const classes = useStyles();
    const [myValue,setMyValue]=useState(undefined);
    const handleChange=(e)=>{
        // console.log(e.target.value);
        setMyValue(e.target.value);
        props.onSetValue({"item":props.field,"value":e.target.value});
    }
    let rel="";
    return(
        <div>
            {props.action && (props.action=="view" || props.allowEdit=="false" || props.allowNew=="false") && <div id={props.field}>
                <div className="field-view-title" >{props.title}</div>
                <div className={(rel==""?"field-view-value":"is-hidden")}>{(props.value?props.value:"(not defined)")}</div>
            </div>}
            {props.action && props.action=="edit" && (props.allowEdit==undefined || props.allowEdit=="true") &&
                <TextField
                    className={classes.textField}
                    id={props.field}
                    name={props.field}
                    label={props.title}
                    type={props.type}
                    autoComplete={props.autocomplete}
                    helperText={(props.description?props.description:"")}
                    value={(myValue==undefined?props.value:myValue)}
                    // defaultValue="-"
                    onChange={handleChange}
                    fullWidth
                />
            }
            {props.action && props.action=="new" && (props.allowNew=="true" || props.allowNew==undefined) &&
                <TextField
                    className={classes.textField}
                    id={props.field}
                    label={props.title}
                    type={props.type}
                    name={props.field}
                    autoComplete={props.autocomplete}
                    multiline={(props.multiline=="true"?true:false)}
                    rows={(props.multiline=="true"?4:1)}
                    helperText={(props.description?props.description:"")}
                    value={myValue}
                    onChange={handleChange}
                    fullWidth
                />
            }
        </div>
    );
}

export default FieldRecordText;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment from "moment";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_Sin_Compras = (props) => {
    const classes = useStyles();
    const [name] =useState("Sin Compra");
    const [checkedDates,setCheckedDates] =useState(false);
    const [dateFromValue,setDateFromValue] =useState(new Date());
    const [dateToValue,setDateToValue] =useState(new Date());

    const handleCheckboxChange=(e)=>{
        setCheckedDates(e.target.checked);
    }
    const handleSave=()=>{
        let valor={};
        console.log("apclicamos evaluador",props.id,name);
        if(checkedDates){
            valor.mode="fechas";
            valor.desde = moment(dateFromValue).format('YYYY-MM-DD').toString();
            valor.hasta = moment(dateToValue).format('YYYY-MM-DD').toString();
            console.log("valor",valor);
        }
        props.onSetValue(props.id,name,valor);
    }
    const handleDateFromChange=(v)=>{
        setDateFromValue(v);
    }
    const handleDateToChange=(v)=>{
        setDateToValue(v);
    }
    return(
        <div className="father-header-title">
            <div className="">
                Busca clientes que no hayan finalizado una compra
            </div>
            <div>Obtenemos datos de clientes que no hayan pagado ningún pedido</div>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                        checked={checkedDates}
                        onChange={handleCheckboxChange}
                        name="checkedF"
                            />
                    }
                        label="Por fechas"
                    />
                </Grid>
                {checkedDates && <Grid container>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDateTimePicker
                            variant="inline"
                            format="dd/MM/yyyy HH:mm"
                            ampm={false}
                            readOnly={props.action=="view"}
                            id="date-picker-inline"
                            label="Fecha Desde"
                            value={dateFromValue}
                            onChange={handleDateFromChange}
                            helperText={"Fecha desde"}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDateTimePicker
                            variant="inline"
                            format="dd/MM/yyyy HH:mm"
                            ampm={false}
                            readOnly={props.action=="view"}
                            id="date-picker-inline"
                            label="Fecha Hasta"
                            value={dateToValue}
                            helperText={"Fecha hasta"}
                            onChange={handleDateToChange}
                            KeyboardButtonProps={{
                            'aria-label': 'change date',
                            }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>}
            </Grid>
            <div>
                <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<EditIcon />}
                onClick={handleSave}
                    >
                    Aplicar
                </Button>
            </div>
        </div>
)
}

export default Evaluador_Sin_Compras;
import React,{useState,useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import useLocalStorage from "../providers/store/localStorage";
import OperatorPopup from "./OperatorPopup";
import {FormatDateTime} from "../providers/Utils/utils";
const useStyles = makeStyles((theme) => ({
    paperBackground:{
        backgroundColor:"#f0f0f0"}
}));
const PublicConditionComponent = (props) => {
    const classes = useStyles();
    const [operatorPopup,setOperatorPopup] =useState(null);
    const [operatorValue,setOperatorValue] =useState("Y");
    const [condiciones,setCondiciones] =useState(null);
    const [v,setV]=useState(null);
    useEffect(()=>{
        setCondiciones(props.condiciones);
        console.log("lemento",props.el);
        if(props.el.ev=="6" || props.el.ev==7 || props.el.ev==8){
            // console.log("jelem",props.el);
            let vText="Valor="+props.el.value.v;

                if(props.el.value.mode) {
                    vText += " Modo=" + props.el.value.mode;
                    vText += " Desde=" + FormatDateTime(props.el.value.desde);
                    vText += " Hasta=" + FormatDateTime(props.el.value.hasta);
            }
            setV(vText);

        }else if(props.el.ev==16){
            let vText="Valor=Sin compras";
            if(props.el.value.mode) {
                vText += " Modo=" + props.el.value.mode;
                vText += " Desde=" + FormatDateTime(props.el.value.desde);
                vText += " Hasta=" + FormatDateTime(props.el.value.hasta);
            }
            setV(vText);
        }else{
            if(props.el.textValue)
                setV(props.el.textValue);
            else{
                setV(props.el.value);
            }
        }
    },[]);

    const handleChangeOperator=(id,value)=>{
        console.log("change operator");
            console.log("change operator", id);
            setOperatorPopup(id);
            setOperatorValue(value);
    }
    const handleSetOperatorPopup=(id,v)=>{
        setOperatorPopup(null);
        changeItem(condiciones,id,v);
    }
    const changeItem=(arr,id,value)=>{
        let cond=arr;
        cond.filter(elem => elem.id == id)
            .forEach(elem => elem.operator = value);
        setCondiciones(cond);
        props.onSetCondiciones(cond);
    }
    return(
        <Grid container className={classes.paperBackground}>
            <Grid container>
                <Grid item xs={6}>Nombre:</Grid>
                <Grid item xs={6}><b>{props.el.name}</b></Grid>
            </Grid>
            {props.action=="view" &&
                <Grid container>
                    <Grid item xs={6}>Operador:</Grid>
                    <Grid item xs={6}>{props.el.operator}</Grid>
                </Grid>
            }
            {(props.action=="edit" || props.action=="new") &&
                <Grid container>
                    <Grid item xs={6}>Operador:</Grid><Grid item xs={6}>
                        <Button onClick={()=>{handleChangeOperator(props.el.id,props.el.operator)}} >{props.el.operator}</Button>
                    </Grid>
                </Grid>}
            <Grid container>
                <Grid item xs={6}>Valor:</Grid>
                <Grid item xs={6}><b>{v}</b></Grid>
            </Grid>
            <OperatorPopup open={operatorPopup} value={operatorValue} onSetValue={handleSetOperatorPopup}/>
            </Grid>
    )
}

export default PublicConditionComponent;




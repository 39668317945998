

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,UserRol} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from "react-i18next";
import {Button, Dialog, FormControl, InputLabel, Select} from "@material-ui/core";
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CustomPostalCodesToolbar from "../../components/CustomPostalCodesToolbar";
import Popup from "../../components/Popup";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    },
    centerText:{
        width:'100%',
        textAlign:'center'
    },
    formControl:{
        marginTop:20,
        marginBottom:20,
        width:'100%'
    }

}));

const BaseTablePostalCodes = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleClosePopupError=()=>{
        setOpenPopupError(false);
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [postalCodesIds,setPostalCodesIds] =useState(null);
    const [tableActions,setTableActions]=useState([
    ]);
    const [baseSelected,setBaseSelected] =useState(null);
    const [basePopup,setBasePopup] =useState(false);
    const [baseCleanPopup,setBaseCleanPopup] =useState(false);
    const [openPopupError,setOpenPopupError] =useState(false);
    const [titlePopupError,setTitlePopupError] =useState("");
    const [descPopupError,setDescPopupError] =useState("");
    const [resendEmail,setResendEmail] =useState(null);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomPostalCodesToolbar
                        selectedRows={selectedRows} onRowClean={()=>handleBaseClean(selectedRows,data)}
                        onRowBaseAssign={()=>handleBaseAssign(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        // onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleBaseClean=(selectedRows,d)=>{
        console.log("sel",selectedRows);
        console.log("dindex",selectedRows.data[0].dataIndex);
        let dIndex=selectedRows.data[0].dataIndex;
        console.log("d",d);
        console.log("id a asignar",d[selectedRows.data]);
        let found=d.find(element=>element.dataIndex==dIndex);
        console.log("found",found.data[0]);
        let postalIds=[]
        for(let r in selectedRows.data){
        //     // console.log("id a asignar",d[selectedRows.data[r].dataIndex].data[0]);
            let dIndex=selectedRows.data[r].dataIndex;
            let found=d.find(element=>element.dataIndex==dIndex);
            if(found){
                postalIds.push(found.data[0]);
            }
        }

        console.log("lista de postalids",postalIds);
        setPostalCodesIds(postalIds);
        setBaseSelected(null);
        setBaseCleanPopup(true);
    }
    const handleBaseAssign=(selectedRows,d)=>{
        // console.log("asignamos el almacen");
        // console.log("sel",selectedRows.data);
        // console.log("d",d);
        // console.log(table.data(selectedRows.data[0].dataIndex));
        let postalIds=[]
        for(let r in selectedRows.data){
            // console.log("r",selectedRows.data[r].dataIndex);
            let row=selectedRows.data[r].index;
            // console.log("id",d[r].data[0]);
            // let findIndex=selectedRows.data[r].dataIndex;
            // console.log("a buscar ="+findIndex);
            // let found=d.find(element=>element.dataIndex==findIndex);
            // console.log("found",found.data[0]);
            // console.log("id a asignar",d[selectedRows.data[r].dataIndex]);
            postalIds.push(d[row].data[0]);
            // postalIds.push(d[selectedRows.data[r].dataIndex].data[0]);
        }
        setPostalCodesIds(postalIds);
        setBaseSelected(null);
        setBasePopup(true);
    }
    const handleBaseAssignClick=(e)=>{
        console.log("base seleccionada para asignar a los codigos postales");
        //hacer peticion al server para asignar
        console.log("mandar al server",baseSelected,postalCodesIds);
        if(baseSelected==null) {
            setOpenPopupError(true);
            setTitlePopupError("Faltan datos por rellenar");
            setDescPopupError("Tienes que seleccionar un almacén para hacer la asignación");
        }
        else{
            let formData={"baseSelected":baseSelected,"postalCodes":postalCodesIds};
            http_post("config-postal-codes-assign",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    console.log("asignado!");
                    setBasePopup(false);
                    window.location.reload();
                }else{
                    alert("There´s been an error during save action");
                }
            });
        }
    }
    const handleBaseCleanClick=(e)=>{
        console.log("base seleccionada para asignar a los codigos postales");
        //hacer peticion al server para asignar
        console.log("mandar al server",postalCodesIds);
        let formData={"postalCodes":postalCodesIds};
        http_post("config-postal-codes-clean",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                console.log("quitado!");
                setBaseCleanPopup(false);
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCloseBasePopup=()=>{
        console.log("close popup");
        setBasePopup(false);
    }
    const handleCloseBaseCleanPopup=()=>{
        setBaseCleanPopup(false);
    }
    const handleBaseChange=(e)=>{
        console.log("change base");
        setBaseSelected(e.target.value);
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log(res);
            // let foundVisible=res.columns.findIndex(element=>element.name=="visible");
            // if(foundVisible!=-1){
            //     res.columns[foundVisible]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
            //         return (
            //             <div>
            //             {(value==1?"Sí":"No")}
            //     </div>
            //     );
            //     }
            // }
            setTable(res);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });
    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  mode="profile" selected={pageSelected} />
    <main className="page-main">
        <div className={classes.toolbar}/>
    <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
    <div className={(table.data==null?"circular-position":"is-hidden")}>
<CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
            title={t("pages."+props.title)}
            data={table.data}
            columns={table.columns}
            options={tableOptions}
        />
    </div>
    </div>
    <div className={clsx(classes.contentBody,"page-footer")}>
<Footer/>
    </div>
    <Dialog
        open={(basePopup?basePopup:false)}
        onClose={handleCloseBasePopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Seleccionar el almacén </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div>Has seleccionado <b>{(postalCodesIds?postalCodesIds.length:"")} códigos postales</b> para ser asignados a un almacén.</div>
                <div>Ten en cuenta que si asignar un código postal que ya tiene asignado un almacén se sustituirá por el nuevo que vas a asignar ahora.</div>

                <div className={classes.centerText}>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="demo-simple-select-label">Almacén</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={baseSelected}
                            defaultValue={baseSelected}
                            onChange={handleBaseChange}
                                >
                                {table["bases"] && table["bases"].map(menuItem=>{
                                    return (
                                        <MenuItem key={menuItem.id} value={menuItem.id}>
                                        {menuItem.name}
                                        </MenuItem>
                                );
                                })}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleBaseAssignClick} color="primary">
                        {"Asignar almacén"}
                    </Button>
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseBasePopup} color="primary">
        {t("buttons.cancel")}
            </Button>
        </DialogActions>
    </Dialog>
    <Dialog
    open={(baseCleanPopup?baseCleanPopup:false)}
    onClose={handleCloseBaseCleanPopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Quitar almacen</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
        <div>Has seleccionado <b>{(postalCodesIds?postalCodesIds.length:"")} códigos postales</b> </div>
    <div>Vas a proceder a quitar el almacén de estos códigos postales,¿Estás seguro?</div>

    <div className={classes.centerText}>
        <Button variant="contained" onClick={handleBaseCleanClick} color="primary">
        {"Quitar almacén"}
        </Button>
        </div>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseBaseCleanPopup} color="primary">
        {t("buttons.cancel")}
</Button>
    </DialogActions>
    </Dialog>
    {<Popup title={titlePopupError} description={descPopupError} open={openPopupError} onClose={handleClosePopupError}/>}
    </main>
    </div>
);
}
export default BaseTablePostalCodes;

import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from "@material-ui/core/styles";
import {Call_Get} from "../providers/Utils/utils";
import useLocalStorage from "../providers/store/localStorage";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_OtraConsulta = (props) => {
    const classes = useStyles();
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [name] =useState("Por otra consulta");
    const [almacenSelected,setAlmacenSelected] =useState(null);
    const [almacenTextSelected,setAlmacenTextSelected] =useState(null);
    const [almacenes,setAlmacenes] =useState(null);

    const handleAlmacen=(e)=>{
        setAlmacenSelected(e.target.value);
        let found=almacenes.find(element=>element.id==e.target.value);
        setAlmacenTextSelected(found.name);
    }
    useEffect(() => {
        console.log("cargamos consultas en el cuadro");
        Call_Get("public-actives", storeToken).then((res) => {
            console.log(res);
            setAlmacenes(res.result);
        });
    },[]);
    const handleSave=()=>{
        console.log("apclicamos evaluador",props.id,name,almacenSelected);
        props.onSetValue(props.id,name,almacenSelected,almacenTextSelected);
    }
    return(
        <div className="father-header-title">
        <div className="">
        Busca clientes por otra consulta
    </div>
    <div>Obtenemos datos de otra consulta almacenada</div>
    <div>
    <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Almacén
        </InputLabel>
        <Select
            value={almacenSelected}
            onChange={handleAlmacen}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Almacen' }}
        >
    {almacenes && almacenes.map((el)=>{
        return (
            <MenuItem value={el.id}>{el.name}</MenuItem>
    )
    })}
        </Select>
        <FormHelperText>Almacén</FormHelperText>
    </FormControl>
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Evaluador_OtraConsulta;
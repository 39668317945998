import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import AddIcon from "@material-ui/icons/Add";
// import { withStyles } from "@material-ui/core/styles";
// import AppBarComponent from "./AppBarComponent";
import ResendIcon from '@material-ui/icons/Email';
// const defaultToolbarStyles = {
//     iconButton: {
//     },
// };

const CustomClientsInviteToolbar = (props) => {
    // const [selected,setSelected] =useState([]);
    useEffect(() => {
        console.log("custom order toolbar");
        // setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data,props.mode]);
    // const handleClickAdd = () => {
    //     console.log("clicked on icon!");
    // }
    const handleClick=()=>{
        console.log("resend client");
        props.onRowSelect(props.selectedRows)
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
            <Tooltip title={"Reenviar Invitación"}>
                <IconButton  onClick={handleClick}>
                    <ResendIcon />
                </IconButton>
            </Tooltip>

        </div>
        );

}
export default CustomClientsInviteToolbar;

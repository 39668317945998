

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,ClienteEstado} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from '@material-ui/icons/Visibility';
import {useTranslation} from "react-i18next";
import {Button, Dialog} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";

import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

// const tableIcons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     // Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
//     Save:forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
//     Edit:forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
//     View:forwardRef((props, ref) => <ViewIcon {...props} ref={ref} />)
// };

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    }

}));

const BaseTableClients = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push("clients/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push("clients/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push("clients/"+rowData.id+"/view");
    }

    const handleBannedClient=(selectedRows,d)=>{
        // console.log("main view",selectedRows);
        // console.log("Baneamos al cliente si popup ok el");
        // // console.log(table.data(selectedRows.data[0].dataIndex));
        // console.log("id a banear",d[selectedRows.data[0].dataIndex].data[0]);
        // console.log("id a banear",d[selectedRows.data[0].dataIndex].data[1]);
        // setUserBanned(d[selectedRows.data[0].dataIndex].data[1]);
        // setBannedUserPopup(true);



        let dIndex=selectedRows.data[0].dataIndex;
        let found=d.find(element=>element.dataIndex==dIndex);
        console.log("found",found.data[0]);
        let clientIds=[];
        for(let r in selectedRows.data){
            //     // console.log("id a asignar",d[selectedRows.data[r].dataIndex].data[0]);
            let dIndex=selectedRows.data[r].dataIndex;
            let found=d.find(element=>element.dataIndex==dIndex);
            if(found){
                clientIds.push(found.data[1]);
            }
        }
        setUserBanned(clientIds);
        setBannedUserPopup(true);

        // history.push(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push("clients/"+row[0]+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [bannedUserPopup,setBannedUserPopup] =useState(false);
    const [userBanned,setUserBanned] =useState(null);
    const [bannedObservations,setBannedObservations] =useState(null);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'multiple',

        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},
        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomClientsToolbar selectedRows={selectedRows} onRowBanned={()=>handleBannedClient(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData),

        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleSetObservations=()=>{
        console.log("observations set",bannedObservations);
        let postData={email:userBanned,observations:bannedObservations};
        http_post(props.id+"-banned-user",postData,storeToken).then((res)=> {
            if(res.status=="ok"){
                console.log(res);
                window.location.reload();
                // history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
        return;
    }
    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleFillObservations=(e)=>{
        setBannedObservations(e.target.value);
    }
    const handleCloseBannedUserPopup=()=>{
        console.log("close popup");
        setBannedUserPopup(false);
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            let foundStatus=res.columns.findIndex(element=>element.name=="status_id");
            if(foundStatus!=-1){
                res.columns[foundStatus]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {ClienteEstado(value)}
                        </div>
                    );
                }
            }
            setTable(res);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });
    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
                <div className={classes.contentBody}>
                    <BreadCrumbComponent data={bc}/>
                    <div className={(table.data==null?"circular-position":"is-hidden")}>
                        <CircularProgress disableShrink className="is-loading"/>
                    </div>

                    <MUIDataTable
                        title={t("pages."+props.title)}
                        data={table.data}
                        columns={table.columns}
                        options={tableOptions}
                        />
                </div>
            </div>
            <div className={clsx(classes.contentBody,"page-footer")}>
                <Footer/>
            </div>
            <Dialog
                open={(bannedUserPopup?bannedUserPopup:false)}
                onClose={handleCloseBannedUserPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Restringir acceso al usuario</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>Vamos a restringir el acceso al usuario {userBanned && userBanned.length>0 && userBanned.join(",")}</div>
                            <div>Cuando haga login en la APP le aparecerá un cartel y el teléfono de donde puede ponerse en contacto con GSYM para resolver su problema</div>
                            <div className={classes.centerText}>
                                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                    <TextField className={classes.whiteText}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={"Motivo de bloqueo del usuario"}
                                        name="Observaciones"
                                        autoComplete="off"
                                        multiline
                                        onChange={handleFillObservations}
                                        value={bannedObservations}
                                        autoFocus
                                        />
                                </form>

                                <Button variant="contained" onClick={handleSetObservations} color="primary">
                                 {"Restringir acceso a usuario"}
                                </Button>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBannedUserPopup} color="primary">
                        {t("buttons.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </main>
        </div>
);
}
export default BaseTableClients;
import React,{useState,useEffect} from 'react';
import {Dialog, Divider} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import myTheme from "../theme/theme";
import clsx from "clsx";
import {NumberPrint, NumberValue} from "../providers/Utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
    buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    redColorInput:{
        '& input':{
            color:'#ff0000'
        }
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },

    bigTextField:{
        "& div": {
            backgroundColor: 'rgba(200,200,200,.5)',
        },
        "& input":{
            fontSize:20,
            color:'#c0c0c0'
        }
    },
    textFieldGreen:{

    },
    text:{
        fontSize:14,
        color:'#c0c0c0',
        paddingTop:24
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    orderDetail:{
        padding:10,
        marginTop:10,
        marginBottom:10
    },
    totalLine:{
        fontSize:18,
        borderTop:'1px solid #cccccc',
        paddingTop:10
    },
    line:{
        paddingTop:5,
        paddingBottom:5
    },
    cantidad:{
        fontSize:18,
        fontWeight:'bold'
    },
    finalText:{
        color:"rgba(35,193,48,1)"
    }
}));

const PopupFinish1 = (props) => {
    const [openPopup,setOpenPopup]=useState(false);
    const classes = useStyles();
    const [finalAmount,setFinalAmount] =useState(null);
    const [finalPrice,setFinalPrice] =useState(null);
    const [finalPricePrint,setFinalPricePrint] =useState(null);
    const [otherPrice,setOtherPrice] =useState(null);
    const [totalPrice,setTotalPrice] =useState(null);
    const [totalPrice2,setTotalPrice2] =useState(null);
    const [totalPriceLabel,setTotalPriceLabel] =useState(null);
    const [totalPrice2Label,setTotalPrice2Label] =useState(null);
    const handleClosePopup=()=>{
        props.onClose(false);
    }
    const handleNextPopup=()=>{
        props.onNext(finalAmount,finalPrice,totalPrice,totalPrice2);
    }
    const getImporte=()=>{
        let myTotalPrice=props.record.amount*props.record.price+otherPrice;
        setTotalPrice(myTotalPrice);
        let v=new Intl.NumberFormat("es-ES",{style: "currency", currency: "EUR"} ).format(myTotalPrice);
        setTotalPriceLabel(v);
        // return v;
    }
    const getImporte2=(finalAmount,finalPrice)=>{
        console.log("f1",finalAmount);
        console.log("f2",finalPrice);
        console.log("f3",otherPrice);
        let myTotal2=finalAmount*finalPrice+otherPrice;
        // setTotalPrice2(myTotal2);
        if(myTotal2>totalPrice) {
            alert("El precio no puede ser superior a lo que el cliente ya pagó con tarjeta");
            return false;
        }else {
            setTotalPrice2(myTotal2);
            //let v=new Intl.NumberFormat("es-ES",{style: "currency", currency: "EUR"} ).format(myTotal2);
            console.log("tttt",myTotal2);

            setTotalPrice2Label(NumberPrint(myTotal2.toFixed(2).toString()));
            return true;
        }
    }
    const variacionImporte=()=>{
        let v=new Intl.NumberFormat("es-ES", {style: "currency", currency: "EUR"}).format(totalPrice-totalPrice2);
        return v;
    }
    const handleChangeFinalPrice=(e)=>{
        let p =NumberPrint(e.target.value);
        let v =NumberValue(e.target.value);
        console.log("print",p);
        console.log("value",v);
        let numCommas=v.split(".");
        // console.log("numberCommas",numCommas);
        if(numCommas.length<=2) {
            if(getImporte2(finalAmount,v)){
                setFinalPrice(v);
                setFinalPricePrint(p);
            }
        }
    }
    const handleChangeFinalAmount=(e)=>{
        if(e.target.value<=props.record.amount) {
            setFinalAmount(e.target.value);
            getImporte2(e.target.value,finalPrice);
        }else
            alert("La cantidad entregada no puede ser superior a la solicitada");
    }

    useEffect(()=>{
        setOpenPopup(props.open);
        // {"orderId":record.id,"amount":record.amount,"price":record.price}
        setFinalAmount(props.record.amount);
        setFinalPrice(props.record.price);
        if(props.record.price>0)
            setFinalPricePrint(NumberPrint(props.record.price.toString()));
        // console.log(props.record);
        // console.log("orderdetail",props.orderDetail);
        generateOtherPrice(props.orderDetail);
        getImporte();
        getImporte2(props.record.amount,props.record.price);
    },[props]);

    const generateOtherPrice=(orderDetail)=>{
        let sum=0;
        if(orderDetail) {
            // console.log(orderDetail.data);
            for(let el in orderDetail.data){
                // console.log("element",el);
                if(orderDetail.data[el].order_detail_category_id!=1){
                    sum+=orderDetail.data[el].price;
                }
            }
        }
        //
        setOtherPrice(sum);
    }
    return(
        <Dialog
    open={(openPopup?openPopup:false)}
    onClose={handleClosePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Finalizar pedido (1/2)</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className={classes.centerText}>
                    <form className={classes.form} noValidate autoComplete="off" >
                    <TextField className={clsx(classes.bigTextField,classes.width50)}
                        variant="outlined"
                        margin="normal"
                        disabled
                        fullWidth
                        id="email"
                        label={"Cantidad solicitada"}
                        name="Liters"
                        value={props.record.amount}
                        autoFocus
                    />
                    <TextField className={clsx(classes.bigTextField,classes.width50)}
                        variant="outlined"
                        margin="normal"
                        disabled
                        fullWidth
                        id="email"
                        label={"Precio asignado x litro"}
                        name="Price"
                        value={props.record.price}
                        autoFocus
                    />
                    <div className={clsx(classes.width50,classes.text)}>
                        Otros cargos debidos a gastos de forma de pago o dto de promociones
                    </div>
                    <TextField className={clsx(classes.bigTextField,classes.width50)}
                    variant="outlined"
                    margin="normal"
                    disabled
                    fullWidth
                    id="email"
                    label={"Precio otros cargos"}
                    name="Price"
                    value={otherPrice}
                    autoFocus
                    />
                    <div className={classes.finalText}>Introduce aqui si hay variación entre la cantidad solicitada y la entregada finalmente por el repartidor</div>
                    <TextField className={clsx(classes.textFieldGreen,classes.width50)}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="email"
                        label={"Cantidad entregada"}
                        name="Liters"
                        value={finalAmount}
                        onChange={handleChangeFinalAmount}
                        autoFocus
                     />
                    <TextField className={clsx(classes.textFieldGreen,classes.width50)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label={"Precio final x litro"}
                    name="Price"
                    value={finalPricePrint}
                    onChange={handleChangeFinalPrice}
                    autoFocus
                    />
                    <Divider/>
                </form>
                <div className={clsx(classes.width50,classes.totalStyle)}>
                    <br/>
                    Importe solicitado<br/>
                    Total= {totalPriceLabel}
                    <br/>
        </div>
        <div className={clsx(classes.width50,classes.totalStyle)}>
                    <b>Importe suministrado<br/>
                    Total= {totalPrice2Label}</b>
                    <br/>
        </div>
                <div className={classes.variationStyle}>
                    Variación=El precio varia {variacionImporte()} con respecto a las tarifas oficiales
                </div>
            </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleNextPopup} color="primary">
        Siguiente
        </Button>
        <Button onClick={handleClosePopup} color="primary">
        Cancelar
        </Button>
        </DialogActions>
        </Dialog>
)
}

export default PopupFinish1;




import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_Email = (props) => {
    const classes = useStyles();
    const [name] =useState("Por email");
    const [email,setEmail] =useState(null);
    const handleEmail=(e)=>{
        setEmail(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos evaluador",props.id,name,email);
        props.onSetValue(props.id,name,email);
    }
    return(
        <div className="father-header-title">
        <div className="">
        Busca clientes por email
    </div>
    <div>Obtenemos datos de clientes donde su Emailcorresponda con una cuenta válida</div>
    <div className={classes.formControl}>
    <TextField
    id="email"
    label="Email del usuario"
    InputLabelProps={{ shrink: true }}
    helperText={"Email de la cuenta"}
    onChange={handleEmail}
    value={(email?email:"")}
    fullWidth
    />
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Evaluador_Email;
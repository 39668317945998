import React from 'react';
import Evaluador_Provincia from "./Evaluador_Provincia";
import Evaluador_Email from "./Evaluador_Email";
import Evaluador_CodPostal from "./Evaluador_CodPostal";
import Evaluador_GasoleoA from "./Evaluador_GasoleoA";
import Evaluador_GasoleoB from "./Evaluador_GasoleoB";
import Evaluador_GasoleoC from "./Evaluador_GasoleoC";
import Evaluador_Almacen from "./Evaluador_Almacen";
import Evaluador_OtraConsulta from "./Evaluador_OtraConsulta";
import Evaluador_FechaCompra from "./Evaluador_FechaCompra";
import Evaluador_FechaRegistro from "./Evaluador_FechaRegistro";
import Evaluador_Todos from "./Evaluador_Todos";
import Evaluador_Sin_Compras from "./Evaluador_Sin_Compras";
//pages

const Evaluador = (props) => {
    const handleOnSetValue=(id,name,v,textValue)=>{
        props.onSetValue(id,name,v,textValue);
    }
    const EvList = {
        3: Evaluador_Provincia,
        4: Evaluador_Email,
        5: Evaluador_CodPostal,
        6: Evaluador_GasoleoA,
        7: Evaluador_GasoleoB,
        8: Evaluador_GasoleoC,
        9: Evaluador_Almacen,
        10:Evaluador_OtraConsulta,
        12:Evaluador_FechaCompra,
        13:Evaluador_FechaRegistro,
        15:Evaluador_Todos,
        16:Evaluador_Sin_Compras

    };
    const Ev = EvList[props.ev];
    return (<Ev id={props.ev} onSetValue={handleOnSetValue} />);

}

export default Evaluador;
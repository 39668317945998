import React, { useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {Dialog, Divider, Input, InputLabel, Link, Paper, Select} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import {getWheelDelta} from "leaflet/src/dom/DomEvent";
import RelatedAddress from "../../components/RelatedAddress";
import RelatedBillings from "../../components/RelatedBillings";
import MapComponent from "../../components/MapComponent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PopupDenyAddress from "../../components/PopupDenyAddress";
import Popup from "../../components/Popup";
import PopupVerifyAddress from "../../components/PopupVerifyAddress";
import PopupVerifyBillingAddress from "../../components/PopupVerifyBillingAddress";
import RelatedPromotions from "../../components/RelatedPromotions";
import RelatedPayments from "../../components/RelatedPayments";
import RelatedIncidences from "../../components/RelatedIncidences";
import RelatedOrders from "../../components/RelatedOrders";
import OrdersResume from "../../components/OrdersResume";
import RelatedInvoices from "../../components/RelatedInvoices";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    link:{
        color:"#62bf62!important",
        display:'block',
        paddingTop:15
    },
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const BillingAddressesRecord = (props) => {
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const handleMoreClick = (event) => {
        // console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };
    const handleOpenSendCredentials=()=>{
        let formData={"userId":record.id};
        setOpenPopup(true);
    }
    const handleEditAddress=()=>{
        setName(record.name);
        setEmail(record.email);
        history.push(props.parentUrl+"/"+props.id+"/edit");
    }
    const handleDenyAddress=()=>{
        setPopupDenyAddress(true);
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [record,setRecord]=useState(null);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [popupDenyAddress,setPopupDenyAddress] =useState(false);
    const [orderPrice,setOrderPrice] =useState(null);
    const [openPopupVerifyAddress,setOpenPopupVerifyAddress]=useState(false);
    const [email,setEmail] =useState(null);
    const [cif,setCif] =useState(null);
    const [address,setAddress] =useState(null);
    const [cp,setCP] =useState(null);
    const [phone,setPhone] =useState(null);
    const [poblation,setPoblation] =useState(null);
    const [province,setProvince] =useState(null);
    const [navisionId,setNavisionId] =useState(null);
    const [name,setName] =useState(null);
    const [id,setId] =useState(null);
    const [verified,setVerified] =useState(false);
    const [readOnly,setReadOnly] =useState(false);
    const [basesUser,setBasesUser] =useState([]);
    const handleBack=()=>{
        console.log("back");
        if(!isRefreshed){
            if(props.action=="view")
                history.push("/"+props.parentId);
            else{
                history.goBack();
            }
        }
        else{
            setIsRefreshed(false);
            history.goBack();
            history.goBack();
        }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleSetPrice=(e)=>{
        setOrderPrice(e.target.value);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    // const handleDelete=(e)=>{
    //     console.log("vamos a eliminar el id="+props.id);
    //     http_post("documents/delete/"+props.id,null,storeToken).then((res)=> {
    //         if(res.res=="ok"){
    //             history.push("/"+props.parentId);
    //         }else{
    //             alert("There´s been an error during save action");
    //         }
    //     });
    // }
    const handleSaveAddress=()=>{
        console.log("save address");
        let formData={"id":id,"email":email,"name":name,"nif":cif,"address":address,"cp":cp};
        console.log(formData);
        http_post("billingaddresses-save/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleCancel=()=>{
        setName(null);
        setEmail(null);
        history.push("/"+props.parentId+"/"+props.id+"/view");
    }

    const handleView=(e,rowData)=>{
        console.log("View");
        history.push(pageSelected.id+"/"+rowData.id+"/view");
    }
    const handleEdit=()=>{
        console.log("edit");
        // console.log(rowData.id);
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push("/"+pageSelected.id+"/"+props.id+"/edit/"), 10);
        // history.push("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.replace("/"+pageSelected.id+"/"+props.id+"/edit/");
        // history.refreshState();
    }
    // const handleSavePreGame=(e)=>{
    //     console.log("pregame");
    // }
    // const handleSavePostGame=(e)=>{
    //     console.log("postGame");
    // }
    const handleSave=(e,id)=>{
        //if id then edit
        if(action=="newAll"){
            console.log(id);
            console.log("guardar todos");
            console.log(updateDetail);
            console.log(listItems);
            const postData = new FormData();
            for (var key in listItems) {
                if(typeof listItems[key] === "object")
                    postData.append(key, listItems[key]);
                else
                    postData.append(key, listItems[key]);
            }
            for (var key in updateDetail) {
                if(typeof updateDetail[key] === "object")
                    postData.append(key, updateDetail[key]);
                else
                    postData.append(key, updateDetail[key]);
            }
            http_post(props.parentId+"/saveAll",postData,storeToken).then((res)=> {
                if(res.res=="ok"){
                    history.push("/"+props.parentId);
                }else{
                    alert("There´s been an error during save action");
                }
            });
            return;
        }
        console.log(id);
        var postItems=[];
        const postData = new FormData();
        postData.append("id",id);
        console.log(listItems);
        for (var key in listItems) {
            if(typeof listItems[key] === "object"){
                // console.log("file");
                // console.log(listItems[key]);
                postData.append(key, listItems[key]);
            }else {
                // console.log("no file");
                postData.append(key, listItems[key]);
            }
            // postItems.push({"item":key,"value":listItems[key]});
        }
        // var postData={id:props.id,data:postItems};
        http_post(props.parentId+"/save",postData,storeToken).then((res)=> {
            if(res.res=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleItems=(item)=>{
        console.log("handle items ");
        listItems[item.item]=item.value;
        setListItems(listItems);
    }
    //refresh if we change the page from same baserecord
    // if(props.action!=actionPage){
    //     let init=false;
    //     if(actionPage=="")
    //         init=true;
    //     setActionPage(props.action);
    //     if(init==false)
    //         history.go(0);
    // }

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(['GK- Juan Gomez', 'DF- Luis Martínez', 'MD- Manuel Alen', 'FW - Alejandro Saez']);
    const [right, setRight] = React.useState(['MD- Alonso Moya', 'FW- Gonzalo Montes', 'DF- Rafael Simarro', 'DF- Gabriel del fresno']);

    const [t, i18n] = useTranslation('common');

    const [tab1Value, setTab1Value] = useState(0);
    const [tab2Value, setTab2Value] = useState(1);
    const [content1Value,setContent1Value] = useState(true);
    const [content2Value,setContent2Value] = useState(true);
    const [data,setData]=useState([]);
    const [stats,setStats] =useState(null);
    const [openAlert, setOpenAlert] =useState(false);
    const [dialogTitle,setDialogTitle] =useState("");
    const [dialogButtons,setDialogButtons] =useState(true);
    const [dialogAction,setDialogAction] =useState("");
    const [dialogYes,setDialogYes] =useState("Yes");
    const [dialogNo,setDialogNo] =useState("No");
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [dialogDescription,setDialogDescription] = useState("");
    const [pricesPopup,setPricesPopup] =useState([]);
    const [pos,setPos] =useState(null);
    const [invoices,setInvoices] =useState(null);
    const CssTextField = withStyles({
        root: {
            marginTop:15,
            '& label.Mui-focused': {
                color: 'green',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: 'green',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'red',
                },
                '&:hover fieldset': {
                    borderColor: 'yellow',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'green',
                },
            },
        },
    })(TextField);

    const handleOnSetValue=(val)=>{
        setUpdateDetail(val);
    }

    const handleDenyAddressPost=()=>{
        console.log("Deny address");
        let formData={id:props.id};
        http_post("addresses-deny",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                history.push("/"+props.parentId);
            }else{
                alert("There´s been an error during save action");
            }
        });
        setPopupDenyAddress(false);
    }
    const handleCloseDenyAddress=()=>{
        setPopupDenyAddress(false);
    }
    const handleVerifyAddress=()=>{
        console.log("verify address");
        setOpenPopupVerifyAddress(true);
    }
    const handleClosePopupVerify=()=>{
        setOpenPopupVerifyAddress(false);
    }

   useEffect(() => {
        let url="";
        setAction(props.action);
        if(props.action=="newAll") {
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+" " +props.action}]);
            url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
       if(props.action=="view"){
           setReadOnly(true);
           setIsRefreshed(false);
       }else{
           setReadOnly(false);
       }
        Call_Get(url,storeToken).then((res)=> {
            console.log("datos",res);
            // console.log(res.item.itemColumns);
            // setFields(res.item.itemColumns);
            // if(props.action!="newAll") {
            setRecord(res.record);
            if(res.record.gps_position) {
                let tmpPos = res.record.gps_position.split(",");
                setPos(tmpPos);
                // console.log("pos", tmpPos);
            }
            setId(res.record.id);
            // setRelateds(res.related);
            setInvoices(res.related["invoices"]);
            if(props.action=="view")
                setReadOnly(true);
            else
                setReadOnly(false);
            // // setSessions(res.item.tables.sessions);
            // setTables(res.item.tables);
            // console.log(res);
        });
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[props.action]);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChangeBases = (event) => {
        console.log("base change",event.target.value,event);
        setBasesUser(event.target.value);
    };
    const handleChangeVerified=(e)=>{
        console.log("cambio de check");
        alert("La verificación se hace mediante el boton 'Verificar dirección'");

    }
    const handleVerifyBillingAddress=(navisionId)=>{
        console.log(navisionId);
        setOpenPopupVerifyAddress(false);
        setNavisionId(navisionId);
        let form={"navisionId":navisionId};
        http_post("billing-addresses-verify/"+props.id,form,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const onVerifyAddress=(lat,lng,address,cp)=>{
        console.log("1",lat);
        console.log("2",lng);
        console.log("3",address);
        console.log("4",cp);
        let formData={lat:lat,lng:lng,address:address,cp:cp};
        http_post("addresses-verify/"+props.id,formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });

    }
    const handleCifChange=(e)=>{
        console.log("cif");
        setCif(e.target.value);
    }
    const handleAddressChange=(e)=>{
        console.log("address");
        setAddress(e.target.value);
    }
    const handleNavisionIdChange=(e)=>{
        console.log("navision");
        setNavisionId(e.target.value);
    }
    const handleCPChange=(e)=>{
        console.log("cp");
        setCP(e.target.value);
    }
    const handlePhoneChange=(e)=>{
        console.log("phone");
        setPhone(e.target.value);
    }
    const htmlContenido=()=>{
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <InputLabel shrink id={"select_"+props.field}>
                    {"Cliente"}
                    </InputLabel>
                    {record && <Link href={"/Clients/"+record.client_id+"/view"}
                    className={classes.link}>
                       &nbsp; {record.client_name}
                    </Link>}
                </Grid>
                <Grid item xs={12} sm={6}>
                        <TextField
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        id="filled-name"
                        label="Navision Id"
                        type="text"
                        helperText="Id de navision para esta dirección de cliente"
                        value={(props.action=="view"?(record && record.navision_id?record.navision_id:""):(navisionId?navisionId:(record && record.navision_id?record.navision_id:"")))}

                        onChange={handleNavisionIdChange}
                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    id="lat"
                    label="NIF"
                    InputProps={{
                    readOnly: readOnly,
                    }}
                    helperText="NIF del cliente"
                    value={(props.action=="view"?(record && record.cif?record.cif:""):(cif?cif:(record && record.cif?record.cif:"")))}

                    onChange={handleCifChange}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        id="filled-name"
                        label="Razón Social"
                        type="text"
                        helperText="Nombre de facturacion (Razón social)"
                        value={(props.action=="view"?(record && record.name?record.name:""):(name?name:(record && record.name?record.name:"")))}
                        onChange={handleNameChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        id="filled-name"
                        label="Teléfono"
                        type="text"
                        helperText="Teléfono del cliente"
                        value={(props.action=="view"?(record && record.phone?record.phone:""):(phone?phone:(record && record.phone?record.phone:"")))}

                        onChange={handlePhoneChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="direccion"
                        label="Dirección"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Direccion del cliente"
                        value={(props.action=="view"?(record && record.address?record.address:""):(address?address:(record && record.address?record.address:"")))}
                        onChange={handleAddressChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="cp"
                        label="CP"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        helperText="Código postal"
                        value={(props.action=="view"?(record && record.cp?record.cp:""):(cp?cp:(record && record.cp?record.cp:"")))}
                        onChange={handleCPChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                        <TextField
                        id="town"
                        label="Población"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Población"
                        value={(props.action=="view"?(record && record.poblation?record.poblation:""):(poblation?poblation:(record && record.poblation?record.poblation:"")))}

                        fullWidth
                        />
                </Grid>
                <Grid item xs={12} sm={6}>
                        <TextField
                        id="town"
                        label="Provincia"
                        InputProps={{
                        readOnly: true,
                    }}
                        helperText="Provincia"
                        value={(props.action=="view"?(record && record.province?record.province:""):(province?province:(record && record.province?record.province:"")))}
                        fullWidth
                        />
                </Grid>
                        {record && record.verified!=2 && <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleChangeVerified}
                                        checked={(record && record.verified?(record.verified==1?true:false):(verified?verified:false))}
                                        name="checkedF"
                                            />
                                }
                                label="Dirección verificada"
                            />
                                    </Grid>}

        </Grid>

        );
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" && <div className={classes.right}>
                                            <Button
                                                color="secondary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<CheckIcon />}
                                                onClick={handleVerifyAddress}
                                                    >
                                                    <span className="textButton">Verificar dirección</span>
                                            </Button>
                                            {/*<Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEditAddress}
                                                    >
                                                Editar dirección
                                            </Button>*/}
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleSaveAddress}
                                                    >
                                                    <span className="textButton">Guardar dirección</span>
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancel}
                                                    >
                                                    <span className="textButton">Cancelar guardar</span>
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Información</h2>
                                                {htmlContenido()}
                                            </Paper>
                                            {/* DETALLE */}
                                            <div className={classes.contentTab}>
                                                <div className={classes.content}>
                                                    <div className={classes.tabComponent}>
                                                        <AppBar position="static">
                                                            <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="simple tabs example">
                                                                {<Tab className={classes.tab} label={t("tabs.paymentBills")} {...a11yProps(0)} />}
                                                            </Tabs>
                                                        </AppBar>
                                                        {<TabPanel value={tab1Value} index={0}>
                                                            {invoices && <RelatedInvoices id={record.id} table={invoices}/>}
                                                            {/*<PlayerDetailTrainingsComponent data={data.trainings} chartList={data.trainingList30} chartTotal={data.trainingTotal30}/>*/}
                                                        </TabPanel>}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*{action == "view" &&*/}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
                <PopupDenyAddress address={(record?record.address:null)} cp={(record?record.cp:null)} open={popupDenyAddress} onClose={handleCloseDenyAddress} onDenyAddress={handleDenyAddressPost}/>
                <PopupVerifyBillingAddress open={openPopupVerifyAddress} onVerifyBillingAddress={handleVerifyBillingAddress} onClose={handleClosePopupVerify}/>
             </main>
            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>

    );
}
export default BillingAddressesRecord;


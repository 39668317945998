
import React, {Fragment,forwardRef, useEffect, useState,useRef} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../../components/Footer';
import AppBarComponent from '../../../components/AppBarComponent';
import BreadCrumbComponent from "../../../components/BreadCrumbComponent";
import {Call_Get, CheckToken} from "../../../providers/Utils/utils";
import useLocalStorage from "../../../providers/store/localStorage";
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from "react-i18next";
import {http_get} from "../../../providers/http/http";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Button} from "@material-ui/core";
import history from "../../../providers/History/history";
import clsx from 'clsx';
import myTheme from "../../../theme/theme";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:'#f0f0f0'  //#000000
    },
    field:{
        height:"450px",
    },
    tab:{
        minWidth:"60px",
        backgroundColor:'#E5DA6B'
    },
    tabContent:{
        backgroundColor:'#808080!important',
        height:'400px'
    },
    table: {
        minWidth: 650,
    },
    tableBody:{

    },
    tablePaper:{
        height:'350px'
    },
    avatar:{
        width:'80px'
    },
    sectionBackground:{
        // backgroundColor:"#242518",
        marginBottom:"10px",
    },
    section:{
        // backgroundColor:'rgba(245,0,47,.5)',// '#f50057',
        paddingLeft:'10px',
        paddingRight:'10px',
        paddingTop:'10px',
        paddingBottom:'10px',
        width:'100%',
        height:'40px',
        // marginLeft:"10px",
        // marginRight:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    link:{
        color:'#ffffff',
        textDecoration:'none',
        "&:hover": {
            textDecoration:'underline'
        }
    },
    notfound:{
        textAlign:'center'
    },
    avatarWidth:{
        width:'80px'
    },
    teamName:{
        fontSize:"24px",
        color:"#e5da6b"
    },
    centerItem:{
        textAlign:"center"
    },
    bigScore:{
        display:"inline-block",
        padding:"10px 40px",
        margin:"10px"
    },
    scoreRed:{
        backgroundColor:"#bf0811"
    },
    scoreYellow:{
        backgroundColor:"#e5da6b"
    },
    scoreGreen:{
        backgroundColor:"#62bf62"
    },
    scoreTitle:{
        color:"#000000",
        fontWeight:"bold",
        fontSize:"16px"
    },
    scoreValue:{
        color:"#ffffff",
        fontWeight:"bold",
        fontSize:"24px"
    },
    teamInfo:{
        padding:"20px"
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        ...myTheme.fieldRow
    },
    fieldField:{
        ...myTheme.fieldField
    },
    last5:{
        textAlign:"center",
        marginTop:"50px"
    },
    teamIcon:{
        float:"right",
        display:"inline-flex"
    },
    position:{
        display:"inline-flex",
    },
    positionCenter:{
        "&:before": {
            content:'""',
            display:"inline-block",
            height:"100%",
            verticalAlign:"middle",

        },
    },
    positionTitleContent:{
        display:"inline-block"
    },
    positionTitle:{
        textAlign:"right",
        color:myTheme.palette.titleFields.main,
        fontSize:"12px"

    },
    positionValue:{
        color:"#FFFFFF",
        textAlign:"center",
        fontWeight:"bold",
        fontSize:"28px"
    },
    positionImage:{
        display:"inline-block",
        paddingLeft:"20px",
        alignItems: "stretch",
    },
    textLink:{
        ...myTheme.textLink
    }
}));

export default function Languages() {
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const bc=[];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [t, i18n] = useTranslation('common');
    const matches = useMediaQuery('(min-width:800px)');
    const [langSelected,setLangSelected] =useLocalStorage("language","es");


    useEffect(() => {
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        setPageSelected({"id": "languages", "title": "Languages"});

    },[]);
    const handleChangeLanguage=(lang)=>{
        i18n.changeLanguage(lang);
        setLangSelected(lang)
        return ;
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent mode="profile" selected={pageSelected} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <Grid className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography paragraph>
                                    {t("main.languageSelected")} : {t("languages."+i18n.language)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button
                                    variant="contained"
                                    name="submit"
                                    color={(i18n.language=="es"?"primary":"default")}
                                    onClick={()=>{handleChangeLanguage("es")}}
                                >{t("languages.es")}{(i18n.language=="es"?<CheckIcon fontSize="small"/>:"") }</Button><br/><br/>
                                <Button
                                    variant="contained"
                                    name="submit"
                                    color={(i18n.language=="en"?"primary":"default")}
                                    onClick={()=>{handleChangeLanguage("en")}}
                                >{t("languages.en")}{(i18n.language=="en"?<CheckIcon fontSize="small"/>:"") }</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="page-footer">
                    <Footer/>
                </div>
            </main>
        </div>
    )
}
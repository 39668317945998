

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GasoleosLogo from '../../res/GSYM-logo2.png';
import useLocalStorage from "../../providers/store/localStorage";
import history from '../../providers/History/history';
import {CheckToken} from "../../providers/Utils/utils";
import {http_get, http_post} from "../../providers/http/http";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackgroundImage from '../../res/fondo1.jpg';
import BackgroundImageDev from '../../res/fondoDEV.jpg';
import { Link as RouterLink } from 'react-router-dom';
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Copyright() {
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    useEffect(() => {
        CheckToken(storeToken,"login");
    },[]);
    return (
        <Typography variant="body2" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.gsym.net/">
        GSYM - Portal
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
</Typography>
);
}

const useStyles = makeStyles((theme) => ({
    page:{
        backgroundImage:`url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height:"100%",
        overflowY:"auto"
    },
    pageDEV:{
        backgroundImage:`url(${BackgroundImageDev})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height:"100%",
        overflowY:"auto"
    },
    box:{
        backgroundColor:"rgba(255,255,255,.7)",
        minHeight:"100%",

    },
    paper: {
        // marginTop: theme.spacing(8),
        paddingTop:theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    whiteText:{
        // borderColor:'#ffffff',
        // // backgroundColor:'#ffffff',
        // "& input":{
        //     color:'#ffffff'
        // },
    },
    title:{
        textAlign:"center",
        fontSize:"18px",
    },
    forgot:{
        fontSize:"12px",
        color:"#ffffff",
    },
    registerlink:{
        color:theme.palette.primary.main,
    },
    register:{
        paddingTop:theme.spacing(3),
        textAlign:"center",
    },
    footer:{
        bottom:"0px",
        left:"0px",
        right:"0px",
        textAlign:"center",
    },
    gap:{
        paddingBottom:"20px"
    },
    centerText:{
        textAlign:'center'
    }
}));

export default function Home() {
    const classes = useStyles();
    const [email,setEmail] = useLocalStorage('GSYM_email','');
    const [forgotEmail,setForgotEmail] =useState("");
    const [forgotCode,setForgotCode] =useState('');
    const [newPassword,setNewPassword] =useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token',null);
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    // const [storePermissions,setStorePermissions] =useLocalStorage('GSYM_store_permissions',null);
    const [storeName,setStoreName] =useLocalStorage('GSYM_store_name');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const [password,setPassword] =useState("");
    const [openPopup,setOpenPopup]= useState(false);
    const [forgotPopup,setForgotPopup] = useState(false);
    const [forgotCodePopup,setForgotCodePopup] = useState(false);
    const [submitting,setSubmitting] =useState(false);
    const [langSelected,setLangSelected] =useLocalStorage("language","es");
    const [t, i18n] = useTranslation('common');
    const [openSnackBar,setOpenSnackBar]=useState(false);
    const [openSnackBarError,setOpenSnackError]=useState(false);
    const [messagePwdError,setMessagePwdError] =useState("");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_prof_referer',null);
    const [loginError,setLoginError] =useState("");

    const handleClosePopup=e=>{
        setOpenPopup(false);
    }
    const handleCloseForgotPopup=e=>{
        setForgotPopup(false);
        setForgotCodePopup(false);
    }
    const initialFormData = Object.freeze({
        email: email,
        password: ""
    });
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const [formData, updateFormData] = React.useState(initialFormData);
    const handleEmail=(e)=>{
        setEmail(e.target.value);
        updateFormData({
            ...formData,
            // Trimming any whitespace
            ["email"]: e.target.value.trim()
        });
    }
    const handleForgotEmail=(e)=>{
        setForgotEmail(e.target.value);
    }
    const handleForgotCode=(e)=>{
        setForgotCode(e.target.value);
    }
    const handleNewPassword=(e)=>{
        setNewPassword(e.target.value);
    }
    const handleRecoverPwd=(e)=>{
        console.log("recover pass");
        var data={};
        data.forgotEmail=forgotEmail;
        http_post("admin-recover", data,null).then((res) => {
            if(res.status=="ok"){
                setForgotPopup(false);
                setForgotCode("");
                setNewPassword("");
                setForgotCodePopup(true);
            }
        });
    }
    const handleCodeRecoverPwd=(e)=>{
        console.log("recover text pass send code");
        if(newPassword=="" || forgotCode==""){
            alert("no hay datos");
        } else{
            var data={};
            data.newPassword=newPassword;
            data.code=forgotCode;
            data.forgotEmail=forgotEmail;
            http_post("admin-recover-verify", data,null).then((res) => {
                console.log(res);
                if(res.status=="ok"){
                    setForgotCodePopup(false);
                    setOpenSnackBar(true);
                }else{
                    setMessagePwdError(res.additional_info.error);
                    setOpenSnackError(true);
                }
            });
        }

    }
    const handleCloseForgotCodePopup=(e)=>{
        setForgotCodePopup(false);
    }

    const handleChangeLanguage=(lang)=>{
        i18n.changeLanguage(lang);
        setLangSelected(lang)
        return ;
    }

    const handlePwd=(e)=>{
        setPassword(e.target.value);
        updateFormData({
            ...formData,

            // Trimming any whitespace
            ["password"]: e.target.value.trim()
        });
    }
    const handleForgot=(e)=>{
        console.log("forgot popup");
        setForgotPopup(true);
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        http_post("admin-login", formData,null).then((res) => {
            console.log(res);
            if(res.status==403){
                if(res.errors.messages[0]=="access-denied"){
                    setLoginError("Acceso denegado, ponte en contacto con el administrador del sistema para solucionar este problema");
                }else if(res.errors.messages[0]=="Incorrect credentials"){
                    setLoginError("Ha habido un error al comprobar las credenciales, por favor, comprueba la info de acceso y prueba de nuevo");

                }
                setOpenPopup(true);
                setSubmitting(false);
            }
            if(res.data!=null){
                //login ok
                setStoreName(res.data.name);
                setStoreAdminId(res.data.adminId);
                setStoreToken(res.data.token);
                setStorePagination(res.data.pagination);
                setStoreBases(res.data.bases);
                setStoreLocalization({
                    header:{
                        actions:t("tables.actions")
                    },
                    pagination:{
                        labelRowsSelect:t("tables.elements"),
                    },
                    body: {
                        filterRow:{
                            filterTooltip:t("tables.filter")
                        },
                    },
                    toolbar:{
                        exportTitle:t("tables.export"),
                    },
                });
                // setStorePermissions(res.data.permissions);
                if(storeReferer!=null){
                    history.push(storeReferer);
                    setStoreReferer(null);
                }else
                    history.push(res.data.redirect);
            }else{
                //login error
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const handleClickLogin=(e)=>{
    }
    const handleSnackClose=(e)=>{
        setOpenSnackBar(false);
    }
    const handleSnackErrorClose=(e)=>{
        setOpenSnackError(false);
    }

    useEffect(() => {
        setStoreName(null);
        setStoreToken(null);
        if(forgotEmail=="" && email!=""){
            setForgotEmail(email);
        }else{
            setForgotEmail("");
        }
    },[]);

    return (
        <div className={(process.env.REACT_APP_MODE=="prod"?classes.page:classes.pageDEV)}>
            <Container component="main" maxWidth="xs" className={classes.box}>
                <CssBaseline />
                <div className={classes.paper}>
                    <div className={classes.title} >
        {process.env.REACT_APP_TITLE}
                    </div>
                    <img className="home-logo" src={GasoleosLogo} />

                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                        <TextField className={classes.whiteText}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={t("home.email")}
                            name="email"
                            autoComplete="email"
                            onChange={handleEmail}
                            value={email}
    color="primary"
                            autoFocus
                            />
                        <TextField className={classes.whiteText}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t("home.password")}
                            onChange={handlePwd}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleClickLogin}>
                            {submitting ? (
                                <CircularProgress
                                style={{color: "#fff"}}
                                color={"inherit"}
                                size={16}
                                />
                            ) : (
                                t("home.login")
                            )}
                        </Button>
                        <Grid container>
                            <Grid item xs={12}>
                                <Link className={classes.forgot} href="#" variant="body2" onClick={handleForgot}>
                                    {t("home.forgot")}
                                </Link>
                            </Grid>
                            {/*<Grid item xs={6} className={classes.register}>
                                <div><Button
                                    variant="contained"
                                    name="submit"
                                    color={(i18n.language=="es"?"primary":"secondary")}
                                    onClick={()=>{handleChangeLanguage("es")}}
                                >{t("languages.es")}</Button></div>
                            </Grid>
                            <Grid item xs={6} className={classes.register}>
                                <div> <Button
                                    variant="contained"
                                    name="submit"
                                    color={(i18n.language=="en"?"primary":"secondary")}
                                    onClick={()=>{handleChangeLanguage("en")}}
                                >{t("languages.en")}</Button></div>
                            </Grid>*/}
                        </Grid>
                    </form>
                </div>
                <div className={classes.gap}></div>
                <Box className={classes.footer} mt={8}>
                    <Copyright />
                </Box>
                <Dialog
                    open={(openPopup?openPopup:false)}
                    onClose={handleClosePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Error en las credenciales"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {loginError}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={(forgotPopup?forgotPopup:false)}
                    onClose={handleCloseForgotPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t("forgot.title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{t("forgot.forgotText")}</div>
                            <div className={classes.centerText}>
                                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                    <TextField className={classes.whiteText}
                                               variant="outlined"
                                               margin="normal"
                                               required
                                               fullWidth
                                               id="email"
                                               label={t("home.email")}
                                               name="email"
                                               autoComplete="email"
                                               onChange={handleForgotEmail}
                                               value={forgotEmail}
                                               autoFocus
                                    />
                                </form>

                                <Button variant="contained" onClick={handleRecoverPwd} color="primary">
                                    {t('forgot.recover')}
                                </Button>
                            </div>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseForgotPopup} color="primary">
                            {t("buttons.cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={(forgotCodePopup?forgotCodePopup:false)}
                    onClose={handleCloseForgotCodePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t("forgot.codeTitle")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{t("forgot.forgotText2")}</div>
                            <div className={classes.centerText}>
                                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                                    <TextField className={classes.whiteText}
                                               variant="outlined"
                                               margin="normal"
                                               required
                                               id="email"
                                               label={t("forgot.code")}
                                               name="codigo"
                                               autoComplete="codigo"
                                               onChange={handleForgotCode}
                                               value={forgotCode}
                                               autoFocus
                                    />
                                    <TextField className={classes.whiteText}
                                               variant="outlined"
                                               margin="normal"
                                               fullWidth
                                               type="password"
                                               required
                                               id="email"
                                               label={t("forgot.newPassword")}
                                               name="newPassword"
                                               autoComplete="password"
                                               onChange={handleNewPassword}
                                               value={newPassword}
                                               autoFocus
                                    />
                                </form>

                                <Button variant="contained" onClick={handleCodeRecoverPwd} color="primary">
                                    {t('forgot.recover')}
                                </Button>
                            </div>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseForgotPopup} color="primary">
                            {t("buttons.cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="success">
                        Contraseña modificada, prueba a loguearte de nuevo!
                    </Alert>
                </Snackbar>
                <Snackbar open={openSnackBarError} autoHideDuration={4000} onClose={handleSnackErrorClose}>
                    <Alert onClose={handleSnackErrorClose} severity="error">
                        Error {messagePwdError}!
                    </Alert>
                </Snackbar>
            </Container>
        </div>

);
}

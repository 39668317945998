import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Divider, Paper} from "@material-ui/core";
import {FormatDate} from "../providers/Utils/utils";
import useLocalStorage from "../providers/store/localStorage";

//pages

const useStyles = makeStyles((theme) => ({
    scoreType:{
        color:"#ffffff",
        paddingTop:5,
        textAlign:'right',
        paddingLeft:'15%'
    },
    scoreValue:{
        color:"#ffffff",
        fontWeight:"bold",
        fontSize:"24px"
    },
    sectionBackground:{
        // backgroundColor:"#242518",
        marginBottom:"10px",
    },
    sectionTitle:{
        borderBottom:"4px solid #1b2982",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    centerItem:{
        textAlign:"center"
    },
    bigScore:{
        // display:"inline-block",
        // padding:"10px 40px",
        // margin:"10px"
    },
    scoreRed:{
        backgroundColor:"#bf0811",
        width:'33%'
    },
    scoreYellow:{
        backgroundColor:"#1b2982",
        width:'33%'
    },
    scoreGreen:{
        backgroundColor:"#62bf62",
        width:'33%'
    },
    scoreTitle:{
        color:"#ffffff",
        fontWeight:"bold",
        fontSize:"16px",
        borderBottomWidth:1,
        borderBottomColor:"#ffffff",
        borderBottomStyle:'solid',
        padding:5,
    },
    scoreDate:{
        color:"#ffffff",
        borderTopWidth:1,
        borderTopColor:"#ffffff",
        borderTopStyle:'solid',
        padding:5,
        textAlign:'right'
    },
    sectionItem:{
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        '& > *': {
            margin: theme.spacing(1),
            width: '300px',
        },
    },
    noTablePrices:{
        textAlign:'center',
        width:'100%',
        margin:'15px'
    },
    width100:{
        width:'100%'
    }
}));
const OfficialPrices = (props) => {
    const classes = useStyles();
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    const [baseValue,setBaseValue] =useLocalStorage('GSYM_store_base_selected');
    const [baseSelectedText,setBaseSelectedText] =useState(null);

    useEffect(() => {
    // console.log("aaaa",props.a);
    //     if(baseValue)
        if(baseValue==-1){
            setBaseSelectedText(null);
        }else {
            let foundProducts = storeBases.find(element => element.id == baseValue);
            // console.log(foundProducts);
            setBaseSelectedText(foundProducts.name.toUpperCase());
        }
    },[]);
    return(
        <div className={classes.width100}>
        {!baseSelectedText && <Grid item md={12} xs={12} className={classes.noTablePrices}>
            <div className={classes.noTablePrices}>Selecciona una base por defecto para poder ver la tabla de precios oficiales</div>
        </Grid>}
        {baseSelectedText && <Grid item md={12} xs={12} className={classes.sectionBackground}>
        <div className={classes.sectionTitle}><div className={classes.sectionName}>PRECIOS COMBUSTIBLES OFICIALES PARA <b>{baseSelectedText }</b></div></div>
        <div className={clsx(classes.sectionItem,classes.centerItem)}>
            <Paper elevation={3} className={classes.scoreYellow}>
            <div className={clsx(classes.bigScore)}>
                <div className={classes.scoreTitle}>PRECIO GASÓLEO A</div>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>0-500 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}>{props.a.a1.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>501-1.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}>{props.a.a2.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>1.001-3.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}> {props.a.a3.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>3.001-32.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}> {props.a.a4.price}</div>
                    </Grid>
                </Grid>
                <div className={classes.scoreDate}>Ult.Actualización: {FormatDate(props.a.a1.date)}</div>
            </div>
        </Paper>
         <Paper elevation={3} className={classes.scoreGreen}>
            <div className={clsx(classes.bigScore)}>
                <div className={classes.scoreTitle}>PRECIO GASÓLEO B</div>
                    <Grid container>
                        <Grid item md={7} xs={12}>
                            <div className={classes.scoreType}>0-500 litros</div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <div className={classes.scoreValue}>{props.b.b1.price}</div>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <div className={classes.scoreType}>501-1.000 litros</div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <div className={classes.scoreValue}>{props.b.b2.price}</div>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <div className={classes.scoreType}>1.001-3.000 litros</div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <div className={classes.scoreValue}> {props.b.b3.price}</div>
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <div className={classes.scoreType}>3.001-32.000 litros</div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <div className={classes.scoreValue}> {props.b.b4.price}</div>
                        </Grid>
                    </Grid>
                    <div className={classes.scoreDate}>Ult.Actualización: {FormatDate(props.b.b1.date)}</div>
                </div>
            </Paper>
            <Paper elevation={3} className={classes.scoreRed}>
            <div className={clsx(classes.bigScore)}>
                <div className={classes.scoreTitle}>PRECIO GASÓLEO C</div>
                <Grid container>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>0-500 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}>{props.c.c1.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>501-1.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}>{props.c.c2.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>1.001-3.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}>{props.c.c3.price}</div>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <div className={classes.scoreType}>3.001-32.000 litros</div>
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className={classes.scoreValue}> {props.c.c4.price}</div>
                    </Grid>
                </Grid>
                <div className={classes.scoreDate}>Ult.Actualización: {FormatDate(props.c.c1.date)}</div>
            </div>
        </Paper>
            </div>
            </Grid>}
            </div>
)
}

export default OfficialPrices;



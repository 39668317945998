import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from '@material-ui/core';
import {useTranslation} from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from "mui-datatables";
import CustomClientsToolbar from "./CustomClientsToolbar";
import history from "../providers/History/history";


const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    }
}));
const RelatedBillings = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [table, setTable] = useState({});
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("300px");

    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomClientsToolbar mode="not-verified" selectedRows={selectedRows} />
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push("/billing-addresses/"+row[0]+"/view");
    }
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("Related address");
        setTable(props.table);
        console.log("direcciones",props.table);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickVerify=()=>{
        console.log("verificar cuenta");
        props.onRowVerifyAddress(props.selectedRows)
    }
    const handleClickLocation=()=>{
        console.log("resend email");
        props.onRowLocation(props.selectedRows)
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
            <MUIDataTable
                title={"Direcciones de facturación"}
                data={table.data}
                columns={table.columns}
                options={tableOptions}
            />

            </div>
        );

}
export default RelatedBillings;
import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from '@material-ui/core';

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import BasesIcon from '@material-ui/icons/PinDrop';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginBottom:5
        //marginRight:10,

    },
    position:{
        paddingRight:10,
        textAlign:'right'

    }
}));
const CustomPostalCodesToolbar = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const RedButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff2727",
            '&:hover': {
                backgroundColor: "#a00000",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("custom base  toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickClean=()=>{
        // console.log("quitar los almacenes de estos codigos postales");
        props.onRowClean(props.selectedRows)
    }
    const handleClickAssign=()=>{
        // console.log("Asignar almacen");
        props.onRowBaseAssign(props.selectedRows);
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
                <div className={classes.position}><Tooltip className={classes.margin10} title={"Asignar almacén"}>
                    <GreenButton
                        onClick={handleClickAssign}
                        variant="contained"
                        color="default"
                        startIcon={<BasesIcon />}
                        >
                        <span className="textButton">Asignar a almacén</span>
                    </GreenButton>
                </Tooltip>
                <Tooltip title={"Quitar almacén"} className={classes.margin10} >
                    <RedButton
                        onClick={handleClickClean}
                        variant="contained"
                        color="default"
                        startIcon={<ClearIcon />}
                    >
<span className="textButton">Quitar almacén</span>
                    </RedButton>
                </Tooltip></div>

        </div>
        );

}
export default CustomPostalCodesToolbar;

import React,{useState,useEffect} from 'react';
import {Dialog, Paper,Link} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
    FormatDate,
    FormatNumber,
    FormatDateTime,
    getFranjas,
    GetProducts,
    PedidoEstado,
    PedidoFranja,
    FormatPrice,
    GetPaymentType, NumberPrint
} from "../providers/Utils/utils";
import {makeStyles} from "@material-ui/core/styles";

import myTheme from "../theme/theme";
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import off1 from '../res/encuestas/1off.png';
import off2 from '../res/encuestas/2off.png';
import off3 from '../res/encuestas/3off.png';
import off4 from '../res/encuestas/4off.png';
import off5 from '../res/encuestas/5off.png';
import on1 from '../res/encuestas/1on.png';
import on2 from '../res/encuestas/2on.png';
import on3 from '../res/encuestas/3on.png';
import on4 from '../res/encuestas/4on.png';
import on5 from '../res/encuestas/5on.png';


import clsx from "clsx";
import PopupCancelOrder from "./PopupCancelOrder";
import PopupCotejarManual from "./PopupCotejarManual";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%',
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    padding20:{
        padding:'20px!important'
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
    buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    redColorInput:{
        '& input':{
            color:'#ff0000'
        }
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    bigTextField:{
        "& input":{
            fontSize:20,
            color:'#000000'
        }
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    orderDetail:{
        padding:10,
        marginTop:10,
        marginBottom:10
    },
    totalLine:{
        fontSize:18,
        borderTop:'1px solid #cccccc',
        paddingTop:10
    },
    line:{
        paddingTop:5,
        paddingBottom:5
    },
    cantidad:{
        fontSize:18,
        fontWeight:'bold'
    },
    formControlSelect:{
        width:'100%'},
    encuestaBlock:{
        width:300,
        height:70,
        border:'1px solid #444444',
        backgroundColor:'#f0f0f0',
        textAlign:'center',
        margin:'auto'
    },
    encuestaValue:{
        '& img':{
            width:40,
            height:40
        }
    },
    encuestaValueBold:{
        fontWeight:"bold",
        fontSize:16
    },
    white:{
        color:"#ffffff"
    },
    width100:{
        width:"100%"
    },
    paymentType:{
        marginTop:10,
        marginBottom:10,
        width:'50%',
        display:'inline-block'
    },
    rowDescription:{
        fontSize:10
    },
    cotejar:{
        paddingRight:10,
        cursor:'pointer'
    }
}));

function createData(name, date,type, price, description) {
    return { name, date, type,price, description };
}


const OrderInfoComponent = (props) => {
    const classes = useStyles();
    const [numPedido,setNumPedido] =useState(null);
    const [redsysLink,setRedsysLink] =useState(null);
    const [cotejarManual,setCotejarManual] =useState(false);
    const [observationGSYM,setObservationGSYM] =useState(null);
    const [date,setDate] =useState(null);
    const [franja,setFranja] =useState(null);
    const [franjaSelected,setFranjaSelected] =useState(null);
    const [record,setRecord] =useState(null);
    const [action,setAction] =useState(null);
    const [orderObservations,setOrderObservations] =useState(null);
    const [orderGSYMObservations,setOrderGSYMObservations] =useState(null);
    const [rows,setRows] = useState(null);
    const [franjas] =useState(getFranjas());
    useEffect(()=>{
        setRedsysLink(process.env.REACT_APP_REDSYS_LINK);
        // console.log(process.env('REACT_REDSYS_LINK'));
        // console.log("record",props.record);
        // console.log("transactions",props.orderTransaction);
        // console.log("numPedido",props.numPedido);
        setNumPedido(props.numPedido);
        setRecord(props.record);
        setAction(props.action);
        loadTransactions();
    },[props]);
    const loadTransactions=()=>{
        if(props.orderTransaction!=null){
            console.log("fields",props.orderTransaction.fields);
            let data=props.orderTransaction.data;
            console.log("length",data);
            if(data.length>0) {
                let list=[];
                for(let r in data){
                    console.log("record",r);
                    let p=FormatPrice(data[r].price);
                    list.push(createData(r,FormatDateTime(data[r].date),getTransactionType(data[r].type),p,data[r].detail));
                }
                setRows(list);
            }
        }

    }
    const getTransactionType=(v)=>{
        if(v==1)
            return "Pago";
        else
            return "Devolución";
    }


    const handleNumPedidoChange=(e)=>{
        setNumPedido(e.target.value);
    }
    const handleDateChange=(v)=>{
        console.log(v);
        setDate(v);
        props.onSetDate(v);
    }
    const handleCloseCancelCotejar=()=>{
        setCotejarManual(false);
    }
    const handleCancelCotejar=()=>{
        setCotejarManual(false);
    }
    const handleOrderObservationsChange=(e)=>{
        setOrderObservations(e.target.value);
    }
    const handleOrderGSYMObservationsChange=(e)=>{
        setOrderGSYMObservations(e.target.value);
        props.onSetOrderObservations(e.target.value);
    }
    const handleFranjaChange=(v)=>{
        console.log("franja sele",v.target.value);
        setFranjaSelected(v.target.value);
        props.onSetFranja(v.target.value);
    }
    const handleClickPrice=()=>{
        alert("Para asignar precio, pulsa el botón Asignar precio de arriba a la derecha");
    }
    const htmlContenidoCliente=()=>{
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    id="filled-name"
                    label="Nombre Cliente APP"
                    type="text"
                    helperText="Nombre del cliente de la APP"
                    value={(record && record.client_name?record.client_name:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    id="filled-name"
                    label="Teléfono"
                    type="text"
                    helperText="Teléfono del cliente"
                    value={(record && record.client_phone?record.client_phone:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                    id="address-billing"
                    label="Razón social factura"
                    InputProps={{
                        readOnly: true,
                        style: {fontWeight: 'bold'}
                    }}
                    helperText="Razón social de la empresa para facturar"
                    value={(record && record.billing_company?record.billing_company:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                    id="address-billing"
                    label="NIF"
                    InputProps={{
                        readOnly: true,
                        style: {fontWeight: 'bold'}
                    }}
                    helperText="NIF del cliente"
                    value={(record && record.billing_nif?record.billing_nif:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                    id="address-billing"
                    label="Dirección de facturacion"
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="Dirección de facturación"
                    value={(record && record.billing_address?record.billing_address:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                    id="cp"
                    label="CP facturación"
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="CP de facturación"
                    value={(record && record.billing_address_cp?record.billing_address_cp:"")}
                    fullWidth
                    />
                </Grid>
            </Grid>
    );
    }
    const handleCotejar=()=>{
        console.log("cotejar");
        setCotejarManual(true);
    }
    const htmlTransaccionDetalle=()=>{
        return (
            <div className={classes.width100}>
            {record && <div className={classes.paymentType}>Forma de pago: <b>{GetPaymentType(record.payment_type_id)}</b><br/></div>}
            {record && record.payment_type_id && <div className={clsx(classes.paymentType,classes.alignRight)}>
                <Link className={classes.cotejar} onClick={handleCotejar} target="_blank" rel="noopener" variant="body2">
                    Cotejamiento manual
                </Link>
                <Link href={redsysLink} target="_blank" rel="noopener" variant="body2">
                    Acceso a Redsys
                </Link>
                </div>}
            {rows && rows.length>0 && <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.white}>Fecha</TableCell>
                            <TableCell className={classes.white}>Tipo</TableCell>
                            <TableCell className={classes.white}>Descripción</TableCell>
                            <TableCell className={classes.white} align="right">Importe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >

                                <TableCell>{row.date}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell className={classes.rowDescription}>{row.description}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>}
        {(rows==null || rows.length==0) && <div>No hay ninguna transaccion bancaria en este pedido</div>}
        <PopupCotejarManual record={record}  open={cotejarManual} onCancel={handleCancelCotejar} onClose={handleCloseCancelCotejar}/>

        </div>
        );
    }
    const htmlContenidoDetalle=()=>{
        return (
            <Grid container >
        {props.orderDetail && props.orderDetail.data.map((el)=>{
            return(<><Grid item xs={6} className={classes.line}>
                    {el.line}
            </Grid>
                <Grid item xs={6} className={classes.line}>
                    {(el.price?FormatPrice(el.price):"0")}
                </Grid></>)
        })}
        <Grid item xs={6} className={classes.totalLine}>
            <b>Total</b>
            </Grid>
        {props.orderDetail && <Grid item xs={6} className={classes.totalLine}>
            <b>{FormatPrice(props.orderDetail.total)}</b>
        </Grid>}
        </Grid>
    )}
    const htmlContenidoPedido=()=>{
        return (
            <div>
            <Grid container spacing={3}>
                <Grid item xs={6}  sm={6}>
                    <TextField
                        className={classes.textField}
                        id="filled-email"
                        label="Fecha Pedido"
                        type="email"
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record && record.date?FormatDateTime(record.date):"")}
                        helperText="Fecha del pedido"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}  sm={6}>
                    <TextField
                        className={classes.textField}
                        id="filled-email"
                        label="Franja Solicitada"
                        type="default"
                        InputProps={{
                            readOnly: (props.action=="view"?true:false)
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={(record && record.hour_id?PedidoFranja(record.hour_id):"")}
                        helperText="Franja solicitada cliente"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="address"
                        label="Dirección de entrega"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Dirección de entrega"
                        value={(record && record.address?record.address:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                    id="piso"
                    label="Portal/Piso/Planta"
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="Portal, piso o planta"
                    value={(record && record.address_floor?record.address_floor:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        id="cp"
                        label="CP"
                        InputProps={{
                            readOnly: true,
                        }}
                        helperText="Código postal"
                        value={(record && record.address_cp?record.address_cp:"")}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    id="GPS1"
                    label="GPS Dirección"
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="GPS Dirección"
                    value={(record && record.address_gps_address?record.address_gps_address:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    id="GPS2"
                    label="GPS Depósito"
                    InputProps={{
                        readOnly: true,
                    }}
                    helperText="GPS Depósito"
                    value={(record && record.address_gps_deposit?record.address_gps_deposit:"")}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="contact"
                        label="Persona de contacto"
                        helperText="Persona de contacto que recibirá el pedido"
                        value={(record && record.contact_name?record.contact_name:"")}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </div>
    );
    }
    const getImg=(i)=>{
        if(i==1)
            return on1;
        if(i==2)
            return on2;
        if(i==3)
            return on3;
        if(i==4)
            return on4;
        if(i==5)
            return on5;
    }
    const getEncuestaValue=()=>{
        let value=[];

        value.push( <img src={off1}/>);
        value.push( <img src={off2}/>);
        value.push( <img src={off3}/>);
        value.push( <img src={off4}/>);
        value.push( <img src={off5}/>);

        // if(record.encuesta_value>=1)
        //     value[0]=<img src={on1}/>
        // if(record.encuesta_value>=2)
        //     value[1]=<img src={on2}/>
        // if(record.encuesta_value>=3)
        //     value[2]=<img src={on3}/>
        // if(record.encuesta_value>=4)
        //     value[3]=<img src={on4}/>
        for(let i=1;i<=record.encuesta_value;i++){
            value[i-1]=<img src={getImg(record.encuesta_value)}/>
        }
        // if(record.encuesta_value==5){
        //     value[0]=<img src={on5}/>
        //     value[1]=<img src={on5}/>
        //     value[2]=<img src={on5}/>
        //     value[3]=<img src={on5}/>
        //     value[4]=<img src={on5}/>
        // }else if(record.encuesta_value==4){
        //     value[0]=<img src={on1}/>
        //     value[1]=<img src={on2}/>
        //     value[2]=<img src={on3}/>
        //     value[3]=<img src={on4}/>
        // }
        return value;
    }
    const htmlContenidoGSYM=()=>{
        return (
            <div>
            <Grid container spacing={3}>
            {record && record.encuesta_value && <Grid item xs={12} sm={12}>
                <div className={classes.encuestaBlock}>
                    Resultado Encuesta <span className={classes.encuestaValueBold}>{record.encuesta_value}</span>
                    <div className={classes.encuestaValue}>{getEncuestaValue()}

                    </div>
                </div>

            </Grid>}
            <Grid item xs={12} sm={6}>
                <TextField
                InputProps={{
                    readOnly: (props.action=="view"?true:false)
                }}
                InputLabelProps={{ shrink: true }}
                id="filled-number"
                label="Num.Pedido"
                value={(props.action=="view"?(record && record.number?record.number:""):(numPedido==null?(record && record.number?record.number:""):numPedido))}
                defaultvalue="1"
                onChange={handleNumPedidoChange}
                fullWidth
                helperText="Número de pedido"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                {record && record.status_id && PedidoEstado(record.status_id,"order-view")}
            </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
        inputProps={{style: {fontWeight: 'bold'}}}
        label="Cantidad solicitada (litros)"
        helperText="Cantidad en litros solicitada"
        InputProps={{
            readOnly: true,
        }}
        value={(record && record.amount?record.amount:"")}
        fullWidth
        />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
        InputProps={{
            readOnly: true,
                style: {fontWeight: 'bold'}
        }}
        id="product-id"
        label="Gasóleo seleccionado"
        helperText="Tipo de gasóleo a suministrar"
        value={(record && record.product_id?GetProducts(record.product_id):"")}
        fullWidth
        />
        </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                id="base-price"
                label="Precio base (€)"
                helperText="Precio oficial base x litro"
                inputProps={{style: {fontWeight: 'bold'}}}
                value={(record && FormatNumber(record.base_price,false,true)?FormatNumber(record.base_price,false,true):"")}
                onClick={handleClickPrice}
                fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField className={classes.redColorInput}
                id="precio"
                label="Precio asignado al cliente (€)"
                inputProps={{style: {fontWeight: 'bold'}}}
                helperText="Precio para establecer a este pedido"
                fullWidth
                value={(record && record.price?FormatNumber(record.price,false,true):"No establecido todavía")}
                onClick={handleClickPrice}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                id="observations"
                label="Observaciones del pedido"
                helperText="Observaciones que ha realizado el cliente para este pedido"
                value={(record && record.observations?record.observations:"")}
                fullWidth
                />
            </Grid>
            <Grid item xs={6}  sm={6}>
            {props.action=="view" && <TextField
                className={classes.textField}
                id="filled-email"
                className={(props.action=="view"?"":classes.editInput)}
                label="Fecha Entrega"
                type="email"
                InputProps={{
                    readOnly: true,
                }}
                value={(record && record.date_delivery?FormatDate(record.date_delivery):"")}
                helperText="Fecha de entrega GSYM"
                fullWidth
                />}
                {props.action!="view" &&
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                    className={classes.editInput}
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-inline"
                    label="Fecha Entrega"
                    value={(date?date:(record && record.date_delivery?record.date_delivery:null))}
                    onChange={handleDateChange}
                    helperText={"Fecha entrega "}
                    KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
            </MuiPickersUtilsProvider>}
        </Grid>
        <Grid item xs={6}  sm={6}>
            {props.action=="view" && <TextField
                className={classes.textField}
                id="filled-email"
                label="Franja de Entrega"
                className={(props.action=="view"?"":classes.editInput)}
                type="email"
                InputProps={{
                    readOnly: true,
                }}
                value={(record && record.hour_delivery_id?PedidoFranja(record.hour_delivery_id):"")}
                helperText="Franja de entrega GSYM"
                fullWidth
                />}
            {props.action!="view" && <FormControl className={clsx(classes.formControlSelect,classes.editInput)}>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                   Franja de entrega
                </InputLabel>
                <Select
                    value={franjaSelected}
                    onChange={handleFranjaChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Franja de entrega' }}
                >
                    {franjas && franjas.map((el)=>{
                        return (
                            <MenuItem value={el.id}>{el.name}</MenuItem>
                    )
                })}
                </Select>
                <FormHelperText>Franjas de entrega</FormHelperText>
            </FormControl>}
        </Grid>

        <Grid item xs={12} sm={12}>
            <TextField
                id="observations_gsym"
                label="Observaciones de pedido GSYM"
                className={(props.action=="view"?"":classes.editInput)}
                helperText="Observaciones de GSYM para este pedido"
                value={(props.action=="view"?(record && record.observations_gsym?record.observations_gsym:""):(orderGSYMObservations!=null?orderGSYMObservations:(record && record.observations_gsym?record.observations_gsym:"")))}
                onChange={handleOrderGSYMObservationsChange}
                fullWidth
                />
        </Grid>
        </Grid>
        </div>
    );
    }
    return(
        <Grid item md={12} xs={12} className={classes.sectionBackground}>
                <Grid item sm={12} md={12} >
                    <Paper elevation={3} className={classes.paperBlock} >
                        <h2>Info Pedido GSYM</h2>
                        {htmlContenidoGSYM()}
                    </Paper>
                </Grid>
                <Grid container spacing={2}>
                <Grid item sm={12} md={6} className={classes.padding20}>
                    <Paper elevation={3} className={classes.paperBlock} >
                        <h2>Info de entrega</h2>
                        {htmlContenidoPedido()}
                    </Paper>
                </Grid>
            <Grid item sm={12} md={6} className={classes.padding20}>
                <Paper elevation={3} className={classes.paperBlock} >
                    <h2>Info de facturación y cliente</h2>
                    {htmlContenidoCliente()}
                </Paper>
            </Grid>

            <Grid item sm={12} md={12}>
                <Paper elevation={3} className={classes.paperBlock} >
                    <h2>Detalle del pedido</h2>
                    {record && record.status_id>=4 && htmlContenidoDetalle()}
                    {record && record.status_id <4 && <div>Todavía no dispone de detalle</div>}
                </Paper>
            </Grid>
            {record && record.status_id>=4 && <Grid item sm={12} md={12}>
                <Paper elevation={3} className={classes.paperBlock} >
                    <h2>Forma de pago y transacciones</h2>
                    {record && record.status_id>=4 && htmlTransaccionDetalle()}
                </Paper>
            </Grid>}
        </Grid>
    </Grid>

    )
}

export default OrderInfoComponent;




import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from '@material-ui/core';
import {useTranslation} from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import MUIDataTable from "mui-datatables";
import CustomClientsToolbar from "./CustomClientsToolbar";
import history from "../providers/History/history";
import MapIcon from "@material-ui/icons/Room";
import CustomToolbar from "./CustomToolbar";
import PopupDenyAddress from "./PopupDenyAddress";
import PopupNewInvoice from "./PopupNewInvoice";
import CustomInvoicesToolbar from "./CustomInvoicesToolbar";
import {http_download, http_get, http_post} from "../providers/http/http";
import useLocalStorage from "../providers/store/localStorage";
import fileDownload from "js-file-download";
import PopupYesNo from "./PopupYesNo";


const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    }
}));
const RelatedInvoices = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [table, setTable] = useState({});
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("300px");
    const [popupNewInvoice,setPopupNewInvoice] =useState(false);
    const [openYesNoPopup,setOpenYesNoPopup] =useState(false);
    const [selectedDeleteId,setSelectedDeleteId] =useState(null);
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},
        customToolbar: () => {
            return (
                < CustomToolbar
            onNewClick = {handleNewPayment} />
        );
        },
        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomInvoicesToolbar mode="not-verified" onRowView={()=>handleViewDocument(selectedRows,data)} onRowDelete={()=>handleDeleteDocument(selectedRows,data)} selectedRows={selectedRows} />
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        // onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const handleViewDocument=(selectedRows,d)=>{
        console.log("Ver documento");
        // console.log(selectedRows,d);
        // console.log("yes",d[selectedRows.data[0].dataIndex].data[0]);
        // let postData={id:d[selectedRows.data[0].dataIndex].data[0]};
        let id=d[selectedRows.data[0].dataIndex].data[0];
        let nombre=d[selectedRows.data[0].dataIndex].data[2];
        http_download("document-view/"+id,storeToken).then((res)=> {
            fileDownload(res, "document.pdf");
                // const url = window.URL.createObjectURL(new Blob([res.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'documento.pdf');
                // document.body.appendChild(link);
                // link.click();
        });
    }
    const handleCloseYesNoPopup=()=>{
        setOpenYesNoPopup(false);
    }
    const handleDeleteYes=()=>{
        console.log("vamos a borrar el id=",selectedDeleteId);
        let formData={"id":selectedDeleteId};
        http_post("document-delete",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                alert("Documento eliminado correctamente");
                window.location.reload();
            }else{
                alert("111There´s been an error during save action");
            }
        });
        setSelectedDeleteId(null);
        setOpenYesNoPopup(false);
    }
    const handleDeleteDocument=(selectedRows,d)=>{
        console.log("eliminar documento");
        // console.log(selectedRows,d);
        // console.log("yes",d[selectedRows.data[0].dataIndex].data[0]);
        // let postData={id:d[selectedRows.data[0].dataIndex].data[0]};
        let id=d[selectedRows.data[0].dataIndex].data[0];
        let nombre=d[selectedRows.data[0].dataIndex].data[2];
        setSelectedDeleteId(id);
        setOpenYesNoPopup(true);
    }
    const handleNewPayment=()=>{
        console.log("new");
        setPopupNewInvoice(true);
    }
    const handleRowClick=(row)=>{
        // alert("descargar factura");
        // console.log("download invoice",row);
        // //history.push("/Addresses/"+row[0]+"/view");
    }
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("Related address");
        setTable(props.table);
        console.log("direcciones",props.table);
        let foundGPS=props.table.columns.findIndex(element=>element.name=="gps_position");
        if(foundGPS!=-1){
            props.table.columns[foundGPS]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                if(value){
                    return (
                        <div>
                        <a target="_blank" href={"https://maps.google.com/?q="+value}><MapIcon/> Ver en mapa</a>
                    </div>
                );
                }
            }
        }
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickVerify=()=>{
        console.log("verificar cuenta");
        props.onRowVerifyAddress(props.selectedRows)
    }
    const handleClickLocation=()=>{
        console.log("resend email");
        props.onRowLocation(props.selectedRows)
        // props.onRowView("value");
    }
    const handleCloseNewInvoice=()=>{
        setPopupNewInvoice(false);
    }
    // const { classes } = props;

        return (
            <div>
            <MUIDataTable
                title={"Facturas"}
                data={table.data}
                columns={table.columns}
                options={tableOptions}
            />
            <PopupNewInvoice id={props.id} open={popupNewInvoice} onClose={handleCloseNewInvoice} />
            <PopupYesNo title="Eliminar documento" description={"¿Estás seguro que quieres eliminar el documento?"} open={openYesNoPopup} onYes={handleDeleteYes} onClose={handleCloseYesNoPopup}/>

            </div>

);

}
export default RelatedInvoices;

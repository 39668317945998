import React from 'react';
import Evaluador_Provincia from "./Evaluador_Provincia";
import Evaluador_Email from "./Evaluador_Email";
import Evaluador_CodPostal from "./Evaluador_CodPostal";
import Evaluador_GasoleoA from "./Evaluador_GasoleoA";
import Evaluador_GasoleoB from "./Evaluador_GasoleoB";
import Evaluador_GasoleoC from "./Evaluador_GasoleoC";
import Evaluador_Almacen from "./Evaluador_Almacen";
import Evaluador_OtraConsulta from "./Evaluador_OtraConsulta";
import Evaluador_FechaCompra from "./Evaluador_FechaCompra";
import Evaluador_FechaRegistro from "./Evaluador_FechaRegistro";
import Evaluador_Todos from "./Evaluador_Todos";
import Promocion_Dto_Directo from "./Promocion_Dto_Directo";
import Promocion_CP_Generico from "./Promocion_CP_Generico";
import Promocion_CP_Unico from "./Promocion_CP_Unico";
//pages

const Promotion = (props) => {
    const handleOnSetValue=(id,name,v,textValue)=>{
        props.onSetValue(id,name,v,textValue);
    }
    const EvList = {
        1: Promocion_Dto_Directo,
        2: Promocion_CP_Generico,
        3: Promocion_CP_Unico,


    };
    const Ev = EvList[props.ev];
    return (<Ev id={props.ev} onSetValue={handleOnSetValue} />);

}

export default Promotion;
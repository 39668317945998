import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_CodPostal = (props) => {
    const classes = useStyles();
    const [name] =useState("Por código postal");
    const [cp,setCp] =useState(null);
    const handleCP=(e)=>{
        setCp(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos evaluador",props.id,name,cp);
        props.onSetValue(props.id,name,cp);
    }
    return(
        <div className="father-header-title">
        <div className="">
            Busca clientes por codigo postal
        </div>
        <div>Obtenemos datos de clientes donde su código postal corresponda con una de sus direcciones de entrega</div>
        <div className={classes.formControl}>
            <TextField
                id="cp"
                label="CP"
                helperText="Código postal"
                onChange={handleCP}
                value={(cp?cp:"")}
                fullWidth
            />
        </div>
                    <div>
        <Button
            color="primary"
            variant="contained"
            className={classes.button}
            startIcon={<EditIcon />}
            onClick={handleSave}
                >
            Aplicar
        </Button>
            </div>

    </div>
)
}

export default Evaluador_CodPostal;
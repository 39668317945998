import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const API_PREFIX = process.env.REACT_APP_API_PREFIX

export function http_get(apiCall,token) {
	let headers = {};
	headers = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			"X-Authorization":token
		}
	}
	let url = `${API_URL}${API_PREFIX}${apiCall}`
	var promise = new Promise((resolve, reject) => {
		console.log(url);
		axios.get(url,headers).then(response => response.data)
			.then((data) => {
				resolve(data)
			}).catch(err => {
				console.log(err);
				reject(err)
			})
	})
	return promise
}

export function http_download(apiCall,token) {
	let headers = {};
	headers = {
		responseType: 'blob',
		headers: {
			"X-Authorization":token,
		}
	}
	let url = `${API_URL}${API_PREFIX}${apiCall}`
	var promise = new Promise((resolve, reject) => {
		console.log(url);
		axios.get(url,headers).then(response => response.data)
			.then((data) => {
				resolve(data)
			}).catch(err => {
			console.log(err);
			reject(err)
		})
	})
	return promise
}

export function http_post(apiCall, data,token=null) {
	let headers = {};
	headers = {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			"X-Authorization":token
		}
	}

	let url = `${API_URL}${API_PREFIX}${apiCall}`
	console.log(url);
	var promise = new Promise((resolve, reject) => {
		axios.post(url, data, headers).then(response => response.data)
			.then((data) => {
				resolve(data)
			}).catch(err => {
				reject(err)
			})
	})
	return promise
}

export function plain_post(url, data,token=null) {
    let headers = {};
    headers = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-Authorization":token
        }
    }

    console.log(url);
    var promise = new Promise((resolve, reject) => {
        axios.post(url, data, headers).then(response => response.data)
            .then((data) => {
                resolve(data)
            }).catch(err => {
            reject(err)
        })
    })
    return promise
}

import React, {Fragment, useState,useEffect} from 'react';
import history from "../History/history";
import useLocalStorage from "../../providers/store/localStorage";
import AppBarComponent from "../../components/AppBarComponent";
import {http_get} from "../http/http";
import {useTranslation} from "react-i18next";
import PedidoInicio from '../../res/orders/pedidoInicio.png';
import PrecioSolicitar from '../../res/orders/precioSolicitar.png';
import PrecioConfirmado from '../../res/orders/precioEstablecido.png';
import Transito from '../../res/orders/pedidosReparto.png';
import Entregado from '../../res/orders/pedidosFinalizados.png';
import Pagado from '../../res/orders/pedidoPagado.png';
import Cancelado from '../../res/orders/pedidoCancelado.png';
import Navision from '../../res/orders/pedidoNavision.png';
import Tramitado from '../../res/orders/pedidoTramitado.png';
import Enrutado from '../../res/orders/pedidoEnrutado.png';
import GasoleoA from '../../res/gasoil/gasoleo-a.png';
import GasoleoB from '../../res/gasoil/gasoleo-b.png';
import GasoleoC from '../../res/gasoil/gasoleo-c.png';



export function NormalizeText(str) {
   return str.normalize("NFD").replace(/ /g,"-").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}
export function GenerateRandUserId(){
    let d =new Date().getTime();
    let min = 1000;
    let max = 9999;
    let v=Math.round(min + (Math.random() * (max - min)));
    return d +"-" + v;
}
export function GetRelDates(){
    return ["D-0","D-1","D-2","D-3","D-4","D-5","D-6","D-7","S-0","S-1","S-2","M-0","M-1","M-2","M-3","M-4","M-5","M-6","Y-0","Y-1"];
}
export function GeneratePedidoCode(){
    let len=8;
    let str = '';
    let vowels=["a","e","i","o","u"];
    let consonants =['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm','n', 'p', 'r', 's', 't', 'v', 'w', 'x', 'y', 'z'];
    let max = len/2;
    for(let i=1;i<max;i++){
        str+=consonants[Math.round(Math.random()*19)];
        str+=vowels[Math.round(Math.random()*4)];
    }
    return str;
}

export function shortDate(systemDate){
    //transform dates from system date (yyyy-mm-dd) to dd/mm
    let d = new Date();
}
export function FormatDate(systemDate){
    let d = new Date(systemDate);
    let day="";
    let month="";
    day=(d.getDate()<9?"0"+d.getDate():d.getDate());
    month=((d.getMonth()+1)<9?"0"+(d.getMonth()+1):(d.getMonth()+1));
    return day+"/"+month+"/"+d.getFullYear();
}

export function FormatDateTime(systemDate){
    let d = new Date(systemDate);
    let day="";
    let month="";
    let hour="";
    let minutes="";
    day=(d.getDate()<9?"0"+d.getDate():d.getDate());
    month=((d.getMonth()+1)<9?"0"+(d.getMonth()+1):(d.getMonth()+1));
    hour=(d.getHours()<9?"0"+d.getHours():d.getHours());
    minutes=(d.getMinutes()<9?"0"+d.getMinutes():d.getMinutes());
    return day+"/"+month+"/"+d.getFullYear() + " "+hour +":"+minutes;
}
export function FormatPrice(num){
    return FormatNumber(num)+ " €";
}
export function FormatNumber(num,noDecimals=false,threeDecimals=false){
        if (!num || num == 'NaN') return '-';
        if (num == 'Infinity') return '&#x221e;';
        num = num.toString().replace(/\$|\,/g, '');
        if (isNaN(num))
            num = "0";
        let sign = (num == (num = Math.abs(num)));
        let factor=100;
        if(threeDecimals)
            factor=1000;
        num = Math.floor(num * factor + 0.50000000001);
        let cents = num % factor;
        num = Math.floor(num / factor).toString();
        if (cents < 10)
            cents = "0" + cents;
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3) ; i++)
            num = num.substring(0, num.length - (4 * i + 3)) + '.' + num.substring(num.length - (4 * i + 3));
        if(noDecimals)
            return  (((sign) ? '' : '-') + num);
        else
            return (((sign) ? '' : '-') + num + ',' + cents);
}
export function GetProducts(product_id){
    switch(product_id.toString()){
        case "1":
            return "Gasóleo A Automoción";
            break;
        case "2":
            return "Gasóleo B Agrícola";
            break;
        case "3":
            return "Gasóleo C Calefacción";
            break;
    }
}
export function GetPaymentType(payment_type_id){
    if(payment_type_id==null)
        return "Pago no seleccionado";
    else {
        switch (payment_type_id.toString()) {
            case "1":
                return "Pago con tarjeta";
                break;
            case "3":
                return "Pago al repartidor";
                break;
        }
    }
    //return "Pago desconocido";
}
export function GetProductRanges(range_id,onlyNumbers=null){
    switch(range_id.toString()){
        case "1":
            if(onlyNumbers)
                return "0 - 500";
            else
                return "Rango 1 (0 - 500)";
            break;
        case "2":
            if(onlyNumbers)
                return "501 - 1.000";
            else
                return "Rango 2 (501 - 1.000)";
            break;
        case "3":
            if(onlyNumbers)
                return "1.001 - 3.000";
            else
                return "Rango 3 (1.001 - 3.000)";
            break;
        case "4":
            if(onlyNumbers)
                return "3.001 - 32.000";
            else
                return "Rango 4 (3.001 - 32.000)";
            break;
    }
}
export function PedidoFranja(franja_id,mode){
    switch(franja_id){
        case 1:
            if(mode=="short")
                return "9-14";
            else
                return "Mañanas (9-14)";
            break;
        case 2:
            if(mode=="short")
                return "16-20";
            else
                return "Tardes (16-20)";
            break;
        case 3:
            if(mode=="short")
                return "9-20";
            else
                return "Todo el dia";
            break;
    }
}
export function UserRol(rol_id){
    switch (rol_id.toString()) {
        case "1":
            return "Admin";
            break;
        case "2":
            return "User";
            break;
    }
}
export function ClienteEstado(status_id) {
    switch (status_id.toString()) {
        case "1":
            return "Habilitado";
            break;
        case "2":
            return "Deshabilitado";
            break;
        case "3":
            return "Bloqueado por GSYM";
            break;
        case "4":
            return "No verificado";
            break;
    }
}
export function GasoleoIcon(gasoleo_id){
    switch(gasoleo_id.toString()){
        case "A":
            return <div><img src={GasoleoA} className="order-status"/></div>;
            break;
        case "B":
            return <div><img src={GasoleoB} className="order-status"/></div>;
            break;
        case "C":
            return <div><img src={GasoleoC} className="order-status"/></div>;
            break;
    }
}
export function PedidoEstado(status_id,orderCss=null,onlyText=false){
    switch(status_id.toString()){
        case "1":
            if(onlyText==false)
                return <div><img src={PedidoInicio} className="order-status"/>Inicio</div>;
            else
                return "Inicio";
            break;
        case "2":
            if(onlyText==false)
                return <div><img src={PrecioSolicitar} className={"order-status "+orderCss}/>Precio solicitado</div>;
            else
                return "Precio solicitado";
            break;
        case "3":
            if(onlyText==false)
                return <div><img src={PrecioConfirmado} className="order-status"/>Precio Confirmado</div>;
            else
                return "Precio confirmado";
            break;
        case "4":
            if(onlyText==false)
                return <div><img src={Pagado} className="order-status"/>Pagado</div>;
            else
                return "Pagado";
            break;
        case "5":
            if(onlyText==false)
                return <div><img src={Transito} className="order-status"/>En transito</div>;
            else
                return "En transito";
            break;
        case "6":
            if(onlyText==false)
                return <div><img src={Entregado} className="order-status"/>Finalizado</div>;
            else
                return "Finalizado";
            break;
        case "7":
            if(onlyText==false)
                return <div><img src={Cancelado} className="order-status"/>Cancelado</div>;
            else
                return "Cancelado";
            break;
        case "8":
            if(onlyText==false)
                return <div><img src={Navision} className="order-status"/>Enviar Navision</div>;
        case "9":
            if(onlyText==false)
                return <div><img src={Tramitado} className="order-status"/>Tramitado</div>;
        case "10":
            if(onlyText==false)
                return <div><img src={Enrutado} className="order-status"/>Enrutado</div>;
        else
            return "Enviar Navision";
            break;
    }
}


export function TranslateTable(arrayTable,trans,countryArr){

    let result=arrayTable;
    for(let item in arrayTable){
        // console.log(trans("fields.date"));
        // console.log(arrayTable[item]["field"]);
        result[item]["title"]=trans("fields."+arrayTable[item]["field"]);
        if(arrayTable[item].lookup){
            if(arrayTable[item]["field"]=="sex_id"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"sex");
            }
            if(arrayTable[item]["field"]=="hand_id"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"hand_id");
            }
            if(arrayTable[item]["field"]=="player_position_id"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"main_positions");
            }
            if(arrayTable[item]["field"]=="player_position_detail_id1"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"positions");
            }
            if(arrayTable[item]["field"]=="country_id"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"countries");
            }
            if(arrayTable[item]["field"]=="nationality1"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"countries");
            }
            if(arrayTable[item]["field"]=="nationality2"){
                result[item]["lookup"]=checkFilter(arrayTable[item].lookup,"countries");
            }
        }
    }
    return result;

    function checkFilter(list,transKey){
        let ret=[];
        for(let el in list){
            ret[el]= trans(transKey+"."+list[el]);
        }
        return ret;
    }
}

export function TranslateField(field){
    return field;
}
export function GetBases(base_id){
    switch(base_id){
        case 1:
            return "Albacete";
            break;
        case 2:
            return "C. Real";
            break;
        case 3:
            return "Cuenca";
            break;
        case 4:
            return "Toledo";
            break;
        case 5:
            return "Madrid";
            break;
        case 6:
            return "Jaén";
            break;
    }
}
export function getFranjas(){
    return [{"id":1,"name":"Mañanas (9-14)"},{"id":2,"name":"Tardes (16-20)"},{"id":3,"name":"Todo el dia (9-20)"}];
}
export function getClientStatuses(){
    return [{"id":1,"name":"Habilitado"},{"id":2,"name":"Deshabilitado"},{"id":3,"name":"Bloqueado por GSYM"},{"id":4,"name":"No verificado"}];

}
export function GetStatusTxt(status){
    switch(status){
        case 1:
            return "Pendiente";
        case 2:
            return "Preparado";
        case 4:
            return "Entregado";
        case 5:
            return "Cancelado";
    }
}
export function CheckToken(storeToken,mode){
    if(mode=="login"){
        if(storeToken!=undefined && storeToken!=null && storeToken!=""){
            history.push('/dashboard');
            return false;
        }
    }else if(mode=="logged" && (storeToken==undefined || storeToken==null || storeToken=="")){
        console.log(storeToken);
        if(storeToken==null || storeToken==""){
            history.push('/');
            return false;
        }else
            return true;
    }
}
export function get_Status_User_Team(status_id){
    if(status_id==1)
        return "Invitación enviada";
    else if(status_id==2)
        return "Habilitado";
    else if(status_id==3)
        return "Deshabilitado";
    else
        return "-";
}

export function Call_Get(apiCall,token){
    var promise = new Promise((resolve, reject) => {
        http_get(apiCall,token).then((res)=>{
            // console.log(res);
            if(res.status=="error") {
                if (res.redirect) {
                    if (res.redirect == "not-verified")
                        history.push("/verification");
                    else if (res.redirect == "disabled")
                        history.push("/user-problem");
                    else if(res.redirect=="permission-deny")
                        history.push("/permission-deny");
                }
            }else
                resolve(res);
        }).catch(err=>{
            reject(err);
        });
    });
    return promise;
}

export function AddWorkDays(startDate, days) {
    if(isNaN(days)) {
        console.log("Value provided for \"days\" was not a number");
        return
    }
    if(!(startDate instanceof Date)) {
        console.log("Value provided for \"startDate\" was not a Date object");
        return
    }
    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);
    // If the current day is Sunday add one day
    if (dow == 0)
        daysToAdd++;
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
        //Subtract days in current working week from work days
        var remainingWorkDays = daysToAdd - (5 - dow);
        //Add current working week's weekend
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
            //Add two days for each working week by calculating how many weeks are included
            daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
            //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
            if (remainingWorkDays % 5 == 0)
                daysToAdd -= 2;
        }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
}

export function ReplaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function NumberPrint(str) {
    let p= str.replace(new RegExp(/\./g, 'g'), ",");
    p=ReplaceAll(p,"[A-Za-z]","");
    return p;
}

export function NumberValue(str) {
    let v=str.replace(new RegExp(/,/g, 'g'), ".");
    v=ReplaceAll(v,"[A-Za-z]","");
    return v;
}

export function openWindowRedsys(url,version, parameters, signature) {
    console.log("version",version);

    console.log("params",parameters);
    console.log("signature",signature);
    console.log("url",url);
    let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    form.setAttribute("target", windowName);

    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "Ds_SignatureVersion");
    hiddenField.setAttribute("value", version);
    form.appendChild(hiddenField);
    var hiddenField2 = document.createElement("input");
    hiddenField2.setAttribute("type", "hidden");
    hiddenField2.setAttribute("name", "Ds_MerchantParameters");
    hiddenField2.setAttribute("value", parameters);
    form.appendChild(hiddenField2);
    var hiddenField3 = document.createElement("input");

    hiddenField3.setAttribute("type", "hidden");
    hiddenField3.setAttribute("name", "Ds_Signature");
    hiddenField3.setAttribute("value", signature);
    form.appendChild(hiddenField3);
    document.body.appendChild(form);

    window.open('', windowName);

    form.submit();
}


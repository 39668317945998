import React, {useEffect,useState} from "react";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {AreaChart, Area, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";

const defaultToolbarStyles = {
    iconButton: {
    },
};
const useStyles = makeStyles((theme) => ({

    gridStyle:{
        flexGrow: 1,
        margin:'auto',
        "& .MuiPaper-rounded":{
            borderRadius:'15px',
            overflow:'hidden',
        }
    },
    center:{
        textAlign:'center',
    },
    paperTitle:{
        fontSize:16,
        fontWeight:'bold'
    },
    paperValue:{
        fontSize:15,
        fontWeight:'normal',
        // paddingLeft:20,
        // textAlign:'left'
    },
    paperWidth:{
        padding:'20px',
        minWidth:'200px'
    },
    redColor:{
        color:"#ff2727"
    }
}));
const BasesAccessComponent = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();


    useEffect(() => {
        console.log("bases component");
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }

        return (
            <div className={classes.center}>
                <Grid container className={classes.gridStyle} spacing={5} direction="row" justifyContent="center" alignItems="center">
        {props.data && props.data.length==0 && <div className={classes.redColor}>No tienes acceso a ninguna base, ponte en contacto con el administrador y que te adjudique una base para poder gestionar pedidos </div>}
        {props.data && props.data.map((item)=>{
            return (
                <Grid key={Math.random()} item xs={6} sm={4} md={3}>
                    <Paper className={classes.paperWidth}>
                    <div className={classes.paperTitle}>{item.name}</div>
                    <div className={classes.paperValue}>{item.email}</div>
                    <div className={classes.paperValue}>{item.phone}</div>

                </Paper>
                </Grid>
            )
            })}

                </Grid>
            </div>
        );

}
export default BasesAccessComponent;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import {GetRelDates} from "../providers/Utils/utils";
import Checkbox from '@material-ui/core/Checkbox';
import moment from "moment";

//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    },button:{
        marginTop:20
    }
}));
const Evaluador_GasoleoA = (props) => {
    const classes = useStyles();
    const [name] =useState("Por Gasóleo A mínimo litros");
    const [amount,setAmount] =useState(0);
    const [dateFromValue,setDateFromValue] =useState(new Date());
    const [dateFromRelValue,setDateFromRelValue] =useState(null);
    const [dateToRelValue,setDateToRelValue] =useState(null);
    const [checkedDates,setCheckedDates] =useState(false);
    const [dateMode,setDateMode] =useState("absolute");
    const [dateToValue,setDateToValue] =useState(new Date());
    const [relDates] =useState(GetRelDates());
    const handleAmount=(e)=>{
        setAmount(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos evaluador",props.id,name,amount);
        // props.onSetValue(props.id,name,amount);
        let valor={};
        valor.v=amount;

        if(checkedDates){
            valor.mode="fechas";
            valor.desde = moment(dateFromValue).format('YYYY-MM-DD').toString();
            valor.hasta = moment(dateToValue).format('YYYY-MM-DD').toString();
            console.log("valor",valor);
        }
        props.onSetValue(props.id,name,valor);
    }
    const handleDateFromChange=(v)=>{
        setDateFromValue(v);
    }
    const handleDateToChange=(v)=>{
        setDateToValue(v);
    }
    const handleDateModeChange=(v)=>{
        setDateMode(v.target.value);
    }
    const handleChangeFromRel=(e)=>{
        setDateFromRelValue(e.target.value);
    }
    const handleChangeToRel=(e)=>{
        setDateToRelValue(e.target.value);
    }
    const handleCheckboxChange=(e)=>{
        setCheckedDates(e.target.checked);
    }
    return(
        <div className="father-header-title">
            <div className="">
                Busca clientes por compras de Gasóleo A
            </div>
            <div>Obtenemos datos de clientes que hayan comprado Gasóleo A</div>
            <Grid container spacing={3}>
                <Grid item xs="12">
                    <div className={classes.formControl}>
                        <TextField
                        id="cantidad"
                        label="Cantidad mínima"
                        InputLabelProps={{ shrink: true }}
                        helperText={"Litros mínimos (0 - todos)"}
                        onChange={handleAmount}
                        value={(amount)}
                        fullWidth
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                        checked={checkedDates}
                        onChange={handleCheckboxChange}
                        name="checkedF"
                        />
                    }
                        label="Por fechas"
                            />
                </Grid>
                {checkedDates && <Grid container>
                {/*<Grid item xs={12}>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Modo de fechas</FormLabel>
                            <RadioGroup aria-label="mode" name="dateMode" value={dateMode} onChange={handleDateModeChange}>
                        <FormControlLabel value="absolute" control={<Radio />} label="Fechas Absolutas" />
                        <FormControlLabel value="relative" control={<Radio />} label="Fechas Relativas" />
                        </RadioGroup>
                        </FormControl>
                    </Grid>*/}
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        readOnly={props.action=="view"}
                        id="date-picker-inline"
                        label="Fecha Desde"
                        value={dateFromValue}
                        onChange={handleDateFromChange}
                        helperText={"Fecha desde"}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                    </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        readOnly={props.action=="view"}
                        id="date-picker-inline"
                        label="Fecha Hasta"
                        value={dateToValue}
                        helperText={"Fecha hasta"}
                        onChange={handleDateToChange}
                        KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                        />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {/*dateMode=="relative" && <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                Fecha Rel Desde
                            </InputLabel>
                        <Select
                            value={dateFromRelValue}
                            onChange={handleChangeFromRel}
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'fromrel' }}
                            autoWidth
                            >
                            {relDates && relDates.map((el)=>{
                                return (
                                    <MenuItem value={el}>{el}</MenuItem>
                            )
                            })}
                        </Select>
                    <FormHelperText>Fecha relativa ej(D0 ->Hoy, D-5 ->hace 7 dias) </FormHelperText>
                    </FormControl>
                    </Grid>*/}
                    {/*dateMode=="relative" && <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Fecha Rel Hasta
                    </InputLabel>
                    <Select
                        value={dateToRelValue}
                        onChange={handleChangeToRel}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'fromrel' }}
                        autoWidth
                        >
                        {relDates && relDates.map((el)=>{
                            return (
                                <MenuItem value={el}>{el}</MenuItem>
                        )
                        })}
                    </Select>
                    <FormHelperText>Fecha relativa ej(D0 ->Hoy, D-5 ->hace 7 dias) </FormHelperText>
                    </FormControl>
                    </Grid>*/}
                </Grid>}
            </Grid>
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    startIcon={<EditIcon />}
                    onClick={handleSave}
                    >
                    Aplicar
                </Button>
            </div>
        </div>
)
}

export default Evaluador_GasoleoA;
import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from "@material-ui/core/styles";
import {Call_Get} from "../providers/Utils/utils";
import useLocalStorage from "../providers/store/localStorage";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evaluador_Provincia = (props) => {
    const classes = useStyles();
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [name] =useState("Por provincia");
    const [provinciaSelected,setProvinciaSelected] =useState(null);
    const [provinciaTextSelected,setProvinciaTextSelected] =useState(null);
    const [provincias,setProvincias] =useState(null);

    const handleProvincia=(e)=>{
        setProvinciaSelected(e.target.value);
        let found=provincias.find(element=>element.id==e.target.value);
        setProvinciaTextSelected(found.name);
    }
    useEffect(() => {
        console.log("cargamos provincias en el cuadro");
        Call_Get("config-provinces-actives", storeToken).then((res) => {
            console.log(res);
            setProvincias(res.result);
        });
    },[]);
    const handleSave=()=>{
        console.log("apclicamos evaluador",props.id,name,provinciaSelected);
        props.onSetValue(props.id,name,provinciaSelected,provinciaTextSelected);
    }
    return(
        <div className="father-header-title">
        <div className="">
        Busca clientes por provincia
    </div>
    <div>Obtenemos datos de clientes donde su provincia esté en una de sus direcciones de entrega</div>
    <div>
    <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Provincia
        </InputLabel>
        <Select
            value={provinciaSelected}
            onChange={handleProvincia}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Provincia' }}
        >
    {provincias && provincias.map((el)=>{
        return (
            <MenuItem value={el.id}>{el.name}</MenuItem>
    )
    })}
        </Select>
        <FormHelperText>Provincia</FormHelperText>
    </FormControl>
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Evaluador_Provincia;


import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,UserRol} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from "react-i18next";
import {Button, Dialog} from "@material-ui/core";
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";

import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CustomProvincesToolbar from "../../components/CustomProvincesToolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    }

}));

const BaseTableProvinces = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }
    const handleDeleteClick=(selectedRows,d)=>{
        console.log("delete");
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');
    const [provincesIds,setProvincesIds] =useState(null);
    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [provincesShowPopup,setProvincesShowPopup] =useState(false);
    const [provincesHidePopup,setProvincesHidePopup] =useState(false);

    const [resendEmail,setResendEmail] =useState(null);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomProvincesToolbar mode="not-verified" selectedRows={selectedRows} onRowProvinceHide={()=>handleHideClick(selectedRows,data)} onRowProvinceShow={()=>handleShowClick(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleCloseHidePopup=()=>{
        setProvincesHidePopup(false);
    }
    const handleCloseShowPopup=()=>{
        setProvincesShowPopup(false);
    }
    const handleHideProvinces=(e)=>{
        console.log("mandar al server",provincesIds);
        let formData={"provincesIds":provincesIds};
        http_post("config-provinces-hide",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                console.log("ocultados!");
                setProvincesHidePopup(false);
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
    const handleShowProvinces=(e)=>{
        console.log("mandar al server",provincesIds);
        let formData={"provincesIds":provincesIds};
        http_post("config-provinces-show",formData,storeToken).then((res)=> {
            if(res.status=="ok"){
                console.log("mostrados!");
                setProvincesShowPopup(false);
                window.location.reload();
            }else{
                alert("There´s been an error during save action");
            }
        });
    }
   const handleHideClick=(selectedRows,d)=>{
       let provincesIds=[]
       for(let r in selectedRows.data){
           // console.log("id a asignar",d[selectedRows.data[r].dataIndex].data[0]);
           provincesIds.push(d[selectedRows.data[r].dataIndex].data[0]);
       }
       setProvincesIds(provincesIds);
       setProvincesHidePopup(true);
   }
   const handleShowClick=(selectedRows,d)=>{
       let provincesIds=[]
       for(let r in selectedRows.data){
           console.log("id a asignar",d[selectedRows.data[r].dataIndex].data[0]);
           provincesIds.push(d[selectedRows.data[r].dataIndex].data[0]);
       }
       setProvincesIds(provincesIds);
       setProvincesShowPopup(true);
    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log(res);

            let foundVisible=res.columns.findIndex(element=>element.name=="visible");
            if(foundVisible!=-1){
                res.columns[foundVisible]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {(value==1?"Sí":"No")}
                        </div>
                );
                }
            }
            setTable(res);

        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  mode="profile" selected={pageSelected} />
    <main className="page-main">
        <div className={classes.toolbar}/>
    <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
    <div className={(table.data==null?"circular-position":"is-hidden")}>
<CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
    title={t("pages."+props.title)}
    data={table.data}
    columns={table.columns}
    options={tableOptions}
    />
    </div>
    </div>
    <div className={clsx(classes.contentBody,"page-footer")}>
<Footer/>
    </div>
    <Dialog
    open={(provincesShowPopup?provincesShowPopup:false)}
    onClose={handleCloseShowPopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Mostrar provincias</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
        <div>Has seleccionado <b>{(provincesIds?provincesIds.length:"")}</b> para ser mostradas</div>
    <div>Mostrando la provincia, permitimos asignar códicos postales a almacenes de GSYM, por lo que los clientes con esas direcciones de entrega, podrán comenzar a realizar solicitudes de pedidod a través de la App.</div>
    <div className={classes.centerText}>

    <Button variant="contained" onClick={handleShowProvinces} color="primary">
        {"Mostrar provincias"}
        </Button>
        </div>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseShowPopup} color="primary">
        {t("buttons.cancel")}
</Button>
    </DialogActions>
    </Dialog>
    <Dialog
    open={(provincesHidePopup?provincesHidePopup:false)}
    onClose={handleCloseHidePopup}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">Ocultar provincias</DialogTitle>
    <DialogContent>
    <DialogContentText id="alert-dialog-description">
        <div>Has seleccionado <b>{(provincesIds?provincesIds.length:"")}</b> para ser ocultadas</div>
        <div>Ocultando la provincia, ocultamos también todos sus códigos postales asociados, por lo que los clientes con esas direcciones de entrega, no van a poder realizar solicitudes de pedidod a través de la App.</div>
    <div className={classes.centerText}>

    <Button variant="contained" onClick={handleHideProvinces} color="primary">
        {"Ocultar provincias"}
        </Button>
        </div>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseHidePopup} color="primary">
        {t("buttons.cancel")}
</Button>
    </DialogActions>
    </Dialog>
    </main>
    </div>
);
}
export default BaseTableProvinces;
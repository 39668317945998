

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useLocalStorage from "../../providers/store/localStorage";
import history from '../../providers/History/history';
import {Call_Get, CheckToken} from "../../providers/Utils/utils";
import {http_get, http_post} from "../../providers/http/http";
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackgroundImage from '../../res/fondo1.jpg';
import { Link as RouterLink } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { validate } from 'validate.js';
import constraints from '../../components/Constraints';




import MenuItem from "@material-ui/core/MenuItem";

function Copyright() {
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');

    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.gsym.net/">
                 {process.env('REACT_APP_NAME')}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    page:{
        backgroundImage:`url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height:"100%",
        overflowY:"auto"
    },
    box:{
        backgroundColor:"rgba(0,0,0,.7)",
        minHeight:"100%",

    },
    paper: {
        // marginTop: theme.spacing(8),
        paddingTop:theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    whiteText:{
        // backgroundColor:'#ffffff'
    },
    title:{
        textAlign:"center",
        fontSize:"18px",
    },
    registerlink:{
        color:theme.palette.primary.main,
    },
    register:{
        paddingTop:theme.spacing(3),
        textAlign:"center",
    },
    footer:{
        bottom:"0px",
        left:"0px",
        right:"0px",
        textAlign:"center",
    },
    formControl:{
        marginTop:"15px"
    }
}));

export default function PermissionDeny() {
    const classes = useStyles();
    const [email,setEmail] = useLocalStorage('email','');
    const [name,setName] = useLocalStorage('name','');
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeName,setStoreName] =useLocalStorage('RV_store_prof_name');
    const [password,setPassword] =useState("");
    const [professionalType,setProfessionalType] = useState("");
    const [openPopup,setOpenPopup]= useState(false);
    const [openPopupTitle,setOpenPopupTitle] =useState("");
    const [openPopupDescription,setOpenPopupDescription] =useState("");
    const [submitting,setSubmitting] =useState(false);
    const [t, i18n] = useTranslation('common');
    const [promotionSelected,setPromotionSelected] =useState(1);
    const [professionalTypeSelected,setProfessionalTypeSelected] =useState(1);
    const [promotionsList,setPromotionsList] =useState([]);
    const [professionalTypeList,setProfessionalTypeList]=useState([]);
    const[emailHelper,setEmailHelper]=useState("");
    const [nameHelper,setNameHelper] =useState("");
    const[passwordHelper,setPasswordHelper]=useState("");

    const handleClosePopup=e=>{
        setOpenPopup(false);
    }

    const initialFormData = Object.freeze({
        email: email,
        password: "",
        name:name,
        professionalType:1,
        promotion:1
    });
    const [formData, updateFormData] = React.useState(initialFormData);
    const handleEmail=(e)=>{
        setEmail(e.target.value);
        updateFormData({
            ...formData,

            // Trimming any whitespace
            ["email"]: e.target.value.trim()
        });
        let validateE = validate({emailAddress: e.target.value}, constraints);
        if(validateE.emailAddress){
            setEmailHelper(t("validation.register."+validateE.emailAddress[0]));
        }else{
            setEmailHelper("");
        }
    }
    const handleBack=(e)=>{
        history.goBack();
        history.goBack();
    }
    const handleSubmit=(e)=>{
        e.preventDefault();

        let validationResult = validate({emailAddress: email,registerName:name,password:password}, constraints);

        if(validationResult) {
            if (validationResult.emailAddress) {
                setEmailHelper(t("validation.register." + validationResult.emailAddress[0]));
            } else if (validationResult.registerName) {
                setNameHelper(t("validation.register." + validationResult.registerName[0]));
            } else if (validationResult.password) {
                setPasswordHelper(t("validation.register." + validationResult.password[0]));
            }
        }else{
            //validation success
            http_post("professional-register", formData,null).then((res) => {
                console.log(res);
                if(res.status=="ok"){
                    setStoreToken(res.additional_info.msg);
                    history.push('/dashboard');
                }
                if(res.status=="error"){
                    setOpenPopup(true);
                    setOpenPopupTitle("Error en las credenciales");
                    let desc=t("register.error.generic");
                    if(res.additional_info.error=="user-exists-professional"){
                        desc=t("register.error."+res.additional_info.error);
                    }else if(res.additional_info.error=="user-exists-talent"){
                        desc=t("register.error."+res.additional_info.error);
                    }
                    setOpenPopupDescription(desc);
                    setSubmitting(false);
                }

            }).catch(err => {
                console.log(err);
            });
        }

    }
    const handleClickRegister=(e)=>{
        // var data ={"email":email,"password":password};
        // http_post("admin-login", data,null).then((res) => {
        //     console.log(res);
        //     // console.log(res);
        //     if(res.status==403){
        //         console.log("err mesg");
        //         setOpenPopup(true);
        //         setSubmitting(false);
        //     }
        //     if(res.data!=null){
        //          //login ok
        //         setStoreToken(res.data.token);
        //         history.push('/dashboard');
        //     }else{
        //          //login error
        //     }
        // }).catch(err => {
        //     console.log(err);
        // });
        // // setStoreToken("testtoken");
        // // history.push('/dashboard');

    }
    useEffect(() => {
        Call_Get('professional-register-check-verify',null).then((res)=> {
            console.log(res);

           // console.log(res);
        });
        // console.log("register");
    },[]);
    return (
        <div className={classes.page}>
            <Container component="main" maxWidth="xs" className={classes.box}>
                <CssBaseline />
                <div className={classes.paper}>
                    <div className={classes.title} >
                        Scouting<br/>Portal
                    </div>
    {/* <img className="home-logo" src={RedVixelLogo} />*/}
                    <div>You don´t have permissions to access to this page,
                        please contact with the main account to ask for permissions.</div>
                    <Button variant="contained" color="primary" onClick={handleBack}>
                        Back
                    </Button>
                </div>
                <Box className={classes.footer} mt={8}>
                    <Copyright />
                </Box>
                <Dialog
                    open={(openPopup?openPopup:false)}
                    onClose={handleClosePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{openPopupTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {openPopupDescription}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>

    );
}

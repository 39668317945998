import React, {Fragment, useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBarComponent from "../../components/AppBarComponent";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import Footer from "../../components/Footer";
import {makeStyles,withStyles} from "@material-ui/core/styles";
import history from "../../providers/History/history";
import useLocalStorage from "../../providers/store/localStorage";
import {
    Avatar,
    Dialog,
    Divider,
    Input,
    InputLabel,
    ListItemAvatar,
    Paper,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import {http_post} from "../../providers/http/http";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Call_Get, GetProducts, PedidoEstado,PedidoFranja,FormatNumber} from "../../providers/Utils/utils";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FormatDateTime} from "../../providers/Utils/utils";
import EditIcon from '@material-ui/icons/Edit';
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
// import PromotionPopup from "../../components/PromotionPopup";
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OperatorPopup from "../../components/OperatorPopup";
import RelatedPublicResult from "../../components/RelatedPublicResult";
import EventPopup from "../../components/EventPopup";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import PublicPopup from "../../components/PublicPopup";
import RelatedPromoResult from "../../components/RelatedPromoResult";
import RelatedPromoAnalysis from "../../components/RelatedPromoAnalysis";
import PublicComponent from "../../components/PublicComponent";
import PromotionComponent from "../../components/PromotionComponent";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
>
    {value === index && (
    <Box p={3}>
        <div>{children}</div>
        </Box>
    )}
</div>
);
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9",
        width:'100%'
    },
    contentTab:{
        paddingBottom:'50px',
        // backgroundColor:"#3d3f49",
        width:'100%'
    },
    tabComponent:{
        border:'1px solid #cccccc',
        margin:"20px 0",
    },
    paper: {
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        // backgroundColor:"#383b44",
    },
    paperBlock:{
        padding:10,
        width:'100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& h2':{
            width:'100%',
            borderBottomWidth:1,
            borderBottomStyle:'solid',
            borderBottomColor:'#cccccc'
        }
    },
    transferPaper:{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
        backgroundColor:"#3d3f49",
        width:"100%"
    },
    transferList:{
        width:"100%"
    },
    transferPlayers:{
        width:"100%",
        maxWidth:"250px"
    },
    transferButtons:{
        width:"70px"
    },
    transferIcon:{
        textAlign:"center",
    },
    transferSaveIcon:{
        fontSize:"12px"
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        // ...myTheme.fieldRow
        padding:"5px 0",
    },
    fieldField:{
        ...myTheme.fieldField
    },
    team:{
        fontSize:"18px",
        paddingTop:"10px"
    },
    result:{
        backgroundColor:"#cccccc",
        height:"40px",
        textAlign:"center",
        paddingTop:"10px",
        fontSize:"18px"
    },
    alignRight:{
        textAlign:"right"
    },
    input:{
        width:"100%",
        backgroundColor:"#383b44",
        height:"200px",
        color:"#ffffff",
        padding:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #e5da6b",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    transferListItem:{
        width:"100%"
    },
    tabSmall:{
        minWidth:"90px",
        fontSize:"12px"
    },
    tab:{
        minWidth:"120px",
        fontSize:"14px"
    },
    header:{
        position:'relative',
        // height:'190px',
        //border:'1px solid #cccccc',
        padding:'0',
        // margin:'90px 0 0 0',
        backgroundColor:'#383b44'
    },
    left:{
        display:'inline-block'
    },
    right:{
        display:'inline-block',
        float:'right'
    },
    button:{
        margin:'0 5px'
    },
    option:{
        verticalAlign:"middle"
    },
    iconPadding:{
        paddingTop:"5px"
    },
    // blockLast5:{
    //     border:"1px solid #ff0000"
    // }
  buttonBack:{
        width:'46px',
        height:'46px'
    },
    sectionBackground:{
        paddingTop:10
    },
    redColor:{
        color:"#ff2727"
    },
    greenColor:{
        color:"#62bf62"
    },
    blockUser:{
        fontWeight:"bold"
    },
    editInput:{
        '& label': {
            color: 'orange',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'orange',
        },
        '& .MuiInputBase-input': {
            color: 'orange',
        },
    },
    width50:{
        display:'inline-block',
        width:'50%',
        padding:5
    },
    totalPrice:{
        fontSize:24,
        fontWeight:'bold'
    },
    centerText:{
        textAlign:'center'
    },
    totalStyle:{
        padding:25,
        fontSize:20
    },
    variationStyle:{
        padding:10
    },
    width100:{
        width:'100%'
    },
    conditions:{
        marginBottom:0,
    },
    uniqueBlock:{
        width:'100%'
    },
    uniqueLine:{
        display:'inline-block',
        width:'50%',
    },
    rightText:{
        textAlign:'right'
    },
    padding10:{
        padding:10
    },
    containerContenido:{
        paddingBottom:20
    },
    paperBackground:{
    backgroundColor:"#f0f0f0"}
}));

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PromotionRecord = (props) => {
    // const [visiblePopup,setVisiblePopup] = useState(false);
    const [visiblePopupMode,setVisiblePopupMode] = useState(false);
    const [operatorPopup,setOperatorPopup] =useState(null);
    const [operatorValue,setOperatorValue] =useState("Y");
    const [action,setAction] = useState("");
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');
    const matches = useMediaQuery('(min-width:800px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [options,setOptions] = useState([]);
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    const [publicEl,setPublicEl] =useState(null);
    const [promotionsEl,setPromotionsEl]=useState(null);
    // const handleCloseVisiblePopup=()=>{
    //     setVisiblePopup(false);
    // }
    const handleChangeValuePopup=(e)=>{
        console.log("value",e);
    }
    const AddCondition=(ev,name,v,textValue,operator,element=null)=>{
        // console.log("Añadir condicion");
        let cond=element;
        if(cond==null)
            cond=[];
        let next=1;
        if(cond.length>0){
            next=cond[cond.length-1].id+1;
        }
        cond.push({id:next,ev:ev,name:name,operator:operator,value:v,textValue:textValue});
        // console.log("condiciones",cond);
        return cond;
    }

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("page-selected","dashboard");
    const [storePermissions,setStorePermissions] =useLocalStorage('RV_store_prof_permissions');
    const [fields,setFields] =useState([]);
    const [record,setRecord]=useState({});
    const [relateds,setRelateds] = useState([]);
    const [actionPage,setActionPage] =useState("");
    const [bc,setBc]=useState([]);
    const [listItems,setListItems]=useState([]);
    const [isRefreshed,setIsRefreshed]=useLocalStorage("isRefreshed",false);
    const [updateDetail,setUpdateDetail]=useState({});
    const [openPopup,setOpenPopup] =useState(false);
    const [openCancelOrderPopup,setOpenCancelOrderPopup] =useState(false);
    const [userId,setUserId] =useState(null);
    const [name,setName] =useState(null);
    const [description,setDescription] =useState(null);
    const [title,setTitle] =useState(null);
    const [body,setBody] =useState(null);
    const [numUsos,setNumUsos] =useState(1);
    const [phone,setPhone] =useState(null);
    const [visible,setVisible] =useState(null);
    const [result,setResult] =useState(null);
    const [analysis,setAnalysis]= useState(null);
    const [readOnly,setReadOnly] =useState(false);
    const [modeAdmin,setModeAdmin] =useState(false);
    const [valuePublic,setValuePublic] =useState(null);
    const [valuePromotion,setValuePromotion] =useState(null);

    const handleBack=()=>{
        console.log("back");
        if(props.action=="edit")
            history.push(props.parentUrl+"/"+props.id+"/view");
        else
            history.push(props.parentUrl);
        // if(!isRefreshed)
        //     history.goBack();
        // else{
        //     setIsRefreshed(false);
        //     history.goBack();
        //     history.goBack();
        // }
    }

    const handleClosePopup=()=>{
        setOpenPopup(false);
    }
    const handleNameChange=(e)=>{
        setName(e.target.value);
    }
    const handleNumUsosChange=(e)=>{
        setNumUsos(e.target.value);
    }
    const handleDescriptionChange=(e)=>{
        setDescription(e.target.value);
    }
    const handleTitleChange=(e)=>{
        setTitle(e.target.value);
    }
    const handleBodyChange=(e)=>{
        setBody(e.target.value);
    }
    const handleNumUsos=(e)=>{
        setNumUsos(e.target.value);
    }

    const handleVisibleChange=(e)=>{
        console.log("change visible",e.target.value);
        setVisible(e.target.value);
    }

    const [t, i18n] = useTranslation('common');

    const [data,setData]=useState([]);
    const [tab1Value, setTab1Value] = useState(0);
    const [content1Value,setContent1Value] = useState(true);
    const [recordBlocked,setRecordBlocked] =useState(false);
    const [condiciones,setCondiciones] =useState(null);
    const [dateFromValue,setDateFromValue] =useState(Date.now());
    const [dateToValue,setDateToValue] =useState(null);

   useEffect(() => {
        let url="";
        setAction(props.action);
        if(props.action=="new") {
            // setPublicEl([]);
            setValuePublic([]);
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.title}]);
            // url = props.parentId + "/" + props.action;
        }else{
            setBc([{"id":props.id,"title":props.parentTitle,"src":props.parentUrl},{"id":props.id+"-"+props.action,"title":props.parentTitle+"--" +props.action+ "--"+props.id}]);
            url=props.parentId+"/"+props.action+"/"+props.id;
        }
        if(props.action=="view"){
            setIsRefreshed(false);
        }
        // console.log("action",props.action);
        if(props.action=="new") {
            setVisible("0");
            setCondiciones([]);
            setDateFromValue(new Date());
        }
        if(props.action!="new") {
            Call_Get(url, storeToken).then((res) => {
                // console.log(res);
                setRecord(res.record);
                setRelateds(res.related);
                setName(res.record.name);
                setDateFromValue(res.record.publish_date);
                setDateToValue(res.record.expiracy_date);
                setTitle(res.record.title);
                setBody(res.record.body);

                setVisible(res.record.visible.toString());
                setResult(res.related["result"]);
                setAnalysis(res.related["analysis"]);
                if (props.action == "view") {
                    setReadOnly(true);
                }else
                    setReadOnly(false);

                let v=JSON.parse(res.record.value);
                console.log("valores",v);
                loadPublic(v["public"]);
                loadPromotions(v["promotion"]);
            });
        }
        // }
        setPageSelected({"id":props.parentId,"title":props.parentTitle});
    },[]);
    const loadPublic=(value)=>{
        let cond=[];
        for(let el in value){
            let item=value[el];
            cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,valuePublic);
        }
        setValuePublic(cond);
    }
    const loadPromotions=(value)=>{
       console.log("load promotions",value);
        let cond=[];
        for(let el in value){
            let item=value[el];
            cond=AddCondition(item.ev,item.name,item.value,item.textValue,item.operator,promotionsEl);
        }
        // setPromotionsEl(cond);
        setValuePromotion(cond);
    }
    const handleTab1Change=(e,newValue)=>{
        setContent1Value(false);
        console.log("change");
        setTab1Value(newValue);
        setContent1Value(true);

    }
    const handleEdit=()=>{
        setIsRefreshed(true);
        history.push("/blank");
        setTimeout(() => history.push(props.parentUrl+"/"+props.id+"/edit"), 10);
        console.log("edit");
        // history.push(props.parentUrl+"/"+props.id+"/edit");

    }
    const handleSave=()=>{
        console.log("save");
        // console.log("save",props.id);
        // console.log("public",valuePublic);
        // console.log("promotion",valuePromotion);
        // console.log(name);
        // console.log("from",dateFromValue);
        // console.log("to",dateToValue);
        // console.log(!props.id);
        // console.log(!props.id || name=="" || dateFromValue=="" || dateToValue=="" || (valuePromotion && valuePromotion.length==0) ||(valuePublic && valuePublic.length==0));
        if(name=="" || dateFromValue==null || dateToValue==null || !valuePromotion || valuePromotion.length==0 || !valuePublic || valuePublic.length==0) {
            alert("Faltan datos: Nombre, descripción o condiciones");
        }else{
            let formData={name:name,body:body,publish_date:dateFromValue,expiracy_date:dateToValue,num_usos:numUsos,
                promotion:valuePromotion,public:valuePublic,visible:visible};
            if(props.id)
                formData.id=props.id;
            http_post("promotion/save",formData,storeToken).then((res)=> {
                if(res.status=="ok"){
                    console.log(res);
                    // alert("data saved successfully");
                    history.push("/"+props.parentId);
                    // window.location.reload();
                }else
                    alert("There´s been an error during save action");
            });
        }
        // if(name=="" || description=="" || publicEl.length==0 || promotionsEl.length==0){
        //     alert("Faltan datos: Nombre, descripción o condiciones");
        // }

    }
    const handleCancelSave=()=>{
        console.log("cancel save");
        history.push("/blank");
        setTimeout(() => {
                if (props.action == "new")
                    history.push(props.parentUrl);
                else
                    history.push(props.parentUrl + "/" + props.id + "/view");
            }
        , 10);
    }
    // const handleNewCondition=()=>{
    //     console.log("new condition");
    //     setVisiblePromotionPopup(true);
    //
    // }
    const handleChangeOperator=(id,value)=>{
       if(props.action!="view") {
           console.log("change operator", id);
           setOperatorPopup(id);
           setOperatorValue(value);
       }
    }
    const handleDateFromChange=(v)=>{
        setDateFromValue(v);
    }
    const handleDateToChange=(v)=>{
        setDateToValue(v);
    }
    const handleDeleteCondition=(mode,id)=>{
        let condStr="";
        switch(mode){
            case "promotion":
                condStr=JSON.stringify(promotionsEl);
                break;
            case "public":
                condStr=JSON.stringify(publicEl);
                break;
        }

        console.log("id",id);
        console.log("condstr",condStr);
        let cond=JSON.parse(condStr);
        console.log("cond",cond);
        let found=cond.findIndex(element=>element.id==id);
        console.log("found",found);
        if(found>=0){
            cond.splice(found,1);
        }
        if(cond.length==0)
            cond=null;
        console.log("condiciones",cond);
        switch(mode){
            case "promotion":
                // setPromotionsEl(cond);
                setValuePromotion(cond);
                break;
            case "public":
                // setPublicEl(cond);
                setValuePublic(cond);
                break;
        }
    }

    const htmlContenido=()=>{
        return (
            <Grid className={classes.containerContenido} container spacing={3}>

                <Grid item xs={12}  sm={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-name"
                        label="Nombre"
                        type="text"
                        autoComplete="off"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.name:(name?name:(record.name?record.name:"")))}
                        onChange={handleNameChange}
                        helperText="Nombre amigable para reutilizar"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}  sm={12}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-description"
                        label="Descripción"
        multiline
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.body:(body?body:(record.body?record.body:"")))}
                        onChange={handleBodyChange}
                        helperText="Texto de la promoción visible en la APP"
                        fullWidth
                        />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {props.action!="view" && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDateTimePicker
                            variant="inline"
                            format="dd/MM/yyyy HH:mm"
                            ampm={false}
                            readOnly={props.action=="view"}
                            id="date-picker-inline"
                            label="Fecha Desde"
                            value={dateFromValue}
                            onChange={handleDateFromChange}
                            helperText={"Fecha desde"}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>}
                    {props.action=="view" &&
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                            id="filled-date"
                            label="Fecha Desde"
                            type="default"
                            InputProps={{
                            readOnly: readOnly
                        }}
                            value={(props.action=="view"?(record.publish_date?FormatDateTime(record.publish_date):""):"")}
                            helperText="Fecha desde"
                            fullWidth
                        />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {props.action!="view" && <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDateTimePicker
                        variant="inline"
                        format="dd/MM/yyyy HH:mm"
                        ampm={false}
                        readOnly={props.action=="view"}
                        id="date-picker-inline"
                        label="Fecha Hasta"
                        value={dateToValue}
                        onChange={handleDateToChange}
                        helperText={"Fecha hasta"}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                    </MuiPickersUtilsProvider>}
                    {props.action=="view" &&
                            <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-date"
                        label="Fecha Hasta"
                        type="default"
                        InputProps={{
                        readOnly: readOnly
                    }}
                        value={(props.action=="view"?(record.expiracy_date?FormatDateTime(record.expiracy_date):""):"")}
                        helperText="Fecha hasta"
                        fullWidth
                        />
                    }
                </Grid>
                {/*<Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-description"
                        label="Título"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?record.title:(title?title:(record.title?record.title:"")))}
                        onChange={handleTitleChange}
                        helperText="Título de la promoción visible en la APP"
                        fullWidth
                    />
                </Grid>*/}

                <Grid item xs={12}  sm={6}>
                    {props.action!="view" && <FormControl className={classes.formFields}>
                        <InputLabel shrink id="demo-simple-select-label">Visible</InputLabel>
                        <RadioGroup
                            aria-label="visible" name="Visible" value={visible} onChange={handleVisibleChange}>
                        <FormControlLabel  value="1" control={<Radio />} label="Sí" />
                        <FormControlLabel  value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>}
                    {props.action=="view" && <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="filled-lng"
                        label="Visible"
                        type="default"
                        InputProps={{
                            readOnly: readOnly
                        }}
                        value={(props.action=="view"?(record.visible==1?"Sí":"No"):(visible?(visible==1?"Sí":"No"):(record.visible?(record.visible==1?"Sí":"No"):"")))}
                        helperText="Visibilidad del elemento"
                        fullWidth
                        />}
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        id="num-usos"
                        label="Num. Usos"
                        type="text"
                        autoComplete= 'new-password'
                        InputProps={{
                            readOnly: readOnly,
                                autoComplete: 'no',
                        }}
                        value={(props.action=="view"?record.num_usos:(numUsos?numUsos:(record.num_usos?record.num_usos:"")))}
                        onChange={handleNumUsosChange}
                        helperText="Número de usos de la promoción -1 sin límite"
                        fullWidth
                    />
                </Grid>
            </Grid>
        );
    }
    const handleChangeCondiciones=(cond)=>{
        console.log("cambio de condiciones",cond);
        setValuePublic(cond);
    }
    const handleChangePromotion=(cond)=>{
        console.log("cambio de promociones",cond);
        setValuePromotion(cond);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBarComponent selected={pageSelected} permission={storePermissions} />
            <main className="page-main">
                <div className={classes.toolbar}/>
                <div>
                    <div className={classes.contentBody}>
                        <BreadCrumbComponent data={bc}/>
                        <Paper elevation={3} className={classes.paper} >
                            <FormControl className={classes.formFields}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <div className={classes.left}>
                                            <IconButton className={classes.buttonBack} aria-label="back" size="small" onClick={handleBack}>
                                                <BackIcon />
                                            </IconButton>
                                        </div>
                                        {props.action=="view" && <div className={classes.right}>
                                            <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleEdit}
                                                    >
                                                Editar promoción
                                            </Button>
                                        </div>}
                                        {props.action!="view" &&<div className={classes.right}>
                                                <Button
                                                color="primary"
                                                disabled={recordBlocked}
                                                variant="contained"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={handleSave}
                                                    >
                                                    Guardar
                                            </Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={recordBlocked}
                                                className={classes.button}
                                                startIcon={<CancelIcon />}
                                                onClick={handleCancelSave}
                                                    >
                                                    Cancelar
                                            </Button>
                                            </div>}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Box margin={0}>
                            <Grid container spacing={0}>
                                {/*--INFO RECORD --*/}
                                <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <Paper elevation={3} className={classes.paperBlock} >
                                                <h2>Promoción</h2>
                                                {htmlContenido()}
                                                {/*htmlPublic()*/}
                                                {/*htmlPromo()*/}
                                            {(valuePublic ||props.action=="new" )&& <PublicComponent title="Público" action={props.action} value={valuePublic} onSetCondiciones={handleChangeCondiciones} />}
                                            {(valuePromotion ||props.action=="new" )&& <PromotionComponent title="Promoción" action={props.action} value={valuePromotion} onSetCondicionesPromo={handleChangePromotion} />}

                                            </Paper>
                                            {props.action=="view" && <div className={classes.contentTab}>
                                                <div className={classes.content}>
                                                    <div className={classes.tabComponent}>
                                                        <AppBar position="static">
                                                            <Tabs value={tab1Value} onChange={handleTab1Change} aria-label="simple tabs example">
                                                                {<Tab className={classes.tab} label={t("tabs.result")} {...a11yProps(0)} />}
                                                                {<Tab className={classes.tab} label={t("tabs.analysis")} {...a11yProps(1)} />}
                                                            </Tabs>
                                                        </AppBar>
                                                        {<TabPanel value={tab1Value} index={0}>
                                                            {result && <RelatedPromoResult data={result}/>}
                                                            {/*<PlayerDetailTrainingsComponent data={data.trainings} chartList={data.trainingList30} chartTotal={data.trainingTotal30}/>*/}
                                                        </TabPanel>}
                                                        {<TabPanel value={tab1Value} index={1}>
                                                            {result && <RelatedPromoAnalysis table={analysis}/>}
                                                            {/*<PlayerDetailTrainingsComponent data={data.trainings} chartList={data.trainingList30} chartTotal={data.trainingTotal30}/>*/}
                                                        </TabPanel>}
                                                    </div>
                                                </div>
                                            </div>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </main>
            {/*visiblePopupMode=="public" && <PublicPopup open={visiblePopup} onChange={handleChangeValuePopup} onClose={handleCloseVisiblePopup} onSetValue={(id,name,v,textValue)=>handleAddCondition("public",id,name,v,textValue)}/>*/}
            {/*visiblePopupMode=="promotion" && <PromotionPopup open={visiblePopup} onChange={handleChangeValuePopup} onClose={handleCloseVisiblePopup} onSetValue={(id,name,v,textValue)=>handleAddCondition("promotion",id,name,v,textValue)}/>*/}

            {/*  <PromotionPopup open={visiblePromotionPopup} onChange={handleChangeValuePopup} onClose={handleClosePromotionPopup} onSetValue={handleAddCondition}/>*/}
                {/*<OperatorPopup open={operatorPopup} value={operatorValue} onSetValue={handleSetOperatorPopup}/>*/}
                                            {/*<Snackbar open={savePlayersOpen} autoHideDuration={2000} onClose={handleCloseSavePlayers}>*/}
            {/*    <Alert onClose={handleCloseSavePlayers} severity="success">*/}
            {/*        Data saved successfully!*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}
        </div>

    );
}
export default PromotionRecord;


import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Promocion_CP_Generico = (props) => {
    const classes = useStyles();
    const [name] =useState("Por cod genérico");
    const [codigo,setCodigo] =useState(null);
    const [dtoEur,setDtoEur] =useState(null);
    const [dto,setDto] =useState(null);
    const handleCodigo=(e)=>{
        setCodigo(e.target.value);
    }
    const handleSave=()=>{
            console.log("apclicamos Promocion", props.id, name, codigo);
            let myDto=dto;
            let tipo="%";
            if(dtoEur) {
                myDto = dtoEur;
                tipo="EUR";
            }
            props.onSetValue(props.id, name, codigo,{"Código":codigo,"Valor":myDto,"Tipo":tipo});
    }
    const handleDtoEur=(e)=>{
        setDtoEur(e.target.value);
    }
    const handleDto=(e)=>{
        setDto(e.target.value);
    }

    return(
        <div className="father-header-title">
        <div className="">
        Descuento Código Promocional Genérico
    </div>
    <div>Código que podrán usar todos los clientes que encajen en la promoción </div>
    <div className={classes.formControl}>
        <TextField
    id="codigo"
    label="Código de Descuento"
    InputLabelProps={{ shrink: true }}
    helperText={"Código de descuento"}
    autoComplete='off'
    onChange={handleCodigo}
    value={(codigo?codigo:"")}
    fullWidth
    />
    <TextField
    id="dto"
    label="Descuento en euros"
    InputLabelProps={{ shrink: true }}
    helperText={"Descuento en Euros"}
    autoComplete='off'
    onChange={handleDtoEur}
    value={(dtoEur?dtoEur:"")}
    fullWidth
    />
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}

    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Promocion_CP_Generico;
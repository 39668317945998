import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ViewIcon from '@material-ui/icons/Visibility';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import CancelIcon from '@material-ui/icons/Clear';
import FinishIcon from '@material-ui/icons/Flag';
import {Button} from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
const useStyles = makeStyles((theme) => ({

    iconButton: {
    },
    buttonsContainer:{
        marginRight:20
    },
    finalizar:{
        color:"#30b352"
    },
    cancelar:{
        color:"#ff2727"
    }
}));

const CustomOrderProcessedToolbar = (props) => {
    const [selected,setSelected] =useState([]);
    const classes=useStyles();
    useEffect(() => {
        console.log("custom order toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickFinish=()=>{
        console.log("finish Elements");
        console.log("finish");
        props.onRowFinish(props.selectedRows);
        // props.onRowView("value");
    }
    const handleClickIncidence=()=>{
        console.log("incidence Elements");
        console.log("incidence");
        props.onRowIncidence(props.selectedRows);
        // props.onRowView("value");
    }

    return (
        <div className={classes.buttonsContainer}>
            <Button  onClick={handleClickFinish} color="primary" className={classes.finalizar}>
                <FinishIcon/>Finalizar pedido</Button>
            <Button onClick={handleClickIncidence} color="secondary" className={classes.Cancelar}>
                <WarningIcon />Incidencia pedido</Button>
        </div>
    );
}
export default CustomOrderProcessedToolbar;
import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import useLocalStorage from '../providers/store/localStorage'
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
const useStyles = makeStyles((theme) => ({

}));
const FieldText = (props) => {
    const classes = useStyles();
    const [value,setValue]=useState(null);
    const handleChange=(e)=>{
        console.log("change");
        setValue(e.target.value);
        props.onSetValue({"item":props.item.field,"value":e.target.value});
        // props.onSetValue("1");
    }

    if(props.action=="view"){
        let rel="";
        return(
                <div id={props.item.field}>
                    <div className="field-view-title" >{props.item.title}</div>
                    <div className={(rel==""?"field-view-value":"is-hidden")}>{(props.record?props.record:"(not defined)")}</div>
                </div>
        );
    }else if(props.action=="edit"){
        return(
            <TextField
                className={classes.textField}
                id="filled-name"
                label={props.item.title}
                type={props.type}
                helperText={(props.item.description?props.item.description:"")}
                value={(value==null?props.record:value)}
                onChange={handleChange}
                fullWidth
            />
        );
    }else if(props.action=="new"){
        return (
            <TextField
                className={classes.textField}
                id="filled-name"
                label={props.item.title}
                type={props.type}
                helperText={(props.item.description?props.item.description:"")}
                value={value}
                onChange={handleChange}
                fullWidth
            />
        );
    }
}

export default FieldText;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Action_Popup = (props) => {
    const classes = useStyles();
    const [name] =useState("Mostrar popup");
    const [text,setText] =useState(null);
    const [title,setTitle] =useState(null);
    const [description,setDescription] =useState(null);

    const handleTitle=(e)=>{
        setTitle(e.target.value);
    }
    const handleDescription=(e)=>{
        setDescription(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos accion",props.id,name,text);
        props.onSetValue(props.id,name,{"title":title,"description":description});
    }
    return(
        <div className="father-header-title">
        <div className="">
        Escribe el texto del popup a mostrar
    </div>
    <div className={classes.formControl}>
    <TextField
    id="text"
    label="Título del popup"
    InputLabelProps={{ shrink: true }}
    helperText={"Título del popup"}
    onChange={handleTitle}
    value={(title?title:"")}
    fullWidth
    />
    <TextField
    id="text"
    label="Descripción del popup"
    InputLabelProps={{ shrink: true }}
    helperText={"Descripción del popup"}
    onChange={handleDescription}
    value={(description?description:"")}
    fullWidth
    />
    </div>
    <div>
    <Button
    color="primary"
    variant="contained"
    className={classes.button}
    startIcon={<EditIcon />}
    onClick={handleSave}
        >
        Aplicar
        </Button>
        </div>

        </div>
)
}

export default Action_Popup;
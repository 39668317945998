import React from 'react';
import Evento_Login from "./Evento_Login";
import Evento_Direccion_Entrega_Nueva from "./Evento_Direccion_Entrega_Nueva";
import Evento_Direccion_Facturacion_Nueva from "./Evento_Direccion_Facturacion_Nueva";
import Evento_Registro from "./Evento_Registro";
import Evento_Pedido_Cambio_Estado from "./Evento_Pedido_Cambio_Estado";
import Evento_Nuevo_Pedido from "./Evento_Nuevo_Pedido";
//pages

const Evento = (props) => {
    const handleOnSetValue=(id,name,v,textValue)=>{
        props.onSetValue(id,name,v,textValue);
    }
    const EvList = {
        2: Evento_Login,
        3: Evento_Direccion_Entrega_Nueva,
        5: Evento_Nuevo_Pedido,
        9: Evento_Pedido_Cambio_Estado,
        6: Evento_Direccion_Facturacion_Nueva,
        7: Evento_Registro,

    };
    const Ev = EvList[props.ev];
    return (<Ev id={props.ev} onSetValue={handleOnSetValue} />);

}

export default Evento;
import React from 'react';
//pages

const Notfound = (props) => {
    return(
        <div className="pepe">
        <div className="">
            pagina no encontrada
        </div>
        </div>
)
}

export default Notfound;
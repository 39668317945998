

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,PedidoEstado,PedidoFranja,GasoleoIcon,FormatPrice} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
// import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from '@material-ui/icons/Visibility';
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";
import {FormatDateTime} from '../../providers/Utils/utils'
import Tooltip from '@material-ui/core/Tooltip';


import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from "clsx";
import CustomOrderToolbar from "../../components/CustomOrderToolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
// Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
Save:forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
Edit:forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
View:forwardRef((props, ref) => <ViewIcon {...props} ref={ref} />)
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    },
    centerAlign:{
        textAlign:'center'
    },
    rightAlign:{
        textAlign:'right'
    }
}));

const BaseTableOrders = (props) => {
    const [table, setTable] = useState({});
    const [data, setData] = useState([]);
    const [storeAdminId,setStoreAdminId] =useLocalStorage('GSYM_store_adminId');

    const handleViewOrder=(selectedRows,d)=>{
        console.log("main view",selectedRows);
        console.log("ff",selectedRows.data[0].dataIndex);
        // console.log(table.data(selectedRows.data[0].dataIndex));
        console.log(d);
        console.log(d[0].data[0]);
        // console.log(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
        history.push(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");
    }
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomOrderToolbar selectedRows={selectedRows} onRowView={()=>handleViewOrder(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });

    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.title}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    useEffect(() => {
        console.log("init");
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log("res server",res);
            // console.log("columns",res.columns);

            // let foundDate=res.columns.findIndex(element=>element.name=="date");
            // // console.log("found",foundDate);
            // if(foundDate!=-1){
            //     // console.log("yes",res.columns[foundDate]);
            //     res.columns[foundDate]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
            //         return (
            //             <div>
            //             {FormatDateTime(value)}
            //             </div>
            //     );
            //     }
            // }
            let foundStatus=res.columns.findIndex(element=>element.name=="status_id");
            if(foundStatus!=-1){
                res.columns[foundStatus]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {PedidoEstado(value)}
                        </div>
                );
                }
            }
            let foundFranja=res.columns.findIndex(element=>element.name=="hour_id");
            if(foundFranja!=-1){
                res.columns[foundFranja]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div className={classes.centerAlign}>
                        {PedidoFranja(value,"short")}
                </div>
                );
                }
            }
            let foundAmount=res.columns.findIndex(element=>element.name=="amount");
            if(foundAmount!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundAmount]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.rightAlign}>
                        {v}
                        </div>
                );
                }
            }
            let foundBasePrice=res.columns.findIndex(element=>element.name=="base_price");
            if(foundBasePrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundBasePrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.rightAlign}>
                        {v}
                        </div>
                );
                }
            }
            let foundPrice=res.columns.findIndex(element=>element.name=="price");
            if(foundPrice!=-1){
                // console.log("yes",res.columns[foundDate]);
                res.columns[foundPrice]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    // let v=new Intl.NumberFormat("es-ES", ).format(value);
                    return (
                        <div className={classes.rightAlign}>
                        {value>0 && <b>{FormatPrice(value)}</b>}
                        {value==0 && <span>{value}</span>}
                        </div>
                );
                }
            }
            console.log("columnas" ,res.columns);
            let foundOperatorId=res.columns.findIndex(element=>element.name=="operator_id");
            console.log(foundOperatorId);
            if(foundOperatorId!=-1){
                res.columns[foundOperatorId]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    if(value==null){
                        return (<div></div>);
                    }else{
                        if(value!=storeAdminId){
                            return (<div>Usuario</div>);

                        }else
                            return (<div>Tú</div>);
                    }
                }
            }
            let foundObservations=res.columns.findIndex(element=>element.name=="observations");
            if(foundObservations!=-1){
                res.columns[foundObservations]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {value && value.length>30 && <Tooltip placement="bottom" title={value}>
                        <span>{value.substring(1,30)+"..."}</span>
                        </Tooltip>}
                    {!value || value.length<=30 && <span>{value}</span>}
                    </div>
                    );
                }
            }
            let foundGasoil=res.columns.findIndex(element=>element.name=="product_id");
            if(foundGasoil!=""){
                res.columns[foundGasoil]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {GasoleoIcon(value)}
                        </div>
                );
                }
            }


            // res.columns[foundCurrent]["render"]=rowData =>{return (rowData.current_status=="0"?<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMxODdDMjM5M0EyNzExRUI5NjE1QkNDRUZFNzYzNERDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMxODdDMjNBM0EyNzExRUI5NjE1QkNDRUZFNzYzNERDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzE4N0MyMzczQTI3MTFFQjk2MTVCQ0NFRkU3NjM0REMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzE4N0MyMzgzQTI3MTFFQjk2MTVCQ0NFRkU3NjM0REMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7t/lB3AAAAxElEQVR42mL87+zKQADsAWIzMIuNlYHh2w8GhoMXgZzvQMwKU3MFiK3wGcLCQBhIAjEvATXShAxhIsKij0So+UANi6gCRqZF/4hQ859eFv0lpIAFmk+48KjRIcIiDSA+hkf+G8giZypEAcihloSC7jMd0sLn0Xw0ahGKRbx0sIcXlI9OEMiwKgTkQQBYGzLcIpRhLQkYchiIbQiouQHEhpTGESO9EgNV1IzmI5paxE+EGgFiKj5C4DkQyxJQ85SQIQABBgAzIhvaVi37+gAAAABJRU5ErkJggg=="/>:"On")}
            // let foundPlayerId=res.columns.findIndex(element=>element.field=="player_name");
            // if(foundPlayerId!=-1)
            //     res.columns[foundPlayerId]["render"]=rowData =><a href={"/player-details/"+rowData.player_id+"/view"} >{rowData.player_name}</a>
            // let foundAvatar=res.columns.findIndex(element=>element.field=="avatar");
            // if(foundAvatar!=-1)
            //     res.columns[foundAvatar]["render"]=rowData => <img src={rowData.avatar} style={{width: 30,height:30 , borderRadius: '50%'}}/>;
            // let foundImage=res.columns.findIndex(element=>element.field=="image");
            // if(foundImage!=-1)
            //     res.columns[foundImage]["render"]=rowData => <img src={rowData.image} style={{width: 30,height:30}}/>;
            //
            // let foundDownload=res.columns.findIndex(element=>element.field=="documentUrl");
            // if(foundDownload!=-1){
            //     let file=res.columns[foundDownload]["documentUrl"];
            //     res.columns[foundDownload]["render"]=rowData =>
            //         <IconButton aria-label="download" className={(rowData.documentUrl!=null?classes.margin:"is-hidden")} size="small" onClick={(e)=>{handleDownloadFile(e,rowData.documentUrl)}}>
            //             <DownloadIcon color="secondary" fontSize="inherit" />
            //         </IconButton>;
            // }
            setTable(res);
            setData(res.data);
            var tmp_actions=tableActions;
            if(res.allowNew!==false ){
                tmp_actions.push({
                    icon: tableIcons.Add,
                    tooltip: 'Add Element',
                    isFreeAction: true,
                    onClick: (event) => handleNew(event)
                });
            }
            if(res.allowView!==false){
                tmp_actions.push({
                    icon: tableIcons.View,
                    tooltip: 'View Element',
                    onClick: (event, rowData) => handleView(event,rowData)
                });
            }
            if( res.allowEdit!==false){
                tmp_actions.push({
                    icon: tableIcons.Edit,
                    tooltip: 'Edit Element',
                    onClick: (event, rowData) => handleEdit(event,rowData)
                });

            }
            if(res.allowDelete!==false){
                tmp_actions.push({
                    icon: tableIcons.Delete,
                    tooltip: 'Delete Element',
                    onClick: (event, rowData) => handleDelete(event,rowData)
                });

            }
            setTableActions(tableActions);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  selected={pageSelected} />
    <main className="page-main">
        <div className={classes.toolbar}/>
    <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
    <div className={(table.data==null?"circular-position":"is-hidden")}>
<CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
    title={t("pages."+props.title)}
    data={table.data}
    columns={table.columns}
    options={tableOptions}
    />
    {/*<MaterialTable icons={tableIcons}
            className={classes.materialtable}
            title=""
            columns={table.columns}
            data={table.data}
            options={{
                cellStyle:{
                    paddingTop:'5px',
                    paddingBottom:'5px',
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                },
                filtering:(table.filtering==false?false:true),
                sorting:true,
                exportButton:true,
                pageSize:storePagination["pageSize"],
                emptyRowsWhenPaging: storePagination["emptyRowsWhenPaging"],
                pageSizeOptions:storePagination["pageSizeOptions"]}}
                actions={tableActions}
               localization={storeLocalization}

    />*/}
</div>
    {/*<div className="page-footer">*/}
    {/*    <Footer/>*/}
    {/*    </div>*/}
</div>
    <div className={clsx(classes.contentBody,"page-footer")}>
<Footer/>
    </div>
    </main>
    </div>
);
}
export default BaseTableOrders;

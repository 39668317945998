import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import logo from '../res/GSYM-logo2.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Badge from '@material-ui/core/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { withStyles } from '@material-ui/core/styles';

// import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import useLocalStorage from "../providers/store/localStorage";
import history from "../providers/History/history";
import {Home} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Divider from "@material-ui/core/Divider";
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import MenuComponent from "./MenuComponent";
import ProfileIcon from '@material-ui/icons/AccountCircle';
import {useTranslation} from "react-i18next";
import {Call_Get} from "../providers/Utils/utils";
import {InputLabel, Select, Tooltip} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        marginLeft:"10px",
        "& svg": {
            color:'#ffffff',
        }
    },

    menuButtonMini:{
        margin:0,
        "& svg": {
            color:'#ffffff',
        }
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',

    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor:"#f0f0f0!important"
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        backgroundColor:"#f0f0f0", //#000000
        color:"#000000",
    },
    toolbar2:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbarTitle:{
        width:'300px',
        fontSize:"1rem",
        color:theme.palette.light.main,
        fontWeight:'400',
        paddingLeft:"20px"
    },
    toolbarTitleMini:{
        width:'100%',
        fontSize:"1rem",
        color:'#ffffff',
        fontWeight:'400'
    },
    darkColor:{
        color:theme.palette.light.main,
    },
    logo:{
        width:"40px",
    },
    black:{
        color:theme.palette.dark.main,
    },
    light:{
        color:theme.palette.light.main,
    },
    white:{
        color:"#ffffff",
    },
    column:{
        display:"inline-block",
        margin:"10px",
        verticalAlign:'middle'
    },
    full:{
        display:"inline-block"
    },
    small:{
        display:"none"
    },
    formControlWhite:{
        marginRight:"15px",
        minWidth: 120,
        "& *":{
            color:'#ffffff'
            },
        "& .MuiInput-underline:before":{
            borderColor:'#ffffff'
        },
        "& .MuiSelect-select.MuiSelect-select":{
            fontSize:18
        }
    },
}));
const AppBarComponent = (props) => {
    // const containerDrawer = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useLocalStorage(true);
    const [element,setElement] =useState(null);
    const [storeName] =useLocalStorage('GSYM_store_name');
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    // const [updateAlerts,setUpdateAlerts] =useLocalStorage('GSYM_store_update_alerts');
    const [alertsNumber,setAlertsNumber] =useLocalStorage('GSYM_store_alert_number');
    const [lastPage,setLastPage] =useLocalStorage("GSYM_store_last_page","");
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    const [baseValue,setBaseValue] =useLocalStorage('GSYM_store_base_selected');

    const matches = useMediaQuery('(min-width:800px)');
    const [t, i18n] = useTranslation('common');

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };
    const handleProfileClick=()=>{
        // console.log("profileclick");
        console.log(window.location.pathname);
        setLastPage(window.location.pathname);
        console.log("sip");
        history.push("/Config-profile");
    }
    const handleBaseChange=(e)=>{
        console.log("basechange",e);
        setBaseValue(e.target.value);
        window.location.reload();
    }
    const handleAlertClick=()=>{
        history.push("/Alerts");
    }
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };
    const [scrollPosition, setScrollPosition] =  useLocalStorage('list_scroll');
    const handleScroll = () => {
        // console.log("pepe");
        const position = window.pageYOffset;
        // console.log(position);
        setScrollPosition(position);
    };
    const resetScroll=()=>{
        setScrollPosition(0);
        window.scrollTo(0,0);
    }
    // const checkAlerts=()=>{
    //     console.log("check alerts");
    //     let url="alerts-check";
    //     Call_Get(url,storeToken).then((res)=> {
    //         // console.log(res);
    //         // console.log(res.item);
    //         setAlertsNumber(res.item);
    //         setUpdateAlerts(new Date().getTime());
    //         // console.log(res.item);
    //     });
    // }
    useEffect(() => {
        // console.log("pagina actual");
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[]);
    useEffect(() => {
        // var t = new Date().getTime();
        // if(!updateAlerts || t-updateAlerts>10000) {
        //     checkAlerts();
        // }
    },);
    useEffect(() => {
        // if(props.update){
        //     // console.log("cambia el update");
        //     checkAlerts();
        // }
    },[props]);
    const StyledBadge = withStyles((theme) => ({
        badge: {
            right: 5,
            top: 18,
            padding: '0 4px',
        },
    }))(Badge);
    return (
        <div>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpen && matches,[classes.appBarMobile]:  !matches
            })}
            >
                <Toolbar className={classes.toolbar2}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(matches?classes.menuButton:classes.menuButtonMini, {
                            [classes.hide]: drawerOpen,
                        })}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={matches?classes.toolbarTitle:classes.toolbarTitleMini}>
                       {t("pages."+props.selected.id)}
                    </Typography>
                    <div className="header-title">
                        <div className={classes.column}>
        {/* <StyledBadge badgeContent={alertsNumber} color="secondary">
                                <IconButton  aria-label="upload picture" className="logo-mini-toolbar" onClick={handleAlertClick}>
                                    <Tooltip title={t("buttons.alert-icon")} aria-label="comprueba tus alertas">
                                        <NotificationsActiveIcon className={classes.light}/>
                                    </Tooltip>
                                </IconButton>
                            </StyledBadge>*/}
                        </div>
                        <div className={clsx(classes.column,classes.verticalCenter)}>
                            <span className={(matches?classes.full:classes.small)}>{t("main.hello")} {(storeName?storeName:"")}</span>
                            {props.mode!="profile" && <IconButton  aria-label="upload picture" className="logo-mini-toolbar" onClick={handleProfileClick}>
                                <ProfileIcon className={classes.darkColor}/>
                            </IconButton>}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            {matches && <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: drawerOpen,
                        [classes.drawerClose]: !drawerOpen,
                    }),
                }}
            >
                <div className={classes.toolbar}><img className={classes.logo} src={logo}/><div className="portal-professional" >{process.env.REACT_APP_NAME}</div>
                    <IconButton onClick={handleDrawerClose} className={classes.black}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <MenuComponent permission={props.permission} expanded={drawerOpen} mode={props.mode} selected={props.selected} type={props.type}/>
            </Drawer>}
            {!matches && <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <MenuComponent expanded={drawerOpen} mode={props.mode} selected={props.selected} type={props.type}/>
            </SwipeableDrawer>}
        </div>
    );
}
export default AppBarComponent;


import React from 'react';
import CopyrightIcon from '@material-ui/icons/Copyright';
const Footer = () => {
    return(
        <div className="footer-father">
            <div className="footer-son">
                <CopyrightIcon fontSize="small" className="footer-copy"/> The Raccoon Lab - {new Date().getFullYear()}
            </div>
        </div>
    )
}

export default Footer;

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {Call_Get, CheckToken} from "../../providers/Utils/utils";
import useLocalStorage from "../../providers/store/localStorage";
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import StatsImage from '../../res/estadisticas.png';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from "react-i18next";
import {http_get, http_post,plain_post} from "../../providers/http/http";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Button} from "@material-ui/core";
import history from "../../providers/History/history";
import {Link} from "react-router-dom";
import clsx from "clsx";
import myTheme from "../../theme/theme";
import TableViewComponent from "../../components/TableViewComponent";
import OfficialPrices from "../../components/OfficialPrices";
import OfficialPrices2 from "../../components/OfficialPrices2";

import StatsComponent from "../../components/StatsComponent";
import BasesAccessComponent from "../../components/BasesAccessComponent";
import EditIcon from "@material-ui/icons/Edit";
import PopupNewInvoice from "../../components/PopupNewInvoice";
import PopupDevolucion from "../../components/PopupDevolucion";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:theme.palette.bodyBackground.main  //#000000
    },
    field:{
        height:"450px",
    },
    tab:{
        minWidth:"60px",
        backgroundColor:'#1b2982'
    },
    tabContent:{
        backgroundColor:'#808080!important',
        height:'400px'
    },
    table: {
        minWidth: 650,
    },
    tableBody:{

    },
    tablePaper:{
        height:'350px'
    },
    avatar:{
        width:'80px'
    },
    sectionBackground:{
        // backgroundColor:"#242518",
        marginBottom:"10px",
    },
    section:{
        // backgroundColor:'rgba(245,0,47,.5)',// '#f50057',
        paddingLeft:'10px',
        paddingRight:'10px',
        paddingTop:'10px',
        paddingBottom:'10px',
        width:'100%',
        height:'40px',
        // marginLeft:"10px",
        // marginRight:"10px"
    },
    sectionTitle:{
        borderBottom:"4px solid #1b2982",
        marginBottom:"10px"
    },
    sectionName:{
        textAlign:"left",
        display:"inline-block",
        width:"50%"
    },
    sectionLink:{
        textAlign:"right",
        display:"inline-block",
        width:"50%"
    },
    sectionItem:{
        // backgroundColor:"#383b44",
        // borderRadius:"5px",
        padding:"10px",
    },
    sectionItemLeft:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        marginRight:"5px"
    },
    sectionItemRight:{
        backgroundColor:"#383b44",
        padding:"10px",
        borderRadius:"5px",
        // marginLeft:"5px"
    },

    sectionLeft:{
        display:'inline-block',
        textAlign:'left',
        float:'left',
    },
    sectionRight:{
        display:'inline-block',
        textAlign:'right',
        float:'right'
    },
    link:{
        color:'#ffffff',
        textDecoration:'none',
        "&:hover": {
            textDecoration:'underline'
        }
    },
    notfound:{
        textAlign:'center'
    },
    avatarWidth:{
        width:'80px'
    },
    teamName:{
        fontSize:"24px",
        color:"#1b2982"
    },
    centerItem:{
        textAlign:"center"
    },
    bigScore:{
        display:"inline-block",
        padding:"10px 40px",
        margin:"10px"
    },
    scoreRed:{
        backgroundColor:"#bf0811"
    },
    scoreYellow:{
        backgroundColor:"#1b2982"
    },
    scoreGreen:{
        backgroundColor:"#62bf62"
    },
    scoreTitle:{
        color:"#ffffff",
        fontWeight:"bold",
        fontSize:"16px"
    },
    scoreDate:{
        color:"#ffffff",
    },
    scoreType:{
        color:"#ffffff",
    },
    scoreValue:{
        color:"#ffffff",
        fontWeight:"bold",
        fontSize:"24px"
    },
    teamInfo:{
        padding:"20px"
    },
    fieldTitle:{
        ...myTheme.fieldTitle
    },
    fieldValue:{
        ...myTheme.fieldValue
    },
    fieldRow: {
        ...myTheme.fieldRow
    },
    fieldField:{
        ...myTheme.fieldField
    },
    last5:{
        textAlign:"center",
        marginTop:"50px"
    },
    teamIcon:{
        float:"right",
        display:"inline-flex"
    },
    position:{
        display:"inline-flex",
    },
    positionCenter:{
        "&:before": {
            content:'""',
            display:"inline-block",
            height:"100%",
            verticalAlign:"middle",

        },
    },
    positionTitleContent:{
        display:"inline-block"
    },
    positionTitle:{
        textAlign:"right",
        color:myTheme.palette.titleFields.main,
        fontSize:"12px"

    },
    positionValue:{
        color:"#FFFFFF",
        textAlign:"center",
        fontWeight:"bold",
        fontSize:"28px"
    },
    positionImage:{
        display:"inline-block",
        paddingLeft:"20px",
        alignItems: "stretch",
    },
    textLink:{
        ...myTheme.textLink
    }

}));

function createData(player, completeName, skills ,  position) {
    return { player,completeName,skills,position};
}

const rows = [
    createData({"alias":"juanito","avatar":"https://dev-api.vixel.red/images/avatar_1.png"}, 'juanito valderrama',{"height":189,"weight":80},1),
    createData({"alias":"juanito","avatar":"https://dev-api.vixel.red/images/avatar_2.png"}, 'juanito valderrama',{"height":189,"weight":80},1),
    createData({"alias":"juanito","avatar":"https://dev-api.vixel.red/images/avatar_3.png"}, 'juanito valderrama',{"height":189,"weight":80},1),
    createData({"alias":"juanito","avatar":"https://dev-api.vixel.red/images/avatar_4.png"}, 'juanito valderrama',{"height":189,"weight":80},1),
    createData({"alias":"juanito","avatar":"https://dev-api.vixel.red/images/avatar_1.png"}, 'juanito valderrama',{"height":189,"weight":80},1),
];

export default function Dashboard() {
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    // const [storePermissions,setStorePermissions] =useLocalStorage('GSYM_store_permissions',null);
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer');
    const bc=[];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [tabValue,setTabValue] =useState(0);
    const [t, i18n] = useTranslation('common');
    const [playerWallets,setPlayerWallets] =useState([]);
    const [gk,setGK]=useState([]);
    const [df,setDF] =useState([]);
    const [mf,setMF] =useState([]);
    const [fw,setFW] =useState([]);
    const [professionalType,setProfessionalType]=useState(null);
    const [contracts,setContracts] = useState([]);
    const [notifications,setNotifications] =useState([]);
    const [coachWallets,setCoachWallets] =useState([]);
    const matches = useMediaQuery('(min-width:800px)');
    const [prices,setPrices] =useState(null);
    const [storeBaseValue,setStoreBaseValue] =useLocalStorage('GSYM_store_base_selected',1);
    const [storeBases,setStoreBases] =useLocalStorage('GSYM_store_bases');
    const [dataStats,setDataStat] =useState([]);
    const [dataStats2,setDataStat2] =useState([]);
    const [dataStats3,setDataStat3] =useState([]);
    const [dataStats4,setDataStat4] =useState([]);
    const [popupDevolucion,setPopupDevolucion] =useState(false);
    const [popupDevolucionData,setPopupDevolucionData] =useState(null);

    const [baseSelectedText,setBaseSelectedText] =useState(null);
    const [statGeneral2,setStatGeneral2] =useState([
        {"id":1,"name":"Clientes APP","value":150,"stroke":"#1a2b3c","fill":"#636d79","data":dataStats},
        {"id":2,"name":"Solicitudes","value":85,"stroke":"#02d6f1","fill":"#00e1f4","data":dataStats2},
        {"id":3,"name":"Pedidos APP","value":85,"stroke":"#ff7c72","fill":"#ff5e51","data":dataStats3},
        {"id":4,"name":"T.M. Atención","value":"2min","stroke":"#ff9535","fill":"#ffb16f","data":dataStats4}]);
    const [statGeneral,setStatGeneral] =useState([]);

    const [statBase,setStatBase] =useState(
        [{"id":1,"name":"Clientes APP","value":150,"stroke":"#1a2b3c","fill":"#636d79","data":dataStats},
            {"id":2,"name":"Solicitudes","value":85,"stroke":"#02d6f1","fill":"#00e1f4","data":dataStats3},
            {"id":3,"name":"Pedidos APP","value":85,"stroke":"#ff7c72","fill":"#ff5e51","data":dataStats2},
            {"id":4,"name":"T.M. Atención","value":"2min","stroke":"#ff9535","fill":"#ffb16f","data":dataStats4}])
    const [dataBases,setDataBases] =useState(null);
    const [pricesList,setPricesList] =useState(null);
    // const [pedidosHoy,setPedidosHoy] =useState([{"id":1,"name":"Solicitudes","value":38,"stroke":"#1b2982","fill":"#616fcc","data":dataStats},{"id":2,"name":"Sin cotizar","value":8,"stroke":"#bf0811","fill":"#da5a60","data":dataStats4},{"id":3,"name":"Cotizados","value":30,"stroke":"#62bf62","fill":"#a2c4a2","data":dataStats3},{"id":4,"name":"Convertidos","value":10,"stroke":"#b85c09","fill":"#d3965f","data":dataStats2}])
    const [pedidosHoy,setPedidosHoy] =useState(null);
    const [addressesNotVerified,setAddressesNotVerified] =useState(null);

    const [direccionesCliente,setDireccionesCliente] =useState([{"id":1,"name":"Sin cotizar","value":8,"stroke":"#1b2982","fill":"#616fcc","data":dataStats}])
    const handleTabChange=(e,newValue)=>{
        setTabValue(newValue);
    }
    const handleDetail=(e,id)=>{
        console.log("detalle player",id);
        history.push("player-details/"+id+"/view");
    }
    const handleDetailCoach=(e)=>{
        console.log("dtalle coach");
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleCloseDevolucion=()=>{
        setPopupDevolucion(false);
    }
useEffect(() => {
    // console.log("1111");
    let current=window.location.pathname;
    let result=CheckToken(storeToken,"logged");
    if(result==false) {
        setStoreReferer(current);
    }
        setPageSelected({"id":"dashboard","title":"dashboard"});
        Call_Get('dashboard/',storeToken).then((res)=> {
            // console.log("dashboard");
            console.log(res);
            if(res.status=="ok"){
                if(res.bases){
                    setDataBases(res.bases);
                }
                if(res.stats && res.stats.all){
                    console.log(res.stats.all);
                    console.log(statGeneral2);
                    setStatGeneral(res.stats.all);
                }
                if(res.stats && res.stats.pedidosHoy){
                    setPedidosHoy(res.stats.pedidosHoy);
                }
                if(res.stats && res.stats.addressesNotVerified){
                    setAddressesNotVerified(res.stats.addressesNotVerified);
                }
                console.log("pricesss",res.pricesList);
                    setPricesList(res.pricesList);
                if(res.prices) {
                    setPrices(res.prices);
                    // setPricesA({"a1": res.prices.a1, "a2": res.prices.a2, "a3": res.prices.a3,"a4":res.prices.a4});
                    // setPricesB({"b1": res.prices.b1, "b2": res.prices.b2, "b3": res.prices.b3,"b4":res.prices.b4});
                    // setPricesC({"c1": res.prices.c1, "c2": res.prices.c2, "c3": res.prices.c3,"c4":res.prices.c4});
                }
            }
            // else{
            //     if(res.redirect) {
            //         if (res.redirect == "not-verified")
            //             history.push("/verification");
            //     }else if(res.redirect=="disabled")
            //         history.push("/user-problem");
            // }
            // if(storeBaseValue==-1){
            //     setBaseSelectedText(null);
            // }else {
            //     let foundProducts = storeBases.find(element => element.id == storeBaseValue);
            //     // console.log(foundProducts);
            //     setBaseSelectedText(foundProducts.name.toUpperCase());
            // }

        }).catch(err=>{
            console.log(err);
        });

    },[]);
    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
                <div className={classes.contentBody}>
                    <BreadCrumbComponent data={bc}/>
                    <Grid container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>ALMACENES CON ACCESO</div></div>
                                <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                    {dataBases && <BasesAccessComponent  data={dataBases} />}
                                </div>
                            </Grid>
                            {pricesList && pricesList.map((el)=>{
                                return(
                                <Grid key={Math.random()} item md={6} sm={12} xs={12} className={classes.sectionBackground}>
                                    <OfficialPrices2 name={el.name} prices={el.data} />
                                </Grid>)
                            })}
                                {/*{prices && <OfficialPrices2 prices={prices} />}*/}
                                    {/*-- ESTADISTICAS --*/}
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>ESTADÍSTICAS DE TODOS LOS ALMACENES</div></div>
                                    <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                        <StatsComponent serie="num" elements={statGeneral} data={dataStats} />
                                </div>
                            </Grid>
                            {/*-- PEDIDOS --*/}
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>PEDIDOS HOY</div><div className={classes.sectionLink}><Link className={classes.textLink}to="/wallet-players">Ver pedidos</Link></div></div>
                            <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                <StatsComponent serie="num" elements={pedidosHoy}  />
                                </div>
                                </Grid>
                            {/*-- DIRECCIONES SIN VERIFICAR --*/}
                            <Grid item md={12} xs={12} className={classes.sectionBackground}>
                                <div className={classes.sectionTitle}><div className={classes.sectionName}>DIRECCIONES DE CLIENTES SIN VERIFICAR</div><div className={classes.sectionLink}><Link className={classes.textLink}to="/nursing-revisions">Ver clientes</Link></div></div>
                                    <div className={clsx(classes.sectionItem,classes.centerItem)}>
                                        <StatsComponent serie="num" elements={addressesNotVerified}  />
                                    </div>
                            </Grid>
                        </Grid>

                    </Grid>
                                    <PopupDevolucion  open={popupDevolucion} data={popupDevolucionData} onClose={handleCloseDevolucion} />
                </div>
                <div className="page-footer">
                   <Footer/>
                </div>
            </div>
            </main>
    </div>
);
}



import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,GetProducts} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from "react-i18next";
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";
import MapIcon from '@material-ui/icons/Room';
import clsx from "clsx";
import CustomAddressesToolbar from "../../components/CustomAddressesToolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },

}));

const BaseTableBillingAddresses = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push(pageSelected.id+"/"+rowData.id+"/edit");
    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push(props.id+"/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push(props.id+"/"+rowData.id+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');

    const bc=[{"id":props.id,"title":props.title}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'none',
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomAddressesToolbar mode="not-verified" selectedRows={selectedRows} onRowVerifyAddress={()=>handleVerifyAddress(selectedRows,data)} onRowLocation={()=>handleLocationAddress(selectedRows,data)}/>
        );
        },
        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const [t, i18n] = useTranslation('common');
    const handleLocationAddress=(selectedRows,d)=>{
        // console.log(table.data(selectedRows.data[0].dataIndex));
        let direction=d[selectedRows.data[0].dataIndex].data[4];
        let cp=d[selectedRows.data[0].dataIndex].data[5];
        console.log("direccion",d[selectedRows.data[0].dataIndex].data[4]);
        window.open('https://maps.google.com/?q='+direction +","+cp, '_blank');
        console.log("edit location");
    }
    const handleVerifyAddress=()=>{
        console.log("verify address");
    }
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const manualColumns = ["Name", "Company", "City", "State"];

    const manualData = [
        ["Joe James", "Test Corp", "Yonkers", "NY"],
        ["John Walsh", "Test Corp", "Hartford", "CT"],
        ["Bob Herm", "Test Corp", "Tampa", "FL"],
        ["James Houston", "Test Corp", "Dallas", "TX"],
    ];
    const handleRowClick=(row)=>{
        // console.log("click",row);
        history.push(props.id+"/"+row[0]+"/view");

    }
    useEffect(() => {
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            console.log(res);
            let foundProducts=res.columns.findIndex(element=>element.name=="favorite_product_id");
            if(foundProducts!=-1){
                res.columns[foundProducts]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {GetProducts(value)}
                        </div>
                );
                }
            }
            let foundGPS=res.columns.findIndex(element=>element.name=="gps_position");
            if(foundGPS!=-1){
                res.columns[foundGPS]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    if(value){
                        return (
                            <div>
                            <a target="_blank" href={"https://maps.google.com/?q="+value}><MapIcon/> Ver en mapa</a>
                            </div>
                        );
                    }
                }
            }
            setTable(res);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });


    },[]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
    <div className={classes.contentBody}>
        <BreadCrumbComponent data={bc}/>
        <div className={(table.data==null?"circular-position":"is-hidden")}>
            <CircularProgress disableShrink className="is-loading"/>
        </div>

        <MUIDataTable
            title={"Direcciones de facturación"}
            data={table.data}
            columns={table.columns}
            options={tableOptions}
            />
            </div>
        </div>
            <div className={clsx(classes.contentBody,"page-footer")}>
                <Footer/>
            </div>
        </main>
        </div>
);
}
export default BaseTableBillingAddresses;
import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import { Button } from '@material-ui/core';
//Providers
import history from "../providers/History/history";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px'
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
        },
    },
    formFields:{
        margin: theme.spacing(1),
        width: '100%',
    },
    picker:{
        margin: theme.spacing(1),
        width: '100%',
    },
}));
const FieldDate = (props) => {
    const classes = useStyles();
    // const [selectedDate, handleDateChange] = useState(new Date());
    const [value,setValue]=useState(null);
    const handleChange=(e)=>{
        var date=new Date(e);
        var systemDateTime=toSystemDate(date);
        props.onSetValue({"item":props.item.field,"value":systemDateTime});
        setValue(e);
    }
    const formatDate=(d)=>{
        var date=new Date((props.record?props.record:""));
        var formattedDate = format(date, "dd/MM/yyyy");
        return formattedDate;
    }

    if(props.action=="view"){
        let rel="";
        return(
            <div id={props.item.field}>
                <div className="field-view-title" >{props.item.title}</div>
                <div className="field-view-value">{(props.record?formatDate(props.record):"-")}</div>
            </div>
        );
    }else if(props.action=="edit"){
        return(
            <div>
            <div className="field-edit-title" >{props.item.title}</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.picker} locale={esLocale}>
                <DateTimePicker
                    value={(value==null?props.record:value)}
                    onChange={handleChange} format="dd/MM-yyyy H:mm" />
        </MuiPickersUtilsProvider>
        </div>
        );
    }
    function toSystemDate(date){
        return date.getFullYear()+"-"+(ceroString(date.getMonth()+1))+"-"+date.getDate()+" "+ceroString(date.getHours())+":"+ceroString((date.getMinutes())+":"+ceroString(date.getSeconds()));
    }
    function ceroString(val){
        if(parseInt(val) <10){
            return "0"+val.toString();
        }else{
            return val;
        }
    }

}

export default FieldDate;
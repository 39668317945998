import React, {useEffect,useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from '@material-ui/core';

import AddIcon from "@material-ui/icons/Add";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import AppBarComponent from "./AppBarComponent";
import EditMapIcon from '@material-ui/icons/EditLocation';
import CheckIcon from '@material-ui/icons/Check';
import VisibleIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    margin10: {
        marginLeft:10,
        marginRight:10
    }
}));
const CustomPaymentsToolbar = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();
    const GreenButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#27ff27",
            '&:hover': {
                backgroundColor: "#048804",
            },
        },
    }))(Button);
    const OrangeButton = withStyles((theme) => ({
        root: {
            color: "#ffffff",
            backgroundColor: "#ff5800",
            '&:hover': {
                backgroundColor: "#bb5f00",
            },
        },
    }))(Button);
    useEffect(() => {
        console.log("custom order toolbar");
        setSelected(props.selectedRows);
        console.log(props.selectedRows);
        // console.log(containerDrawer);
        // var chil=containerDrawer.current.children[0];
        // console.log(chil);
        // containerDrawer.current.addEventListener('scroll', handleScroll, { passive: true });
        // console.log("scroll "+scrollPosition);
        // return () => {
        //     containerDrawer.current.removeEventListener('scroll', handleScroll);
        // };
    },[props.data]);
    const handleClickHide=()=>{
        // console.log("quitar los almacenes de estos codigos postales");
        props.onRowPaymentHide(props.selectedRows)
    }
    const handleClickShow=()=>{
        // console.log("Asignar almacen");
        props.onRowPaymentShow(props.selectedRows);
        // props.onRowView("value");
    }
    // const { classes } = props;

        return (
            <div>
                {/*props.mode=="not-verified" && <div><Tooltip className={classes.margin10} title={"Habilitar la forma de pago en la app"}>
                    <OrangeButton
                        onClick={handleClickShow}
                        variant="contained"
                        color="default"
                        startIcon={<VisibleIcon />}
                        >
                    Mostrar
                    </OrangeButton>
                </Tooltip>
                <Tooltip title={"Ocultar la f. de pago en la app"}>
                    <GreenButton
                        onClick={handleClickHide}
                        variant="contained"
                        color="default"
                        startIcon={<HideIcon />}
                    >
                        Ocultar
                    </GreenButton>
                </Tooltip></div>
                */}

        </div>
        );

}
export default CustomPaymentsToolbar;
import React, {Fragment,forwardRef, useEffect, useState} from 'react';
//Componentes
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

}));

const FieldRecordCheckbox = (props) => {
    const classes = useStyles();
    const [myValue,setMyValue]=useState(null);

    const handleChange=(e)=>{
        console.log(e.target.checked);
        setMyValue(e.target.checked);
        props.onSetValue({"item":props.field,"value":e.target.checked});
    }
    const CustomCheckbox = withStyles({
        root: {
            color: "#e5011e",
            '&$checked': {
                color: "#e5011e",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);
    let rel="";
    useEffect(() => {
        if(myValue==null){
            if(props.value)
                setMyValue((props.value?true:false));
        }

    },[props]);
    return(
        <div>
            {props.action && (props.action=="view" || props.allowEdit=="false" || props.allowNew=="false") && <div id={props.field}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={myValue}
                                onChange={handleChange}
                                name={props.field}
                                disabled
                            />

                        }
                        label={props.title}
                    />
                    {myValue==false && !props.value && <FormHelperText>{(props.description?props.description:"")}</FormHelperText>}
                </FormGroup>
                {/*<div className="field-view-title" >{props.title}</div>*/}
                {/*<div className={(rel==""?"field-view-value":"is-hidden")}>{(props.value?props.value:"(not defined)")}</div>*/}
            </div>}
            {props.action && props.action=="edit" && (props.allowEdit==undefined || props.allowEdit=="true") &&
            <FormGroup>
                <FormControlLabel
                    control={
                        <CustomCheckbox
                            checked={myValue}
                            onChange={handleChange}
                            name={props.field}
                        />

                    }
                    label={props.title}
                />
                {myValue==false && <FormHelperText>{(props.description?props.description:"")}</FormHelperText>}
            </FormGroup>
            }
            {props.action && props.action=="new" && (props.allowNew=="true" || props.allowNew==undefined) &&

                <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={myValue}
                                onChange={handleChange}
                                name={props.field}
                            />

                        }
                        label={props.title}
                    />
                    {myValue==false && <FormHelperText>{(props.description?props.description:"")}</FormHelperText>}
                </FormGroup>

                }

        </div>
    );
}

export default FieldRecordCheckbox;
import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
//pages
const useStyles = makeStyles((theme) => ({
    formControl:{
        width:'100%',
        marginTop:20,
        marginBottom:20
    }
}));
const Evento_Pedido_Cambio_Estado = (props) => {
    const classes = useStyles();
    const [name] =useState("Evento Estado Pedido");
    const [value,setValue] =useState(null);
    const [statusSelected,setStatusSelected] =useState(null);
    const [statusTextSelected,setStatusTextSelected] =useState(null);
    const [statuses,setStatuses] =useState([
        {id:1,name:"Inicio"},
        {id:2,name:"Precio Solicitado"},
        {id:3,name:"Precio Confirmado"},
        {id:4,name:"Pagado"},
        {id:5,name:"En transito"},
        {id:6,name:"Entregado"},
        {id:7,name:"Cancelado"},
        {id:8,name:"Navision"},
        {id:9,name:"Tramitado"},
        // {id:10,name:"Enrutado"},
    ]);
    const handleValue=(e)=>{
        setValue(e.target.value);
    }
    const handleSave=()=>{
        console.log("apclicamos evento",props.id,name);
        props.onSetValue(props.id,name,statusSelected,"Cambio de estado de pedido="+statusTextSelected);
    }
    const handleStatuses=(e)=>{
        setStatusSelected(e.target.value);
        let found=statuses.find(element=>element.id==e.target.value);
        setStatusTextSelected(found.name);
    }
    return(
        <div className="father-header-title">
            <div className="">
                Evento Pedido Cambio Estado
            </div>
            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                        Estado
                    </InputLabel>
                    <Select
                        value={statusSelected}
                        onChange={handleStatuses}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Estado' }}
                    >
                        {statuses && statuses.map((el)=>{
                            return (
                                <MenuItem value={el.id}>{el.name}</MenuItem>
                        )
                        })}
                    </Select>
                    <FormHelperText>Estado de pedido</FormHelperText>
                </FormControl>
            </div>
            <div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    startIcon={<EditIcon />}
                    onClick={handleSave}
                    >
                    Aplicar
                </Button>
            </div>
        </div>
)
}

export default Evento_Pedido_Cambio_Estado;
import React, {useEffect,useState} from "react";
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {AreaChart, Area, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, ResponsiveContainer} from "recharts";
import clsx from "clsx";
import history from "../providers/History/history";

const defaultToolbarStyles = {
    iconButton: {
    },
};
const useStyles = makeStyles((theme) => ({
    paperWidth:{
        width:'160px',
        height:'190px',
        margin:'auto'

    },
    gridStyle:{
        flex:1,
        // flexGrow: 1,
        width:'100%',
        alignItems:'center',
        justifyContent:"space-between",
        margin:'auto',
        "& .MuiPaper-rounded":{
            borderRadius:'15px',
            overflow:'hidden'
        }
    },
    itemGrid:{
        width:'fit-content',
        margin:'auto',
    },
    center:{
        textAlign:'center'
    },
    paperTitle:{
        paddingTop:20,
        fontSize:16,
        fontWeight:'bold'
    },
    paperValue:{
        fontSize:34,
        fontWeight:'bold',
        paddingLeft:20,
        textAlign:'left'
    },
    paperValueBig:{
        paddingTop:20,
        fontSize:40,
        fontWeight:'bold',
        textAlign:'center'
    },
    redColor:{
        color:"#ff2727"
    },
    link:{
        cursor:"pointer"
    }
}));
const StatsComponent = (props) => {
    const [selected,setSelected] =useState([]);
    const classes = useStyles();


    useEffect(() => {
        console.log("stats component");
    },[props.data]);
    const handleClickAdd = () => {
        console.log("clicked on icon!");
    }
    const handleClickElement=(item)=>{
        if(item.link){
            history.push(item.link);
        }
    }

        return (
            <div className={classes.center}>
                <Grid container className={classes.gridStyle} spacing={5} direction="row"  >

        {props.elements && props.elements.map((item)=>{
            return (
                <Grid key={Math.random()} item xs={6} md={2} className={clsx(classes.itemGrid,(item.link?classes.link:""))} onClick={()=>{handleClickElement(item)}}>
                    <Paper className={classes.paperWidth}>
                    <div className={classes.paperTitle}>{item.name}</div>
                    <div className={clsx((props.data?classes.paperValue:classes.paperValueBig),(item.color=='red'?classes.redColor:""))}>{item.value}</div>
                {props.data && <AreaChart
                        width={180}
                        height={100}
                        data={(item.data?item.data:[])}
                        margin={{ top: 0, right: 0, left: -5, bottom: -15 }}
                    >
                        <CartesianGrid strokeDasharray="0 3" />
                            <XAxis hide={true} axisLine={false } dataKey="name" />
                            <YAxis hide={true} axisLine={false} />
                        <Tooltip cursor={false} />
                        <Area type="monotone" dataKey={props.serie} stroke={(item.stroke?item.stroke:"#243444")} fill={(item.fill?item.fill:"#636d79")} strokeWidth={3}  />
                    </AreaChart>}
                </Paper>
                </Grid>
            )
            })}
                </Grid>
            </div>
        );

}
export default StatsComponent;
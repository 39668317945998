

import React, {Fragment,forwardRef, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Footer from '../../components/Footer';
import AppBarComponent from '../../components/AppBarComponent';
import useLocalStorage from "../../providers/store/localStorage";
import history from "../../providers/History/history";
import BreadCrumbComponent from "../../components/BreadCrumbComponent";
import {http_get,http_post,http_download} from "../../providers/http/http";
import {Call_Get, CheckToken,ClienteEstado} from "../../providers/Utils/utils";
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';
import {useTranslation} from "react-i18next";
import {Button, Dialog} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import fileDownload from 'js-file-download';
import MUIDataTable from "mui-datatables";

import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from "clsx";
import CustomClientsToolbar from "../../components/CustomClientsToolbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CustomToolbar from "../../components/CustomToolbar";
import PublicPopup from "../../components/PublicPopup";
import ManageTableComponent from "../../components/ManageTableComponent";
import CustomDeleteToolbar from "../../components/CustomDeleteToolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height:'100%',
        "& tr:not(.MuiTableRow-head)": {
            height:'60px',
            cursor:'pointer'
        },

    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    contentBody:{
        padding: theme.spacing(3),
        paddingBottom:'50px',
        backgroundColor:"#f6f7f9"
    },
    materialtable:{
        backgroundColor:"#ff0000"
    }

}));

const BaseTableManage = (props) => {
    const handleDelete=(e,rowData)=>{
        console.log("vamos a eliminar el id="+rowData.id);
    }
    const handleEdit=(e,rowData)=>{
        console.log("edit");
        console.log(rowData.id);
        history.push("clients/"+rowData.id+"/edit");
    }
    const handleNewManage=()=>{
        console.log("new manage");
        // setVisiblePublicPopup(true);
        history.push("/marketing-manage/new");

    }
    const handleNew=(e)=>{
        console.log("new");
        console.log(props.id);
        history.push("clients/new");
    }
    const handleView=(e,rowData)=>{
        console.log("View");
        console.log(pageSelected.id+"/"+rowData.id+"/view");
        history.push("marketing-manage/"+rowData.id+"/view");
    }

    const handleBannedClient=(selectedRows,d)=>{
        // console.log("main view",selectedRows);
        console.log("Baneamos al cliente si popup ok el");
        // console.log(table.data(selectedRows.data[0].dataIndex));
        console.log("id a banear",d[selectedRows.data[0].dataIndex].data[0]);
        console.log("id a banear",d[selectedRows.data[0].dataIndex].data[1]);
        setResendEmail(d[selectedRows.data[0].dataIndex].data[1]);
        setResendPopup(true);
        // history.push(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
    }
    const handleRowClick=(row)=>{
        console.log("click",row);
        history.push("marketing-manage/"+row[0]+"/view");
    }
    const [table, setTable] = useState({});
    const classes = useStyles();
    const [pageSelected,setPageSelected] = useLocalStorage("GSYM-page-selected","dashboard");
    const [storeReferer,setStoreReferer] =useLocalStorage('GSYM_store_referer',null);
    const [storeLocalization,setStoreLocalization] =useLocalStorage('GSYM_store_localization');
    const [visiblePublicPopup,setVisiblePublicPopup] = useState(false);

    const bc=[{"id":props.id,"title":props.id}];
    const [storeToken,setStoreToken] = useLocalStorage('GSYM_store_token');
    const [storePagination,setStorePagination] = useLocalStorage('GSYM_store_pagination');
    const [tableActions,setTableActions]=useState([
    ]);
    const [resendPopup,setResendPopup] =useState(false);
    const [resendEmail,setResendEmail] =useState(null);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("500px");
    const [tableOptions,setTableOptions] =useState({filter: true,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        selectableRows: 'single',
        customToolbar: () => {
            return (
                <CustomToolbar onNewClick={handleNewManage} />
        );
        },
        // onRowsSelect:(currentRowsSelected, allRowsSelected) => {console.log(allRowsSelected);},

        customToolbarSelect: (selectedRows,data) => {
            return (
                <CustomDeleteToolbar selectedRows={selectedRows} onRowDeleted={()=>handleDeletedElement(selectedRows,data)}/>
        );
        },

        textLabels: {
            body: {
                noMatch: "No hay registros encontrados",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Página siguiente",
                previous: "Página anterior",
                rowsPerPage: "Registros por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todos",
                title: "FILTROS",
                reset: "LIMPIAR",
            },
            viewColumns: {
                title: "Mostrar columnas",
                titleAria: "Mostrar/Ocultar columnas",
            },
            selectedRows: {
                text: "registro(s) seleccionados",
                delete: "Eliminar",
                deleteAria: "Eliminar registros seleccionados",
            },

        },
        onRowClick: rowData => handleRowClick(rowData)
        // tableBodyMaxHeight
    });
    const handleDeletedElement=(selectedRows,d)=>{
        // console.log("main view",selectedRows);
        console.log("Eliminamos el elemento");
        // console.log(table.data(selectedRows.data[0].dataIndex));
        console.log("id a eliminar",d[selectedRows.data[0].dataIndex].data[0]);
        console.log("id a eliminar",d[selectedRows.data[0].dataIndex].data[1]);
        let postData={id:d[selectedRows.data[0].dataIndex].data[0]};
        http_post(props.id+"-delete",postData,storeToken).then((res)=> {
            if(res.status=="ok"){
                // alert("data saved successfully");
                console.log(res);
                window.location.reload();
            }else
                alert("There´s been an error during save action");
        });
        // setResendEmail(d[selectedRows.data[0].dataIndex].data[1]);
        // setResendPopup(true);
        // history.push(props.id+"/"+d[selectedRows.data[0].dataIndex].data[0]+"/view");
    }
    const [t, i18n] = useTranslation('common');
    const handleDownloadFile=(e,url)=>{
        let path=process.env.REACT_APP_API_URL+process.env.REACT_APP_API_PREFIX+"file/"+url;
        http_download("file/"+url,storeToken).then((res)=> {
            fileDownload(res, url.replace("--","."));
        });
    }
    const handleChangeValuePopup=(e)=>{
        console.log("value",e);
    }
    const handleClosePublicPopup=()=>{
        setVisiblePublicPopup(false);
    }
    const handleOnSetValue=(e)=>{
        console.log("value final",e);
        setVisiblePublicPopup(false);
    }

    const handleSubmit=()=>{
        console.log("sumbit form");
    }
    const handleResendEmail=(e)=>{
        console.log("resend email");
        setResendEmail(e.target.value);
    }
    const handleCloseResendPopup=()=>{
        console.log("close popup");
        setResendPopup(false);
    }
    useEffect(() => {
        console.log("tabla manage");
        setTableActions([]);
        setPageSelected({"id":props.id,"title":props.title});
        let current=window.location.pathname;
        let result=CheckToken(storeToken,"logged");
        if(result==false) {
            setStoreReferer(current);
        }
        Call_Get(props.id,storeToken).then((res)=> {
            let foundStatus=res.columns.findIndex(element=>element.name=="status_id");
            if(foundStatus!=-1){
                res.columns[foundStatus]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                        {ClienteEstado(value)}
                        </div>
                    );
                }
            }
            let foundValue=res.columns.findIndex(element=>element.name=="value");
            if(foundValue!=-1){
                res.columns[foundValue]["options"]["customBodyRender"]=(value, tableMeta, updateValue) =>{
                    return (
                        <div>
                            <ManageTableComponent value={value}/>
                        </div>
                );
                }
            }
            setTable(res);
        }).catch(err=>{
            setStoreToken(null);
            history.push("/");
            console.log(err);
        });
    },[props.id]);

    return (
        <div className={classes.root}>
        <CssBaseline />
        <AppBarComponent  selected={pageSelected} />
        <main className="page-main">
            <div className={classes.toolbar}/>
            <div>
                <div className={classes.contentBody}>
                    <BreadCrumbComponent data={bc}/>
                    <div className={(table.data==null?"circular-position":"is-hidden")}>
                        <CircularProgress disableShrink className="is-loading"/>
                    </div>

                    <MUIDataTable
                        title={t("pages."+props.title)}
                        data={table.data}
                        columns={table.columns}
                        options={tableOptions}
                        />
                </div>
            </div>
            <div className={clsx(classes.contentBody,"page-footer")}>
                <Footer/>
            </div>

            <PublicPopup open={visiblePublicPopup} onChange={handleChangeValuePopup} onClose={handleClosePublicPopup} onSetValue={handleOnSetValue}/>
        </main>
        </div>
);
}
export default BaseTableManage;